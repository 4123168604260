// external lib dependencies
import { Paper, List, Fade } from "@material-ui/core";

// absolute path dependencies
import useAccordionStore from "hooks/useAccordionStore";
import { Survey } from "config/types";

// local dependencies
import SmallTableRow from "./SmallTableRow";
import { AuditTableProps } from "./types";
import useStyles from "./styles";

const AuditsSmallTable = ({ surveys, onDownload, setActiveSurvey, activeSurvey }: AuditTableProps) => {
  const { state, handleStateUpdates } = useAccordionStore(surveys);

  const classes = useStyles();

  return (
    <Fade in>
      <Paper elevation={0} className={classes.smallTablePaper}>
        <List>
          {surveys.map((survey: Survey, index) => (
            <SmallTableRow
              key={index}
              isExpanded={state[`row${index}`]}
              handleExpand={handleStateUpdates(index)}
              onDownloadClick={onDownload}
              survey={survey}
              setActiveSurvey={setActiveSurvey}
              activeSurvey={activeSurvey}
            />
          ))}
        </List>
      </Paper>
    </Fade>
  );
};

export default AuditsSmallTable;
