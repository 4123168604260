// absolute path dependencies
import EngagementListSmall from "pages/EngagementList/EngagementListSmall";
import EngagementList from "pages/EngagementList";
import NoEngagements from "pages/NoEngagements";
import FilesSharing from "pages/FilesSharing";
import PageNotFound from "pages/PageNotFound";
import WorkOrders from "pages/WorkOrders";
import Team from "pages/Team";
import SubmitBug from "pages/SubmitBug";
import Feedback from "pages/Feedback";
import Audit from "pages/Audit";
import ViewFeedback from "pages/Feedback/ViewFeedback";
import { DrawerDisplayType } from "config/types";
import MemberProfile from "pages/MemberProfile/MemberProfile";
import Notification from "pages/Notification/Notification";
import StakeholdersPage from "pages/Stakeholders/StakeholdersPage";
import ReleaseNotesPage from "pages/ReleaseNotes/ReleaseNotesPage";
import RequestTeamPage from "pages/RequestTeam/RequestTeamPage";
import PortfolioDirector from "pages/MemberProfile/PortfolioDirector";
import PeopleFeedback from "pages/PeopleFeedback/PeopleFeedback";

interface AppRoute {
  name: string;
  path: string;
  exact: boolean;
  navigation?: {
    displayDrawer: boolean;
    drawerDisplayMode?: DrawerDisplayType;
    collapsedList?: React.ElementType;
  };
  component: () => JSX.Element | null;
}

export const allRoutes: AppRoute[] = [
  {
    name: "Engagements",
    path: "/engagements",
    exact: true,
    component: EngagementList,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
  },

  {
    name: "Audit",
    path: "/engagements/:engagementId/audits/:auditId",
    exact: false,
    component: Audit,
  },

  {
    name: "No engagements",
    path: "/no-engagements",
    exact: false,
    component: NoEngagements,
  },

  {
    name: "Your Team",
    path: "/engagements/:id/team",
    exact: true,
    component: Team,
  },

  {
    name: "Stakeholders",
    path: "/engagements/:id/stakeholders",
    exact: true,
    component: StakeholdersPage,
  },

  {
    name: "Work Orders",
    path: "/work-orders",
    exact: true,
    component: WorkOrders,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
  },
  {
    name: "Service Feedback",
    path: "/view-feedback",
    exact: true,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
    component: ViewFeedback,
  },
  {
    name: "People Feedback",
    path: "/people-feedback",
    exact: true,
    component: PeopleFeedback,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
  },
  {
    name: "Feedback",
    path: "/view-feedback/:id",
    exact: true,
    component: ViewFeedback,
  },
  {
    name: "Provide Feedback",
    path: "/feedback",
    exact: true,
    component: Feedback,
  },
  {
    name: "Provide Feedback",
    path: "/feedback/:id",
    exact: true,
    component: Feedback,
  },
  {
    name: "Notifications",
    path: "/notification",
    exact: true,
    component: Notification,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
  },
  {
    name: "What's new?",
    path: "/release-notes",
    exact: true,
    component: ReleaseNotesPage,
    navigation: {
      displayDrawer: true,
      drawerDisplayMode: "normal",
    },
  },
  {
    name: "Submit a bug",
    path: "/submit-bug",
    exact: true,
    component: SubmitBug,
  },

  {
    name: "Files",
    path: "/engagements/:id/files",
    exact: true,
    component: FilesSharing,
  },
  {
    name: "Member Profile",
    path: "/engagements/:id/employees/:employee_id",
    exact: true,
    component: MemberProfile,
  },
  {
    name: "Request a Team",
    path: "/engagements/:id/request-team",
    exact: true,
    component: RequestTeamPage,
  },
  {
    name: "Portfolio Director",
    path: "/engagements/:id/portfolio-director",
    exact: false,
    component: PortfolioDirector,
  },
  {
    name: "Page not found",
    path: "*",
    exact: false,
    component: PageNotFound,
  },
];

export const routesWithNavigation: AppRoute[] = allRoutes.filter((route) => route.navigation);
