// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  colorPrimaryIcon: {
    color: "green",
  },

  tableContainer: {
    maxHeight: "600px",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export default useStyles;
