import { jsPDF } from "jspdf";
import {
  fontStyle,
  fontSize,
  hAlign,
} from "./utils/configurations/font-constants";
import { elementPosition } from "./utils/configurations/element-position";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import { AnsweredQuestion, Survey } from "../../config/types";
import { writeText } from "./utils/text-utils";
import autoTable from "jspdf-autotable";
import { ContentItem } from "./types";

export default function generateSectionsPage(
  doc: jsPDF,
  survey: Survey
): ContentItem[] {
  doc.addPage();

  const contents: ContentItem[] = [];

  // Audit Sections static text
  writeText({
    doc,
    color: [34, 84, 118],
    fontStyle: fontStyle.bold,
    fontSize: fontSize.sections,
    text: surveyPdfContent.sections,
    x: elementPosition.sections.getX(),
    y: elementPosition.sections.getY(),
  });

  // Notes static text
  writeText({
    doc,
    color: "black",
    fontStyle: fontStyle.normal,
    fontSize: fontSize.notesText,
    text: surveyPdfContent.notes,
    x: elementPosition.notesText.getX(),
    y: elementPosition.notesText.getY(),
  });

  contents.push({
    title: surveyPdfContent.sections,
    page: doc.getNumberOfPages(),
    y: elementPosition.sections.getY(),
    children: [],
  });

  // Notes dynamic text
  writeText({
    doc,
    color: "black",
    fontStyle: fontStyle.normal,
    fontSize: fontSize.notes,
    text: survey.notes ?? surveyPdfContent.emptyNotes,
    x: elementPosition.notesFirstPage.getX(),
    y: elementPosition.notesFirstPage.getY(),
    options: { maxWidth: elementPosition.notesMaxWidth, align: hAlign.left },
  });

  const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
  const linesCount = doc.splitTextToSize(
    survey.notes ?? surveyPdfContent.emptyNotes,
    elementPosition.textDimension
  ).length;

  let sectionsY =
    elementPosition.notesFirstPage.getY() +
    lineHeight * (linesCount + 1) +
    elementPosition.surveyInfoMargin;

  // FIXME: here they add notes to contents
  contents[0].children.push({
    title: surveyPdfContent.notes,
    page: doc.getNumberOfPages(),
    y: sectionsY,
    children: [],
  });

  survey.sections.forEach((section) => {
    if (sectionsY > elementPosition.maxHeightSurveyInfo) {
      doc.addPage();
      sectionsY = elementPosition.sections.getY();
    }

    writeText({
      doc,
      color: "black",
      fontStyle: fontStyle.bold,
      fontSize: fontSize.auditSection,
      text: section.value,
      x: elementPosition.sections.getX(),
      y: sectionsY,
      options: {
        maxWidth: elementPosition.sectionNameMaxLength,
        align: hAlign.left,
      },
    });

    contents[0].children.push({
      title: section.value,
      page: doc.getNumberOfPages(),
      y: sectionsY,
      children: [],
    });

    if (section.percentageScore > 0) {
      writeText({
        doc,
        color: "black",
        fontStyle: fontStyle.normal,
        fontSize: fontSize.chartInPdf,
        text: `${section.percentageScore}%`,
        x: elementPosition.auditSectionsScore.getX(),
        y: sectionsY,
        options: {
          maxWidth: elementPosition.sectionNameMaxLength,
          align: hAlign.right,
        },
      });
    }

    sectionsY =
      sectionsY +
      elementPosition.tableTopMargin * Math.ceil(section.value.length / 43);

    if (section.description) {
      sectionsY = sectionsY + elementPosition.emptyTableRowTopMargin;

      writeText({
        doc,
        color: "black",
        fontStyle: fontStyle.normal,
        fontSize: fontSize.tableContent,
        text: section.description,
        x: elementPosition.sections.getX(),
        y: sectionsY,
        options: { maxWidth: elementPosition.notesMaxWidth },
      });

      const linesCount = doc.splitTextToSize(
        section.description,
        elementPosition.notesMaxWidth
      ).length;
      sectionsY += linesCount * lineHeight;
    }

    const rowsLength = section.answeredQuestions.length;

    if (rowsLength === 0) {
      writeText({
        doc,
        color: "black",
        fontStyle: fontStyle.normal,
        fontSize: fontSize.tableContent,
        text: surveyPdfContent.emptyTableRow,
        x: elementPosition.sections.getX(),
        y: sectionsY + elementPosition.emptyTableRowTopMargin,
        options: { maxWidth: elementPosition.notesMaxWidth },
      });

      return sectionsY;
    }

    section.answeredQuestions.forEach((answeredQuestion) => {
      sectionsY =
        addTable(doc, answeredQuestion, sectionsY) +
        elementPosition.spaceBetweenSurveysTables;

      if (
        doc.internal.pageSize.getHeight() <
        sectionsY + elementPosition.minTableHeight
      ) {
        doc.addPage();
        sectionsY = elementPosition.projectStatusReportTitle.getY();
      }
    });
  });

  return contents;
}

function addTable(
  doc: jsPDF,
  answeredQuestion: AnsweredQuestion,
  y: number
): number {
  const header: any[][] = [];
  const body: any[][] = [];

  for (let i = 0; i < answeredQuestion.options.length; i++) {
    const option = answeredQuestion.options[i];

    if (option.score > 0) {
      header.push([
        {
          content: answeredQuestion.questionTitle,
          colSpan: 4,
        },
        {
          colSpan: 1,
          styles: { halign: hAlign.center, cellWidth: 20 },
          content: option.score,
        },
      ]);
    } else {
      header.push([
        {
          colSpan: 5,
          content: answeredQuestion.questionTitle,
        },
      ]);
    }
    const titleCellWidth = 35;

    body.push([
      {
        content: "Answer",
        styles: {
          cellWidth: titleCellWidth,
        },
      },
      {
        colSpan: 4,
        content: option.value,
      },
    ]);

    if (option.risks.length) {
      body.push([
        {
          content: "Risk",
          styles: {
            cellWidth: titleCellWidth,
          },
        },
        {
          colSpan: 4,
          content: addBulletAndNewLine(option.risks),
        },
      ]);
    }
  }

  autoTable(doc, {
    body: body,
    head: header,
    startY: y,
    margin: {
      left: elementPosition.sections.getX(),
      top: elementPosition.tableTopPageMargin,
      bottom: elementPosition.tableBottomPageMargin,
    },
    styles: {
      overflow: "linebreak",
      lineWidth: 0.005,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
      font: "Helvetica",
    },
    headStyles: {
      fontStyle: fontStyle.bold,
      fontSize: fontSize.tableContentSurveyInfo,
      fillColor: [213, 220, 228],
      textColor: [0, 0, 0],
      halign: hAlign.left,
      valign: "middle",
      font: "Helvetica",
    },
    pageBreak: "avoid",
    rowPageBreak: "avoid",
  });

  // @ts-ignore
  return doc.previousAutoTable.finalY;
}

function addBulletAndNewLine(text: string[]): string {
  let result = "";
  text.forEach((word) => {
    result += "\u2022  " + word + surveyPdfContent.newLine;
  });
  return result;
}
