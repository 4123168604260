// external lib dependencies
import { useState, useCallback } from "react";

const useUpdateData = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [data, setData] = useState<any>();

    const callback = useCallback(async (data: any) => {
        setLoading(true);

        const errorMessage = "Error!";
        try {
            const request = await fetch('api/notifications', {
                method: 'PATCH',
                body: JSON.stringify(data),
            });
            const response = await request.json();

            setData(response);

            if (request.status !== 200) {
                setError(response.detail ?? errorMessage);
            }
        } catch (err) {
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    }, []);

    return { callback, loading, error, data };
};

export default useUpdateData;
