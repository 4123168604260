interface TableColumn {
    key: string;
    label: string;
}

export const columns: TableColumn[] = [
    { key: '1', label: 'Status' },
    { key: '2', label: 'Date' },
    { key: '3', label: 'Sent by' },
    { key: '4', label: 'Request team members' },
    { key: '5', label: 'Quantity' },
    { key: '6', label: 'Start date' },
    { key: '7', label: 'Duration' },
    { key: '8', label: 'Comment' },
    { key: '9', label: '' },
    { key: '10', label: '' }
];

export const positions = [
    { label: 'Associate Software developer', value: 'Associate Software developer' },
    { label: 'Software developer', value: 'Software developer' },
    { label: 'Delivery Lead', value: 'Delivery Lead' },
    { label: 'QA Engineer', value: 'QA Engineer' }
];

export const skills = [
    { label: 'React', value: 'React' },
    { label: 'Angular', value: 'Angular' },
    { label: 'Python', value: 'Python' },
    { label: 'Scrum', value: 'Scrum' },
    { label: 'C# ASP.NET', value: 'C# ASP.NET' }
];

export const durations = [
    { label: '1 month', value: '1 month' },
    { label: '3 months', value: '3 months' },
    { label: '6 months', value: '6 months' },
    { label: '12 months', value: '12 months' },
    { label: 'Other', value: 'Other' }
];
