import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) => createStyles({
    // common styles

    checkboxFields: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 16px",
    },
    
    field: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    formContainer: {
      width: "30%",
      marginTop: theme.spacing(2),
      alignSelf: "center",
    },

    fieldContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      padding: "10px 12px",
      overflow: "hidden",
    },

    fieldContainerXS: {
      padding: "16px 32px",
      backgroundColor: theme.palette.background.default,
      width: "100vw",
      overflow: "hidden",
    },

    // InputField styles
    fieldInput: {
      width: "100%",
    },

    // MessageForm styles
    sectionContainer: {
      paddingTop: "16px",
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      borderTop: "1px solid #e0e0e0",
      backgroundColor: "#FFFFFF",
    },

    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#ffffff",
      border: "1px solid #ededed",
      padding: theme.spacing(3),
      margin: theme.spacing(3),
    },

    heading: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
      padding: "0 5px 10px 5px",
      alignSelf: "start",
    },

    engagementName: {
      fontWeight: 500,
      fontSize: "20px",
    },

    messageFormFieldsContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "32%",
      marginTop: "16px",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      padding: "14px",
    },

    navBar: {
      backgroundColor: "#003366",
      display: "flex",
      flexDirection: "row",
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "none",
      whiteSpace: "nowrap",
      position: "relative",
      alignItems: "center",
    },

    navBarLogo: {
      width: theme.spacing(12.5),
    },

    clientPortalItem: {
      width: theme.spacing(12.5),
      marginLeft: theme.spacing(4.5),
      color: "white",
    },

    iconButton: {
      padding: theme.spacing(0, 0.55),
    },

    messageButton: {
      marginRight: theme.spacing(1),
      width: "120px",
      borderRadius: "4px",
    },

    closeIcon: {
      color: "white",
      position: "absolute",
      right: "0",
    },

    // MessageFormButton styles
    openFormButton: {
      display: "flex",
      flexDirection: "row",
    },
    openFormButtonXS: {
      display: "flex",
      flexDirection: "row",
      flex: "1 0 100%",
    },



    // MessageFormFields styles
    selectedField: {
      padding: "12px 8px 0 8px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      alignSelf: "center",
    },

    messageButtons: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "14px 9px 18px 0",
    },

    requestButton: {
      padding: theme.spacing(0.9, 2),
      borderRadius: "4px",
    },

    cancelButton: {
      padding: theme.spacing(1.1, 2),
      marginRight: "10px",
      width: "82px",
      height: "36px",
      borderRadius: "4px",
    },

    sendButton: {
      padding: theme.spacing(1.1, 2),
      width: "68px",
      height: "36px",
      borderRadius: "4px",
    },

    labelFont: {
      fontSize: "16px",
      lineHeight: "24px",
    },

    // ResponseMessage styles
    responseMessage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
    },

    messageIcon: {
      marginBottom: theme.spacing(4),
    },

    messageText: {
      marginBottom: theme.spacing(2.2),
    },

    closeButton: {
      backgroundColor: "#071E4D",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "16px",
      width: "73px",
    },

    selectRecipientMessage: {
      color: "#f44336"
    },
  })
);

export default useStyles;

export const useStylesDarkerTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
    placement: "bottom",
    textAlign: 'center',
  },
}));
