import { jsPDF } from "jspdf";
import {
  fontSize,
  fontStyle,
  hAlign,
} from "./utils/configurations/font-constants";
import { elementPosition } from "./utils/configurations/element-position";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import { Survey } from "../../config/types";
import { COLORS } from "./utils/configurations/colors";
import Chart, { ChartOptions } from "chart.js";
import plugin, { Context } from "chartjs-plugin-datalabels";
import { imageDimension } from "./utils/configurations/image-dimension";
import { ContentItem } from "./types";
import { writeText } from "./utils/text-utils";

export default function generateChartPage(
  doc: jsPDF,
  survey: Survey,
  dataScore: any[]
): ContentItem[] {
  doc.addPage();
  const contents: ContentItem[] = [];

  // Charts title static text
  writeText({
    doc,
    color: [34, 84, 118],
    fontSize: fontSize.charts,
    fontStyle: fontStyle.bold,
    text: surveyPdfContent.charts,
    x: elementPosition.charts.getX(),
    y: elementPosition.charts.getY(),
  });

  contents.push({
    title: surveyPdfContent.charts,
    page: doc.getNumberOfPages(),
    y: elementPosition.charts.getY(),
    children: [],
  });

  writeText({
    doc,
    color: "black",
    fontSize: fontSize.scoreSectionDescription,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.scoreSectionDescription,
    x: elementPosition.scoreSectionDescription.getX(),
    y: elementPosition.scoreSectionDescription.getY(),
    options: {
      maxWidth: elementPosition.scoreSectionDescriptionMaxWidth,
      align: hAlign.justify,
    },
  });

  const container = document.createElement("div");
  container.style.maxWidth = surveyPdfContent.canvasContainerMaxWidth;
  container.style.position = "absolute";
  container.style.height = surveyPdfContent.canvasContainerHeight;
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.style.width = surveyPdfContent.canvasContainerMaxWidth;
  canvas.style.height = surveyPdfContent.canvasContainerHeight;
  container.appendChild(canvas);
  document.body.appendChild(container);

  const context = canvas.getContext("2d");

  const drawableDataScore = [...dataScore];

  for (let i = 0; i < drawableDataScore.length; i++) {
    drawableDataScore[i].borderColor = COLORS[i];
    drawableDataScore[i].backgroundColor = Chart.helpers
      .color(COLORS[i])
      .alpha(0.3)
      .rgbString();
    drawableDataScore[i].pointBackgroundColor = COLORS[i];
    drawableDataScore[i].pointBorderColor = "#fff";
    drawableDataScore[i].pointHoverBackgroundColor = "#fff";
    drawableDataScore[i].pointHoverBorderColor = COLORS[i];
  }

  new Chart(context!, {
    type: "line",
    data: {
      datasets: drawableDataScore,
    },
    options: getLineChartOptions({
      xAxeScaleLabelText: "Date",
      yAxeScaleLabelText: "Score, %",
      disableAnimations: true,
      lineWidth: fontSize.chartGridlineWidthPdf,
      yAxeSuggestedMax: 100,
      
    }),
    plugins: [plugin],
  });

  const dataUrl = canvas.toDataURL("image/png");

  doc.addImage(
    dataUrl,
    "png",
    elementPosition.chart.getX(),
    elementPosition.chart.getY(),
    imageDimension.chartImage.getWidth(),
    imageDimension.chartImage.getHeight()
  );

  document.body.removeChild(container);

  return contents;
}

interface SetOptionsParams {
  titleText?: string;
  xAxeScaleLabelText: string;
  yAxeScaleLabelText: string;
  disableAnimations: boolean;
  lineWidth?: number;
  yAxeMax?: number;
  yAxeSuggestedMax?: number;
  stepSize?: number;
  yAxeMin?: number;
}

export const getLineChartOptions = ({
  titleText = "",
  xAxeScaleLabelText,
  yAxeScaleLabelText,
  disableAnimations,
  lineWidth = fontSize.chartGridlineWidth,
  yAxeMax,
  yAxeSuggestedMax,
  stepSize,
  yAxeMin,
}: SetOptionsParams): ChartOptions => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: !!titleText,
      text: titleText,
      fontSize: fontSize.chartDefault,
      
    },
    animation: {
      duration: disableAnimations ? 0 : 3000,
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontSize: fontSize.chartDefault,
      },
    },
    layout: {
      padding: {
        left: 25,
        right: 25,
      },
    },
    elements: {
      point: {
        radius: fontSize.chartPointRadius,
      },
    },
    tooltips: {
      mode: "point",
    },
    scales: {
      xAxes: [
        {
          type: "time",
          distribution: "series",
          bounds: "ticks",
          ticks: {
            source: "data",
            fontSize: fontSize.chartDefault,
          },
          time: {
            unit: "week",
            tooltipFormat: "ll h:mm A",
          },
          scaleLabel: {
            display: true,
            labelString: xAxeScaleLabelText,
            fontSize: fontSize.chartDefault,
          },
          gridLines: {
            display: true,
            lineWidth: lineWidth,
            drawTicks: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            fontSize: fontSize.chartDefault,
            max: yAxeMax,
            min: yAxeMin,
            stepSize: stepSize,
            suggestedMax: yAxeSuggestedMax,
          },
          scaleLabel: {
            display: true,
            labelString: yAxeScaleLabelText,
            fontSize: fontSize.chartDefault,
          },
          gridLines: {
            display: true,
            lineWidth: lineWidth,
            drawTicks: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: function (context: Context): any {
          return context.dataset.data
            ? context.dataset.data[context.dataIndex] !== 0
            : false;
        },
        formatter: function (value: any): any {
          return value.y;
        },
        backgroundColor: "transparent",
        align: "end",
        anchor: "end",
      },
    },
  };
};
