// external lib dependencies
import { List } from "@material-ui/core";

// local dependencies
import { useGridItemListStyles } from "./styles";
import useListSort from "../hooks/useListSort";
import useYearSpan from "../hooks/useYearSpan";
import Engagement from "./Engagement";
import { Engagement as EngagementType } from "config/types";

interface GridItemListProps {
  unfoldAll: boolean;
  selectedYear: number;
  engagements: EngagementType[];
  checked: boolean;
}

const GridItemList = ({ unfoldAll, selectedYear, engagements, checked }: GridItemListProps) => {
  const { getMinMaxDate } = useYearSpan();
  const classes = useGridItemListStyles();
  const sortList = useListSort();

  return (
    <List className={classes.gridItemList} data-testid="gridItemList">
      {engagements.map((engagement: EngagementType, index) => {
        return (
          <Engagement
            key={index}
            selectedYear={selectedYear}
            unfoldAll={unfoldAll}
            engagementName={engagement.title}
            workOrders={engagement.work_orders}
            minDate={getMinMaxDate(engagement).min}
            maxDate={getMinMaxDate(engagement).max}
            index={index}
            checked={checked}
          />
        );
      })}
    </List>
  );
};

export default GridItemList;
