// external lib dependencies
import {useState, useCallback} from 'react'

// absolute path dependencies
import {sendSubmitBugData} from 'services/customerSurveyService'


const useSubmitHandler = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [sent, setSent] = useState(false)
    const postData = useCallback(async (data: any) => {
        setLoading(true);
        const errorMessage = "Well this is embarrassing... We couldn\'t send your data. Can you please try again later ?"
        try {
            const fetch = await sendSubmitBugData(data)
            const response = await fetch.json()

            if (fetch.status !== 200) {
                setError(response.detail ?? errorMessage)
            }

            setSent(true)

        } catch {
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    }, []);

    return {postData, loading, sent, error}
}

export default useSubmitHandler;
