import { Box, Typography, FormControlLabel, Divider } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { Dispatch, SetStateAction } from "react";
import { useWOPageHeaderStyles } from "./styles";
import SearchBar from "components/SearchBar/SearchBar";

interface WorkOrdersHeaderProps {
  showActiveWO: boolean;
  showMyEngagements: boolean;
  setShowActiveWO: Dispatch<SetStateAction<boolean>>;
  setShowMyEngagements: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  isStakeholder: boolean | undefined;
  isLoading: boolean;
}

export default function WorkOrdersHeader({
  showActiveWO = true,
  showMyEngagements = true,
  setShowActiveWO,
  setShowMyEngagements,
  searchValue,
  setSearchValue,
  isStakeholder,
  isLoading,
}: WorkOrdersHeaderProps) {
  const classes = useWOPageHeaderStyles();

  return (
    <>
      <Box className={classes.headerContainer}>
        <Typography variant="h6">Work Orders</Typography>
        <Box className={classes.checkBoxAndSearchContainer}>
          <SearchBar
            textFieldProps={{
              disabled: isLoading,
              value: searchValue,
              onChange: (e) => {
                setSearchValue(e.target.value);
              },
            }}
            boxProps={{
              className: classes.searchContainer,
            }}
          />
          {isStakeholder ? (
            ""
          ) : (
            <FormControlLabel
              style={{ flexDirection: "row-reverse", marginRight: "10px" }}
              control={
                <Checkbox
                  disabled={isLoading}
                  checked={showMyEngagements}
                  onChange={() => setShowMyEngagements((prev) => !prev)}
                  style={{ color: "#03172B" }}
                />
              }
              label={<Typography variant="body1">My Engagements</Typography>}
            />
          )}
          <Divider />
          <FormControlLabel
            style={{ flexDirection: "row-reverse", paddingLeft: "10px", borderLeft: "1px solid #EDEDED" }}
            control={
              <Checkbox
                disabled={isLoading}
                checked={showActiveWO}
                onChange={() => setShowActiveWO((prev) => !prev)}
                style={{ color: "#03172B" }}
              />
            }
            label={<Typography variant="body1">Active Work Orders Only</Typography>}
          />
        </Box>
      </Box>
    </>
  );
}
