// external lib dependencies
import {Theme, makeStyles, createStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarList: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            boxSizing: "border-box",
            paddingRight: theme.spacing(0.75),
            paddingLeft: theme.spacing(0.75),
            gap: theme.spacing(3),
        },
    })
);

export default useStyles;
