export interface FeedbackQuestion {
  question: string;
  typeOptions: {
    questionType: string;
    selectProject?: boolean;
    selectOptions?: string;
    multiline?: boolean;
    maxRows?: number;
    minRows?: number;
    isTextArea?: boolean;
    selectCompany?: boolean;
    isLarge?: boolean;
  };
}

export const questions: FeedbackQuestion[] = [
  { question: "Name", typeOptions: { questionType: "textInput" } },
  { question: "Job Title", typeOptions: { questionType: "textInput" } },
  { question: "Project", typeOptions: { questionType: "projectInput", selectProject: true, isLarge: true } },
  { question: "Company", typeOptions: { questionType: "companyInput", selectCompany: true, isLarge: true } },

  {
    question: "How would you rate your overall satisfaction with the service you receive from Amdaris?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant4",
    },
  },

  {
    question: "How would you assess the Value for Money of the service that you receive from Amdaris?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant1",
    },
  },

  {
    question:
      "How satisfied are you with the communication with your Amdaris team (e.g. promptness, appropriateness and level of detail)?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant1",
    },
  },

  {
    question: "How would you rate Amdaris' responsiveness and speed of resolution of requests/issues?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant1",
    },
  },

  {
    question:
      "How would you rate your Amdaris team in terms of their understanding your business requirements and delivering effective software solutions?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant1",
    },
  },

  {
    question:
      "On a scale from 10 to 1 how likely are you to recommend Amdaris to your peers? (1-6 means you aren't satisfied and wouldn't recommend Amdaris, 7-8 means you are neutral and therefore unlikely to recommend Amdaris, 9-10 means you would be happy to recommend Amdaris)",
    typeOptions: {
      questionType: "scoreInput",
      selectOptions: "variant2",
    },
  },

  {
    question: "What improvements, if any, can Amdaris make in order to deliver a better Service for you?",
    typeOptions: {
      questionType: "textInput",
      multiline: true,
      maxRows: 11,
      minRows: 9,
      isTextArea: true,
    },
  },
  {
    question: "Is there anything else that Amdaris can do for you that we aren't currently doing?",
    typeOptions: {
      questionType: "textInput",
      multiline: true,
      maxRows: 11,
      minRows: 9,
      isTextArea: true,
    },
  },
  {
    question: "Are you happy to be contacted for a reference?",
    typeOptions: {
      questionType: "radioInput",
      selectOptions: "variant3",
    },
  },
];

export const selectOptions = [
  [
    "Consistently meets or exceeds expectations",
    "Meets expectations almost all the time",
    "Mostly meets expectations",
    "Falls below expectations",
    "Dissatisfied",
  ],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  ["Yes", "No"],
  ["Very satisfied", "Satisfied", "Neutral", "Unsatisfied", "Very unsatisfied"],
];

// if needed, new validation criteria can be added here
export const validationOptions = {
  name: {
    validate: (value: string) => {
      return !!value.trim();
    },
    minLength: {
      value: 4,
      message: "Name is too short, please provide a valid name",
    },
    pattern: {
      value: /^[a-zA-Z \-’]+$/i,
      message: "Invalid characters, please provide a real name",
    },
  },

  company: {
    validate: (value: string) => {
      return !!value.trim();
    },
    minLength: {
      value: 3,
      message: "Company name is too short, please provide a valid name",
    },
  },

  role: {
    validate: (value: string) => {
      return !!value.trim();
    },
    minLength: {
      value: 2,
      message: "Job Title is too short, please provide a valid name",
    },
  },

  feedback: {
    validate: (value: string) => {
      return !!value.trim();
    },
    minLength: {
      value: 3,
      message: "Your feedback should be at least 3 characters long",
    },
  },

  email: {
    validate: (value: string) => {
      return !!value.trim();
    },
    minLength: {
      value: 6,
      message: "Email is too short",
    },
    pattern: {
      value:
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      message: "Email is invalid",
    },
  },
};
