// external lib dependencies
import { FC, useRef, useEffect } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { FormLabel, MenuItem, Select, Container } from "@material-ui/core";

// local dependencies
import { selectOptions } from "./questions";
import useStyles from "../styles";

interface Props {
  required?: boolean;
  question: string;
  label: string;
  isLarge?: boolean;
}

const InputSelectScore: FC<Props> = ({ required, question, label, isLarge }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { register } = useForm();

  useEffect(() => {
    if (`${label}` in errors && selectInputRef.current) {
      register(selectInputRef.current);
      selectInputRef.current.focus();
    }
  }, [errors, label, register]);

  let selectInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Container className={classes.wrapper} data-testid="selectInput">
      <FormLabel error={Boolean(errors[`${label}`])} className={classes.inputLabelFont}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      <Controller
        as={
          <Select
            inputRef={(e) => {
              register(e);
              selectInputRef.current = e;
            }}
            variant="outlined"
            id={label}
            className={isLarge ? classes.inputSelectItemLarge : classes.inputSelectItem}
          >
            {selectOptions[1].reverse().map((option, index) => (
              <MenuItem key={index} value={option.toString()}>
                {option.toString()}
              </MenuItem>
            ))}
          </Select>
        }
        name={`${label}`}
        defaultValue=""
        control={control}
        rules={{ required: "Please select an option !" }}
      />
    </Container>
  );
};

export default InputSelectScore;
