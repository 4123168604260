import { Controller, useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { ContentState, convertFromHTML, EditorState } from "draft-js";
import he from "he";

import { useAppDispatch } from "store/hook";
import { createNoteAsync, updateNoteAsync } from "store/reducers/releaseNotes/releaseNoteSlice";

import { NoteFormInput, ReleaseNote } from "config/types";
import useStyles from "../styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./TextEditor";

interface NoteDialogProps {
  open: boolean;
  handleClose: () => void;
  releaseNote?: ReleaseNote;
}

const ReleaseNoteDialog = ({ open, handleClose, releaseNote }: NoteDialogProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentDate = releaseNote?.release_date ? new Date(releaseNote.release_date) : new Date();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NoteFormInput>();

  function replaceInsWithU(htmlString: any) {
    const regex = /<ins\b[^>]*>(.*?)<\/ins>/g;
    const replacedString = htmlString.replace(regex, "<u>$1</u>");
    return replacedString;
  }

  const handleEditorContent = (): EditorState => {
    if (releaseNote) {
      const decodedString = replaceInsWithU(he.decode(releaseNote?.note_content));
      const contentBlock: any = convertFromHTML(decodedString);
      const contentState = ContentState.createFromBlockArray(contentBlock);
      const editorState = EditorState.createWithContent(contentState);

      return editorState;
    }
    return EditorState.createEmpty();
  };

  function hasTextContent(input: string) {
    const decodedString = he.decode(input).replace(/\s+/g, "");
    const hasText = /\S/.test(decodedString);
    const hasOnlyEmptyParagraphs = /^<p><\/p>\s*$/.test(decodedString);
    return hasText && !hasOnlyEmptyParagraphs;
  }
  const validateWhiteSpace = (value: string) => {
    if (!hasTextContent(value)) {
      return "Input must have text content";
    }
    return true;
  };

  const onSubmit = (inputs: NoteFormInput) => {
    if (releaseNote) {
      const updatedNote: ReleaseNote = {
        ...releaseNote,
        release_date: inputs.release_date,
        note_content: inputs.note_content.trim(),
      };
      dispatch(updateNoteAsync(updatedNote));
      handleClose();
    } else {
      const note = { ...inputs };
      dispatch(createNoteAsync(note));
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitle }}>
        New Release note
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="release_date"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Date field is required",
              },
            }}
            defaultValue={currentDate}
            render={({ value, onChange }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date-picker-inline"
                  placeholder="Select release date"
                  autoOk
                  value={value}
                  onChange={(date: any) => onChange(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={!!errors.release_date}
                  helperText={errors.release_date?.message ?? ""}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
          />
          <Controller
            name="note_content"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Note content field is required",
              },
              validate: validateWhiteSpace,
            }}
            defaultValue={""}
            render={({ value, onChange }) => {
              return <TextEditor value={releaseNote ? handleEditorContent() : value} onChange={onChange} />;
            }}
          />
          <Typography color="error" variant="body1">
            {errors.note_content?.message ?? ""}
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReleaseNoteDialog;
