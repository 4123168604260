import { Theme, createStyles, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //dialog modal general styles
    dialogModal: {
      "& .MuiDialog-paper": {
        borderRadius: "8px",
      },
    },

    dialogModalOffset: {
      "& .MuiDialog-paper": {
        padding: theme.spacing(1.875),
      },
    },

    dialogTitle: {
      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "20px",
      },
    },

    dialogContentText: {
      color: "#000000",
    },

    dialogActionContent: {
      padding: theme.spacing(1.25, 0, 0, 0),
    },
  }),
);

export default useStyles;
