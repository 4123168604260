// external lib dependencies
import { FC, useEffect, useState } from "react";
import { Paper, Typography, Box, CircularProgress } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

// absolute path dependencies
import Button from "components/Button";
import { FeedbackCompany, FeedbackProject, User } from "config/types";

// local dependencies
import InputTextField from "../components/InputTextField";
import InputRadio from "../components/InputRadio";
import InputSelectCompany from "../components/InputSelectCompany";
import InputSelectProject from "../components/InputSelectProject";
import InputSelectScore from "../components/InputSelectScore";
import { questions, validationOptions } from "../components/questions";
import useStyles from "../styles";

interface Props {
  loading: boolean;
  error: string;
  currentUser: User | null;
}

const FormQuestions: FC<Props> = ({ loading, error, currentUser }) => {
  const classes = useStyles();
  const [selectedProject, setSelectedProject] = useState<FeedbackProject | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<FeedbackCompany | null>(null);
  const [projects, setProjects] = useState<FeedbackProject[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [dataError, setDataError] = useState<string | null>(null);
  const { setValue } = useFormContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch("/api/customer-survey/projects");
        if (!response.ok) {
          throw new Error("Something went wrong...");
        }
        let projects = await response.json();
        setProjects(projects);
        setDataError(null);
      } catch (err: any) {
        setDataError(err.message);
        setProjects([]);
      } finally {
        setLoadingData(false);
      }
    };
    getData();
  }, []);

  const changeCompanyValue = (newValue: string | undefined) => {
    setValue(
      questions.findIndex((question) => question.typeOptions.questionType === "companyInput").toString(),
      newValue,
    );
  };

  const changeProjectValue = (newValue: string | undefined) => {
    const projectsWithSelectedCompany = projects.filter((option: FeedbackProject) => option.client_name === newValue);

    if (projectsWithSelectedCompany.length > 1) {
      setValue(questions.findIndex((question) => question.typeOptions.questionType === "projectInput").toString(), "");
    } else if (projectsWithSelectedCompany.length === 1) {
      setValue(
        questions.findIndex((question) => question.typeOptions.questionType === "projectInput").toString(),
        projectsWithSelectedCompany[0].title,
      );
    }
  };

  if (loadingData) {
    return (
      <Box className={classes.loadingBox}>
        <CircularProgress />
      </Box>
    );
  }

  if (dataError) {
    return (
      <Box className={classes.errorMsgBox}>
        <Typography variant="h5">{dataError}</Typography>
      </Box>
    );
  }

  return (
    <>
      {questions.map((q: any, index: number) => {
        if (q?.typeOptions?.questionType && q.typeOptions.questionType === "textInput" && currentUser) {
          return (
            <Paper className={classes.paperMargin} key={index}>
              <div data-testid="textInput">
                <InputTextField
                  question={q.question}
                  label={index.toString()}
                  required
                  rules={
                    q.question === "Name"
                      ? validationOptions.name
                      : q.question === "Job Title"
                      ? validationOptions.role
                      : q.question ===
                        "What improvements, if any, can Amdaris make in order to deliver a better Service for you?"
                      ? validationOptions.feedback
                      : q.question ===
                        "Is there anything else that Amdaris can do for you that we aren't currently doing?"
                      ? validationOptions.feedback
                      : {}
                  }
                  multiline={q.typeOptions.multiline}
                  maxRows={q.typeOptions.maxRows}
                  minRows={q.typeOptions.minRows}
                  isTextArea={q.typeOptions.isTextArea}
                  currentUser={currentUser}
                />
              </div>
            </Paper>
          );
        } else if (q.typeOptions.questionType === "radioInput") {
          return (
            <Paper className={classes.paperMargin} key={index}>
              <InputRadio
                data-testid="radioInput"
                question={q.question}
                label={index.toString()}
                required
                variant={q.typeOptions.selectOptions}
              />
            </Paper>
          );
        } else if (q.typeOptions.questionType === "companyInput") {
          return (
            <Paper className={classes.paperMargin} key={index}>
              <InputSelectCompany
                data-testid="selectInput"
                required
                question={q.question}
                label={index.toString()}
                isLarge={q.typeOptions.isLarge}
                setSelectedCompany={setSelectedCompany}
                selectedProject={selectedProject}
                projects={projects}
                changeProjectValue={changeProjectValue}
              />
            </Paper>
          );
        } else if (q.typeOptions.questionType === "projectInput") {
          return (
            <Paper className={classes.paperMargin} key={index}>
              <InputSelectProject
                data-testid="selectInput"
                required
                question={q.question}
                label={index.toString()}
                isLarge={q.typeOptions.isLarge}
                setSelectedProject={setSelectedProject}
                selectedCompany={selectedCompany}
                projects={projects}
                changeCompanyValue={changeCompanyValue}
              />
            </Paper>
          );
        } else if (q.typeOptions.questionType === "scoreInput") {
          return (
            <Paper className={classes.paperMargin} key={index}>
              <InputSelectScore
                data-testid="selectInput"
                required
                question={q.question}
                label={index.toString()}
                isLarge={q.typeOptions.isLarge}
              />
            </Paper>
          );
        }
      })}
      <Button
        type="submit"
        disabled={loading || Boolean(error)}
        loading={loading}
        data-testid="submitButton"
        className={classes.submitButton}
      >
        Submit
      </Button>
    </>
  );
};

export default FormQuestions;
