import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from "@material-ui/core";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import useStyles from "./styles";
import { ISelectOptions } from "./types";
import InfoToolTip from "pages/Stakeholders/components/InfoToolTip";

interface SelectFieldProps {
  control: Control<any>;
  name: string;
  labelText: string;
  selectOptions: ISelectOptions[];
  required?: boolean;
  renderFormLabel?: boolean;
  requiredSymbol?: boolean;
  errors?: string;
  defaultValue?: any;
  tooltip: boolean;
}

const SelectField: FC<SelectFieldProps> = ({
  control,
  name,
  labelText,
  selectOptions,
  errors,
  tooltip,
  renderFormLabel = true,
  requiredSymbol = false,
  defaultValue = "",
  required = false,
}) => {
  const classes = useStyles();

  const generateSelectOptions = () => {
    return selectOptions.map((selectOption, index) => {
      return (
        <MenuItem key={index} value={selectOption.value}>
          {selectOption.label}
        </MenuItem>
      );
    });
  };

  return (
    <>
      {renderFormLabel && (
        <FormLabel error={Boolean(errors)} data-testid="subjectLabel" className={classes.labelFont}>
          {labelText}
          {requiredSymbol && <span style={{ color: "red" }}>*</span>}
          {tooltip && <InfoToolTip />}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: {
            value: required,
            message: "We require this information",
          },
        }}
        render={({ onChange, value }) => (
          <FormControl required={required} fullWidth variant="outlined" size="small" error={Boolean(errors)}>
            <Select value={value} onChange={onChange}>
              {generateSelectOptions()}
            </Select>
            <FormHelperText>{errors || ""}</FormHelperText>
          </FormControl>
        )}
      />
    </>
  );
};

export default SelectField;
