import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { RequestContent } from 'config/types';
import React, { FC } from 'react'
import { format, parseISO } from "date-fns";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from '../styles';

interface DialogRowProps {
    memberRequest: RequestContent;
    onRemoveMember: (member: RequestContent) => void;
}

const DialogRow: FC<DialogRowProps> = ({ memberRequest, onRemoveMember }) => {

    const classes = useStyles();

    const {
        member_role,
        primary_skill,
        member_type_quantity,
        member_start_date,
        member_duration
    } = memberRequest;

    const handleRemoveMember = (member: RequestContent) => {
        onRemoveMember(member);
    }

    return (
        <TableRow className={classes.dialogRow}>
            <TableCell>
                <Box className={classes.cellContainer}>
                    <Typography variant='body2'>
                        {member_role}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box className={classes.cellContainer}>
                    <Typography variant='body2'>
                        {primary_skill}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box className={classes.cellContainer}>
                    <Typography variant='body2'>
                        {member_type_quantity}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box className={classes.cellContainer}>
                    <Typography variant='body2'>
                        {format(parseISO(member_start_date), "dd MMM yyyy")}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    className={classes.cellContainer}
                >
                    <Typography variant='body2'>
                        {member_duration}
                    </Typography>
                    <IconButton onClick={() => handleRemoveMember(memberRequest)}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default DialogRow;
