// external lib dependencies
import { FC, useRef, useEffect } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { FormLabel, MenuItem, Select, Container } from "@material-ui/core";

// local dependencies
import useStyles from "../styles";
import { FeedbackCompany, FeedbackProject, UrlParams } from "config/types";
import { useParams } from "react-router-dom";

interface Props {
  required?: boolean;
  question: string;
  label: string;
  isLarge?: boolean;
  setSelectedProject: React.Dispatch<React.SetStateAction<FeedbackProject | null>>;
  selectedCompany: FeedbackCompany | null;
  projects: FeedbackProject[];
  changeCompanyValue: (newValue: string | undefined) => void;
}

const InputSelectProject: FC<Props> = ({
  required,
  question,
  label,
  isLarge,
  setSelectedProject,
  selectedCompany,
  projects,
  changeCompanyValue,
}) => {
  const classes = useStyles();
  const { id } = useParams<UrlParams>();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { register } = useForm();

  const projectTitle = projects.find(project => project.id === Number(id))?.title ?? "";

  useEffect(() => {
    if (`${label}` in errors && selectInputRef.current) {
      register(selectInputRef.current);
      selectInputRef.current.focus();
    }
  }, [errors, label, register]);

  let selectInputRef = useRef<HTMLInputElement | null>(null);

  const chooseSelectInputOptions = (): JSX.Element | JSX.Element[] | undefined => {
    if (!selectedCompany) {
      return projects.map((option: FeedbackProject) => (
        <MenuItem
          key={option.id}
          value={option.title}
          onClick={() => {
            setSelectedProject(option);
            changeCompanyValue(option.client_name);
          }}
        >
          <span id="DropdownProjectId" data-index={option.id}>
            {option.title}
          </span>
        </MenuItem>
      ));
    } else {
      const selectedProjectedCompany = selectedCompany.name;

      const projectsWithSelectedCompany = projects.filter(
        (option: FeedbackProject) => option.client_name === selectedProjectedCompany
      );

      return projectsWithSelectedCompany.map((option: FeedbackProject) => (
        <MenuItem key={option.id} value={option.title}>
          <span id="DropdownProjectId" data-index={option.id}>
            {option.title}
          </span>
        </MenuItem>
      ));
    }
  };

  return (
    <Container className={classes.wrapper} data-testid="selectInput">
      <FormLabel error={Boolean(errors[`${label}`])} className={classes.inputLabelFont}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      <Controller
        as={
          <Select
            inputRef={(e) => {
              register(e);
              selectInputRef.current = e;
            }}
            variant="outlined"
            id={label}
            className={isLarge ? classes.inputSelectItemLarge : classes.inputSelectItem}
          >
            {chooseSelectInputOptions()}
          </Select>
        }
        name={`${label}`}
        control={control}
        rules={{ required: "Please select an option !" }}
        defaultValue={projectTitle}
      />
    </Container>
  );
};

export default InputSelectProject;
