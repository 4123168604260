// external lib dependencies
import clsx from "clsx";
import { Button as MuiButton, ButtonProps as MuiButtonProps } from "@material-ui/core";

// absolute path dependencies
import LoadingIndicator from "components/LoadingIndicator";
import { ButtonCategory, ButtonColorVariant } from "config/types";

// local dependencies
import useStyles from "./styles";

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  category?: ButtonCategory;
  colorVariant?: ButtonColorVariant;
}

const Button = ({ children, loading, category = "text", colorVariant = "white", ...rest }: ButtonProps) => {
  const classes = useStyles();

  const clsxClasses = clsx(
    colorVariant === "white" && category === "text" && classes.textWhite,
    colorVariant === "black" && category === "text" && classes.textBlack,
    colorVariant === "white" && category === "icon" && classes.iconWhite,
    colorVariant === "black" && category === "icon" && classes.iconBlack,
    category === "text" && classes.textButton,
    rest.className
  );

  return (
    <MuiButton
      {...rest}
      type={rest.type || "button"}
      disabled={loading || rest.disabled}
      variant={category === "text" ? "outlined" : "text"}
      className={clsxClasses}
    >
      {loading ? <LoadingIndicator style={{ width: "33px" }} data-testid="loading-component" /> : children}
    </MuiButton>
  );
};

export default Button;
