import React, { useState } from 'react';
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import useStyles from "../styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

type EditorProps = {
    value: EditorState;
    onChange: (...event: any[]) => void;
}

const TextEditor = ({value, onChange}: EditorProps) => {

    const classes = useStyles();
    const [editorState, setEditorState] = useState<EditorState>(value);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        return onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        )
    }

    return (
        <div className={classes.editor}>
            <Editor 
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
            />
        </div>
    )
}

export default TextEditor
