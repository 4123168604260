import { FetchData } from "./FetchData";

export async function getIndividualFeedbacks(
  page_number: number,
  signal?: AbortSignal,
  name_filters?: string[],
  project_filters?: string[],
  company_filters?: string[],
  items_per_page: number = 10,
) {
  const nameFilters = encodeURIComponent(JSON.stringify(name_filters));
  const projectFilters = encodeURIComponent(JSON.stringify(project_filters));
  const companyFilters = encodeURIComponent(JSON.stringify(company_filters));

  let url: string = `/feedback/individual-feedback?page_number=${page_number}&items_per_page=${items_per_page}`;

  if (nameFilters !== "%5B%5D" && nameFilters !== "undefined") {
    url += `&name_filters=${nameFilters}`;
  }
  if (projectFilters !== "%5B%5D" && projectFilters !== "undefined") {
    url += `&project_filters=${projectFilters}`;
  }
  if (companyFilters !== "%5B%5D" && companyFilters !== "undefined") {
    url += `&company_filters=${companyFilters}`;
  }

  return await new FetchData(url).GET(signal);
}

export async function getIndividualFeedbacksFilters(signal?: AbortSignal) {
  let url: string = "/feedback/individual-feedback-filters";
  return await new FetchData(url).GET(signal);
}

export async function getTeamFeedbacks(
  page_number: number,
  signal?: AbortSignal,
  project_filters?: string[],
  team_filters?: string[],
  provider_full_filters?: string[],
  items_per_page: number = 10,
) {
  const teamFilters = encodeURIComponent(JSON.stringify(team_filters));
  const projectFilters = encodeURIComponent(JSON.stringify(project_filters));
  const providerFilters = encodeURIComponent(JSON.stringify(provider_full_filters));

  let url: string = `/feedback/team-feedback?page_number=${page_number}&items_per_page=${items_per_page}`;

  if (teamFilters !== "%5B%5D" && teamFilters !== "undefined") {
    url += `&team_filters=${teamFilters}`;
  }
  if (projectFilters !== "%5B%5D" && projectFilters !== "undefined") {
    url += `&project_filters=${projectFilters}`;
  }
  if (providerFilters !== "%5B%5D" && providerFilters !== "undefined") {
    url += `&name_filters=${providerFilters}`;
  }

  return await new FetchData(url).GET(signal);
}

export async function getTeamFeedbacksFilters(signal?: AbortSignal) {
  let url: string = "/feedback/team-feedback-filters";
  return await new FetchData(url).GET(signal);
}