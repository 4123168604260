// external lib dependencies
import { Theme, useTheme } from "@material-ui/core";

// local dependencies
import lightThemeStruct from "./catalog/lightTheme";
import darkThemeStruct from "./catalog/darkTheme";


export interface ThemeStruct {
  muiTheme: Theme;
  isDark: boolean;
  tickFillColor: string;
  tickFontSize: number;
  tooltipBgColor: string;
  gradientOffset0Color: string;
  gradientOffset50Color: string;
  gradientOffset100Color: string;
  cartesianGridColor: string;
  legendStrongColor: string;
  legendNeutralColor: string;
  legendWeakColor: string;
  lineFillDefaultColor: string;
  lineFillGreenColor: string;
  lineFillYellowColor: string;
  lineFillRedColor: string;
  auditCellButtonColor: "black" | "white";
  brightnessIcon: JSX.Element;
};

const themeStructsMap = new Map([
  ["light", lightThemeStruct],
  ["dark", darkThemeStruct]
]);

const defaultThemeName = "light";
const sortedThemeNames = Array.from(themeStructsMap.keys()).sort();

const getThemeName = (theme: Theme): string => {
  let themeName = defaultThemeName;

  themeStructsMap.forEach((themeStruct, name) => {
    if (themeStruct.muiTheme === theme) themeName = name;
  });

  return themeName;
};

const getThemeByName = (themeName: string): Theme => {
  const validThemeName = themeStructsMap.has(themeName) ? themeName : defaultThemeName;

  return (themeStructsMap.get(validThemeName) as ThemeStruct).muiTheme;
};

const saveThemeToLocalStorage = (theme: Theme) => {
  localStorage.setItem("clientPortal.theme", getThemeName(theme));
}

export const getDefaultTheme = (): Theme => {
  return getThemeByName(defaultThemeName);
};

export const getSavedTheme = (): Theme => {
  const savedThemeName = localStorage.getItem("clientPortal.theme") || "";

  return getThemeByName(savedThemeName);
};

export const useNextTheme = (theme?: Theme) => {
  const currentTheme = useTheme();
  const nextThemeIndex = sortedThemeNames.indexOf(getThemeName(theme || currentTheme)) + 1;
  const nextTheme = getThemeByName(sortedThemeNames[nextThemeIndex % sortedThemeNames.length]);

  saveThemeToLocalStorage(currentTheme);

  return nextTheme;
};

export const useThemeStruct = (theme?: Theme): ThemeStruct => {
  const currentTheme = useTheme();

  return themeStructsMap.get(getThemeName(theme || currentTheme)) as ThemeStruct;
};
