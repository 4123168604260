// external lib dependencies
import { FC, useRef, useEffect, useState } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { FormLabel, MenuItem, Select, Container } from "@material-ui/core";

// local dependencies
import useStyles from "../styles";
import { FeedbackCompany, FeedbackProject, UrlParams } from "config/types";
import { useParams } from "react-router-dom";

interface Props {
  required?: boolean;
  question: string;
  label: string;
  isLarge?: boolean;
  setSelectedCompany: React.Dispatch<React.SetStateAction<FeedbackCompany | null>>;
  selectedProject: FeedbackProject | null;
  projects: FeedbackProject[];
  changeProjectValue: (newValue: string | undefined) => void;
}

const InputSelectCompany: FC<Props> = ({
  required,
  question,
  label,
  isLarge,
  setSelectedCompany,
  selectedProject,
  projects,
  changeProjectValue,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { register } = useForm();
  const { id } = useParams<UrlParams>();

  const companyName = projects.find(project => project.id === Number(id))?.client_name ?? "";

  useEffect(() => {
    if (`${label}` in errors && selectInputRef.current) {
      register(selectInputRef.current);
      selectInputRef.current.focus();
    }
  }, [errors, label, register]);

  let selectInputRef = useRef<HTMLInputElement | null>(null);

  const chooseSelectInputOptions = (): JSX.Element | JSX.Element[] | undefined => {
    if (!selectedProject) {
      const companies: FeedbackCompany[] = projects.map((option: FeedbackProject) => ({
        id: option.id,
        name: option.client_name,
      }));

      const uniqueCompanies = companies.filter(
        (value: FeedbackCompany, index: number, self: FeedbackCompany[]) =>
          index === self.findIndex((t: FeedbackCompany) => t.name === value.name)
      );

      return uniqueCompanies.map((option: FeedbackCompany) => (
        <MenuItem
          key={option.id}
          value={option.name}
          onClick={() => {
            setSelectedCompany(option);
            changeProjectValue(option.name);
          }}
        >
          <span id="DropdownProjectId" data-index={option.id}>
            {option.name}
          </span>
        </MenuItem>
      ));
    } else {
      const selectedProjectCompany = projects?.find((option: FeedbackProject) => option.id === selectedProject.id);

      return (
        <MenuItem value={selectedProjectCompany?.client_name}>
          <span id="DropdownProjectId" data-index={selectedProjectCompany?.id}>
            {selectedProjectCompany?.client_name}
          </span>
        </MenuItem>
      );
    }
  };

  return (
    <Container className={classes.wrapper} data-testid="selectInput">
      <FormLabel error={Boolean(errors[`${label}`])} className={classes.inputLabelFont}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      <Controller
        as={
          <Select
            inputRef={(e) => {
              register(e);
              selectInputRef.current = e;
            }}
            variant="outlined"
            id={label}
            className={isLarge ? classes.inputSelectItemLarge : classes.inputSelectItem}
          >
            {chooseSelectInputOptions()}
          </Select>
        }
        name={`${label}`}
        control={control}
        rules={{ required: "Please select an option !" }}
        defaultValue={companyName}
      />
    </Container>
  );
};

export default InputSelectCompany;
