// external lib dependencies
import { FC, FormEvent } from "react";
import { useParams } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, TextField } from "@material-ui/core";

// absolute path dependencies
import { useFilesContext } from "providers/FilesContext";
import { deleteFile } from "services";
import { useDeleteFileDialogStyle } from "./styles";
import InputField from "components/FormInputField/InputField";
import { useForm } from "react-hook-form";
import { useAppSelector } from "store/hook";

interface Props {
  open: boolean;
  onClose: () => void;
  fileID: string;
  uploadedBy: string;
}

interface InputData {
  optional_note: string;
}

const DeleteFileDialog: FC<Props> = ({ open, onClose, fileID, uploadedBy }) => {
  const { control, handleSubmit, errors } = useForm<InputData>();
  const { id } = useParams<{ id?: string }>();
  const { fetchFiles } = useFilesContext();
  const classes = useDeleteFileDialogStyle();
  const { user } = useAppSelector((state) => state.user);

  const handleDelete = (data: InputData) => {
    deleteFile(fileID, data.optional_note).then(() => {
      fetchFiles(Number(id));
    });
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.modalContainer}>
      <DialogTitle>Confirm Delete File</DialogTitle>
      {user?.email === uploadedBy ? (
        <DialogContentText className={classes.content}>Are you sure you want to delete this file</DialogContentText>
      ) : (
        <DialogContentText className={classes.content}>
          Are you sure you want to delete this file? Please note <br />
          that <b>you did not upload this file</b> and the owner of the <br />
          file will receive an email notification about this <br />
          deletion.
        </DialogContentText>
      )}
      <form onSubmit={handleSubmit(handleDelete)}>
        {user?.email === uploadedBy ? (
          ""
        ) : (
          <InputField
            name="optional_note"
            control={control}
            textFieldClassName={classes.textField}
            textFieldPlaceholder="Optional note for the owner of the file"
            multiline={true}
            maxRows={2}
            validation={{
              maxLength: {
                value: 100,
                message: "Optional Note can not be less than 100 characters",
              },
            }}
            errors={errors?.optional_note?.message}
            labelText="Optional Note"
            renderFormLabel={false}
          />
        )}
        <DialogActions className={classes.buttonsContainer}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" color="primary" type="submit" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteFileDialog;
