import { useState, useCallback } from "react";
import { getIndividualFeedback } from "services/memberFeedback";
import { FeedbackResponse } from "../types";

const useFetchIndividualFeedback = () => {
    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState<string>('');
    const [data, setData] = useState<FeedbackResponse>();
    const fetch = useCallback(async (projectId: string, employeeId: string, page: number) => {
        setLoading(true);
        const errorMessage = "Well this is embarrassing... We couldn't take the data. Can you please try again later ?"
        try {
            const response = await getIndividualFeedback(projectId, employeeId, page);

            if (response) {
                setData(response);
            }
        } catch {
            setError(errorMessage);
        } finally {
            setLoading(false)
        }
    }, []);

    return { fetch, loading, error, data }
}

export default useFetchIndividualFeedback;
