import { TableColumn } from "./types";

export const columns: TableColumn[] = [
  {
    label: "Full name",
    filterable: true,
    filterBy: "name_filters",
  },
  {
    label: "Provider full name",
    filterable: false,
  },
  {
    label: "Project name",
    filterable: true,
    filterBy: "project_name",
  },
  {
    label: "Company name",
    filterable: true,
    filterBy: "company_name",
  },
  {
    label: "Date",
    filterable: false,
  },
  {
    label: "Feedback",
    filterable: false,
  },
];

export const teamColumns: TableColumn[] = [
  {
    label: "Team name",
    filterable: true,
    teamFilterBy: "team_filters",
  },
  {
    label: "Project name",
    filterable: true,
    teamFilterBy: "project_filters",
  },
  {
    label: "Provider full name",
    filterable: true,
    teamFilterBy: "provider_full_filters"
  },
  {
    label: "Date",
    filterable: false,
  },
  {
    label: "Feedback",
    filterable: false,
  },
];