import { Box, Tooltip, Typography, withStyles } from "@material-ui/core";
import { ReactComponent as TooltipImage } from "../../../assets/icons/commonIcons/alertCircle.svg";

import useStyles from "./styles";

const AccessLevelToolTip = withStyles((theme) => ({
  tooltip: {
    borderRadius: "6px",
    border: "1px solid EDEDED",
    backgroundColor: "#F6FBFD",
    color: "rgb(242,242,242)",
    boxShadow: theme.shadows[3],
  },
}))(Tooltip);

const InfoToolTip = () => {
  const classes = useStyles();

  return (
    <AccessLevelToolTip
      title={
        <Box className={classes.tooltipContent}>
          <Typography>
            <b>Advanced</b> - Full access
          </Typography>
          <Typography>
            <b>Standard</b> - Limited access to financial information
          </Typography>
        </Box>
      }
      placement="right-start"
    >
      <Box>
        <TooltipImage />
      </Box>
    </AccessLevelToolTip>
  );
};

export default InfoToolTip;
