import { useMemo } from "react";

/**
 * Custom hook that takes an array and returns the filtered elements as an arrey.
 * This function can filter array of complex elements like object but in this case
 * objectKey param should be used in order to choose on which property of the object it should be filtered.
 *
 * @type T Specifies the type of the returned value
 *
 * @param array An array of type any that will be filtered by the term param
 *
 * @param term String that will be split by space (' ') and then each element will be used to filter the array param
 *
 * @param minRequiredTermLength This parameter has default value 0. It is used to limited the filtration by the length of the term parametar
 *
 * @param objectKey Optional parametar of type string that shoould be used if the array param contains elements of type Object
 *
 * @returns An array that contains the element of the filtered array
 */
export function useFilterByTerm<T>(
  array: any[],
  term: string,
  minRequiredTermLength: number = 0,
  objectKey?: string,
): T[] {
  // not sure if useMemo or useCallback is the better choice or neither of them;
  const filteredArrayByName = useMemo(() => {
    if (term.length < minRequiredTermLength) {
      return [];
    }
    const arrayOfSearchTerms = term
      .toLowerCase()
      .trim()
      .split(" ")
      .map((item) => item.trim());

    const set = new Set<T>();
    for (const item of arrayOfSearchTerms) {
      array.forEach((x) => {
        if (objectKey === undefined) {
          if (x.toLowerCase() === item.toLowerCase() || x.toLowerCase().includes(item.toLowerCase())) {
            set.add(x);
          }
        } else {
          if (x[objectKey].toLowerCase() === item || x[objectKey].toLowerCase().includes(item.toLowerCase())) {
            set.add(x);
          }
        }
      });
    }
    return Array.from(set);
  }, [term, minRequiredTermLength, array, objectKey]);

  return filteredArrayByName;
}
