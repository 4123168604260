import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellBox: {
      display: "flex",
      alignItems: "center",
    },
    tableContainer: {
      maxHeight: "600px",
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: theme.palette.common.white,
      },
    },
    feedbackText: {
      maxWidth: 1300,
      wordWrap: "break-word",
    },
    tableRow: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    tableCell: {
      width: "22%",
    },
    centerBox: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(2, 0, 2, 0),
    },
    filterList: {
      overflowY: "auto",
      maxHeight: 320,
    },
    filterPopover: {
      "& .MuiPopover-paper ": {
        overflowY: "hidden",
        overflowX: "hidden",
        width: "300px",
        [theme.breakpoints.down("xs")]: {
          width: "220px",
        },
      },
    },
    filterButtonsBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
    },

    filterButton: {
      [theme.breakpoints.down("xs")]: {
        width: "45%",
      },
    },

    searchBarContainer: {
      padding: "10px",
    },
  }),
);

export default useStyles;
