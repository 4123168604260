// external lib dependencies
import React, { useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";

// local dependencies
import useStyles from "./styles";
import HeaderMemberCard from "./components/HeaderMemberCard/HeaderMemberCard";
import TagPopper from "./components/HeaderMemberCard/TagPopper";

interface HeaderTeamProps {
  engagement: any;
  tags: any[];
  loadingEngagement: boolean;
  canEdit: boolean;
  setEngagement: (p: any) => {};
}

const HeaderTeam = (props: HeaderTeamProps) => {
  const classes = useStyles();

  const [portfolioDirector, setPortfolioDirector] = useState<any>();
  const [clientPartner, setClientPartner] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>({
    employee_name: "",
    tags: [],
    member_role: "",
    type: "",
  });

  const [anchorMainMenu, setAnchorMainMenu] = useState<null | Element>(null);
  const [anchorTagMenu, setAnchorTagMenu] = useState<null | Element>(null);

  React.useEffect(() => {
    if (props.engagement.hasOwnProperty("header_tags")) {
      setClientPartner({
        employee_name: props.engagement.client_partner_name ? props.engagement.client_partner_name : props.engagement.default_client_partner_name,
        tags: props.engagement.header_tags.client_partner,
        member_role: "Client Partner",
        type: "client_partner",
      });

      setPortfolioDirector({
        employee_name: props.engagement.portfolio_director_name,
        tags: props.engagement.header_tags.portfolio_director,
        member_role: "Portfolio Director",
        type: "portfolio_director",
      });
    }
    return () => {
      setClientPartner(undefined);
    };
  }, [props.engagement]);

  const handleOpenMenu = (el: null | Element, item: any) => {
    setAnchorMainMenu(el);
    setSelectedItem(item);
  };

  const handleCloseMenu = () => {
    setAnchorMainMenu(null);
    if (!anchorMainMenu) setSelectedItem({ employee_name: "", tags: [], member_role: "", type: "" });
  };

  const handleOpenTagPopper = (el: null | Element, item: any = null) => {
    setAnchorTagMenu(el);
    if (item) {
      setSelectedItem(item);
    }
  };

  const handleCloseTagPopper = () => {
    setAnchorTagMenu(null);
    setSelectedItem({ employee_name: "", tags: [], member_role: "", type: "" });
  };

  return (
    <Box className={classes.mainBody}>
      <Box className={classes.mainBodyRow}>
        <Box flex="1 0 35%">
          <Box className={classes.avatarList}>
            <HeaderMemberCard
              item={portfolioDirector}
              handleOpenMenu={handleOpenMenu}
              handleOpenTagPopper={handleOpenTagPopper}
            />
            <HeaderMemberCard
              item={clientPartner}
              handleOpenMenu={handleOpenMenu}
              handleOpenTagPopper={handleOpenTagPopper}
            />
          </Box>
        </Box>
      </Box>
      <Popper
        open={Boolean(anchorMainMenu)}
        anchorEl={anchorMainMenu}
        transition
        disablePortal
        placement="right"
        modifiers={{
          preventOverflow: {
            escapeWithReference: true,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={Boolean(anchorMainMenu)}>
                  {props.canEdit && (
                    <MenuItem onClick={(e: React.MouseEvent) => handleOpenTagPopper(anchorMainMenu)}>
                      <LocalOfferOutlinedIcon fontSize="small" className={classes.menuIcon} />
                      Manage tags
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {anchorTagMenu && (
        <TagPopper
          item={selectedItem}
          tags={props.tags}
          anchorRef={anchorTagMenu}
          handleCloseTagPopper={handleCloseTagPopper}
          openTagMenu={Boolean(anchorTagMenu)}
          engagement={props.engagement}
          setEngagement={props.setEngagement}
        />
      )}
    </Box>
  );
};

export default HeaderTeam;
