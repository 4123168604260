// external lib dependencies
import React, { FC } from "react";
import { Avatar, Box, Button, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as TagIcon } from "../../../../../../assets/icons/memberCardIcons/tag.svg"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// absolute path dependencies
import { FILES_BASE_URL } from "config/constants";

// local dependencies
import useStyles from "./styles";
import {getEmployeesNameInitials} from "helpers/getEmployeesNameInitials";
import { useHistory, useParams } from "react-router-dom";

interface Props {
  item: any;
  handleOpenMenu: any;
  handleOpenTagPopper: (arg1: null | Element, arg2: any) => void;
}

const HeaderMemberCard: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<any>();

  const openProfileDirectorPage = () =>{
    if(props.item.type === 'portfolio_director')
    {
      history.push(`/engagements/${params.id}/portfolio-director`);
    }
  }

  return (
    <Box className={classes.memberCard}>
      <Box className={classes.meberCardWrap}>
        <Avatar
          onClick={openProfileDirectorPage} 
          src={
            props.item?.employee_photo_id ? `${FILES_BASE_URL}/images/${props.item?.employee_photo_id}/thumbnail/blob` : ""
          }
          classes={{
            root: props.item?.type === 'portfolio_director' ? classes.avatarRootCursor : classes.avatarRoot,
            colorDefault: props.item?.employee_name ? classes.avatarMainBackgroundColor : classes.avatarBackgroundDefault,
          }}
        >
          {getEmployeesNameInitials(props.item?.employee_name) || "CP"}
        </Avatar>
        <Box className={classes.memberDetails}>
            <Box className={classes.memberInfoContent}>
                <Typography variant="body2">
                  {props.item?.employee_name}
                </Typography>
                <Typography variant="body2">
                  {props.item?.member_role}
                </Typography>
            </Box>
        </Box>
        <IconButton
          className={classes.moreIcon}
          onClick={(e: React.MouseEvent) => props.handleOpenMenu(e.currentTarget, props.item)}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>
      <Box className={classes.tags}>
        <Button
          className={classes.manageTagIcon}
          onClick={(e: React.MouseEvent) => props.handleOpenTagPopper(e.currentTarget, props.item)}
        >
          <TagIcon />
        </Button>
          <Typography variant="body2">
            {props.item?.tags && props.item?.tags.map((i: any) => i.name).join(", ")}
          </Typography>
      </Box>
    </Box>
  );
};

export default HeaderMemberCard;
