import { Box, Chip, CircularProgress, Grid, Typography, withStyles } from "@material-ui/core";
import { FC, useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "store/hook";
import { deleteNoteAsync, fetchNotesAsync } from "store/reducers/releaseNotes/releaseNoteSlice";
import { ReleaseNote } from "config/types";
import { ReactComponent as Calendar } from "../../../assets/icons/commonIcons/calendar.svg";
import { ReactComponent as Extension } from "../../../assets/icons/releaseNotesIcons/extension.svg";

import useStyles from "../styles";
import ReleaseNoteCard from "./ReleaseNoteCard";

const StyledChip = withStyles({
  root: {
    background: "#F9D749",
    color: "#383838",
  },
})(Chip);

interface ReleaseNotesContentProps {
  isSuperUser: boolean;
}

const ReleaseNotesContent: FC<ReleaseNotesContentProps> = ({ isSuperUser }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { releaseNotes, status, error } = useAppSelector((state) => state.releaseNotes);

  useEffect(() => {
    dispatch(fetchNotesAsync());
  }, [dispatch]);

  const sortedNotes = useMemo(() => {
    return [...releaseNotes].sort(
      (note1: ReleaseNote, note2: ReleaseNote) =>
        Number(new Date(note2.release_date)) - Number(new Date(note1.release_date)),
    );
  }, [releaseNotes]);

  const handleNoteDelete = (note: ReleaseNote) => {
    dispatch(deleteNoteAsync(note));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.chipContainer}>
          <StyledChip icon={<Calendar />} label={<Typography variant="h6">Release Date</Typography>} />
          <StyledChip icon={<Extension />} label={<Typography variant="h6">Release Notes</Typography>} />
        </Box>
      </Grid>
      {status === "loading" && (
        <Grid item xs={12}>
          <Box className={classes.circularProgressContainer}>
            <CircularProgress />
          </Box>
        </Grid>
      )}
      {status === "succeeded" &&
        sortedNotes.map((note: ReleaseNote) => (
          <ReleaseNoteCard key={note.id} note={note} onDelete={handleNoteDelete} isSuperUser={isSuperUser} />
        ))}
      {status === "failed" && (
        <Grid item xs={12}>
          <Typography align="center" variant="h6">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ReleaseNotesContent;
