// external lib dependencies
import { useCallback, useState, useEffect } from "react";
import { SubmitHandler, FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Container, Slide, Box, Typography, Paper, Grid } from "@material-ui/core";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

// absolute path dependencies
import SectionTitleBar from "components/SectionTitleBar";
import useScreenSize from "hooks/useScreenSize";
import Button from "components/Button";
import Dropzone from "components/DropZone";

// local dependencies
import useStyles from "./styles";
import InputTextField from "./components/InputTextField";
import useSubmitHandler from "./hooks/useSubmitHandler";
import AlertModal from "./components/AlertModal";

interface FormValues {
  description: string;
  steps: string;
}

export default function SubmitBug() {
  const classes = useStyles();
  const methods = useForm();
  const { postData, loading, error, sent } = useSubmitHandler();
  const [files, setFiles] = useState<any>([]);
  const [acceptExtensions, setAcceptExtensions] = useState(["image/jpeg", "image/png"]);
  const maxSizeFiles = 4_000_000;
  const wrongSizeImageMessage = "Wrong image size";
  const wrongFormatImageMessage = "Wrong image format";

  const { isXS, isXL } = useScreenSize();
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const onSubmit: SubmitHandler<FormValues> = (answers) => {
    const data = new FormData();
    let isError = false;
    let errorMessage = "You have an error with your data";
    data.append("description", answers.description);
    data.append("steps", answers.steps);

    if (!files.length) {
      setOpenAlertDialog(true);
    } else {
      files.map((item: any) => {
        data.append("attachments", item.file);
        isError = item.isError;
        errorMessage = item.error ?? errorMessage;
      });

      if (isError) {
        alert(errorMessage);
      } else {
        postData(data);
      }
    }
  };
  const onSelectFile = (file: File | null, name: string | undefined, containerRef: any) => {
    if (file) setFiles([...files, { file: file, name: name, container: containerRef, isError: false, error: null }]);
    if (!file) setFiles(files.filter((item: any) => item.name !== name));
  };

  useEffect(() => {
    let size = 0;
    for (let item of files) {
      const muiChip = item.container.current.querySelector(".MuiChip-root");
      const muiChipSpan = item.container.current.querySelector(".MuiChip-root > span");
      muiChip.removeAttribute("style");
      muiChipSpan.innerText = muiChip.dataset.storeName;
      item.isError = false;

      if (!acceptExtensions.includes(item.file.type)) {
        muiChip.style.backgroundColor = "#f2473c";
        muiChipSpan.innerText = wrongFormatImageMessage;
        item.error = wrongFormatImageMessage;
        item.isError = true;
      }
      size += item.file.size;
      if (size > maxSizeFiles) {
        muiChip.style.backgroundColor = "#f2473c";
        muiChipSpan.innerText = wrongSizeImageMessage;
        item.error = wrongSizeImageMessage;
        item.isError = true;
      }

      if (Boolean(item.file.size === 0)) {
        muiChip.style.backgroundColor = "#f2473c";
        muiChipSpan.innerText = wrongSizeImageMessage;
        item.error = wrongSizeImageMessage;
        item.isError = true;
      }
    }
  }, [files]);

  const renderCloseButton = useCallback(
    () => (
      <Link className={classes.closeButton} to={`/engagements`}>
        <Button colorVariant="black" fullWidth>
          Close
        </Button>
      </Link>
    ),
    [classes],
  );

  return (
    <div className={classes.mainContainer} data-testid="SBContainer">
      <SectionTitleBar title="Report a bug on the Сlient Portal" renderAction={renderCloseButton} />
      <Slide in direction="up">
        <div>
          <FormProvider {...methods}>
            <Container maxWidth="xl" className={classes.formContainer}>
              {!sent && !error && (
                <form autoComplete="off" data-testid="SBForm" onSubmit={methods.handleSubmit(onSubmit)}>
                  <Paper className={classes.paperMargin}>
                    <Grid container className={classes.inputsContainer}>
                      <Grid item xs={12} md={12} lg={12} data-testid="SBInputTextDescription">
                        <InputTextField
                          question="Provide a short description of the problem"
                          label="description"
                          required
                        />
                      </Grid>

                      <Grid item xs={12} md={12} lg={12} data-testid="SBInputTextProblem">
                        <InputTextField
                          question="Describe how to reproduce the issue / problem"
                          label="steps"
                          required
                          multiline={true}
                          rows={4}
                        />
                      </Grid>
                      {isXL && !isXS && (
                        <Button
                          type="submit"
                          category="text"
                          colorVariant="black"
                          disabled={loading || Boolean(error)}
                          loading={loading}
                          className={classes.submitButton}
                          data-testid="SBSubmitButton"
                        >
                          Submit
                        </Button>
                      )}
                    </Grid>
                    <Grid container className={classes.dropZoneContainer}>
                      {[1, 2, 3, 4].map((number) => {
                        return (
                          <Grid item xs={12} md={5} lg={3} key={number}>
                            <Dropzone
                              name={`file${number}`}
                              variant="small"
                              message={`${isXL && !isXS ? "Click or drop image here" : "Tap here to upload image"}`}
                              accept={acceptExtensions.join(", ")}
                              onSelectFile={onSelectFile}
                            />
                          </Grid>
                        );
                      })}

                      {isXS && (
                        <Button
                          type="submit"
                          category="text"
                          colorVariant="black"
                          disabled={loading || Boolean(error)}
                          loading={loading}
                          data-testid="submitButton"
                          className={classes.submitButton}
                        >
                          Submit
                        </Button>
                      )}
                      <div className={classes.dropzoneText}>
                        <Typography component="span">
                          Important! The format of the uploaded images should be&nbsp;
                          {acceptExtensions.map((item: string, i, arr) => {
                            let ext = item.split("/")[1].toUpperCase();
                            if (i != arr.length - 1) {
                              ext += ",";
                            }
                            return ext + " ";
                          })}
                          with a maximum size 4Mb for all the attached files
                        </Typography>
                      </div>
                    </Grid>
                  </Paper>
                </form>
              )}
              {sent && !error && (
                <Box
                  component="span"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  data-testid="thankYouMessage"
                >
                  <SentimentVerySatisfiedIcon fontSize="large" style={{ marginBottom: "20px" }} />
                  <Typography variant="h5">
                    Thank you for your time to report this problem and for helping us improve Client Portal application
                    !
                  </Typography>
                </Box>
              )}
              {error && (
                <Box
                  component="span"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  data-testid="errorMessage"
                >
                  <SentimentVeryDissatisfiedIcon fontSize="large" />
                  <Typography variant="h5">{error}</Typography>
                </Box>
              )}
            </Container>
          </FormProvider>
        </div>
      </Slide>
      <AlertModal
        contentText={"Please select at least one file."}
        open={openAlertDialog}
        buttonText={"OK"}
        handleClose={handleCloseAlertDialog}
      />
    </div>
  );
}
