// external lib dependencies
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// local dependencies
import useStyles from "./styles";


const IEMessage = () => {
  const classes = useStyles();
  return (
    <div  className={classes.backgroundImage}>
      <img src="/images/logo-mini.svg" alt="logo" className={classes.logo} />
      <div className={classes.messageContainer}>
        <HighlightOffIcon data-testid="IEXIcon" className={classes.stopIcon} />
        <h1  data-testid="IEMessage" className={classes.message}>
          Your browser is not supported. Please upgrade to a newer browser like
          Chrome or Firefox.
        </h1>

        <div className={classes.browserIconsContainer}>
          <a data-testid="ChromeIcon"
            href="https://www.google.com/intl/en_us/chrome/"
            className={classes.browserLink}
          >
            <img
              src="/images/chrome.svg"
              alt="ChromeLogo"
              className={classes.browserIcon}
            />
          </a>
          <a data-testid="FireFoxIcon" href="https://www.mozilla.org/en-US/firefox" className={classes.browserLink}>
            <img
              src="/images/firefox.svg"
              alt="FireFoxLogo"
              className={classes.browserIcon}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default IEMessage;
