import { Redirect, useParams } from "react-router-dom";
import { Container } from "@material-ui/core";

import StakeholdersPageHeader from "./components/StakeholdersPageHeader";
import StakeholdersPageContent from "./components/StakeholdersPageContent";
import { useActions } from "store/hook";
import { useEffect } from "react";

interface Params {
  id?: string;
}

const StakeholdersPage = () => {
  const { id } = useParams<Params>();

  const { fetchEngagementById } = useActions();

  useEffect(() => {
    const abortController = new AbortController();
    fetchEngagementById({ id: Number(id), signal: abortController.signal });

    return () => {
      abortController.abort();
    };
  }, [id]);

  if (!id) return <Redirect to="/engagements" />;

  return (
    <Container maxWidth="xl">
      <StakeholdersPageHeader />
      <StakeholdersPageContent />
    </Container>
  );
};

export default StakeholdersPage;
