// external lib dependencies
import { Box, Card, CardContent, Fade } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import useStyles from "./styles";
import Button from "components/Button";
import SearchField from "../EngagementListScroll/components/SearchField";

const EngagementsGridSkeleton = () => {
  const classes = useStyles();
  const { isSM } = useScreenSize();

  return (
    <Box className={classes.cardContent}>
      <SearchField />
      <Fade in>
        <Card className={classes.engagementCard} elevation={0}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" justifyContent="space-between" flexDirection={isSM ? "column" : "row"}>
              <Box className={classes.engagementCardLeftSide}>
                <Box className={classes.projectDetails}>
                  <Skeleton className={classes.statusChipSkeleton} variant="rect" height="36px" width="106px" />
                  <Box sx={{ maxWidth: "250px", width: "100%" }}>
                    <Skeleton width={"100%"} height="32px" />
                    <Skeleton width={"100%"} height="22px" />
                  </Box>
                </Box>
                <Box className={classes.cardActionsSkeleton}>
                  <Box className={classes.cardActions}>
                    <Button colorVariant="black" className={classes.engagementCardButton}>
                      File Sharing
                    </Button>
                  </Box>
                  <Box>
                    <Skeleton width={"100%"} variant={"rect"} height="36px" />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Skeleton width="140px" height="92.1px" className={classes.textSkeleton} />
                <Skeleton width="140px" height="92.1px" className={classes.textSkeleton} />
                <Skeleton width="140px" height="92.1px" className={classes.textSkeleton} />
                <Skeleton width="140px" height="92.1px" className={classes.textSkeleton} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </Box>
  );
};

export default EngagementsGridSkeleton;
