// external lib dependencies
import { useRef, useState, useEffect } from "react";
import { Chip, Typography } from "@material-ui/core";

// absolute path dependencies
import Button from "components/Button";

// local dependencies
import useStyles from "./styles";

interface DropZoneProps {
  isLoading?: boolean;
  onSubmitFiles?: (files: File[]) => void;
  onSelectFile?: (files: File | null, name: string | undefined, containerRef: any) => void;
  variant?: string;
  message?: string;
  name?: string;
  accept?: string;
}

const Dropzone = ({
  isLoading = false,
  onSubmitFiles = () => {},
  onSelectFile = () => {},
  variant = "regular",
  message = "Drag & Drop files here or click to select file(s)",
  name,
  accept,
}: DropZoneProps) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [dropDepth, setDropDepth] = useState(0);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.style.border = dropDepth > 0 ? "4px dashed #76FF03" : "4px dashed #3F51B5";
    }
  }, [dropDepth]);

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    setDropDepth((prev) => prev + 1);
  };

  const dragLeave = (e: any) => {
    setDropDepth((prev) => prev - 1);
  };

  const handleFiles = (files: FileList) => {
    setSelectedFiles([files[0]]);
    onSelectFile(files[0], name, containerRef);
  };

  const removeFile = () => {
    setSelectedFiles([]);
    onSelectFile(null, name, containerRef);

    if(fileInputRef.current){
      fileInputRef.current.value = "";
    }
    
  };

  const fileDrop = (e: any) => {
    e.preventDefault();
    setDropDepth(0);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const fileSize = (size: number) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  };

  const filesSelected = () => {
    if (fileInputRef?.current && fileInputRef.current.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef && fileInputRef.current && fileInputRef.current.click();
  };

  const handleSubmit = () => {
    onSubmitFiles(selectedFiles);
  };

  if (variant === "small") {
    return (
      <div
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        ref={containerRef}
        data-testid="DZContainer"
        style={{height: "100px"}}
      >
        <div className={classes.dropMessage}>
          <Typography variant="subtitle2" data-testid="DZMessage">
            {selectedFiles.length === 0 && message}{" "}
          </Typography>
          {selectedFiles.length !== 0 &&
            selectedFiles.map((data: File, index: any) => (
              <Chip
                color="primary"
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                key={index}
                label={`${data.name} (${fileSize(data.size)})`}
                onDelete={removeFile}
                data-store-name={`${data.name} (${fileSize(data.size)})`}
              />
            ))}
        </div>
        <input
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          accept={accept}
          onChange={filesSelected}
          data-testid="DZFileInput"
        />
      </div>
    );
  }

  return (
    <div className={classes.dropMainContainer}>
      <div
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        ref={containerRef}
        data-testid="DZContainer"
      >
        <div className={classes.dropMessage} data-testid="DZMessage">
          {message}
        </div>
        <input
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          accept="
            .doc,
            .docx,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            text/csv,
            application/pdf,
            image/jpeg,
            image/png
          "
          onChange={filesSelected}
          data-testid="DZFileInput"
        />
      </div>
      <div className={classes.dropFilesContainer} data-testid="DZFilesContainer">
        {selectedFiles &&
          selectedFiles.map((data: File, index: any) => (
            <Chip
              color="primary"
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              key={index}
              label={`${data.name} (${fileSize(data.size)})`}
              onDelete={() => removeFile}
            />
          ))}
        {selectedFiles.length === 0 && <span>No files selected for upload!</span>}
      </div>
      <Button
        colorVariant="black"
        className={classes.dropButton}
        onClick={handleSubmit}
        loading={isLoading}
        data-testid="DZSubmitButton"
      >
        Submit
      </Button>
    </div>
  );
};

export default Dropzone;
