import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberCard: {
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.04);",
      padding: theme.spacing(1.25),
      position: "relative",
      display: "flex",
      gap: theme.spacing(1.25),
      width: "calc(33% - 12.5px)",
      flexDirection: "column",
      backgroundColor: "#FCFCFC",
      borderRadius: "8px",
      border: "1px solid #EDEDED",
      [theme.breakpoints.down("md")]: {
        width: "32%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "47%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        flexDirection: "column",
      },
    },

    meberCardWrap: {
      gap: theme.spacing(1.25),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    memberDetails: {
      //width: "80%",
      paddingRight: theme.spacing(3.5),
      [theme.breakpoints.down("xs")]: {
        paddingRight: theme.spacing(0),
      },
    },

    memberInfoContent: {
      display: "flex",
      flexDirection: "column",
      "& .MuiTypography-body2": {
        fontSize: "14.22px",
        lineHeight: "24px",
        wordBreak: "break-all",
        //overflow: "hidden",
        //textOverflow: "ellipsis",
        //whiteSpace: "nowrap",
      },
    },

    tags: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      "& .MuiButton-root":{
        minWidth: "auto",
      },
      "& .MuiButton-root > .MuiButton-label":{
        width: "auto",
      },
      "& .MuiTypography-body2": {
        fontSize: "12.64px",
        lineHeight: "24px",
        wordBreak: "break-all" 
      },
    },

    moreIcon: {
      position: "absolute",
      right: theme.spacing(1),
      cursor: "pointer",
      height: 24,
      width: 24,
    },

    avatarRoot: {
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
      cursor: "pointer"
    },

    avatarBackgroundDefault: {
      background: "#e0e0e0",
    },

    avatarMainBackgroundColor: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    menuIcon: {
      marginRight: theme.spacing(1),
    },

    popoverBox: {
      padding: theme.spacing(2),
      maxWidth: 250,
    },

    tagsBox: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1),
      flexWrap: "wrap",
    },

    tagsInput: {
      outline: 0,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0.5),
    },

    iconButton: {
      height: 24,
      width: 24,
    }
  })
);

export default useStyles;
