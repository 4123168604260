// external lib dependencies
import { useCallback } from "react";

// absolute path dependencies
import { Member } from "config/types";


const useTeamMembers =  () => {
  const getUniqueMemberList = useCallback( (members: Array<Member>) => {
    const now: Date = new Date();

    const activeMembers = members.filter((member) => {
      return new Date(member.end_date) >= now && member.employee_name;
    });

    const sortedActiveMembers = activeMembers.sort((member_one, member_two) =>
      member_one.employee_name.localeCompare(member_two.employee_name)
    );

    const uniqueMembers = Array.from(
      new Map(
        sortedActiveMembers.map((member) => [member.employee_id, member])
      ).values()
    );
    return uniqueMembers;
  }, []);

  return  getUniqueMemberList;
};

export default useTeamMembers;
