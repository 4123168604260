// external lib dependencies
import { Route, Switch, useLocation } from "react-router-dom";
import { Box, Theme } from "@material-ui/core";

// absolute path dependencies
import { allRoutes } from "config/routes";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useEffect, Fragment, useRef, createContext } from "react";
import LeftDrawer from "./LeftDrawer/LeftDrawer";

interface RouteListProps {
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
}

interface ScrollbarContextProps {
  scrollbar: React.RefObject<HTMLDivElement>;
}

export const ScrollbarContext = createContext<ScrollbarContextProps>({
  scrollbar: { current: null },
});

const RouteList = ({ setTheme }: RouteListProps) => {
  const { pathname } = useLocation();
  const scrollbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollbar.current) {
      scrollbar.current.scrollTop = 0;
      scrollbar.current.scrollLeft = 0;
    }
  }, [pathname]);

  return (
    <Fragment>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: "0 0 auto" }} style={{ userSelect: "none" }}>
          <LeftDrawer />
        </Box>
        <Box sx={{ flex: "1", overflow: "auto" }}>
          <NavBar setTheme={setTheme} />
          <div ref={scrollbar} className="withHorizontalScrollbar">
            <ScrollbarContext.Provider value={{ scrollbar }}>
              <Switch>
                {allRoutes.map(({ path, name, exact, component }) => (
                  <Route key={name} path={path} exact={exact} component={component} />
                ))}
              </Switch>
            </ScrollbarContext.Provider>
            <Footer />
          </div>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RouteList;
