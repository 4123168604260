import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { UrlParams } from "config/types";
import React, { useEffect } from "react";
import useStyles from "../styles";
import Row from "./Row";

import { Box, CircularProgress, Slide } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchTeamRequestsAsync } from "store/reducers/teamRequest/teamRequestSlice";
import { columns } from "../constants";

const RequestTable = () => {
  const classes = useStyles();
  const params = useParams<UrlParams>();
  const dispatch = useAppDispatch();
  const teamRequestState = useAppSelector((state) => state.teamRequest);
  const { teamRequests, status, error } = teamRequestState;
  const { id } = params;

  const { user } = useAppSelector((state) => state.user);

  function checkStakeholderStatus() {
    if (!user) return true;
    return user?.is_stakeholder ? true : false;
  }

  useEffect(() => {
    dispatch(fetchTeamRequestsAsync(id));
  }, [dispatch, id]);

  return (
    <React.Fragment>
      {status === "loading" ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : status === "failed" ? (
        <Typography variant="h4">{error}</Typography>
      ) : status === "succeeded" && teamRequests.length > 0 ? (
        <Slide in direction="up">
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {columns.map(({ key, label }) => (
                    <TableCell key={key}>{label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {teamRequests.map((request) => (
                  <Row key={request.id} request={request} stakeholderView={checkStakeholderStatus()} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Slide>
      ) : (
        <Box>
          <Typography variant="h4">There are no team requests</Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default RequestTable;
