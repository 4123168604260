// external lib dependencies
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 76,
      [theme.breakpoints.up("xs")]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "0 0 14px 14px",
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
      },
    },

    boxShadow: {
      boxShadow: theme.shadows[3],
    },

    upSmallBoxShadow: {
      [theme.breakpoints.up("sm")]: {
        boxShadow: theme.shadows[3],
      },
    },

    title: {
      [theme.breakpoints.down("xs")]: {
        "& .MuiTypography-root": {
          fontFamily: "Libre Franklin",
          fontSize: 20,
          fontWeight: 600,
        },
      },
    },

    moreBtn: {
      minWidth: "100px",
      padding: 0,
      letterSpacing: "1px",
      textTransform: "none",
      textDecoration: "underline",
    },

    buttonsBox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 0, 1, 0),
      },
    },

    headerLinkActive: {
      "& button": {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        "&:hover": {
          backgroundColor: "#007bff",
          color: theme.palette.common.white,
        },
      },
    },

    checkbox: {
      "&.MuiFormControlLabel-labelPlacementStart": {
        marginLeft: "0",
        marginRight: "0",
        marginBottom: "0",
      },
    },

    expandContainer: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    
  })
);

export default useStyles;
