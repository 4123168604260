// external lib dependencies
import * as dateMath from "date-arithmetic";
import { useCallback } from "react";
// absolute path dependencies
// local dependencies

const useYearSpan = () => {
  const getYearSpan = useCallback((engagements: any[]): number[] => {
    let yearSpan: number[] = [];

    engagements.forEach((eng: any) => {
      eng.work_orders?.forEach((wo: any) => {
        yearSpan.push(new Date(wo.start_date).getFullYear());
        yearSpan.push(new Date(wo.end_date).getFullYear());
      });
    });

    yearSpan = Array.from(new Map(yearSpan.map((year) => [year, year])).values());
    yearSpan = yearSpan.sort();

    return yearSpan;
  }, []);

  const getMinMaxDate = useCallback((engagement: any) => {
    let dateSpan: Date[] = [];

    engagement.work_orders.forEach((wo: any) => {
      dateSpan.push(new Date(wo.start_date));
      dateSpan.push(new Date(wo.end_date));
    });
    
    return { min: dateMath.min(...dateSpan), max: dateMath.max(...dateSpan) };
  }, []);

  return { getYearSpan, getMinMaxDate };
};
export default useYearSpan;
