// TODO: This type has to be completed with additional properies like employee_img, also there should be some concrete data about member_role
// At least we should know 4 important roles: delivery lead, client partner, brm, sales

export interface ClientInfoType {
  id?: number;
  company_name?: string;
  client_state?: number;
  registration_number?: string;
  post_code?: string;
  street?: string;
  city?: string;
  country?: string;
  domain?: string;
  client_partner_name?: string;
  brm_name?: string;
  sales_name?: string;
}
export interface ITeamFeedbackFormState {
  feedback_provider_fullname: string;
  project_name: string;
  team_id: string;
  feedback: string;
}

export const enum TeamViews {
  OversightAndEscalation = "Oversight and Escalation",
  Implementation = "Implementation",
  Ungrouped = "Ungrouped",
  NewTeam = "New Team",
}
