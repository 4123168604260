// external lib dependencies
import { useRef, useState, Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Slide, Button as MuiButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// absolute path dependencies
import { loginSendEmailService } from "services";
import Button from "components/Button";
import { auth } from "services/auth";

// local dependencies
import useStyles from "./styles";

interface Props {
  setEmail: Dispatch<SetStateAction<string>>;
  handleClick: () => Promise<void>;
}

const EmailForm = ({ setEmail, handleClick }: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, errors, setError } = useForm();
  const loginFormRef = useRef<HTMLFormElement>(null);
  const [historyState, _] = useState<any>(history.location.state);

  const handleFormSubmit = (event: any) => {
    event.preventDefault();

    if (!event.target.email.value) {
      return;
    }

    const rememberMe = event.target.remember.checked;
    const email = event.target.email.value;

    if (rememberMe && email) {
      localStorage.setItem("email", email);
      localStorage.setItem("remember", rememberMe);
    }

    setLoading(true);
    //backend email info is case sensitive
    loginSendEmailService(email, historyState.referrerLink)
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setEmail(email);
          return;
        }

        if (res.status === 401) {
          auth.forceLogout();
        }

        return Promise.reject(res);
      })
      .catch(() => {
        setLoading(false);
        if (email.endsWith("@amdaris.com")) {
          setError("email", {
            type: "invalid",
            message: "Please login as Amdaris Team member.",
          });
        } else {
          setError("email", {
            type: "invalid",
            message: "Please contact Amdaris to verify your access to Client Portal.",
          });
        }
      });

    return false;
  };

  const style = useStyles();
  return (
    <>
      <Slide in direction={"up"}>
        <Form className={style.loginFormContainer} onSubmit={handleFormSubmit} ref={loginFormRef}>
          <Form.Control
            id="email"
            type="email"
            placeholder="Enter email"
            className={style.loginFormInput}
            name="email"
            defaultValue={localStorage.getItem("email") || ""}
            ref={register({ required: "Please enter email" })}
          />
          <Form.Check
            type="checkbox"
            name="remember"
            className={style.checkboxInput}
            defaultChecked={!!localStorage.getItem("remember")}
            label="Remember me"
          />
          <Button type="submit" className={style.loginBtn} disabled={loading} loading={loading}>
            SUBMIT
          </Button>
          <MuiButton onClick={handleClick} className={style.teamMemberBtn}>
            Log in as Amdaris team member
          </MuiButton>
        </Form>
      </Slide>
      {errors && errors.email && (
        <div
          className={style.errorMsg}
          style={{
            width: loginFormRef.current ? `${loginFormRef.current.clientWidth}px` : "350px",
          }}
        >
          {errors.email.message}
        </div>
      )}
    </>
  );
};

export default EmailForm;
