import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InputField from "components/FormInputField/InputField";
import { FC, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useStyles from "./styles";
import clsx from "clsx";
import SelectField from "components/SelectField/SelectField";
import WarningModal from "components/WarningModal/WarningModal";
import { ISelectOptions } from "components/SelectField/types";
import { ITeamFeedbackFormState } from "pages/Team/types";
import { createGroupTeamFeedback } from "services/groupTeam";
import { FormStatus } from "config/types";

interface TeamFeedbackModalProps {
  teamView: string;
  providerName: string;
  projectName: string;
  teamNames: ISelectOptions[];
  openFeedback: boolean;
  handleTeamFeedbackDialog: () => void;
}

export const TeamFeedbackModal: FC<TeamFeedbackModalProps> = ({
  openFeedback,
  handleTeamFeedbackDialog,
  teamNames,
  teamView,
  providerName,
  projectName,
}) => {
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ITeamFeedbackFormState>({
    mode: "onChange",
  });

  const classes = useStyles();
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormStatus>("idle");
  const controllerRef = useRef<AbortController | null>(null);

  const onSubmit: SubmitHandler<ITeamFeedbackFormState> = async (data: ITeamFeedbackFormState) => {
    const controller = new AbortController();
    controllerRef.current = controller;

    setFormState("sending");
    try {
      await createGroupTeamFeedback(data, controllerRef.current.signal);

      setFormState("success");
    } catch (error) {
      setFormState("error");
    }
  };

  const handleConfirm = () => {
    setOpenWarning(false);
    handleTeamFeedbackDialog();
  };

  const handleClose = () => {
    const feedback = getValues("feedback");
    if (feedback && feedback.trim().length && formState !== "sending") {
      setOpenWarning(true);
      return;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    handleTeamFeedbackDialog();
  };

  const setDefaultSelectValue = (): string | null => {
    const selectOption = teamNames.find((item) => item.label === teamView);

    return selectOption ? selectOption.value : null;
  };

  return (
    <>
      <Dialog
        open={openFeedback}
        onClose={handleClose}
        className={clsx(classes.dialogModal, classes.dialogModalOffset)}
        fullWidth={true}
      >
        {(formState === "idle" || formState === "error" || formState === "sending") && (
          <>
            <DialogTitle className={classes.dialogTitle}>Team Feedback</DialogTitle>
            {formState === "error" && <Alert severity="error">{"Something went wrong"}</Alert>}
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <InputField
                      disabled={true}
                      defaultValue={providerName}
                      required={true}
                      requiredSymbol={true}
                      name="feedback_provider_fullname"
                      labelText="Provider Name"
                      control={control}
                      errors={errors.feedback_provider_fullname?.message}
                    />
                  </Grid>
                  <Grid item>
                    <InputField
                      disabled={true}
                      defaultValue={projectName}
                      required={true}
                      requiredSymbol={true}
                      name="project_name"
                      labelText="Project Name"
                      control={control}
                      errors={errors.project_name?.message}
                    />
                  </Grid>
                  <Grid item>
                    <SelectField
                      tooltip={false}
                      defaultValue={setDefaultSelectValue()}
                      required={true}
                      control={control}
                      name="team_id"
                      labelText="Team Name"
                      requiredSymbol={true}
                      selectOptions={teamNames}
                      errors={errors.team_id?.message}
                    />
                  </Grid>
                  <Grid item>
                    <InputField
                      textFieldClassName=""
                      required={true}
                      requiredSymbol={true}
                      name="feedback"
                      labelText="Feedback"
                      minRows={7}
                      control={control}
                      multiline={true}
                      validation={{
                        maxLength: {
                          value: 400,
                        },
                      }}
                      errors={errors.feedback?.message}
                    />
                  </Grid>
                </Grid>
                <DialogActions className={classes.dialogActionContent}>
                  <Button variant="contained" color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit" disabled={formState === "sending"}>
                    Send
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </>
        )}
        {formState === "success" && (
          <>
            <DialogContent>
              <DialogContentText>Feedback was successfully added to the team.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <WarningModal
        open={openWarning}
        setOpenWarning={setOpenWarning}
        handleConfirm={handleConfirm}
        title={"Warning"}
        contentText={"Are you sure you want to cancel? Your feedback won't be saved"}
      />
    </>
  );
};
