// external lib dependencies
import { FC } from "react";
import Box from "@material-ui/core/Box";

// absolute path dependencies
import { Member } from "config/types";

// local dependencies
import useStyles from "./styles";
import MemberCard from "../MemberCard/MemberCard";

interface Props {
  members: Member[];
  canEdit: boolean;
}

const EveryoneView: FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.avatarList}>
      {props.members.map((item) => (
        <MemberCard key={item.employee_id} item={item} canEdit={props.canEdit} />
      ))}
    </Box>
  );
};

export default EveryoneView;
