import DateFnsUtils from "@date-io/date-fns";
import { Box, FormControlLabel, MenuItem, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { RequestContent, RequestTeam, UrlParams } from "config/types";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useStyles from "../styles";

import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/hook";
import {
  createTeamRequestAsync,
  RequestTeamParams,
  updateTeamRequestAsync,
} from "store/reducers/teamRequest/teamRequestSlice";
import DialogRow from "./DialogRow";
import { durations, positions, skills } from "../constants";

interface RequestTeamDialogProps {
  open: boolean;
  handleClose: () => void;
  teamRequest?: RequestTeam;
}

const RequestTeamDialog: FC<RequestTeamDialogProps> = (props: RequestTeamDialogProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams<UrlParams>();
  const { id } = params;
  const { open, handleClose, teamRequest } = props;
  const [teamMembers, setTeamMembers] = useState<RequestContent[]>([]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!teamRequest) return;

    setTeamMembers(teamRequest?.request_content);
  }, [teamRequest]);

  const handleAddTeamMembers = () => {
    const values: RequestContent = getValues([
      "member_role",
      "primary_skill",
      "member_type_quantity",
      "member_start_date",
      "member_duration",
    ]);

    if (!Object.values(values).every((value: any) => value)) {
      return;
    }
    values.id = crypto.randomUUID();
    setTeamMembers((prevState: RequestContent[]) => [...prevState, values]);
    setValue("member_role", "");
    setValue("primary_skill", "");
    setValue("member_type_quantity", 1);
    setValue("member_start_date", new Date().toISOString());
    setValue("member_duration", "");
  };

  const onRemove = useCallback(
    (requestMember: RequestContent) => {
      const updatedMembers = teamMembers.filter((member) => member.id !== requestMember.id);
      setTeamMembers(updatedMembers);
    },
    [teamMembers],
  );

  const onSubmit = () => {
    if (!teamMembers.length) return;

    const request_comment = getValues("request_comment");
    const is_new = getValues("is_new");

    if (teamRequest) {
      const teamRequestUpdate: RequestTeam = {
        ...teamRequest,
        id: teamRequest.id,
        project_id: parseInt(id),
        request_content: teamMembers,
        request_comment,
        is_new: !is_new,
      };

      dispatch(updateTeamRequestAsync(teamRequestUpdate));
      handleClose();
    } else {
      const teamMembersRequest: RequestTeamParams = {
        request_content: teamMembers,
        request_comment,
        project_id: parseInt(id),
      };
      dispatch(createTeamRequestAsync(teamMembersRequest));
      setTeamMembers([]);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog} maxWidth="lg">
      <DialogTitle>
        <Typography className={classes.dialogTitle}>Request new team members</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <TableContainer>
              <Table className={classes.dialogTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Controller
                        name="member_role"
                        control={control}
                        defaultValue=""
                        render={({ value, onChange }) => (
                          <TextField
                            select
                            label="Position"
                            variant="outlined"
                            className={classes.position}
                            value={value}
                            onChange={(value) => onChange(value)}
                          >
                            {positions.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name="primary_skill"
                        control={control}
                        defaultValue=""
                        render={({ value, onChange }) => (
                          <TextField
                            select
                            label="Primary skill"
                            variant="outlined"
                            className={classes.skill}
                            value={value}
                            onChange={(value) => onChange(value)}
                          >
                            {skills.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name="member_type_quantity"
                        control={control}
                        defaultValue={1}
                        render={({ value, onChange }) => (
                          <TextField
                            type="number"
                            label="Quantity"
                            variant="outlined"
                            className={classes.quantity}
                            value={value}
                            onChange={(value) => onChange(value)}
                            inputProps={{
                              min: 1,
                              max: 20,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name="member_start_date"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Date field is required",
                          },
                        }}
                        defaultValue={new Date().toISOString()}
                        render={({ value, onChange }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.start}
                              disableToolbar
                              disablePast
                              variant="inline"
                              inputVariant="outlined"
                              format="yyyy-MM-dd"
                              margin="normal"
                              id="date-picker-inline"
                              placeholder="Start date"
                              autoOk
                              value={value}
                              onChange={(date: any) => onChange(date.toISOString())}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        name="member_duration"
                        control={control}
                        defaultValue=""
                        render={({ value, onChange }) => (
                          <TextField
                            select
                            label="Duration"
                            variant="outlined"
                            className={classes.duration}
                            value={value}
                            onChange={(value) => onChange(value)}
                          >
                            {durations.map(({ label, value }) => (
                              <MenuItem key={label} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={handleAddTeamMembers} className={classes.addMemberIcon}>
                        <AddIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamMembers &&
                    teamMembers.map((member, idx) => (
                      <DialogRow key={idx} memberRequest={member} onRemoveMember={onRemove} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            <Controller
              name="request_comment"
              control={control}
              defaultValue={teamRequest?.request_comment}
              rules={{
                maxLength: {
                  value: 300,
                  message: `Comment can not be more than 300 characters`,
                },
              }}
              render={({ value, onChange }) => (
                <TextField
                  error={Boolean(errors.request_comment?.message)}
                  helperText={errors.request_comment?.message || ""}
                  multiline
                  maxRows={3}
                  className={classes.comment}
                  variant="outlined"
                  placeholder="Add your comment (optional)"
                  value={value}
                  onChange={(value) => onChange(value)}
                />
              )}
            />
          </Box>
          {teamRequest && (
            <Box className={classes.checkboxContainer}>
              <Controller
                name="is_new"
                control={control}
                defaultValue={!teamRequest.is_new}
                render={({ value, onChange }) => (
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={value} onChange={(e) => onChange(e.target.checked)} />}
                    label="Mark as completed"
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.dialogCancel} color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" className={classes.dialogSend} color="primary">
            {teamRequest ? "Update" : "Send request"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RequestTeamDialog;
