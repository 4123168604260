// external lib dependencies
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { isIE } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";

// absolute path dependencies
import App from "App";
import IEMessage from "pages/InternetExplorer/IEMessagePage";
import "assets/styles/global.scss";


if (isIE) {
  ReactDOM.render(<IEMessage />, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}
