import { ReactComponent as Briefcase } from "../../assets/icons/leftDrawerIcons/briefcase.svg";
import { ReactComponent as BriefcaseBlack } from "../../assets/icons/leftDrawerIcons/briefcaseBlack.svg";
import { ReactComponent as Book } from "../../assets/icons/leftDrawerIcons/book.svg";
import { ReactComponent as BookBlack } from "../../assets/icons/leftDrawerIcons/bookBlack.svg";
import { ReactComponent as Bell } from "../../assets/icons/leftDrawerIcons/bell.svg";
import { ReactComponent as BellBlack } from "../../assets/icons/leftDrawerIcons/bellBlack.svg";
import { ReactComponent as ThumbsUp } from "../../assets/icons/leftDrawerIcons/thumbs-up.svg";
import { ReactComponent as ThumbsUpBlack } from "../../assets/icons/leftDrawerIcons/trumbs-up-black.svg";
import { ReactComponent as Zap } from "../../assets/icons/leftDrawerIcons/zap.svg";
import { ReactComponent as ZapBlack } from "../../assets/icons/leftDrawerIcons/zapBlack.svg";
import { ReactComponent as People } from "../../assets/icons/leftDrawerIcons/people.svg";
import { ReactComponent as PeopleBlack } from "../../assets/icons/leftDrawerIcons/people-black.svg";
import {
  ENGAGEMENTS_PATH,
  NOTIFICATION_PATH,
  PEOPLE_FEEDBACK_PATH,
  RELEASE_NOTES_PATH,
  VIEW_FEEDBACK_PATH,
  WORK_ORDERS_PATH,
} from "config/constants";

interface NavList {
  label: string;
  path: string;
  blackIcon: any;
  whiteIcon: any;
}
const navListData: Array<NavList> = [
  {
    label: "Engagements",
    path: ENGAGEMENTS_PATH,
    blackIcon: <BriefcaseBlack />,
    whiteIcon: <Briefcase />,
  },
  {
    label: "Work orders",
    path: WORK_ORDERS_PATH,
    blackIcon: <BookBlack />,
    whiteIcon: <Book />,
  },
  {
    label: "Service Feedback",
    path: VIEW_FEEDBACK_PATH,
    blackIcon: <ThumbsUpBlack />,
    whiteIcon: <ThumbsUp />,
  },
  {
    label: "People Feedback",
    path: PEOPLE_FEEDBACK_PATH,
    blackIcon: <PeopleBlack />,
    whiteIcon: <People />,
  },
  {
    label: "Notifications",
    path: NOTIFICATION_PATH,
    blackIcon: <BellBlack />,
    whiteIcon: <Bell />,
  },
  {
    label: "What’s new?",
    path: RELEASE_NOTES_PATH,
    blackIcon: <ZapBlack />,
    whiteIcon: <Zap />,
  },
];

export default navListData;
