// external lib dependencies
import { MouseEvent, KeyboardEvent } from "react";
import { List, ListItem, Typography} from "@material-ui/core";

// absolute path dependencies
import { allRoutes } from "config/routes";
import { VoidFunc } from "config/types";
//import { useThemeStruct } from "themes";
import Button from "components/Button";
import { useAuth } from "providers/AuthContext";

// local dependencies
import SideDrawerItem from "./SideDrawerItem";
//import { useSideDrawerStyles } from "./styles";

interface SideDrawerProps {
  toggleMenu: (_: MouseEvent | KeyboardEvent) => void;
  changeTheme: VoidFunc;
}

const SideDrawer = ({ toggleMenu /*, changeTheme*/ }: SideDrawerProps) => {
  //const classes = useSideDrawerStyles();
  //const themeStruct = useThemeStruct();
  const { logout } = useAuth();

  return (
    <List role="presentation">
      {allRoutes.map((route, index) => {
        if (route.navigation?.displayDrawer) {
          if (route.navigation.drawerDisplayMode === "collapse") {
            return (
              <SideDrawerItem
                key={index}
                drawerItemTitle={route.name}
                drawerDisplayMode="collapse"
                CollapseItemList={route.navigation.collapsedList}
                toggleMenu={toggleMenu}
              />
            );
          } else if (route.navigation.drawerDisplayMode === "normal") {
            return (
              <SideDrawerItem
                key={index}
                drawerItemTitle={route.name}
                drawerDisplayMode="normal"
                toggleMenu={toggleMenu}
                path={route.path}
              />
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      })}
      {/* <ListItem>
        <List style={{ width: "100%" }}>
          <ListItem divider className={classes.listItem}>
            <Typography variant="h5" onClick={() => changeTheme()} classes={{ root: classes.sideMenuItem }}>
              Dark Mode
            </Typography>
            <Switch color="primary" checked={themeStruct.isDark} onChange={() => changeTheme()} />
          </ListItem>
        </List>
      </ListItem> */}
      <ListItem>
        <Button colorVariant="black" fullWidth onClick={() => logout()}>
          <Typography variant="body1">Log Out</Typography>
        </Button>
      </ListItem>
    </List>
  );
};

export default SideDrawer;
