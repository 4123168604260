// external lib dependencies
import { useState, useCallback } from "react";

// absolute path dependencies
import { editProjectTag } from "services/tag";

const useEditHeaderTeamTags = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const callback = useCallback(async (projectId: any, data: any) => {
    setLoading(true);
    setIsSuccess(false);
    setError("");
    const errorMessage = "Error!";
    try {
      const fetch = await editProjectTag(projectId, data);
      setIsSuccess(fetch.ok);

      const response = await fetch.json();
      if (!fetch.ok) {
        setError(response.detail ? response.detail : response ?? errorMessage);
      }
    } catch (err) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { callback, loading, error, isSuccess };
};

export default useEditHeaderTeamTags;
