// external lib dependencies
import * as dateMath from "date-arithmetic";
import { useCallback } from "react";
// absolute path dependencies
// local dependencies

const useBarPercent = () => {
  const daysInYear = useCallback((selectedYear) => {
    if ((selectedYear % 4 === 0 && selectedYear % 100 !== 0) || selectedYear % 400 === 0) {
      return 366;
    }
    return 365;
  }, []);

  const getBarPercents = useCallback((startDate, endDate, selectedYear) => {
    let startPercentage: number = 0;
    let endPercentage: number = 0;
    let diffStart: number = 0;
    let diffEnd: number = 0;
    const oneYearInDays: number = daysInYear(selectedYear);
    const yearStart = new Date(`${selectedYear}-01-01T00:00:00`);
    const yearEnd = new Date(`${selectedYear}-12-31T00:00:00`);
    
    if (yearStart > new Date(startDate)) {
      startPercentage = 0;
    } else {
      diffStart = dateMath.diff(yearStart, new Date(startDate), "day");
      startPercentage = (diffStart / oneYearInDays) * 100;
    }

    if (yearEnd < new Date(endDate)) {
      endPercentage = 100 - startPercentage;
    } else {
      diffEnd = dateMath.diff(new Date(endDate), yearEnd, "day");
      endPercentage = 100 - (diffEnd / oneYearInDays) * 100 - startPercentage;
    }

    return {startPerc: startPercentage, endPerc: endPercentage };
  }, [daysInYear]);

  const getTimePassedPercent = useCallback((startDate: Date, endDate: Date) => {
    const today: Date = new Date()

    if (today >= endDate) {
      return 100
    }
    if (today <= startDate) {
      return 0
    }
    
    const daysInPeriod = diffDays(startDate, endDate)
    const daysTillNow = diffDays(startDate, today)
    
    return Math.floor(daysTillNow / daysInPeriod * 100)
  }, []);

  const diffDays = (start: Date, end: Date) => {
    const msPerDay = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
    const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

    return Math.floor((utc2 - utc1) / msPerDay);
  }

  return { getBarPercents, getTimePassedPercent };
};
export default useBarPercent;
