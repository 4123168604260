// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  // common styles
  engagementCard: {
    height: "100%",
    borderRadius: 14,
    margin: theme.spacing(3, 0, 3, 0),
  },

  engagementCardLeftSide: {
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      marginRight: "0",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  engagementCardRightSide: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      flex: "1",
      width: "100%",
    },
  },

  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  updateTypography: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },

  cardActions: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(8, 0, 0, 0),
    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4, 0, 0, 0),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  linkBtn: {
    width: "100%",
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },

  engagementCardButton: {
    width: "100%",
    margin: theme.spacing(1, 0, 1, 0),
  },

  // EngagementsGridSkeleton styles
  halfWidth: {
    width: "50%",
  },

  statusChipSkeleton: {
    borderRadius: theme.spacing(4.25),
  },

  cardActionsSkeleton: {
    width: theme.spacing(37.5),
  },

  projectDetails: {
    display: "flex",
    gap: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },

  hoverBoxLink: {
    textDecoration: "none !important",
    color: "#000000DE !important",
    width: "25%",
    cursor: "pointer",
    textAlign: "center",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    "&:hover": {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  hoverFlexboxDetails: {
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },

  hoverFlexboxAudits: {
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },

  hoverBoxText: {
    marginBottom: theme.spacing(1),
  },

  auditText: {
    margin: theme.spacing(1, 0, 1, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  noAuditsBox: {
    width: "25%",
    textAlign: "center",
    padding: theme.spacing(2),
  },

  noAuditsText: {
    marginTop: theme.spacing(1),
  },

  textSkeleton: {
    margin: theme.spacing(0, 2.6),
  },
}));

export default useStyles;
