// external lib dependencies
import { Dialog, DialogTitle, List, ListItem, ListItemText, Typography, Box } from "@material-ui/core";
import { FC, useEffect, useState } from "react";

// absolute path dependencies
import { Member } from "config/types";

// local dependencies
import useEditHandler from "./useEditHandler";
import useStyles from "../styles";
import Alert from "@material-ui/lab/Alert";

interface GroupTeam {
  id: string;
  name: string;
  member_assignments: object[];
  project_id: any;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  item: Member;
  groupTeams: GroupTeam[];
  setGroupTeams: any;
  projectId: any;
}

const AddMemberPopup: FC<Props> = ({ open, handleClose, item, groupTeams, setGroupTeams, projectId }) => {
  const generalClasses = useStyles();
  const { callback, loading, error, isSuccess } = useEditHandler();
  const [currentGroupTeam, setCurrentGroupTeam] = useState<any>();
  
  const handleGroupTeamsClick = (team: GroupTeam) => {
    let newTeamMembers = [...team.member_assignments, item];
    setCurrentGroupTeam({ ...team, member_assignments: newTeamMembers });
    callback(projectId, team.id, newTeamMembers);
  };

  useEffect(() => {
    if (isSuccess) {
      setGroupTeams(
        groupTeams.map((value: GroupTeam) => {
          if (value.id === currentGroupTeam.id) {
            return { ...value, member_assignments: currentGroupTeam.member_assignments };
          }
          return { ...value };
        })
      );
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Dialog className={generalClasses.dialogModal} onClose={handleClose} open={open}>
      <DialogTitle className={generalClasses.dialogTitle}>Add member to a team</DialogTitle>
      {error && <Alert severity="error">{error}</Alert>}
      {groupTeams.length === 0 ? (
        <Box style={{ textAlign: "center", padding: "1rem" }}>
          <Typography variant="body1">There are currently no teams created</Typography>
        </Box>
      ) : (
        <List className={generalClasses.dialogList}>
          {groupTeams?.map((team, i) => (
            <ListItem button disabled={loading} onClick={() => handleGroupTeamsClick(team)} key={i}>
              <ListItemText className={generalClasses.dialogListItemText} primary={team.name} />
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  );
};

export default AddMemberPopup;
