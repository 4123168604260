import { AnyAction, PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTIVE_PROJECT_STATUS, EXPIRED_PROJECT_STATUS } from "config/constants";
import { Engagement } from "config/types";
import { getEngagementStatusByWorkOrders } from "helpers/engagementStatus";
import { getEngagementsPaginated } from "services";

interface InitialState {
  activeEngagementList: Engagement[] | null;
  expiredEngagementList: Engagement[] | null;
  loadingActiveEngagements: boolean;
  loadingExpiredEngagements: boolean;
  error: string | null;
}

interface EngagementListParams {
  page_number: number;
  my_engagements_only: boolean;
  search_input?: string;
}

export const fetchActiveEngagementList = createAsyncThunk<Engagement[], EngagementListParams, { rejectValue: string }>(
  "engagementList/fetchActiveEngagementList",
  async ({ page_number, my_engagements_only, search_input }, { rejectWithValue }) => {
    try {
      const result = await getEngagementsPaginated(
        ACTIVE_PROJECT_STATUS,
        page_number,
        search_input,
        my_engagements_only,
      );
      return result.map((engagement: Engagement) => ({
        ...engagement,
        status: getEngagementStatusByWorkOrders(engagement.work_orders),
      }));
    } catch (error: any) {
      return rejectWithValue(error.statusText);
    }
  },
);

export const fetchExpiredEngagementList = createAsyncThunk<Engagement[], EngagementListParams, { rejectValue: string }>(
  "engagementList/fetchExpiredEngagementList",
  async ({ page_number, my_engagements_only, search_input }, { rejectWithValue }) => {
    try {
      const result = await getEngagementsPaginated(
        EXPIRED_PROJECT_STATUS,
        page_number,
        search_input,
        my_engagements_only,
      );
      return result.map((engagement: Engagement) => ({
        ...engagement,
        status: getEngagementStatusByWorkOrders(engagement.work_orders),
      }));
    } catch (error: any) {
      return rejectWithValue(error.statusText);
    }
  },
);

const initialState: InitialState = {
  activeEngagementList: null,
  expiredEngagementList: null,
  loadingActiveEngagements: false,
  loadingExpiredEngagements: false,
  error: null,
};

const engagementsListSlice = createSlice({
  name: "engagementsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveEngagementList.pending, (state: InitialState, action) => {
        state.loadingActiveEngagements = true;
      })
      .addCase(fetchActiveEngagementList.fulfilled, (state: InitialState, action: PayloadAction<Engagement[]>) => {
        state.activeEngagementList = action.payload;
        state.loadingActiveEngagements = false;
      })
      .addCase(fetchExpiredEngagementList.pending, (state: InitialState, action) => {
        state.loadingExpiredEngagements = true;
      })
      .addCase(fetchExpiredEngagementList.fulfilled, (state: InitialState, action: PayloadAction<Engagement[]>) => {
        state.expiredEngagementList = action.payload;
        state.loadingExpiredEngagements = false;
      })
      .addMatcher(isError, (state: InitialState, action: PayloadAction<string>) => {
        state.loadingActiveEngagements = false;
        state.loadingExpiredEngagements = false;
        state.error = action.payload;
      });
  },
});

const isError = (action: AnyAction) => {
  return action.type.endsWith("rejected");
};

export default engagementsListSlice.reducer;
