// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

export const useLeftDrawerStyles = makeStyles((theme: Theme) => ({
  drawerLogoMain: {
    backgroundColor: "#132536",
    width: "100%",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  drawerLogoBlock: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  drawerLogoClose: {
    justifyContent: "center",
  },

  drawerLogoOpen: {
    width: "100%",
    justifyContent: "initial",
    paddingLeft: "15px",
  },

  mainBlockDrawer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#03172B",
    height: "100%",
    overflow: "hidden",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  buttonToggle: {
    cursor: "pointer",
    "&:hover": {},
  },

  NavLinknoUnderline: {
    "&:hover": {
      textDecoration: "none",
    },
  },

  navList: {
    marginTop: "190px",
  },

  listItem: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "25px",
    height: "40px",
  },

  listItemIcon: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#071e4e",
      borderRadius: "5px 5px 5px 5px",
    },
  },

  activeListItemIcon: {
    backgroundColor: "#ffffff",
    borderRadius: "5px 5px 5px 5px",

    "& $listItemIcon:hover": {
      backgroundColor: "transparent",
      borderRadius: "initial",
    },
  },

  listItemText: {
    height: "100%",
    fontSize: "18px",
    fontFamily: "Roboto, sans-serif",
    color: "#C6D0DB",
    width: "100%",
    paddingLeft: "19px",
    whiteSpace: "nowrap",
  },

  notification: {
    "& .MuiBadge-badge": {
      right: "-8%",
      top: "-8%",
      height: "11px",
      minWidth: "11px",
      borderRadius: "6px",
    },
  },
}));
