// absolute path dependencies
import { AnyPropsComponent } from "config/types";

// local dependencies
import useStyles from "./styles";


const LoadingIndicator: AnyPropsComponent = (props) => {
  const classes = useStyles();

  return (
    <div {...props}>
      <img src="/images/spinner.gif" alt="loading" className={classes.loadingSpinner} />
    </div>
  );
};

export default LoadingIndicator;
