// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.common.white,
      marginTop: "auto",
    },

    icon: {
      color: theme.palette.common.black,
    },

    footerText: {
      color: theme.palette.common.black,
    },
  }),
);

export default useStyles;
