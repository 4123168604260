import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
    (theme: Theme) => ({
        sectionTabs: {
            padding: "12px 1rem",
        },
        table: {
            minWidth: "950px",

            "& .MuiTableCell-root": {
                verticalAlign: "top",
            },
        },

        icon: {
            color: "black",
        },

        new: {
            color: "#F44336"
        }, 

        completed: {
            color: "#3CB87F"
        },

        dialog: {
            "& .MuiDialog-paper": {
                maxWidth: "1210px",
            },
            "& .MuiFormControl-marginNormal": {
                marginTop: 0,
                marginBottom: 0,
            }
        },

        fieldBox: {
            display: "flex",
            justifyContent: "space-between",
        },

        position: {
            width: "237px"
        },

        skill: {
            width: "197px"
        },

        quantity: {
            width: "100px"
        },

        start: {
            width: "190px",
        },

        duration: {
            width: "190px"
        },

        comment: {
            width: '87%', 
            marginTop: '2rem', 
            marginLeft: '1rem'
        },

        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1rem',
            marginTop: '2.5rem',

           "& .MuiIconButton-root": {
                color: "black"
           },

           "& .MuiFormControlLabel-label": {
                fontWeight: "800",
                marginTop: "3px"
           }
        },

        addMemberIcon: {
            marginLeft: '-10px',
            
            "& .MuiSvgIcon-root": {
                fontSize: '2rem'
            }
        },
        dialogTable: {
            "& .MuiTableCell-root": {
                borderBottom: "none",
            },
            "& .MuiIconButton-root": {
                color: "black",
            }
        },
        
        cellContainer: {
            paddingLeft: '4px',
            marginTop: '-10px',
        },

        cell: {
            '& > :nth-child(n+2)': {
                lineHeight: '1.5rem',
            },
            whiteSpace: 'nowrap'
        },

        dialogRow: {
            '& td, & th': {
                padding: '4px 17px',
            }
        },

        dialogTitle: {
            fontWeight: 800, 
            marginLeft: '19px', 
            fontSize: '1.1rem'
        },

        cellDuration: {
            minWidth: '110px'
        },

        dialogActions: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "25px",
        },

        dialogCancel: {
            width: "7.875rem",
            height: "1.99rem",
            backgroundColor: `rgba(0, 0, 0, 0.22)`,
            color: "black",
            fontWeight: 800,
            fontSize: "15px",
            lineHeight: "18px",
            padding: "1.2rem",
            textTransform: "capitalize",
            marginLeft: "1rem",
      
            "&:hover": {
              backgroundColor: `rgba(0, 0, 0, 0.22)`,
            }
        },

        dialogSend: {
            width: "9.975rem",
            height: "1.99rem",
            backgroundColor: `rgba(0, 0, 0, 0.79)`,
            color: "white",
            fontWeight: 800,
            fontSize: "15px",
            lineHeight: "18px",
            padding: "1.2rem",
            textTransform: "capitalize",
            marginRight: "1rem",
      
            "&:hover": {
              backgroundColor: `rgba(0, 0, 0, 0.79)`
            }
        },
        
        loadingBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2, 0, 2, 0),
        }
    })
)

export default useStyles;
