// absolute path dependencies
import { API_BASE_URL } from "config/constants";

//local dependencies
import { auth } from "./auth";

export class FetchData {
  private _url: string = API_BASE_URL;

  constructor(path: string) {
    this._url = this._url + path;
  }

  public async GET(
    signal?: AbortSignal,
    request: RequestInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      signal,
    },
  ) {
    const response = await fetch(this._url, request);

    if (response.ok) {
      return (await (response.headers.get("Content-type") === "application/json")) ? response.json() : response;
    }

    if (response.status === 401) {
      auth.forceLogout();
    }

    return Promise.reject(response);
  }

  public async POST(
    data?: Record<any, any>,
    signal?: AbortSignal,
    request: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data ?? ""),
      credentials: "include",
      signal,
    },
  ) {
    const response = await fetch(this._url, request);

    if (response.ok) {
      return (await (response.headers.get("Content-type") === "application/json")) ? response.json() : response;
    }

    if (response.status === 401) {
      auth.forceLogout();
    }

    return Promise.reject(response);
  }

  public async PATCH(
    data?: Record<any, any>,
    signal?: AbortSignal,
    request: RequestInit = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data ?? ""),
      credentials: "include",
      signal,
    },
  ) {
    const response = await fetch(this._url, request);

    if (response.ok) {
      return (await (response.headers.get("Content-type") === "application/json")) ? response.json() : response;
    }

    if (response.status === 401) {
      auth.forceLogout();
    }

    return Promise.reject(response);
  }

  public async DELETE(
    signal?: AbortSignal,
    request: RequestInit = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      signal,
    },
  ) {
    const response = await fetch(this._url, request);

    if (response.ok) {
      return (await (response.headers.get("Content-type") === "application/json")) ? response.json() : response;
    }

    if (response.status === 401) {
      auth.forceLogout();
    }

    return Promise.reject(response);
  }
}
