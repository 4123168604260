// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  typography: {
    fontFamily: "League Gothic, sans-serif",
    fontSize: "36px",
    lineHeight: "48px",
  },

  typographyXS: {
    lineHeight: "28px",
  },

  customTitleBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  rootCheckbox: {
    // color:
  },
  typographyText: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
