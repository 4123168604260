// external lib dependencies
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Chip,
  TextField,
  InputAdornment,
  Popper,
  Paper,
  ClickAwayListener,
  IconButton,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// local dependencies
import useStyles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hook";
import { createTagAsync, updateTagAsync } from "store/reducers/tag/tagSlice";
import AddIcon from "@material-ui/icons/Add";

interface Props {
  item: any;
  openTagMenu: boolean;
  anchorRef: any;
  handleCloseTagMenu: () => void;
}

export const ManageTags = (props: Props) => {
  const classes = useStyles();
  const params = useParams<any>();
  const dispatch = useAppDispatch();

  const tags = useAppSelector((state) => state.tags.tags);

  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [messageInputError, setMessageInputError] = useState<string>("");

  const [checkedTags, setCheckedTags] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  let searchedTags = tags.filter((v) => {
    return v.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const toggleTag = (tag: any) => {
    const index = checkedTags.indexOf(tag.id);
    const newChecked = [...checkedTags];

    let membersTag: any[] = [];

    if (index === -1) {
      newChecked.push(tag.id);
      let newMember = {
        employee_id: props.item.employee_id,
        employee_name: props.item.employee_name,
      };
      membersTag = tag.member_assignments.concat(newMember);
    } else {
      newChecked.splice(index, 1);
      membersTag = tag.member_assignments.filter((v: any) => v.employee_id != props.item.employee_id);
    }

    const data = {
      project_id: params.id,
      tag_id: tag.id,
      body: membersTag,
    };

    dispatch(updateTagAsync(data));
    setCheckedTags(newChecked);
  };

  const handleAddTag = () => {
    setMessageInputError("");
    setIsInputError(false);

    const isExist = tags.some((item) => item.name.toLowerCase() === searchInput.toLowerCase());
    if (!searchInput.length || searchInput.length > 10 || isExist) {
      setMessageInputError("The value must be unique and less than 10 characters");
      setIsInputError(true);
      return;
    }

    const data = {
      project_id: params.id,
      data: {
        name: searchInput,
      },
    };
    dispatch(createTagAsync(data));
    setSearchInput("");
  };

  useEffect(() => {
    let checkedList: any = [];
    for (let v of tags) {
      let isExistMember = v.member_assignments.some((v: any) => v.employee_id == props.item.employee_id);

      if (isExistMember) {
        checkedList.push(v.id);
      }
    }

    setCheckedTags(checkedList);
  }, [tags]);

  const handleChange = (e: any) => {
    e.preventDefault();
    const value = e.currentTarget.value;

    if (value.length > 10) {
      setIsInputError(true);
      setMessageInputError("The value must be unique and less than 10 characters");
    } else {
      setIsInputError(false);
      setMessageInputError("");
    }

    setSearchInput(e.target.value);
  };

  if (searchInput.length > 0) {
    tags.filter((v) => v.name.match(searchInput));
  };

  return (
    <Popper
      open={props.openTagMenu}
      anchorEl={props.anchorRef}
      disablePortal
      placement="right"
      style={{ zIndex: 2 }}
      modifiers={{
        preventOverflow: {
          escapeWithReference: true,
        },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={props.handleCloseTagMenu}>
          <Box className={classes.popoverBox}>
            <Typography variant="h6" className={classes.addTagsLabel}>Add tags</Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                style={{ fontSize: "16px" }}
                size="small"
                placeholder="Search"
                onChange={handleChange}
                value={searchInput}
                error={isInputError}
                helperText={messageInputError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton onClick={handleAddTag} style={{ height: "40px", width: "40px" }}>
                <AddIcon />
              </IconButton>
            </Box>
            <Box className={classes.tagsBox}>
              {!searchedTags.length ? (
                <>
                  <Typography variant="body2" align="left">
                    No tags were found.
                  </Typography>
                  <Typography>Click + to create new tag</Typography>
                </>
              ) : (
                searchedTags.map((tag) => (
                  <Chip
                    variant={checkedTags.indexOf(tag.id) == -1 ? "outlined" : "default"}
                    label={tag.name}
                    size="small"
                    clickable
                    key={tag.id}
                    onClick={() => toggleTag(tag)}
                    className={checkedTags.indexOf(tag.id) == -1 ? classes.tag : classes.checkedTag}
                  />
                ))
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
