// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

// absolute path dependencies
import BackgroundImg from "assets/images/background.svg";


const useStyles = makeStyles(
  (theme: Theme) => ({
    backgroundImage: {
      backgroundImage: `url(${BackgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100vw",
      height: "100vh",
    },

    logo: {
      position: "absolute",
      left: "20px",
      top: "30px",
    },

    messageContainer: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
    },

    message: {
      color: "white",
      textAlign: "center",
      fontSize: "30px",
    },

    stopIcon: {
      color: "white",
      fontSize: "50px",
      margin: "auto",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      marginBottom: "50px",
    },

    browserIcon: {
      width: "30px",
      height: "30px",
    },

    browserIconsContainer: {
      display: "flex",
      justifyContent: "center",
    },

    browserLink: {
      margin: "30px",
    },
  })
);

export default useStyles;
