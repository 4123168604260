// external lib dependencies
import { FC, useMemo } from "react";
import { Box, ListItem } from "@material-ui/core";

// absolute path dependencies
import useLocalStorage from "hooks/useLocalStorage";

// local dependencies
import useStyles from "../styles";
import MemberCard from "../../MemberCard/MemberCard";
import { GroupTeamWithTags } from "config/types";
import { filterMembersByTag } from "helpers/teamSectionHelpers/teamSectionHelpers";
import { useAppSelector } from "store/hook";

interface Props {
  group: GroupTeamWithTags;
  handleOpenDeleteMemberPopup: any;
  canEdit: boolean;
}

const SubTeam: FC<Props> = (props: Props) => {
  const {group, handleOpenDeleteMemberPopup, canEdit} = props
  const classes = useStyles();
  const [toggleShowTeam] = useLocalStorage(props.group.id, true);

  const sortedMembers = useMemo(()=>{
    return group.member_assignments.sort((a, b) => a.member_role_id - b.member_role_id);
  }, [group])

  const { tagsForFiltering } = useAppSelector((state) => state.tags);

  const filteredGroupMembersByTags = filterMembersByTag(sortedMembers, tagsForFiltering)

  return (
    <ListItem className={classes.listItem} disableGutters>
      <Box className={classes.teamInfo}>
        <Box className={classes.avatarList} style={{ display: toggleShowTeam ? "flex" : "none" }}>
          {filteredGroupMembersByTags.map((member) => (
            <MemberCard
              key={member.employee_id}
              group={group}
              item={member}
              groupedMember={true}
              handleOpenDeleteMemberPopup={handleOpenDeleteMemberPopup}
              canEdit={canEdit}
            />
          ))}
        </Box>
      </Box>
    </ListItem>
  );
};

export default SubTeam;
