
export async function createTag(projectId: string, data: object) {
    return await fetch(`/api/projects/${projectId}/tags`, {
        method: "POST",
        body: JSON.stringify(data),
    })
}

export async function getTags(projectId: string) {
    return await fetch(`/api/projects/${projectId}/tags`)
}

export async function deleteTag(projectId: string, tagId: string) {
    return await fetch(`/api/projects/${projectId}/tags/${tagId}`, {
        method: "DELETE"
    })
}

export async function editTag(projectId: string, tagId: string, data: any[]) {
    return await fetch(`/api/projects/${projectId}/tags/${tagId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
    })
}

export async function editProjectTag(projectId: string, data: any[]) {
    return await fetch(`/api/projects/${projectId}/tags`, {
        method: "PATCH",
        body: JSON.stringify(data),
    })
}


