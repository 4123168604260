import { Box, Typography, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import HeaderBar from "components/HeaderBar/HeaderBar";
import { FC } from "react";
import { useAppSelector } from "store/hook";

const RegularTypography = withStyles((theme) => ({
  root: {
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "14px",
    },
  },
}))(Typography);

const StyledSkeleton = withStyles({
  root: {
    maxWidth: "300px",
    height: "36px",
  },
})(Skeleton);

const StakeholdersPageHeader: FC = () => {
  const { engagement, loading } = useAppSelector((state) => state.engagement);

  return (
    <>
      <HeaderBar>
        <Box>
          {loading ? (
            <StyledSkeleton variant="rect" />
          ) : (
            <RegularTypography variant="h5">{`Stakeholder List - ${engagement?.title}`}</RegularTypography>
          )}
        </Box>
      </HeaderBar>
    </>
  );
};

export default StakeholdersPageHeader;
