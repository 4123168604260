// external lib dependencies
import {
  ResponsiveContainer,
  LineChart as RcLineChart,
  CartesianGrid,
  Tooltip,
  Legend,
  YAxis,
  XAxis,
  Line,
} from "recharts";

// absolute path dependencies
import { useThemeStruct } from "themes";
import { formatDateMDY } from "helpers/datetime";

interface LineChartProps {
  data: {
    x: string;
    y: number;
  }[];
  limits: {
    minGreen: number;
    maxGreen: number;
    minYellow: number;
    maxYellow: number;
    minRed: number;
    maxRed: number;
  };
}

const LineChart = ({ data, limits }: LineChartProps) => {
  const themeStruct = useThemeStruct();

  const fillPoint = (value: any, limits: any) => {
    if (limits === undefined || limits === null) return themeStruct.lineFillDefaultColor;
    if (value >= limits.minGreen && value <= limits.maxGreen) return themeStruct.lineFillGreenColor;
    if (value >= limits.minYellow && value <= limits.maxYellow) return themeStruct.lineFillYellowColor;
    if (value >= limits.minRed && value <= limits.maxRed) return themeStruct.lineFillRedColor;

    return themeStruct.lineFillDefaultColor;
  };

  const circleComponent = (props: any) => (
    <circle r="5" key={Math.random()} fill={fillPoint(props.value, limits)} cx={props.cx} cy={props.cy} />
  );

  return (
    <ResponsiveContainer>
      <RcLineChart data={data}>
        <defs>
          <linearGradient id="colorY" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
            {data.map((point, i) => (
              <stop
                key={i}
                offset={data.length > 1 ? `${(100 * i) / (data.length - 1)}%` : "0%"}
                stopColor={fillPoint(point.y, limits)}
              />
            ))}
          </linearGradient>
        </defs>
        <CartesianGrid vertical={false} stroke={themeStruct.cartesianGridColor} />
        <Tooltip
          cursor={false}
          labelFormatter={(label: any) => formatDateMDY(label)}
          formatter={(n: any) => [n, "Value"]}
          contentStyle={{
            backgroundColor: themeStruct.tooltipBgColor,
          }}
        />
        <Legend
          verticalAlign="bottom"
          payload={[
            { id: "Strong", type: "circle", value: "Strong", color: themeStruct.legendStrongColor },
            { id: "Neutral", type: "circle", value: "Neutral", color: themeStruct.legendNeutralColor },
            { id: "PotentialRisk", type: "circle", value: "Potential Risk", color: themeStruct.legendWeakColor },
          ]}
        />
        <YAxis
          dataKey="y"
          type="number"
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          domain={[0, 100]}
          tick={{
            fill: themeStruct.tickFillColor,
            fontSize: themeStruct.tickFontSize,
          }}
        />
        <XAxis
          dataKey="x"
          type="category"
          axisLine
          tickLine={false}
          tick={{
            fill: themeStruct.tickFillColor,
            fontSize: themeStruct.tickFontSize,
          }}
          tickFormatter={formatDateMDY}
          stroke="#d6d6d6"
        />
        <Line
          dataKey="y"
          type="monotone"
          stroke="url(#colorY)"
          strokeWidth={4}
          dot={circleComponent}
          activeDot={false}
        />
      </RcLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
