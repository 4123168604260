// external lib dependencies
import { useCallback, useContext, useEffect, useState } from "react";
import { Redirect, useParams, Link, useHistory } from "react-router-dom";
import { Container, Grid, Slide, Paper, CircularProgress, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// absolute path dependencies
import SectionTitleBar from "components/SectionTitleBar";
import Button from "components/Button";
import { useEngagementData } from "providers/EngagementDataContext";
import { useFilesContext } from "providers/FilesContext";
import DropzoneFiles from "components/DropZone/DropZoneFiles";

// local dependencies
import FilesCategory from "./components/FilesCategory";
import useStyles from "./styles";
import { categories } from "./categories";
import { ScrollbarContext } from "components/RouteList";

const FilesSharing = () => {
  const classes = useStyles();
  const { uploadFiles, fetchFiles, files, loadingFiles, loadingUploadFiles, uploadErrorMessage, uploadSuccessMessage } = useFilesContext();
  const { engagement, initEngagement, loadingEngagement } = useEngagementData();
  const { id } = useParams<{ id?: string }>();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    setErrorMessage(uploadErrorMessage);
  }, [uploadErrorMessage]);

  useEffect(() => {
    setSuccessMessage(uploadSuccessMessage);
  }, [uploadSuccessMessage]);

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [history.length]);

  useEffect(() => {
    if (!id) return;

    if (!engagement.id || engagement.id !== Number(id)) {
      initEngagement(Number(id));
    }
    fetchFiles(Number(id));
  }, [id, initEngagement, fetchFiles]);

  const renderCloseButton = useCallback(
    () => (
      <Link className={classes.closeButton} to={`/engagements/`}>
        <Button colorVariant="black" fullWidth>
          Close
        </Button>
      </Link>
    ),
    [id, classes]
  );

  const {scrollbar} = useContext(ScrollbarContext)

  const handleSubmitFiles = (files: File[], category: string, password: string, expireOn: number | null) => {
    if (id !== undefined && files.length > 0) {
      uploadFiles(files, Number(id), category, password, expireOn);
      if (scrollbar.current) {
        scrollbar.current.scrollTop = 0;
        scrollbar.current.scrollLeft = 0;
      }
    }
  };

  if (!id) return <Redirect to="/engagements" />;

  return (
    <>
      <div className={classes.mainContainer} data-testid="FSContainer">
        <SectionTitleBar
          title={loadingEngagement ? <Skeleton width="30%" /> : `${engagement.title} - Shared Files`}
          boxShadow={false}
          renderAction={renderCloseButton}
        />
        <Container className={classes.container} maxWidth="xl">
          <Grid container spacing={3}>
            <Slide in direction="right">
              <Grid item xs={12} md={4} lg={3} data-testid="FSDropZone">
                <Paper elevation={0} className={classes.chartContainer}>
                  <DropzoneFiles
                    isLoading={loadingUploadFiles}
                    onSubmitFiles={handleSubmitFiles}
                    uploadErrorMessage={errorMessage}
                    uploadSuccessMessage={successMessage}
                  />
                </Paper>
              </Grid>
            </Slide>
            <Slide in direction="up">
              <Grid item xs={12} md={8} lg={9} data-testid="FSTable">
                <Paper elevation={0} className={classes.chartContainer}>
                  {loadingFiles ? (
                    <Box style={{ display: "flex", margin: "0 auto" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box className={classes.categoryBox}>
                      {categories.map((categoryName) => (
                        <FilesCategory
                          categoryName={categoryName}
                          files={files}
                          loadingFiles={loadingFiles}
                          key={categoryName}
                        />
                      ))}
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Slide>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default FilesSharing;
