// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      tableLayout: "fixed",
    },

    arrow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(-0.5),
      "& div:first-child": {
        borderBottom: `${theme.spacing(0.8)}px solid #000`,
        width: "20%",
      },
      "& div:last-child": {
        borderRight: `${theme.spacing(0.8)}px solid transparent`,
        borderLeft: `${theme.spacing(0.8)}px solid transparent`,
        borderTop: `${theme.spacing(0.8)}px solid #000`,
        width: "14%",
      },
    },

    borderBottom: {
      borderBottom: "1px solid #eaeaea",
    },

    chartContainer: {
      borderRadius: theme.spacing(1.75),
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
      height: 350,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        height: 200,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 1, 1, 0),
        borderRadius: 14,
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
        height: 400,
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4),
        height: 420,
      },
    },

    collapse: {
      alignSelf: "stretch",
    },

    tableContainer: {
      borderRadius: theme.spacing(1.75),
      width: "100%",
      maxHeight: theme.spacing(37.5),
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: theme.spacing(1.25),
      },
      "&::-webkit-scrollbar-track": {
        background: "#e0e0e0",
        borderRadius: theme.spacing(1),
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#9a9a9a",
        borderRadius: theme.spacing(1),
      },
    },

    downloadActionWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    downloadAction: {
      borderRadius: "50%",
      height: theme.spacing(4.5),
      minWidth: theme.spacing(4.5),
      flex: `0 0 ${theme.spacing(4.5)}px`,
      padding: theme.spacing(3),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        "& div:first-of-type": {
          "& div:first-of-type": {
            "& div:first-child": {
              borderBottom: `${theme.spacing(0.8)}px solid #ff6666`,
            },
            "& div:last-child": {
              borderRight: `${theme.spacing(0.8)}px solid transparent`,
              borderLeft: `${theme.spacing(0.8)}px solid transparent`,
              borderTop: `${theme.spacing(0.8)}px solid #ff6666`,
            },
          },
          "& div[class*='placement']": {
            borderRight: `${theme.spacing(0.4)}px solid #ff6666`,
            borderLeft: `${theme.spacing(0.4)}px solid #ff6666`,
            borderBottom: `${theme.spacing(0.4)}px solid #ff6666`,
          },
        },
      },
    },

    downloadLink: {
      justifyContent: "center",
      alignItems: "center",
    },

    ellipsis: {
      textOverflow: "ellipsis",
      maxHeight: theme.spacing(12.5),
    },

    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
      "& .MuiTypography-root:first-child": {
        flex: "0 0 30%",
      },
      "& .MuiTypography-root:last-child": {
        flex: "0 0 60%",
      },
    },

    listHeader: {
      flexDirection: "column",
    },

    listHeaderRow: {
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #eaeaea",
    },

    listHeaderRowClickable: {
      padding: theme.spacing(0.5),
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #eaeaea",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F5F6F8",
      },
    },

    listHeaderRowActive: {
      padding: theme.spacing(0.5),
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #eaeaea",
      cursor: "pointer",
      backgroundColor: "#F5F6F8",
    },

    paddTopBottom: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    placement: {
      borderRight: `${theme.spacing(0.4)}px solid #000`,
      borderLeft: `${theme.spacing(0.4)}px solid #000`,
      borderBottom: `${theme.spacing(0.4)}px solid #000`,
      width: theme.spacing(2.2),
      height: theme.spacing(1),
    },

    headerCell: {
      display: "flex",
      flexBasis: "60%",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTypography-root": {
        flexBasis: "60%",
      },
    },

    tableCell: {
      border: "1px solid #eaeaea",
      wordBreak: "break-word",
    },

    accordionSummary: {
      backgroundColor: "#F5F6F8",
    },

    questionBox: {
      backgroundColor: "#eeecec",
      marginBottom: theme.spacing(1),
    },

    reportDetails: {
      marginBottom: theme.spacing(2),
    },

    reportTableCell: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      hyphens: "auto",
      "-webkit-hyphens": "auto",
      "-ms-hyphens": "auto",
      "-moz-hyphens": "auto",
    },

    accordion: {
      marginBottom: theme.spacing(1),
      "&.Mui-expanded": {
        margin: "auto",
        marginBottom: theme.spacing(1),
      },
      "&.MuiAccordion-root:before": {
        top: 0,
      },
    },

    tableRow: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F5F6F8",
      },
    },

    tableRowActive: {
      backgroundColor: "#F5F6F8",
    },

    auditsReportBox: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    smallTablePaper: {
      padding: theme.spacing(2),
    },
  })
);

export default useStyles;
