// external lib dependencies
import React, { useState, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { List, ListItem, Typography, IconButton, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// absolute path dependencies
import { DrawerDisplayType } from "config/types";

// local dependencies
import { useSideDrawerStyles } from "./styles";

interface Props {
  path?: string;
  drawerItemTitle: string;
  drawerDisplayMode?: DrawerDisplayType;
  CollapseItemList?: React.ElementType<any>;
  toggleMenu: (_: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

const SideDrawerItem = ({ path, toggleMenu, drawerDisplayMode, drawerItemTitle, CollapseItemList }: Props) => {
  const history = useHistory();
  const classes = useSideDrawerStyles();
  const [collapseState, setCollapseState] = useState(false);

  const setCollapseStateHandler = useCallback(() => {
    setCollapseState((prev) => !prev);
  }, []);

  return (
    <>
      {drawerDisplayMode === "collapse" && (
        <ListItem>
          <List style={{ width: "100%" }}>
            <ListItem divider className={classes.listItem}>
              <Typography onClick={setCollapseStateHandler} variant="h5" classes={{ root: classes.sideMenuItem }}>
                {drawerItemTitle}
              </Typography>
              <IconButton onClick={setCollapseStateHandler}>
                {collapseState ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItem>
            <ListItem className={classes.removePadding}>
              <Collapse in={collapseState} style={{ width: "100%" }}>
                {CollapseItemList && <CollapseItemList toggleSideMenu={toggleMenu} />}
              </Collapse>
            </ListItem>
          </List>
        </ListItem>
      )}
      {drawerDisplayMode === "normal" && (
        <ListItem>
          <List style={{ width: "100%" }}>
            <ListItem divider className={classes.listItem}>
              {path && (
                <NavLink
                  to={path}
                  className={classes.noDecoration}
                  onClick={(event) => {
                    toggleMenu(event);
                    history.push(path);
                  }}
                >
                  <Typography variant="h5" classes={{ root: classes.sideMenuItem }}>
                    {drawerItemTitle}
                  </Typography>
                </NavLink>
              )}
            </ListItem>
          </List>
        </ListItem>
      )}
    </>
  );
};

export default SideDrawerItem;
