export function getShortDate(date: string) {
  const intl = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  });
  return intl.format(new Date(date));
}

export function getMonthYear(date: string) {
  const intl = new Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "numeric",
  });
  return intl.format(new Date(date));
}
