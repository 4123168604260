// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        engagement: {
            backgroundColor: theme.palette.background.default,
            width: "100%",
        },

        container: {
            marginTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(7.5),
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(2),
            },
        },
        profileSection: {
            borderRadius: theme.spacing(1.75),
            minHeight: theme.spacing(70.75),
            display: "flex",
            flexDirection: "column",
        },
        profileSectionTitle: {
            padding: "20px",
            borderBottom: "2px solid #dadada"
        },
        profileSectionBox: {
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            borderBottom: "1px solid #dadada"
        },
        buttonGoBack: {
            marginBottom: theme.spacing(2),
            textAlign: "right"
        },
        feedbackSectionBox: {
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            marginTop: "3.5rem",
            borderBottom: "1px solid #dadada"
        },
        workOrderSectionBox: {
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          marginTop: "3.5rem",
        }
    })
);

export default useStyles;
