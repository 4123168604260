// external lib dependencies
import { useState, useEffect, useMemo } from "react";
import { Container, Paper, Fade, Box } from "@material-ui/core";

// absolute path dependencies
import { useEngagementData } from "providers/EngagementDataContext";

// local dependencies
import WOPageNavbar from "./components/WOPageNavbar";
import GridItemList from "./components/GridItemList";
import MonthGrid from "./components/MonthGrid";
import useYearSpan from "./hooks/useYearSpan";
import WOPageSkeleton from "./WOPageSkeleton";
import { useWOPageStyles } from "./styles";
import WorkOrdersHeader from "./components/WorkOrdersHeader";

import LoadMoreButtons from "./components/LoadMoreButtons";
import { Engagement } from "config/types";
import useListSort from "./hooks/useListSort";
import { useAppSelector } from "store/hook";
import { getEngagementsPaginated } from "services";
import useBarPercent from "./hooks/useBarPercent";
import { useFilterByTerm } from "hooks/useFilterByTerm";

const WorkOrdersPage = () => {
  const { getYearSpan } = useYearSpan();
  const classes = useWOPageStyles();
  const { user } = useAppSelector((state) => state.user);
  const { allEngagements, loadingEngagements, initAllEngagements } = useEngagementData();
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [unfoldAll, setUnfoldAll] = useState<boolean>(false);
  const [showActiveWO, setShowActiveWO] = useState<boolean>(true);
  const [showMyEngagements, setShowMyEngagements] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loadedEngagementsFromLoadMoreButton, setLoadedEngagements] = useState<Engagement[]>([]);
  const [myEngagements, setMyEngagements] = useState<Engagement[]>([]);
  const { getTimePassedPercent } = useBarPercent();
  const { getMinMaxDate } = useYearSpan();
  const sortList = useListSort();

  const checkCanRender = (engagement: Engagement): boolean => {
    const date = getMinMaxDate(engagement);

    if (showActiveWO) {
      const activeWO = engagement.work_orders?.filter(
        (wo) => getTimePassedPercent(new Date(wo.start_date), new Date(wo.end_date)) < 100,
      );

      const activeWOCurrentYear = activeWO?.filter(
        (wo) =>
          new Date(wo.start_date).getFullYear() <= selectedYear && new Date(wo.end_date).getFullYear() >= selectedYear,
      );

      return (
        date.min.getFullYear() <= selectedYear &&
        date.max.getFullYear() >= selectedYear &&
        activeWOCurrentYear?.length > 0
      );
    } else {
      return date.min.getFullYear() <= selectedYear && date.max.getFullYear() >= selectedYear;
    }
  };

  const allEngagementsForRender = sortList(
    showMyEngagements ? myEngagements : allEngagements,
    "engagements",
    undefined,
    showActiveWO,
    selectedYear,
  ).filter((x) => checkCanRender(x));

  const filteredEngagementsByProjectName = useFilterByTerm<Engagement>(
    allEngagementsForRender,
    searchValue,
    2,
    "title",
  );
  const engagements =
    filteredEngagementsByProjectName.length === 0
      ? loadedEngagementsFromLoadMoreButton
      : filteredEngagementsByProjectName;

  useEffect(() => {
    initAllEngagements();
  }, [initAllEngagements]);

  useEffect(() => {
    setLoadedEngagements(allEngagementsForRender?.slice(0, 10));
  }, [initAllEngagements, allEngagements, showActiveWO, showMyEngagements, selectedYear]);

  useEffect(() => {
    getEngagementsPaginated("Active Work Orders", 1, undefined, true, 20).then((res) => setMyEngagements(res));
  }, [showMyEngagements]);

  useEffect(() => {
    if (user?.is_stakeholder) {
      setShowMyEngagements(false);
    } else {
      setShowMyEngagements(true);
    }
  }, [user]);

  const hideLoadMoreButton = useMemo(() => {
    if (
      (engagements.length === myEngagements.length && showMyEngagements) ||
      (engagements.length === allEngagementsForRender?.length && !showMyEngagements) ||
      filteredEngagementsByProjectName.length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [showMyEngagements, engagements, allEngagements, filteredEngagementsByProjectName]);

  return (
    <Box sx={{ padding: "40px" }}>
      <WorkOrdersHeader
        showActiveWO={showActiveWO}
        showMyEngagements={showMyEngagements}
        setShowActiveWO={setShowActiveWO}
        setShowMyEngagements={setShowMyEngagements}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isStakeholder={user?.is_stakeholder}
        isLoading={loadingEngagements}
      />

      {loadingEngagements ? (
        <WOPageSkeleton />
      ) : (
        <>
          <Container className={classes.WOContentContainer}>
            <Fade in>
              <div data-testid="WOMainComponent">
                <Paper className={classes.WOContentLeftPaper} />
                <MonthGrid />
                {allEngagements.length > 0 && (
                  <WOPageNavbar
                    unfoldAll={unfoldAll}
                    setUnfoldAll={setUnfoldAll}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    yearSpan={getYearSpan(engagements)}
                  />
                )}
                <GridItemList
                  unfoldAll={unfoldAll}
                  engagements={engagements}
                  selectedYear={selectedYear}
                  checked={showActiveWO}
                />
                {hideLoadMoreButton ? (
                  ""
                ) : (
                  <LoadMoreButtons
                    allEngagements={allEngagementsForRender}
                    setLoadedEngagements={setLoadedEngagements}
                    showMyEngagements={showMyEngagements}
                  />
                )}
              </div>
            </Fade>
          </Container>
        </>
      )}
    </Box>
  );
};

export default WorkOrdersPage;
