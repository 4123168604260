// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  engagements: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "100%",
    padding: 0,
  },

  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(10),
  },

  cardsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  
  listItem: {
    padding: "5px 0",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-root, & .MuiButton-root": {
      marginBottom: theme.spacing(0.1),
    },
  },

  viewBtn: {
    alignSelf: "center",
    width: "100%",
  },

  checkbox: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: "0",
      marginRight: "0",
      marginBottom: "0",
    },
  },

  containerCheckbox: {
    marginTop: "15px"
  },

}));

export default useStyles;
