// external lib dependencies
import { useCallback } from "react";

// local dependencies
import { FeedbackQuestion } from "../components/questions";


const useFormatDataObject = () => {
  const formatData = useCallback((projectID, answers, questions: FeedbackQuestion[]) => {
    const questionsWithAnswers = questions.map((q, index) => {
      return { question: q.question, answer: answers[`${index}`] };
    });

    return {
      projectID,
      data: [...questionsWithAnswers]
    };
  }, []);

  return formatData;
};

export default useFormatDataObject;
