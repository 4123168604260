// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //circularProgress
    circularProgressContainer: {
      minHeight: "calc(100vh - 450px)",
    },

    //StakeholderDetails
    minHeightPaper: {
      height: "100vh",
      [theme.breakpoints.down("xs")]: {
        height: "auto",
      },

      [theme.breakpoints.between(600, "sm")]: {
        height: "100%",
      },
    },

    stakeholderDetails: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },

    stakeholderDetailsContent: {
      paddingTop: "24px",
      paddingLeft: "12px",
    },

    stakeholderAvatar: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(8),
      height: theme.spacing(8),
    },

    stakeholderAction: {
      alignItems: "center",
      "& > .MuiGrid-item:first-child": {
        "& .MuiTypography-h5": {
          lineHeight: "48px",
        },
        maxWidth: "360px",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "200px",
        },
      },

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },

    stakeholderAccess: {
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },

    advanceLabel: {
      background: "#ED1E79",
    },

    invitedLabel: {
      background: "#54A5FF",
    },

    //SKELETONE
    stakeholderListBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "32px",
    },

    stakeholderListPaper: {
      maxHeight: 780,
      overflowY: "auto",
      padding: theme.spacing(2),
    },

    leftSideSkeleton: {
      margin: theme.spacing(2, 0),
      marginLeft: theme.spacing(0.5),
    },

    stakeholderDetailsPaper: {
      maxHeight: 780,
      overflowY: "auto",
      padding: theme.spacing(2),
    },

    //stakeholderList
    scrollAutoPaper: {
      overflowY: "auto",
      height: "100vh ",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "400px",
      },

      [theme.breakpoints.between(600, "sm")]: {
        height: "900px",
      },
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },

    listItemActive: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      backgroundColor: "#0000000a",
    },

    avatar: {
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
    },
  }),
);

export default useStyles;
