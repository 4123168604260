// external lib dependencies
import { FC, useMemo } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// local dependencies
import FilesTable from "../components/FilesTable";
import useStyles from "../styles";
import { getDatesDiferenceInDays } from "helpers/datetime";
import { UploadedFile } from "config/types";

interface Props {
  categoryName: string;
  files: UploadedFile[];
  loadingFiles: boolean;
}

const FilesCategory: FC<Props> = ({ categoryName, files, loadingFiles }) => {
  const classes = useStyles();

  const filesWithExpireOnInDays = useMemo(() => {
    return files
              .map(f => ({...f, expireOnStatus: getDatesDiferenceInDays(new Date(f.expire_on), f.archived)}))
              .filter((file) => file.category === categoryName)
  }, [files, categoryName])
  
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{categoryName}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ width: "100%", overflowX: "auto" }}>
        {!loadingFiles && !filesWithExpireOnInDays.length ? (
          <Typography variant="body2" className={classes.noFilesMessage} classes={{ root: "semibold" }}>
            No files currently shared!
          </Typography>
        ) : (
          <FilesTable categoryName={categoryName} files={filesWithExpireOnInDays}/>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilesCategory;
