// external lib dependencies
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, List} from "@material-ui/core";

// absolute path dependencies
import { GroupTeamWithTags, Member } from "config/types";

// local dependencies
import useStyles from "./styles";
import SubTeam from "./components/SubTeam";
import DeleteMemberPopup from "./components/DeleteMemberPopup";
import AddMemberPopup from "./components/AddMemberPopup";
import MemberCard from "../MemberCard/MemberCard";
import { TeamViews } from "pages/Team/types";
import { useAppSelector } from "store/hook";
import { filterMembersByTag } from "helpers/teamSectionHelpers/teamSectionHelpers";

interface GroupViewInterface {
  teamView: string;
  groupTeams: GroupTeamWithTags[];
  setGroupTeams: any;
  ungroupedTeams: any;
  canEdit: boolean;
}

export default function GroupView(props: GroupViewInterface) {
  const { groupTeams, setGroupTeams, ungroupedTeams, teamView, canEdit } = props;
  const classes = useStyles();
  const params = useParams<any>();
  const [openDeleteMemberPopup, setOpenDeleteMemberPopup] = useState<boolean>(false);
  const [openAddMemberPopup, setOpenAddMemberPopup] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>();
  const [itemToAdd, setItemToAdd] = useState<any>();
  const [group, setGroup] = useState<any>();

  const handleCloseDeleteMemberPopup = () => {
    setOpenDeleteMemberPopup(false);
  };

  const handleOpenDeleteMemberPopup = (item: object, group: object) => {
    setItemToDelete(item);
    setGroup(group);
    setOpenDeleteMemberPopup(true);
  };

  const handleCloseAddMemberPopup = () => {
    setOpenAddMemberPopup(false);
  };

  const handleOpenAddMemberPopup = (item: object) => {
    setItemToAdd(item);
    setOpenAddMemberPopup(true);
  };

  const filteredGroupTeams = useMemo(() =>{
    return groupTeams.filter((item) => item.name === teamView)
  },[groupTeams, teamView])

  const { tagsForFiltering } = useAppSelector((state) => state.tags);

  const filteredUngroupedTeamsByTags = filterMembersByTag(ungroupedTeams, tagsForFiltering)

  return (
    <Box>
      {teamView === TeamViews.Ungrouped && (
        <Box className={classes.ungroupedBox}>
          <Box className={classes.avatarList}>
            {filteredUngroupedTeamsByTags.map((item: Member) => (
              <MemberCard
                key={item.employee_id}
                item={item}
                ungroupedMember={true}
                handleOpenAddMemberPopup={handleOpenAddMemberPopup}
                canEdit={canEdit}
              />
            ))}
          </Box>
        </Box>
      )}
      <List className={classes.list}>
        {filteredGroupTeams.map((group) => {
            return (
              <SubTeam
                key={group.id}
                group={group}
                handleOpenDeleteMemberPopup={handleOpenDeleteMemberPopup}
                canEdit={props.canEdit}
              />
            );
        })}
      </List>

      <DeleteMemberPopup
        open={openDeleteMemberPopup}
        handleClose={handleCloseDeleteMemberPopup}
        item={itemToDelete}
        group={group}
        groupTeams={groupTeams}
        setGroupTeams={setGroupTeams}
        projectId={params.id}
      />

      <AddMemberPopup
        open={openAddMemberPopup}
        handleClose={handleCloseAddMemberPopup}
        item={itemToAdd}
        groupTeams={groupTeams}
        setGroupTeams={setGroupTeams}
        projectId={params.id}
      />
    </Box>
  );
}
