// external lib dependencies
import clsx from "clsx";
import { MouseEventHandler } from "react";
import { IconButton, Collapse, List, ListItem, Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// absolute path dependencies
import { Survey, VoidFunc } from "config/types";
import Button from "components/Button";

// local dependencies
import { getLocaleDateString } from "helpers/datetime";
import useStyles from "./styles";

interface SmallTableRowProps {
  isExpanded: boolean;
  handleExpand: MouseEventHandler;
  onDownloadClick: VoidFunc<number>;
  survey: Survey;
  setActiveSurvey: React.Dispatch<React.SetStateAction<Survey | null>>;
  activeSurvey: Survey | null;
}

const SmallTableRow = ({
  survey,
  isExpanded,
  handleExpand,
  onDownloadClick,
  setActiveSurvey,
  activeSurvey,
}: SmallTableRowProps) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.listHeader, classes.paddTopBottom)}>
      <div
        className={survey.id === activeSurvey?.id ? classes.listHeaderRowActive : classes.listHeaderRowClickable}
        onClick={() => setActiveSurvey(survey)}
      >
        <Typography variant="body1" classes={{ root: "semibold" }}>
          Date
        </Typography>
        <div className={classes.headerCell}>
          <Typography variant="body1">{getLocaleDateString(survey.createdAt)}</Typography>
          <IconButton onClick={handleExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </div>
      </div>
      <Collapse in={isExpanded} className={classes.collapse}>
        <List className={classes.paddTopBottom}>
          <ListItem className={clsx(classes.listHeaderRow, classes.listItem)}>
            <Typography variant="body1" classes={{ root: "semibold" }}>
              Provided by
            </Typography>
            <Typography variant="body1">{survey.createdBy}</Typography>
          </ListItem>
          <ListItem className={clsx(classes.listHeaderRow, classes.listItem)}>
            <Typography variant="body1" classes={{ root: "semibold" }}>
              Notes
            </Typography>
            <Typography variant="body1" className={classes.ellipsis}>
              {survey.notes}
            </Typography>
          </ListItem>
          <ListItem className={clsx(classes.listHeaderRow, classes.listItem, classes.borderBottom)}>
            <Typography variant="body1" classes={{ root: "semibold" }}>
              Score
            </Typography>
            <Typography variant="body1">{survey.percentageScore}%</Typography>
          </ListItem>
          <ListItem className={classes.downloadLink}>
            <Button colorVariant="black" className="actionBtn" onClick={() => onDownloadClick(survey.id)}>
              <Typography variant="body1">Download PDF</Typography>
            </Button>
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
};

export default SmallTableRow;
