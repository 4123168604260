import { jsPDF } from "jspdf";

export type WriteTextArg = {
  doc: jsPDF;
  color: string | [number, number, number, number?];
  fontStyle: string;
  fontSize: number;
  text: string;
  x: number;
  y: number;
  options?: Record<string, any>;
};

export function writeText({
  doc,
  color,
  fontSize,
  fontStyle,
  text,
  x,
  y,
  options,
}: WriteTextArg) {
  if (typeof color === "string") {
    doc.setTextColor(color);
  } else {
    doc.setTextColor.apply(doc, color);
  }

  doc.setFontSize(fontSize);
  doc.setFont("Helvetica", fontStyle);
  doc.text(text, x, y, options);
}
