// external lib dependencies
import { useState, ChangeEvent, FormEvent, useEffect, ReactNode, useMemo } from "react";
import { Dialog, 
  DialogTitle, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Box, 
  DialogActions,
  DialogContent,
  Grid,
  Card,
  Button,
  CardHeader,
  Divider,
  Checkbox,
  TextField, 
  ListItemIcon} from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as editModalStyles from "./styles";
import useStyles from "../../styles"
import { ReactComponent as ChevronsRight } from "../../../../../../assets/icons/teamSideBarIcons/ChevronsRight.svg";
import { ReactComponent as ChevronsLeft} from "../../../../../../assets/icons/teamSideBarIcons/ChevronsLeft.svg";
import { ReactComponent as EditTeamModal} from "../../../../../../assets/icons/teamSideBarIcons/EditTeamModal.svg";

// local dependencies
import useEditHandler from "../useEditHandler";
import Alert from "@material-ui/lab/Alert";

interface EditGroupPopupInterface {
  open: boolean;
  handleClose: any;
  groupTeams: any;
  setGroupTeams: any;
  groupId: string;
  ungroupedTeams: any[];
}

function not(a: object[], b: object[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: object[], b: object[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

/*
function union(a: object[], b: object[]) {
  return [...a, ...not(b, a)];
}
*/

const EditGroupPopup = (props: EditGroupPopupInterface) => {
  const classes = editModalStyles.useStyles();
  const generalClasses = useStyles();
  const [nameGroup, setNameGroup] = useState("");
  const [left, setLeft] = useState<object[]>([]);
  const [right, setRight] = useState<object[]>([]);
  const [checked, setChecked] = useState<object[]>([]);
  const [isNameFocused, setIsNamedFocused] = useState(false);
  const { callback, loading, error, isSuccess } = useEditHandler();
  const { id: projectId } = useParams<{ id?: string | undefined }>();
  const [isErrorTextField, setIsErrorTextField] = useState<boolean>(false);
  const [errorTextField, setErrorTextField] = useState<string>("The value must be less than 20 characters");

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useMemo(() => {
    const group = props.groupTeams.find((item: any) => item.id === props.groupId);

    setLeft(props.ungroupedTeams);

    if (group) {
      setNameGroup(group.name);
      setRight(group.member_assignments);
    }
  }, [props.groupId, props.groupTeams]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isErrorTextField) return;

    callback(projectId, props.groupId, right, nameGroup);
  };

  useEffect(() => {
    if (isSuccess) {
      props.setGroupTeams(
        props.groupTeams.map((item: any) => {
          if (item.id === props.groupId) {
            return { ...item, name: nameGroup, member_assignments: right };
          }
          return { ...item };
        })
      );
      props.handleClose();
    }
  }, [isSuccess, error]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.value;
    const forbiddenCharacters = /[<>?\-*\/\\&^%$#]/i;
    const hasForbidden = forbiddenCharacters.test(name);
    const hasOnlySpaces = /^ *$/.test(name); 
    if(!hasOnlySpaces){
      setErrorTextField("The value must be less than 20 characters")
    }
    if (hasOnlySpaces || hasForbidden || !name.length || name.length > 20) {
        setIsErrorTextField(true)
        hasOnlySpaces 
          ? setErrorTextField("Please write something")
          : setErrorTextField("The value must be less than 20 characters")
    } else {
      setIsErrorTextField(false)
    }
    setNameGroup(name)
  }

  const handleToggle = (value: object) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: object[]) => intersection(checked, items).length;

  /*
  const handleToggleAll = (items: object[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  */
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleClose = () => {
    props.handleClose()
    setIsErrorTextField(false)
  }

  const customList = (title: ReactNode, items: any[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        /*
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
            className={classes.checkbox}
          />
        }
        */
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} Selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div">
        {items.map((value: any) => {
          return (
            <ListItem className={classes.editListItem} key={value.employee_id} button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple className={classes.checkbox} />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={value.employee_name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Dialog className={generalClasses.dialogModal} open={props.open} onClose={props.handleClose}>
      <DialogTitle className={generalClasses.dialogTitle}>Edit team</DialogTitle>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <DialogContent className={generalClasses.dialogContent}>
          {!isNameFocused && !isErrorTextField ? (
            <Box display="flex"
              onClick={() => {
                setIsNamedFocused(true);
              }}
            >
              <EditTeamModal/>
              <Typography style={{marginLeft: "15px"}}>{nameGroup}</Typography>
            </Box>
          ) : (
            <TextField
              autoFocus
              value={nameGroup}
              onBlur={(event) => setIsNamedFocused(false)}
              onChange={handleChange}
              required={true}
              error={isErrorTextField}
              helperText={errorTextField}
              InputProps={{
                startAdornment: <EditTeamModal/>,
              }}
            />
          )}
          <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
            <Grid item className={classes.groupList}>
              {customList("Ungrouped", left)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={left.length === 0}
                  aria-label="move selected right"
                >
                 <ChevronsRight />
                </Button>
                <Button
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={right.length === 0}
                  aria-label="move selected left"
                >
                  <ChevronsLeft />
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.groupList}>
              {customList(nameGroup, right)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={generalClasses.dialogActionBlock}>
          <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" type="submit" disabled={loading}>Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGroupPopup;
