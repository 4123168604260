import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NoteFormInput, ReleaseNote} from "config/types";
import {API_BASE_URL} from "config/constants";


const baseUrl: string = `${API_BASE_URL}/release-note`;

interface IInitialState {
    noteAdded: boolean;
    releaseNotes: ReleaseNote[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: IInitialState = {
    noteAdded: false,
    releaseNotes: [],
    status: 'idle',
    error: null
};

export const createNoteAsync = createAsyncThunk(
    "releaseNotes/createNoteAsync",
    async(data: NoteFormInput, { rejectWithValue, dispatch }) => {
        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const result = await response.json() as ReleaseNote;
            dispatch(createNote(result))
        }
        else {
            rejectWithValue("Error creating release note");
        }
})

export const fetchNotesAsync = createAsyncThunk(
    "releaseNotes/fetchNotesAsync",
    async() => {
        const response = await fetch(baseUrl);
        if (response.ok) {
          return (await response.json()) as ReleaseNote[];
        }
})

export const updateNoteAsync = createAsyncThunk(
    "releaseNotes/updateNoteAsync",
    async(data: ReleaseNote, {dispatch, rejectWithValue}) => {
        const updatedNote: NoteFormInput = {
            release_date: data.release_date,
            note_content: data.note_content
        }
        const response = await fetch(`${baseUrl}/${data.id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updatedNote)
        })

        if (!response.ok) {
            rejectWithValue("Error updating the note");
        }

        dispatch(updateNote(data));
})

export const deleteNoteAsync = createAsyncThunk(
    "releaseNotes/deleteNoteAsync",
    async(data: ReleaseNote, { dispatch }) => {
        await fetch(`${baseUrl}/${data.id}`, {
            method: "DELETE"
        })
        dispatch(deleteNote(data))
})

export const isNewNotes = createAsyncThunk(
  "releaseNotes/isNewNotes",
  async () => {
    const response = await fetch(`${baseUrl}/is_new`)

    return response.ok
  }
)

const releaseNoteSlice = createSlice({
    name: "releaseNotes",
    initialState,
    reducers: {
        createNote(state: IInitialState, action: PayloadAction<ReleaseNote>) {
            const note = action.payload;
            state.noteAdded = true;
            state.releaseNotes.push(note);
        },
        viewNotes(state: IInitialState) {
            state.noteAdded = false;
        },
        deleteNote(state: IInitialState, action: PayloadAction<ReleaseNote>) {
            const noteToDelete = action.payload;
            state.releaseNotes = state.releaseNotes.filter((note: ReleaseNote) => note.id !== noteToDelete.id);
        },
        updateNote(state: IInitialState, action: PayloadAction<ReleaseNote>) {
            const note = action.payload;
            const releaseDate: Date | any = note.release_date
            if (releaseDate instanceof Date) {
              note.release_date = releaseDate.toISOString()
            }

            state.releaseNotes = state.releaseNotes.map((item: ReleaseNote) => {
                if (item.id === note.id) {
                    return { ...item, release_date: note.release_date, note_content: note.note_content }
                }
                return { ...item }
            })
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchNotesAsync.fulfilled, (state: IInitialState, action: PayloadAction<any>) => {
            state.releaseNotes = action.payload;
            state.status = "succeeded"
        });
        builder.addCase(isNewNotes.fulfilled, (state: IInitialState, action: PayloadAction<any>) => {
            state.noteAdded = action.payload
        });
        builder.addCase(fetchNotesAsync.pending , (state: IInitialState, action: PayloadAction<any>) => {
            state.status = "loading"
        });
        builder.addCase(fetchNotesAsync.rejected , (state: IInitialState, action: PayloadAction<any>) => {
            state.status = "failed"; 
            state.error = "Some error happened"
        });
    }
})

export const { createNote, viewNotes, deleteNote, updateNote } = releaseNoteSlice.actions;

export default releaseNoteSlice.reducer;
