// external lib dependencies
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

// absolute path dependencies
import { auth } from "services/auth";

interface AuthState {
  user: any | null;
  isAuthenticated: boolean;
  authenticate: (_?: () => void) => void;
  logout: (_?: () => void) => void;
}

const AuthContext = createContext<AuthState>({
  user: null,
  isAuthenticated: false,
  authenticate: () => {},
  logout: () => {},
});

const Provider: FC<RouteComponentProps> = ({ children, history }) => {
  const [user, setUser] = useState<any>(null);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(
    () => {
      const onLogout = () => {
        setUser(null);
        const queryParams = new URLSearchParams(history.location?.search ?? "");
        const email = queryParams.get("email");
        const redirect = queryParams.get("redirect");
        queryParams.delete("email");
        queryParams.delete("redirect");

        // call the encodeURIComponent because parameters may have symbols like &, ?
        let searchParam = encodeURIComponent("?" + queryParams.toString()) ?? "";
        const url: string | null = history.location?.pathname.includes("work-orders")
          ? history.location?.pathname + searchParam
          : null;

        history.push({
          pathname: "/login",
          state: {
            referrerLink: url,
            expired_email: email,
            redirect: redirect,
          },
        });
      };

      auth.addLogoutHandler(onLogout);
      auth.authenticate((user: any) => {
        setUser(user);
        setChecked(true);
      });
      
      return () => {
        auth.removeLogoutHandler(onLogout);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    );
    
    const isAuthenticated = useMemo(() => user != null, [user]);
    
    const authenticate = useCallback(
      (cb?: () => void) => {
        auth.authenticate((user: any) => {
          setUser(user);
        if (cb) cb();
      });
    },
    [setUser]
  );

  const logout = useCallback((cb?: () => void) => {
    auth.logout(() => {
      if (cb) cb();
    });
  }, []);

  const value = useMemo(() => {
    return {
      user,
      isAuthenticated,
      authenticate,
      logout,
    };
  }, [user, isAuthenticated, authenticate, logout]);

  return <AuthContext.Provider value={value}>{checked && children}</AuthContext.Provider>;
};

export const AuthProvider = withRouter(Provider);

export const useAuth = () => useContext(AuthContext);
