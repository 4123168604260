// external lib dependencies
import React, { FC, useState } from "react";
import { TableRow, TableCell, IconButton, Collapse, Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";

// absolute path dependencies
import { formatDateMDY } from "helpers/datetime";

// local dependencies
import useStyles from "./styles";
import { useAnswerScore } from "../../hooks/useAnswerScore";
import { CustomTableRow } from "pages/Feedback/types";

interface Props {
  item: CustomTableRow;
}

const Row: FC<Props> = ({ item }) => {

  const [open, setOpen] = useState<boolean>(false);
  const { chooseAnswerScore, firstQuestionScore } = useAnswerScore();
  const classes = useStyles();


  return (
    <React.Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell component="th" scope="row">
          {item.data[0]?.answer}
        </TableCell>
        <TableCell>{item.data[1]?.answer}</TableCell>
        <TableCell>{item.data[2]?.answer}</TableCell>
        <TableCell>{item.data[3]?.answer}</TableCell>
        <TableCell>{formatDateMDY(item.user_info.survey_date)}</TableCell>
        <TableCell
          style={{
            color:
              parseInt(item.data[9]?.answer) >= 9
                ? "#4dbcb1"
                : (parseInt(item.data[9]?.answer) < 9 && parseInt(item.data[9]?.answer) >= 7)
                  ? "#fac063"
                  : "#f77970",
          }}
        >
          {item.data[9]?.answer}
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.feedbackDetails}>
              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[4]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {item.data[4]?.question?.toLowerCase().startsWith("how well") ?
                    chooseAnswerScore(item.data[4]?.answer)
                    : firstQuestionScore(item.data[4]?.answer)}
                  <Typography variant="body2">{item.data[4]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[5]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {chooseAnswerScore(item.data[5]?.answer)}
                  <Typography variant="body2">{item.data[5]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[6]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {chooseAnswerScore(item.data[6]?.answer)}
                  <Typography variant="body2">{item.data[6]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[7]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {chooseAnswerScore(item.data[7]?.answer)}
                  <Typography variant="body2">{item.data[7]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[8]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {chooseAnswerScore(item.data[8]?.answer)}
                  <Typography variant="body2">{item.data[8]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[9]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {parseInt(item.data[9]?.answer) >= 9 && <CheckCircleIcon style={{ color: "#4dbcb1" }} />}
                  {(parseInt(item.data[9]?.answer) < 9 && parseInt(item.data[9]?.answer) >= 7)
                    && <WarningIcon style={{ color: "#fac063" }} />}
                  {parseInt(item.data[9]?.answer) < 7 && <CancelIcon style={{ color: "#f77970" }} />}
                  <Typography variant="body2">{item.data[9]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[10]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  <InfoIcon style={{ color: "#107ab0" }} />
                  <Typography variant="body2">{item.data[10]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[11]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  <InfoIcon style={{ color: "#107ab0" }} />
                  <Typography variant="body2">{item.data[11]?.answer}</Typography>
                </Box>
              </Box>

              <Box className={classes.questionContainer}>
                <Typography color="textSecondary" variant="body2">
                  {item.data[12]?.question}
                </Typography>
                <Box className={classes.questionBox}>
                  {item.data[12]?.answer === "Yes" && <CheckCircleIcon style={{ color: "#4dbcb1" }} />}

                  {item.data[12]?.answer === "No" && <CancelIcon style={{ color: "#f77970" }} />}
                  <Typography variant="body2">{item.data[12]?.answer}</Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
