import { useMemo } from "react";

export function getLastDateFromDataScore(data: { x: string }[]): null | Date {
  if (!data) return null;

  const dates = data.map(({ x }) => new Date(x).getTime());

  if (!dates.length) {
    return null;
  }

  return new Date(Math.max(...dates));
}

export function getDiffDaysFromNow(date: Date) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const now = new Date();
  const utc1 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function formatLastUpdatedDate(date: Date) {
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const diff = getDiffDaysFromNow(date);

  return rtf.format(diff, "days");
}

export default function useUpdatedAt(data: any) {
  return useMemo(() => {
    const lastUpdate = getLastDateFromDataScore(data);

    // fixme: improve fallback
    return lastUpdate ? formatLastUpdatedDate(lastUpdate) : "this year";
  }, [data]);
}
