// external lib dependencies
import { useState, useEffect, useMemo, useCallback } from "react";
import { Box, List, ListItem, Typography, Collapse, Container } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

// absolute path dependencies
import { formatDateMDY } from "helpers/datetime";
import useScreenSize from "hooks/useScreenSize";
import Button from "components/Button";

// local dependencies
import { useEngagementStyles, useWOStyles } from "./styles";
import useBarPercent from "../hooks/useBarPercent";
import useTitleTrim from "../hooks/useTitleTrim";
import WorkOrder from "./WorkOrder";
import { WorkOrder as WorkOrderType } from "config/types";
import { useFilterByCurrentYear } from "../hooks/useFilterByCurrentYear";
import useListSort from "../hooks/useListSort";

interface EngagementProps {
  engagementName: string;
  workOrders: WorkOrderType[];
  unfoldAll: boolean;
  selectedYear: number;
  minDate: Date;
  maxDate: Date;
  index: number;
  checked: boolean;
}

const Engagement = ({
  engagementName,
  workOrders,
  unfoldAll,
  selectedYear,
  minDate,
  maxDate,
  index,
  checked,
}: EngagementProps) => {
  const { trimSuspension } = useTitleTrim();
  const [unfold, setUnfold] = useState<boolean>(false);
  const { isSM, isXL } = useScreenSize();
  const classesEng = useEngagementStyles();
  const { getTimePassedPercent } = useBarPercent();
  const classesWO = useWOStyles();
  const sortList = useListSort();

  const queryParams = new URLSearchParams(window.location.search);
  const queryProject = queryParams.get("project");

  useEffect(() => {
    setUnfold(unfoldAll);
  }, [unfoldAll]);

  useEffect(() => {
    setUnfold((!!queryProject && queryProject === engagementName) || (!queryProject && index === 0));
    return () => {
      setUnfold(false);
    };
  }, []);

  const [result, setResult] = useState<WorkOrderType[]>([]);

  const _localWorkOrdersCurrentYear = useFilterByCurrentYear(workOrders, selectedYear);

  const _activeLocalWorkOrders: WorkOrderType[] = useMemo(
    () =>
      _localWorkOrdersCurrentYear.filter(
        ({ start_date, end_date }) => getTimePassedPercent(new Date(start_date), new Date(end_date)) < 100,
      ),
    [_localWorkOrdersCurrentYear, getTimePassedPercent],
  );

  const _notActiveLocalWorkOrders: WorkOrderType[] = useMemo(
    () =>
      _localWorkOrdersCurrentYear.filter(
        ({ start_date, end_date }) => getTimePassedPercent(new Date(start_date), new Date(end_date)) >= 100,
      ),
    [_localWorkOrdersCurrentYear, getTimePassedPercent],
  );

  const localWorkOrders: WorkOrderType[] = useMemo(
    () => (checked ? _activeLocalWorkOrders : [..._activeLocalWorkOrders, ..._notActiveLocalWorkOrders]),
    [_activeLocalWorkOrders, _notActiveLocalWorkOrders, checked],
  );

  useEffect(() => {
    setResult(localWorkOrders);
    return () => {
      setResult([]);
    };
  }, [localWorkOrders]);

  const setRequestExtensionDate = useCallback((workOrderId: number, renewalRequestedOn: Date) => {
    setResult((prev) =>
      prev.map((workOrder) => {
        if (workOrder.id === workOrderId) {
          return { ...workOrder, renewal_requested_on: renewalRequestedOn };
        }
        return workOrder;
      }),
    );
  }, []);

  const sortedWorkorders = sortList(result, "workOrders");

  return (
    <ListItem disableGutters className={classesEng.engagement}>
      <Container className={classesEng.sideBoxContainer} data-testid="engagementContainer">
        <Box className={classesEng.leftSideBox}>
          <Button variant="text" onClick={() => setUnfold((prev) => !prev)} className={classesEng.iconButton}>
            <Box className={classesEng.titleBox}>
              <Box className={classesEng.title}>
                <Typography variant="subtitle1" className={classesEng.engagementName} data-testid="engagementName">
                  {trimSuspension(engagementName, 25)}
                </Typography>
              </Box>
              {isSM && (
                <Typography variant="subtitle1" data-testid="engStartEndDate">
                  {`Start: ${formatDateMDY(minDate.toString())} - End: ${formatDateMDY(maxDate.toString())}`}
                </Typography>
              )}
            </Box>
            {unfold || unfoldAll ? (
              <ExpandLess style={{ marginBottom: "20px" }} />
            ) : (
              <ExpandMore style={{ marginBottom: "20px" }} />
            )}
          </Button>
        </Box>
        {isXL && !isSM && (
          <Box className={classesEng.rightSideBox}>
            <Box
              onClick={() => setUnfold((prev) => !prev)}
              className={classesEng.engBar}
              style={{ width: "100%" }}
              data-testid="engagementBar"
            >
              <Typography variant="body2" style={{ textAlign: "center", color: "#333333" }}>
                {_activeLocalWorkOrders.length === 1
                  ? `${_activeLocalWorkOrders.length} Active Work Order`
                  : `${_activeLocalWorkOrders.length} Active Work Orders`}
              </Typography>
            </Box>
          </Box>
        )}
      </Container>
      <Collapse in={unfold || unfoldAll} className={classesEng.collapse}>
        <List className={classesWO.WOList} data-testid="WOList">
          {unfold || unfoldAll
            ? sortedWorkorders &&
              sortList(sortedWorkorders, "workOrders").map((workOrder: any, index: number) => (
                <WorkOrder
                  key={index}
                  WOId={workOrder.id}
                  unfoldAll={unfoldAll}
                  WOName={workOrder?.reference}
                  members={workOrder.member_assignments}
                  WOEndDate={workOrder.end_date}
                  WOStartDate={workOrder.start_date}
                  requestSentDate={workOrder.renewal_requested_on}
                  selectedYear={selectedYear}
                  engagementName={engagementName}
                  checked={checked}
                  setRequestExtensionDate={setRequestExtensionDate}
                />
              ))
            : sortedWorkorders &&
              sortList(sortedWorkorders, "workOrders").map((workOrder: any, index: number) => (
                <ListItem key={index} disableGutters className={classesWO.workOrder} data-testid="WOListItem">
                  <Box className={classesWO.leftSideBox}>
                    <Container className={classesWO.buttonsContainer} disableGutters>
                      <Button onClick={() => setUnfold((prev) => !prev)} className={classesWO.iconButton}>
                        <Box className={classesWO.titleBox}>
                          <Box className={classesWO.title} style={{ minWidth: "0" }}>
                            <Typography
                              variant="subtitle2"
                              className={classesWO.WOName}
                              style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                            >
                              {workOrder?.reference}
                            </Typography>
                            {isSM && (
                              <Typography variant="subtitle1">
                                {`${formatDateMDY(workOrder.start_date.toString())} - ${formatDateMDY(
                                  workOrder.end_date.toString(),
                                )}`}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {unfold || unfoldAll ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </Container>
                  </Box>
                </ListItem>
              ))}
        </List>
      </Collapse>
    </ListItem>
  );
};

export default Engagement;
