import { Box } from "@material-ui/core";
import TitleBar from "components/TitleBar";
import { useEffect, useState } from "react";

import RequestTable from "./components/RequestTable";
import RequestTeamDialog from "./components/RequestTeamDialog";
import { useAppSelector } from "store/hook";

const RequestTeamPage = () => {
  const { user } = useAppSelector((state) => state.user);

  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box>
      <TitleBar
        title={"Request new team members"}
        subtitle={"All Requests"}
        buttonText={"New request"}
        openDialog={handleOpenDialog}
        dialog={<RequestTeamDialog open={open} handleClose={handleCloseDialog} />}
        isStakeholder={user?.is_stakeholder ? user?.is_stakeholder : false}
        isSuperUser={user?.is_superuser ? user?.is_superuser : false}
      />
      <Box ml={3} mr={3} mt={2}>
        <RequestTable />
      </Box>
    </Box>
  );
};

export default RequestTeamPage;
