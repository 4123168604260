// external lib dependencies
import { SubmitHandler, FormProvider, useForm } from "react-hook-form";
import { Slide, Container, Typography, Box } from "@material-ui/core";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

// absolute path dependencies
import SectionTitleBar from "components/SectionTitleBar";
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import { questions } from "./questions";
import useFormatDataObject from "../hooks/useFormatDataObject";
import useSubmitHandler from "../hooks/useSubmitHandler";
import useStyles from "../styles";
import FormQuestions from "./FormQuestions";
import { useEngagementData } from "../../../providers/EngagementDataContext";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "store/hook";

interface Params {
  id?: string;
}
interface FormValues {
  answers: object;
  loggedInUser: object;
  questionList: object;
}

const FeedbackForm = () => {
  const classes = useStyles();
  const methods = useForm();
  const formatData = useFormatDataObject();
  const { postData, loading, error, sent } = useSubmitHandler();
  const { isSM } = useScreenSize();
  const location = useLocation();
  const history = useHistory();

  const { allEngagements, setAllEngagements, engagement, initEngagement, engagementLoadingError } = useEngagementData();
  const { user } = useAppSelector((state) => state.user);
  const { id } = useParams<Params>();

  useEffect(() => {
    if (location.search) {
      history.replace({
        search: "",
      });
    }
  }, [history, location.search]);

  // Get current engagement
  useEffect(() => {
    if (!id) return;

    if (Object.keys(engagement).length === 0 || engagement.id !== Number(id)) {
      initEngagement(Number(id));
    }
  }, [id]);

  const onSubmit: SubmitHandler<FormValues> = (answers) => {
    const projectID = document.getElementById("DropdownProjectId")?.dataset?.index;
    postData(formatData(projectID, answers, questions));
    if (!error && projectID) {
      const newEngagements = allEngagements.map((item) => {
        if (parseInt(projectID) === item.id) {
          let count = item.feedback_project_count ?? 0;
          count += 1;
          return { ...item, feedback_project_count: count };
        }
        return { ...item };
      });

      setAllEngagements(newEngagements);
    }
  };

  if (engagementLoadingError) {
    return <Redirect to="/*" />;
  }

  return (
    <div data-testid="feedbackForm">
      <SectionTitleBar
        title={
          <div data-testid="sectionTitleBar" className={classes.customTitleBar}>
            <Typography className={isSM ? classes.typographyXS : classes.typography}>
              CUSTOMER SATISFACTION SURVEY
            </Typography>
            <Typography variant={isSM ? "h6" : "h5"}>Help us improve your experience !</Typography>
          </div>
        }
        feedbackFormPage={true}
      />
      <Slide in direction="up">
        <div>
          <FormProvider {...methods}>
            <Container maxWidth="xl" className={classes.formContainer}>
              {!sent && !error && (
                <form autoComplete="off" data-testid="form" onSubmit={methods.handleSubmit(onSubmit)}>
                  <FormQuestions loading={loading} error={error} currentUser={user} />
                </form>
              )}
              {sent && !error && (
                <Box
                  component="span"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  data-testid="thankYouMessage"
                >
                  <SentimentVerySatisfiedIcon fontSize="large" />
                  <Typography variant="h5">
                    Thank you for taking the time to complete this survey. Your feedback is very important to us and
                    help us improve the service we offer. If we hold regular reviews with you, your feedback will be
                    discussed at the next review. Many thanks.
                  </Typography>
                </Box>
              )}
              {error && (
                <Box
                  component="span"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  data-testid="errorMessage"
                >
                  <SentimentVeryDissatisfiedIcon fontSize="large" />
                  <Typography variant="h5">{error}</Typography>
                </Box>
              )}
            </Container>
          </FormProvider>
        </div>
      </Slide>
    </div>
  );
};

export default FeedbackForm;
