// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
  },

  typography: {
    fontFamily: "league-gothic, sans-serif",
    fontSize: "36px",
    lineHeight: "48px",
  },

  typographyXS: {
    lineHeight: "28px",
  },

  customTitleBar: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  inputLabelFont: {
    color: theme.palette.type === "dark" ? "#FFF" : "black",
    lineHeight: "1.25rem",
  },

  inputField: {
    maxWidth: theme.spacing(59),
  },

  textAreaField: {
    maxWidth: theme.spacing(83),
  },

  wrapper: {
    marginLeft: "0",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },

  inputSelectContainer: {
    display: "flex",
    flexDirection: "column",
  },

  inputSelectItem: {
    maxWidth: theme.spacing(30),
  },

  inputSelectItemLarge: {
    maxWidth: theme.spacing(59),
  },

  paperMargin: {
    marginTop: theme.spacing(1.5),
  },

  submitButton: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(10),
  },

  viewFeedback: {
    padding: theme.spacing(0, 3, 0, 3),
    margin: theme.spacing(3, 0, 3, 0),
  },

  feedbackContent: {
    maxHeight: "1050px",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "none",
      overflowY: "visible",
    },
  },

  scoreBox: {
    padding: theme.spacing(0.5),
    height: "25px",
    width: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  questionBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  questionContainer: {
    marginBottom: theme.spacing(2),
  },

  loadingBox: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 0),
  },

  accordionSummary: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },

  tableRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },

  feedbackDetails: {
    padding: theme.spacing(1, 0, 1, 0),
  },

  errorMsgBox: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
