// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
    },
    container: {
      marginTop: theme.spacing(7.5),
      paddingBottom: theme.spacing(7.5),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },
    table: {
      tableLayout: "fixed",
    },
    chartContainer: {
      borderRadius: theme.spacing(1.75),
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
        borderRadius: 14,
        backgroundColor: theme.palette.background.paper,
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },

      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4),
      },
    },
    tableContainer: {
      borderRadius: theme.spacing(1.75),
      width: "100%",
      maxHeight: theme.spacing(37.5),
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: theme.spacing(1.25),
      },
      "&::-webkit-scrollbar-track": {
        background: "#e0e0e0",
        borderRadius: theme.spacing(1),
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#9a9a9a",
        borderRadius: theme.spacing(1),
      },
    },

    skeletonButton: {
      margin: 16,
    },

    noFilesMessage: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      margin: "0 auto",
    },

    closeButton: {
      textDecoration: "none",
      minWidth: 150,
      justifySelf: "flex-end",
      marginLeft: "auto",
      "&:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 2, 0),
      },
    },

    categoryBox: {
      [theme.breakpoints.down("sm")]: {
        width: "95%",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: "auto",
        marginLeft: "auto",
      },
    },
  })
);

export default useStyles;
