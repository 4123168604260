import { useState } from "react";

import { format, parseISO } from "date-fns";

import { Box, Chip, IconButton, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { ReleaseNote } from "config/types";
import he from "he";
import useStyles from "../styles";
import ReleaseNoteDialog from "./ReleaseNoteDialog";
import { ReactComponent as Edit } from "../../../assets/icons/commonIcons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/commonIcons/trash.svg";

interface ReleaseNoteCardProps {
  note: ReleaseNote;
  onDelete?: (note: ReleaseNote) => void;
  isSuperUser: boolean;
}

const ReleaseNoteCard = ({ note, onDelete, isSuperUser }: ReleaseNoteCardProps) => {
  const { note_content } = note;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleEditDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleNoteDelete = () => {
    onDelete?.(note);
  };

  function htmlDecode(input: string) {
    const decodedString = he.decode(input);
    return decodedString ? decodedString : "";
  }

  return (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Box className={classes.cardContainer}>
          <Box className={classes.cardDate}>
            <Chip
              color="primary"
              label={<Typography variant="h6">{format(parseISO(note.release_date), "MMM, dd, yyyy")}</Typography>}
            />
          </Box>

          <Box className={classes.cardContent}>
            <div dangerouslySetInnerHTML={{ __html: htmlDecode(note_content)! }} />
          </Box>

          {isSuperUser && (
            <Box className={classes.cardIcon}>
              <IconButton aria-label="edit-note" onClick={handleEditDialog}>
                <Edit />
              </IconButton>

              <IconButton aria-label="delete-note" onClick={handleNoteDelete}>
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
      </Paper>
      {open && <ReleaseNoteDialog open={open} handleClose={handleCloseDialog} releaseNote={note} />}
    </Grid>
  );
};

export default ReleaseNoteCard;
