// external lib dependencies
import { FC } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, TableContainer, Tooltip } from "@material-ui/core";

// absolute path dependencies
import wordIcon from "assets/icons/word-icon.svg";
import excelIcon from "assets/icons/excel-icon.svg";
import pdfIcon from "assets/icons/pdf-icon.svg";
import fileIcon from "assets/icons/file-icon.svg";
import { LockOpen, Lock } from "@material-ui/icons";

// local dependencies
import { useStyles } from "./styles";
import ActionsTableCell from "./ActionsTableCell";
import { FileWithExpireOnStatus} from "config/types";

interface Props {
  categoryName: string;
  files: FileWithExpireOnStatus[];
}

const FilesTable: FC<Props> = ({ categoryName, files }) => {
  const classes = useStyles();

  const getFileIcon = (file_name: string) => {
    let extension = file_name.split(".").pop();

    if (extension === "docx" || extension === "doc") {
      return <img src={wordIcon} className={classes.icon} alt="" />;
    } else if (extension === "xlsx" || extension === "xls" || extension === "csv") {
      return <img src={excelIcon} className={classes.icon} alt="" />;
    } else if (extension === "pdf") {
      return <img src={pdfIcon} className={classes.icon} alt="" />;
    } else return <img src={fileIcon} className={classes.icon} alt="" />;
  };
  
  return (
    <TableContainer>
      <Table className={classes.table} data-testid="table" stickyHeader>
        <TableHead data-testid="tableHead">
          <TableRow>
            <TableCell align="left">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
                data-testid="fileNameCell"
              >
                Confidentiality
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
                data-testid="fileNameCell"
              >
                File Name
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
                data-testid="providedByCell"
              >
                Provided By
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
                data-testid="createdCell"
              >
                Created
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
              >
                Expires in
              </Typography>
            </TableCell>
            <TableCell align="left" width="20%">
              <Typography
                variant="body2"
                classes={{
                  root: "semibold",
                }}
                data-testid="actions"
              >
                Actions
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="tableBody">
          {files.map((file, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>
              {file.password_hash !== null ? (
                  <div className={classes.fileIconNameArea}>
                    <Tooltip title="Sensitive Data" arrow>
                      <Lock fontSize="large" className={classes.icon} />
                    </Tooltip>               
                  </div>
                ) : (
                  <div className={classes.fileIconNameArea}>
                    <Tooltip title="Non-Sensitive Data" arrow>
                      <LockOpen fontSize="large" className={classes.icon} />
                    </Tooltip>               
                  </div>
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <div className={classes.fileIconNameArea}>
                  {getFileIcon(file.file_name)}
                  {file.file_name}
                </div>
              </TableCell>

              <TableCell className={classes.tableCell}>{file.uploaded_by}</TableCell>

              <TableCell className={classes.tableCell}>{file.added_on}</TableCell>
              
              <TableCell className={classes.tableCell}>{file.expireOnStatus}</TableCell>

              <TableCell className={classes.tableCell} align="left">
                <ActionsTableCell 
                  categoryName={categoryName} 
                  fileID={file.id} 
                  fileName={file.file_name} 
                  fileNotSensitive={file.password_hash === null} 
                  archived={file.archived}
                  uploadedBy={file.uploaded_by}
                  />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesTable;
