import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { Engagement } from 'config/types'
import { useLoadMoreButtonStyle } from './styles'

interface ILoadMoreButtonsInterface {
    allEngagements: Engagement[];
    setLoadedEngagements: Dispatch<SetStateAction<Engagement[]>>;
    showMyEngagements: boolean,
}

/**
 * @param allEngagements contains all engagements that need to be 
 * sliced into peaces each time Load More button is clicked
 * 
 * @param setLoadedEngagements contains all engagements that are 
 * sliced from allEngagements and rendered on the page
 * 
 * @param showMyEngagements boolean variable that if its true it 
 * shows only the users engagements and vice verca if its false
 */
const LoadMoreButtons = ({
    allEngagements,
    setLoadedEngagements,
    showMyEngagements,
}: ILoadMoreButtonsInterface
) => {
    const classes = useLoadMoreButtonStyle()
    const sizeOfChunk: number = 10;
    const [collectionReseted, setCollectionReset] = useState<boolean>(true);
    const [startIndex, setStartIndex] = useState<number>(0)
    const endIndex: number = startIndex + sizeOfChunk;
    const isNotLastElement: boolean = (endIndex + 1) <= allEngagements.length

    const LoadMoreEngagements = () => {
        if (isNotLastElement) {
            setStartIndex((prev) => prev += sizeOfChunk)
        }
    }


    useEffect(() => {
        // collection reseted if My Engagements checkbox is checked or unchecked
        setLoadedEngagements(allEngagements.slice(0, 10))
        setStartIndex(0);
        setCollectionReset(true);
        setCollectionReset(true);
    }, [showMyEngagements])

    useEffect(() => {
        const moreEngagement = allEngagements.slice(startIndex, endIndex);
        setLoadedEngagements(prev => {
            if (collectionReseted) {
                setCollectionReset(false);
                return [...prev!]
            } else {
                return [...prev!, ...moreEngagement]
            }
        })
    }, [startIndex]);

    return (
        <Box className={classes.boxStyle}>
            <Button onClick={LoadMoreEngagements} className={classes.leftButtonStyle}>Load More</Button>
            <Button onClick={LoadMoreEngagements} className={classes.rightButtonStyle}>Load More</Button>
        </Box>
    )
}

export default LoadMoreButtons