// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const generalFontStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  color: "#333333",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemAvatars: {
      display: "inline-flex",
      flexDirection: "row-reverse",
      paddingRight: "125px",
      "& > div": {
        overflow: "hidden",
      },
      "& > div:not(:last-child)": {
        marginLeft: "-60px",
      },
    },
    list: {
      width: "100%",
      padding: "0px",
    },
    listItem: {
      padding: "0px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    teamInfo: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        flex: 1,
        width: "100%",
      },
    },
    teamActions: {
      flex: 0.1,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
        flex: 1,
        width: "100%",
      },
    },
    actionsBox: {
      display: "flex",
      gap: theme.spacing(1),
      cursor: "pointer",
      alignItems: "center",
    },
    teamName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    avatarGroup: {
      marginTop: theme.spacing(1),
    },
    ungroupedBox: {
      display: "flex",
      width: "100%",
    },
    ungroupedDetails: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
    createTeamBtn: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    avatarList: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      paddingRight: theme.spacing(0.75),
      paddingLeft: theme.spacing(0.75),
      boxSizing: "border-box",
      gap: theme.spacing(3),
      width: "100%",
    },
    //general modal styles
    dialogModal:{
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
        padding: "16px 0px",
      },
    },
    dialogTitle: {
      padding: "8px 24px",
      "& .MuiTypography-root":{
        ...generalFontStyle,
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
      }
    },
    dialogContent: {
      "&:first-child":{
        paddingTop: "8px",
      }
    },
    
    dialogContentText: {
      ...generalFontStyle,
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "auto",
    },
    dialogActionBlock:{
      padding: "8px 24px",
      "& .Mui-disabled":{
        color: "#FFFFFF",
        backgroundColor: "#757575",
      }
    },
    dialogList: {
      "& .MuiListItem-gutters":{
        padding: "8px 24px"
      }
    },
    dialogListItemText:{
      ...generalFontStyle,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
  })
);

export default useStyles;
