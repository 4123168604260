export const fontSize = {
  surveySummary: 40,
  projectName: 15,
  score: 20,
  template: 9,
  confidentialityTitle: 16,
  confidentialityText: 11,
  contentsText: 11,
  footerText: 5.5,
  contentsTitle: 30,
  pageNumber: 7,
  sections: 25,
  footerAddress: 5,
  charts: 25,
  auditSection: 16,
  tableContent: 11,
  tableLineWeight: 0.05,
  chartDefault: 12,
  chartInPdf: 14,
  notesText: 16,
  notes: 11,
  tableContentSurveyInfo: 9,
  chartPointRadius: 3,
  chartGridlineWidth: 1,
  chartGridlineWidthPdf: 1,
  draft: 40,
  scoreSectionDescription: 10,
};

export const fontStyle:{bold: string, normal: string} = {
  bold: "bold",
  normal: "normal",
};

type HalignType = Record<
  "center" | "right" | "left" | "justify",
  "center" | "right" | "left" | "justify"
>;

export const hAlign: HalignType = {
  center: "center",
  right: "right",
  left: "left",
  justify: "justify",
};
