// external lib dependencies
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { FC } from "react";

interface Props {
  title?: string;
  contentText: string;
  open: boolean;
  buttonText: string;
  handleClose: () => void;
}

const AlertModal: FC<Props> = ({ open, handleClose, contentText, buttonText}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
