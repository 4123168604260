import { Box, TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import Button from "components/Button";
import useStyles from "../styles";

interface Props {
    searchValue?: string,
    handleSearchInput?: any,
    type?: string,
    handleResetSearch?: any,
}
const SearchField = ({ searchValue, handleSearchInput, type, handleResetSearch }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.searchContainer}>
            <Box width={"40%"}>
                <TextField
                    style={{ width: "95%" }}
                    disabled={!handleSearchInput}
                    variant="outlined"
                    margin="normal"
                    size="small"
                    placeholder={type ? `Search ${type} engagements by name` : "Search engagements by name"}
                    value={searchValue}
                    onChange={handleSearchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box width={"50%"}>
                <Button className={classes.resetButton} onClick={handleResetSearch} disabled={!handleResetSearch}>
                    Reset Search
                </Button>
            </Box>
        </div>
    );
}

export default SearchField;