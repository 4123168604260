// external lib dependencies
import { Grid, Typography, Box, Slide, Button, Container } from "@material-ui/core";

// absolute path dependencies
import { VoidFunc } from "config/types";
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import useStyles from "./styles";

interface ResponseMessageProps {
  messageType: "error" | "success";
  message: string;
  loading: boolean;
  onButtonClick: VoidFunc<any>;
}

const ResponseMessage = ({ messageType, message, loading, onButtonClick }: ResponseMessageProps) => {
  const classes = useStyles();
  const { isXS } = useScreenSize();

  return (
    <Container className={classes.messageFormFieldsContainer}>
      <Grid container direction="column" className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
        <Slide in direction="up">
          <Box component="span" className={classes.responseMessage}>
            <Typography variant="h6" className={classes.heading}>
              Send Message
            </Typography>
            {
              messageType === "error"
                ?
                <Typography variant="body2" data-testid="responseMessage" className={classes.messageText} style={{ textAlign: "center" }}>
                  {message}
                </Typography>
                :
                <Typography variant="body2" data-testid="responseMessage" className={classes.messageText} style={{ textAlign: "start" }}>
                  {message}
                </Typography>
            }
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-testid="responseButton"
              onClick={onButtonClick}
              className={classes.cancelButton}
              style={{ alignSelf: "end" }}
            >
              Close
            </Button>
          </Box>
        </Slide>
      </Grid>
    </Container>
  );
};

export default ResponseMessage;
