// local dependencies
import { CustomTableRow } from "pages/Feedback/types";
import { FetchData } from "./FetchData";

export async function sendCustomerSurveyData(data: any) {
  return await new FetchData("/customer-survey").POST(data);
}

export async function getCustomerSurveyData(): Promise<CustomTableRow[]> {
  return await new FetchData("/customer-survey").GET();
}

export async function sendSubmitBugData(data: FormData) {
  return await fetch("/api/submit-bug", {
    method: "POST",
    body: data,
  });
}

