// external lib dependencies
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";

// absolute path dependencies
import { Engagement, Risk } from "config/types";
import { getEngagement, getEngagementMembers, getEngagementsPaginated } from "services";
import { auth } from "services/auth";
import { getAllEngagements, getAudits } from "services/engagementsService";
import { getEngagementStatusByWorkOrders } from "helpers/engagementStatus";

interface EngagementDataState {
  engagement: Engagement;
  activeEngagements: Engagement[];
  expiredEngagements: Engagement[];
  fetchedActiveEngagements: Engagement[];
  fetchedCompletedEngagements: Engagement[];
  allEngagements: Engagement[];
  members: any[];
  audits: any[];
  risks: Risk[];
  loadingEngagement: boolean;
  loadingEngagements: boolean;
  loadingActiveEngagements: boolean;
  loadingExpiredEngagements: boolean;
  loadingMembers: boolean;
  loadingAudits: boolean;
  loadingRisks: boolean;
  engagementLoadingError?: boolean;
  initEngagement: (_: number) => void;
  initEngagements: (page: number, status1?: string, status2?: string, my_engagements_only?: boolean) => void;
  fetchMoreActiveEngagements: (
    status1: string,
    page: number,
    search_input?: string,
    my_engagements_only?: boolean
  ) => void;
  fetchMoreCompletedEngagements: (
    status1: string,
    page: number,
    search_input?: string,
    my_engagements_only?: boolean
  ) => void;
  fetchActiveEngagements: (status: string, page: number, my_engagements_only?: boolean) => void;
  fetchCompletedEngagements: (status: string, page: number, my_engagements_only?: boolean) => void;
  initAllEngagements: () => void;
  setEngagement: any;
  setActiveEngagements: any;
  setExpiredEngagements: any;
  setFetchedActiveEngagements: any;
  setFetchedCompletedEngagements: any;
  setAllEngagements: any;
}

export const EngagementDataContext = createContext<EngagementDataState>({
  engagement: {} as Engagement,
  activeEngagements: [],
  expiredEngagements: [],
  fetchedActiveEngagements: [],
  fetchedCompletedEngagements: [],
  allEngagements: [],
  members: [],
  audits: [],
  risks: [],
  loadingEngagement: false,
  loadingEngagements: false,
  loadingActiveEngagements: false,
  loadingExpiredEngagements: false,
  loadingMembers: false,
  loadingAudits: false,
  loadingRisks: false,
  initEngagement: (_: number) => {},
  initEngagements: (page: number, status1?: string, status2?: string, my_engagements_only?: boolean) => {},
  fetchMoreActiveEngagements: (
    status1: string,
    page: number,
    search_input?: string,
    my_engagements_only?: boolean
  ) => {},
  fetchMoreCompletedEngagements: (
    status1: string,
    page: number,
    search_input?: string,
    my_engagements_only?: boolean
  ) => {},
  fetchActiveEngagements: (status: string, page: number, my_engagements_only?: boolean) => {},
  fetchCompletedEngagements: (status: string, page: number, my_engagements_only?: boolean) => {},
  initAllEngagements: () => {},
  setEngagement: () => {},
  setActiveEngagements: () => {},
  setExpiredEngagements: () => {},
  setFetchedActiveEngagements: () => {},
  setFetchedCompletedEngagements: () => {},
  setAllEngagements: () => {},
});

export const EngagementDataProvider: FC = ({ children }) => {
  const [engagement, setEngagement] = useState<Engagement>({} as Engagement);
  const [activeEngagements, setActiveEngagements] = useState<Engagement[]>([]);
  const [expiredEngagements, setExpiredEngagements] = useState<Engagement[]>([]);
  const [fetchedActiveEngagements, setFetchedActiveEngagements] = useState<Engagement[]>([]);
  const [fetchedCompletedEngagements, setFetchedCompletedEngagements] = useState<Engagement[]>([]);
  const [allEngagements, setAllEngagements] = useState<Engagement[]>([]);
  const [members, setMembers] = useState<any>([]);
  const [audits, setAudits] = useState<any>([]);
  const [risks, setRisks] = useState<Risk[]>([]);
  const [loadingEngagement, setLoadingEngagement] = useState<boolean>(true);
  const [loadingEngagements, setLoadingEngagements] = useState<boolean>(true);
  const [loadingActiveEngagements, setLoadingActiveEngagements] = useState<boolean>(true);
  const [loadingExpiredEngagements, setLoadingExpiredEngagements] = useState<boolean>(true);
  const [loadingMembers, setLoadingMembers] = useState<boolean>(true);
  const [loadingAudits, setLoadingAudits] = useState<boolean>(true);
  const [loadingRisks, setLoadingRisks] = useState<boolean>(true);
  const [engagementLoadingError, setEngagementLoadingError] = useState<boolean>(false);

  useEffect(
    () => {
      const onLogout = () => {
        setEngagement({} as Engagement);
        setActiveEngagements([]);
        setExpiredEngagements([]);
        setFetchedActiveEngagements([]);
        setFetchedCompletedEngagements([]);
        setAllEngagements([]);
        setMembers([]);
        setAudits([]);
        setRisks([]);
        setLoadingEngagement(true);
        setLoadingEngagements(true);
        setLoadingActiveEngagements(true);
        setLoadingExpiredEngagements(true);
        setLoadingMembers(true);
        setLoadingAudits(true);
        setLoadingRisks(true);
      };

      auth.addLogoutHandler(onLogout);

      return () => {
        auth.removeLogoutHandler(onLogout);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchEngagement = useCallback(
    (engagementId: number) => {
      setLoadingEngagement(true);

      getEngagement(engagementId)
        .then((engagementData) => {
          setEngagement({
            ...engagementData,
            status: getEngagementStatusByWorkOrders(engagementData.work_orders),
          } as Engagement);
        })
        .catch(() => setEngagementLoadingError(true))
        .finally(() => setLoadingEngagement(false));
    },
    [setEngagement, setLoadingEngagement]
  );

  const fetchActiveEngagements = useCallback(
    (status: string, page: number, my_engagements_only?: boolean) => {
      
      setLoadingActiveEngagements(true);
      setEngagementLoadingError(false);
      getEngagementsPaginated(status, page, "", my_engagements_only)
        .then((engagementRecords) => {
          setActiveEngagements(
            engagementRecords.map(
              (engagementData: any) =>
                ({
                  ...engagementData,
                  status: getEngagementStatusByWorkOrders(engagementData.work_orders),
                } as Engagement)
            )
          );
        })
        .catch(console.error)
        .finally(() => setLoadingActiveEngagements(false));
    },
    [setActiveEngagements, setLoadingActiveEngagements]
  );

  const fetchCompletedEngagements = useCallback(
    (status: string, page: number, my_engagements_only?: boolean) => {
      setLoadingExpiredEngagements(true);
      setEngagementLoadingError(false);

      getEngagementsPaginated(status, page, "", my_engagements_only)
        .then((engagementRecords) => {
          setExpiredEngagements(
            engagementRecords.map(
              (engagementData: any) =>
                ({
                  ...engagementData,
                  status: getEngagementStatusByWorkOrders(engagementData.work_orders),
                } as Engagement)
            )
          );
        })
        .catch(console.error)
        .finally(() => setLoadingExpiredEngagements(false));
    },
    [setExpiredEngagements, setLoadingExpiredEngagements]
  );

  const fetchSomeMoreActiveEngagements = useCallback(
    (status: string, page: number, search_input?: string, my_engagements_only?: boolean) => {
      setEngagementLoadingError(false);
      getEngagementsPaginated(status, page, search_input, my_engagements_only)
        .then((engagementRecords) => {
          setFetchedActiveEngagements(
            engagementRecords.map(
              (engagementData: any) =>
                ({
                  ...engagementData,
                  status: getEngagementStatusByWorkOrders(engagementData.work_orders),
                } as Engagement)
            )
          );
        })
        .catch(console.error);
    },
    [setFetchedActiveEngagements, setLoadingActiveEngagements]
  );

  const fetchSomeMoreCompletedEngagements = useCallback(
    (status: string, page: number, search_input?: string, my_engagements_only?: boolean) => {
      setEngagementLoadingError(false);
      getEngagementsPaginated(status, page, search_input, my_engagements_only)
        .then((engagementRecords) => {
          setFetchedCompletedEngagements(
            engagementRecords.map(
              (engagementData: any) =>
                ({
                  ...engagementData,
                  status: getEngagementStatusByWorkOrders(engagementData.work_orders),
                } as Engagement)
            )
          );
        })
        .catch(console.error);
    },
    [setFetchedCompletedEngagements, setLoadingActiveEngagements]
  );

  const fetchEngagements = useCallback(() => {
    setLoadingEngagements(true);
    setEngagementLoadingError(false);

    getAllEngagements()
      .then((engagementRecords) => {
        setAllEngagements(
          engagementRecords.map(
            (engagementData: any) =>
              ({
                ...engagementData,
                status: getEngagementStatusByWorkOrders(engagementData.work_orders),
              } as Engagement)
          )
        );
      })
      .catch(console.error)
      .finally(() => setLoadingEngagements(false));
  }, [setAllEngagements, setLoadingEngagements]);

  const fetchMembers = useCallback(
    (engagementId: number) => {
      setLoadingMembers(true);

      getEngagementMembers(engagementId)
        .then((res) => setMembers(res))
        .catch(console.error)
        .finally(() => setLoadingMembers(false));
    },
    [setMembers, setLoadingMembers]
  );

  const fetchAudits = useCallback(
    (engagementId: number) => {
      setLoadingAudits(true);

      getAudits(engagementId)
        .then((res) => {
          // filter audits from backend by ID
          if (res.audits.length > 0) {
            res.audits = res.audits
              .map((aud: any) => ({
                ...aud,
                aphiSurveys: aud.aphiSurveys.filter((survey: any) => survey.createdAt >= "2021"),
              }))
              .filter((aud: any) => aud.dataScore[0])
              .map((aud: any) => {
                aud.dataScore[0].data = aud.dataScore[0].data.filter((score: any) => score.x >= "2021");
                return aud;
              })
              .filter((aud: any) => aud.dataScore[0].data.length > 0);

            res.audits = res.audits.map((aud: any) => {
              if (aud.scoringType === 1) {
                return {
                  ...aud,
                  limits: {
                    minGreen: 0,
                    maxGreen: 20.99,
                    minYellow: 21,
                    maxYellow: 39.99,
                    minRed: 40,
                    maxRed: 100,
                  },
                };
              } else if (aud.scoringType === 2) {
                return {
                  ...aud,
                  limits: {
                    minGreen: 90,
                    maxGreen: 100,
                    minYellow: 50,
                    maxYellow: 89.99,
                    minRed: 0,
                    maxRed: 49.99,
                  },
                };
              } else return aud;
            });

            const auditList = res.audits.filter((aud: any) => ![4, 38, 46, 49, 50].includes(aud.id));
            setAudits(auditList);
          } else {
            setAudits([]);
          }
        })
        .catch(console.error)
        .finally(() => setLoadingAudits(false));
    },
    [setAudits]
  );

  const initEngagement = useCallback(
    (engagementId: number) => {
      fetchEngagement(engagementId);
      fetchAudits(engagementId);
      fetchMembers(engagementId);
    },
    [fetchEngagement, fetchAudits, fetchMembers]
  );

  const initEngagements = useCallback(
    (page: number, status1?: string, status2?: string, my_engagements_only?: boolean) => {
      if (status1) {
        fetchActiveEngagements(status1, page, my_engagements_only);
      }
      if (status2) {
        fetchCompletedEngagements(status2, page, my_engagements_only);
      }
    },
    [fetchActiveEngagements, fetchCompletedEngagements]
  );

  const initAllEngagements = useCallback(() => {
    fetchEngagements();
  }, [fetchEngagements]);

  const fetchMoreActiveEngagements = useCallback(
    (status: string, page: number, search_input?: string, my_engagements_only?: boolean) => {
      fetchSomeMoreActiveEngagements(status, page, search_input, my_engagements_only);
    },
    [fetchSomeMoreActiveEngagements]
  );

  const fetchMoreCompletedEngagements = useCallback(
    (status: string, page: number, search_input?: string, my_engagements_only?: boolean) => {
      fetchSomeMoreCompletedEngagements(status, page, search_input, my_engagements_only);
    },
    [fetchSomeMoreCompletedEngagements]
  );

  // TODO: rename; "value" is a bad very generic name ... almost everything can be a value
  const value = useMemo(() => {
    return {
      engagement,
      activeEngagements,
      expiredEngagements,
      fetchedActiveEngagements,
      fetchedCompletedEngagements,
      allEngagements,
      members,
      audits,
      risks,
      loadingEngagement,
      loadingEngagements,
      loadingActiveEngagements,
      loadingExpiredEngagements,
      loadingMembers,
      loadingAudits,
      loadingRisks,
      engagementLoadingError,
      initEngagement,
      initEngagements,
      initAllEngagements,
      fetchMoreActiveEngagements,
      fetchMoreCompletedEngagements,
      fetchActiveEngagements,
      fetchCompletedEngagements,
      setEngagement,
      setActiveEngagements,
      setFetchedActiveEngagements,
      setFetchedCompletedEngagements,
      setExpiredEngagements,
      setAllEngagements,
    };
  }, [
    engagement,
    activeEngagements,
    expiredEngagements,
    fetchedActiveEngagements,
    fetchedCompletedEngagements,
    allEngagements,
    members,
    audits,
    risks,
    loadingEngagement,
    loadingEngagements,
    loadingActiveEngagements,
    loadingExpiredEngagements,
    loadingMembers,
    loadingAudits,
    loadingRisks,
    engagementLoadingError,
    initEngagement,
    initEngagements,
    initAllEngagements,
    fetchMoreActiveEngagements,
    fetchMoreCompletedEngagements,
    fetchActiveEngagements,
    fetchCompletedEngagements,
  ]);

  return <EngagementDataContext.Provider value={value}>{children}</EngagementDataContext.Provider>;
};

export const useEngagementData = () => useContext(EngagementDataContext);
