import { FilterMenu } from "./components/CustomTable/CustomTable";
import { TableColumn } from "./types";

export const columns: TableColumn[] = [
    {
      label: 'Full Name',
      filterable: true,
      filterBy: FilterMenu.names
    },
    {
      label: 'Position',
      filterable: false,
    },
    {
      label: 'Project Name',
      filterable: true,
      filterBy: FilterMenu.projects,
    },
    {
      label: 'Company Name',
      filterable: true,
      filterBy: FilterMenu.companies,
    },
    {
      label: 'Date',
      filterable: false,
    },
    {
      label: 'NPS Score',
      filterable: false,
    },
    {
      label: '',
      filterable: false,
    },
  ]
