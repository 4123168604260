// external lib dependencies
import { Box, Grid} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// local dependencies
import useStyles from "./styles";


export default function TeamSectionSkeleton() {
  const classes = useStyles();

  return (
      <Grid>
        <Grid item container className={classes.divider} style={{ justifyContent: "space-between", display:"flex", alignItems: "center" }}>
          <Skeleton width="120px" height="30px" />
          <Skeleton width="120px" height="37px" />
        </Grid>
        <Grid item>
          <Box className={classes.mainBody}>
            <Box className={classes.mainBodyRow}>
              <Box flex="1 0 35%">
                <Box className={classes.avatarList} >
                  <Skeleton width="350px" height={"90px"} variant={"rect"} />
                  <Skeleton width="350px" height={"90px"} variant={"rect"} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}
