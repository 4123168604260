// external lib dependencies
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    chip: {
      width: 106,
      height: 36,
      borderRadius: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& .MuiTypography-body2": {
        fontWeight: 600,
      },

      "&.active": {
        backgroundColor: "#2DB57140",
        color: "#2DB571",
      },

      "&.completed": {
        backgroundColor: "#005EB840",
        color: "#005EB8",
      },

      "&.setting-up": {
        backgroundColor: "#ff666640",
        color: "#ff6666",
      },

      "&.onboarding": {
        backgroundColor: "#ffcc6640",
        color: "#ffcc66",
      },
    },
  }),
);

export default useStyles;
