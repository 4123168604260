// external lib dependencies
import { Theme, useMediaQuery } from "@material-ui/core";

const useScreenSize = () => {
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));

  return { isXS, isSM, isMD, isLG, isXL };
};

export default useScreenSize;
