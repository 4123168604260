// external lib dependencies
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Typography, Slide, Button as MuiButton } from "@material-ui/core";

// absolute path dependencies
import { loginSendEmailService } from "services";
import { ACCESS_CODE_RESENDING_TIMEOUT } from "config/constants";
import Button from "components/Button";
import CountdownTimer from "components/CountdownTimer";

// local dependencies
import useStyles from "./styles";

interface AccessCodeFormProps {
  email: string;
  handleClick: () => Promise<void>;
}

const AccessCodeForm = ({ email, handleClick }: AccessCodeFormProps) => {
  const history = useHistory();
  const classes = useStyles();
  const [prohibitResendCode, setProhibitResendCode] = useState(false);
  const [invalidCodeMessage, setInvalidCodeMessage] = useState("");
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [resendCodeMsg, setResendCodeMsg] = useState("");
  const [historyState, _] = useState<any>(history.location.state);

  const resendAccessCode = useCallback(() => {
    setResendCodeLoading(true);
    setResendCodeMsg("");

    loginSendEmailService(email, historyState.referrerLink)
      .then(() => {
        setResendCodeMsg("Access link was successfully resent");
        setTimeout(() => setResendCodeMsg(""), 4000);
      })
      .catch(() => {
        setInvalidCodeMessage("Please contact Amdaris to verify your access to Client Portal.");
        setTimeout(() => setInvalidCodeMessage(""), 4000);
      })
      .finally(() => {
        setProhibitResendCode(true);
        setResendCodeLoading(false);
      });
  }, [email]);

  const enableAccessCodeResending = useCallback(() => setProhibitResendCode(false), []);

  return (
    <Slide in direction={"up"}>
      <Form className={classes.accessCodeForm}>
        <Button
          disabled={prohibitResendCode}
          loading={resendCodeLoading}
          className={classes.resendAccessCodeBtn}
          onClick={resendAccessCode}
        >
          <Typography variant="body1">RESEND VERIFICATION LINK</Typography>
        </Button>
        <MuiButton onClick={handleClick} className={classes.teamMemberBtn}>
          Log in as Amdaris team member
        </MuiButton>
        {resendCodeMsg && (
          <Typography variant="body2" className={classes.successfullyResendCodeMsg}>
            {resendCodeMsg}
          </Typography>
        )}
        {prohibitResendCode && (
          <CountdownTimer
            countdownSeconds={ACCESS_CODE_RESENDING_TIMEOUT}
            onCountdownExpiration={enableAccessCodeResending}
          />
        )}
      </Form>
    </Slide>
  );
};

export default AccessCodeForm;
