// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      width: "250px",
      margin: 0,
    },
    boxWrapper: {
      padding: "15px",
      maxWidth: "270px",
      width: "100%",
    },
    MenuItem: {
      "& .menu-item__delete-button": {
        display: "none",
        width: "40px",
        height: "40px",
      },
      "&:hover .menu-item__delete-button": {
        display: "flex",
        alignItems: "center",
      },
    },
    checkboxColor: {
      "&.Mui-checked": {
        color: "#333333",
      },
    },
    menuItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    menuList: {
      height: "100%",
      maxHeight: "420px",
      overflowY: "auto",
    },
  })
);

export default useStyles;
