// external lib dependencies
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { FC, useEffect, useState } from "react";
import { IconButton, Tooltip, colors } from "@material-ui/core";

// absolute path dependencies
import { useFilesContext } from "providers/FilesContext";

// local dependencies
import ChangeCategoryDialog from "./ChangeCategoryDialog";
import DeleteFileDialog from "./DeleteFileDialog";
import EnterPassword from "./EnterPassword";
import { ReactComponent as Unarchive } from "../../../assets/icons/fileSharingIcons/unarch.svg"
import UnarchiveFileDialog from "./UnarchiveFileDialog"; import { useAppSelector } from "store/hook";


interface Props {
  categoryName: string;
  fileID: string;
  fileName: string;
  fileNotSensitive: boolean;
  archived: boolean;
  uploadedBy: string;
}

const ActionsTableCell: FC<Props> = ({ categoryName, fileID, fileName, fileNotSensitive, archived, uploadedBy }) => {
  const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.user);
  const { fetchFile, errorMessage, isModalClosed } = useFilesContext();
  const accessElevationRoles = ["Portfolio Director", "Client Partner", "Head of Agile Delivery", "Chief Delivery Director"];

  useEffect(() => {
    let jobTitleIndex = -1;
    
    if (user?.job_title) {
      jobTitleIndex = accessElevationRoles.indexOf(user?.job_title);
    }

    if (user?.email === uploadedBy)
      setCanDelete(true);
    else if (!user?.is_stakeholder && fileNotSensitive) {
      setCanDelete(true);
    }
    else if (!user?.is_stakeholder && jobTitleIndex !== -1) {
      setCanDelete(true);
    }
  }, []);

  const handleOpenUnarchiveDialog = () => {
    setOpenUnarchiveDialog(true);
  };

  const handleCloseUnarchiveDialog = () => {
    setOpenUnarchiveDialog(false);
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const handleOpenPasswordDialog = () => {
    setError('')
    if (fileNotSensitive) {
      fetchFile(fileID, fileName, '');
    } else {
      setOpenPasswordDialog(true);
    }
  };

  const handleSubmitPasswordDialog = async (password: string) => {
    await fetchFile(fileID, fileName, password);
  };

  useEffect(() => {
    setError(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (isModalClosed === false) {
      setOpenPasswordDialog(false)
    }
  }, [isModalClosed])

  return (
    <div className="actionsButtons">
      <EnterPassword
        open={openPasswordDialog}
        handleClose={handleClosePasswordDialog}
        handleSubmitPassword={handleSubmitPasswordDialog}
        errorMessage={error}
      />

      {
        !archived &&
        <>
          <Tooltip title="Change Category">
            <IconButton onClick={handleOpenCategoryDialog}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download">
            <IconButton onClick={handleOpenPasswordDialog}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      }

      {
        !user?.is_stakeholder && archived &&
        <Tooltip title="Unarchive">
          <IconButton onClick={handleOpenUnarchiveDialog}>
            <Unarchive />
          </IconButton>
        </Tooltip>
      }

      {
        canDelete ?
          <Tooltip title="Delete">
            <IconButton onClick={handleOpenDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title="Not authorized to delete">
            <IconButton >
              <DeleteIcon style={{ color: '#EAEAEA' }} />
            </IconButton>
          </Tooltip>
      }
      <UnarchiveFileDialog
        open={openUnarchiveDialog}
        onClose={handleCloseUnarchiveDialog}
        fileID={fileID}
      />

      <ChangeCategoryDialog
        open={openCategoryDialog}
        onClose={handleCloseCategoryDialog}
        categoryName={categoryName}
        fileID={fileID}
      />
      <DeleteFileDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        fileID={fileID}
        uploadedBy={uploadedBy}
      />
    </div>
  );
};

export default ActionsTableCell;
