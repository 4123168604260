import { FC, Fragment, useState } from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Typography from "@material-ui/core/Typography";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { RequestTeam } from "config/types";
import { formatDateDMY } from "helpers/datetime";
import WarningDialog from "pages/MemberProfile/components/ProvidedFeeback/WarningDialog";
import { useAppDispatch } from "store/hook";
import { deleteTeamRequestAsync } from "store/reducers/teamRequest/teamRequestSlice";
import useStyles from "../styles";
import RequestTeamDialog from "./RequestTeamDialog";

interface RowProps {
  request: RequestTeam;
  stakeholderView: boolean;
}

const Row: FC<RowProps> = ({ request, stakeholderView }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  const { is_new, requested_by, request_date, request_content, request_comment } = request;

  const requestStatus: string = is_new ? classes.new : classes.completed;
  const status = is_new ? "new" : "completed";

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDelete = (request: RequestTeam) => {
    dispatch(deleteTeamRequestAsync(request));
    setOpenWarning(false);
    setOpen(false);
  };

  const handleRequestDelete = () => {
    setOpenWarning(true);
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell className={requestStatus}>{status.toUpperCase()}</TableCell>
        <TableCell className={classes.cell}>{request_date}</TableCell>
        <TableCell className={classes.cell}>{requested_by}</TableCell>
        <TableCell className={classes.cell}>
          {request_content.length > 0 &&
            request_content.map((member, index) => (
              <Typography variant="body2" key={index}>
                {member.member_role}
              </Typography>
            ))}
        </TableCell>
        <TableCell className={classes.cell}>
          {request_content.length > 0 &&
            request_content.map((member, index) => (
              <Typography variant="body2" key={index}>
                {member.member_type_quantity}
              </Typography>
            ))}
        </TableCell>
        <TableCell className={classes.cell}>
          {request_content.length > 0 &&
            request_content.map((member, index) => (
              <Typography variant="body2" key={index}>
                {formatDateDMY(member.member_start_date)}
              </Typography>
            ))}
        </TableCell>
        <TableCell className={`${classes.cell} ${classes.cellDuration}`}>
          {request_content.length > 0 &&
            request_content.map((member, index) => (
              <Typography variant="body2" key={index}>
                {member.member_duration}
              </Typography>
            ))}
        </TableCell>
        <TableCell>{request_comment}</TableCell>
        {stakeholderView && (
          <TableCell>
            <Box display="flex">
              <IconButton onClick={handleOpenDialog}>
                <EditOutlinedIcon className={classes.icon} />
              </IconButton>
              <IconButton onClick={handleRequestDelete}>
                <DeleteOutlineOutlinedIcon className={classes.icon} />
              </IconButton>
            </Box>
          </TableCell>
        )}
      </TableRow>
      <RequestTeamDialog open={open} handleClose={handleCloseDialog} teamRequest={request} />
      <WarningDialog
        open={openWarning}
        setOpenWarning={setOpenWarning}
        contentText={"Are you sure you want to delete the request ?"}
        request={request}
        onDelete={handleDelete}
      />
    </Fragment>
  );
};

export default Row;
