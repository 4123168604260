import { Box, Fade, ListItem, Tooltip } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import { useLeftDrawerStyles } from "./styles";

interface NavListItemProps {
  label: string;
  path: string;
  blackIcon: any;
  whiteIcon: any;
  open: boolean;
}

const NavListItem = ({ label, path, blackIcon, whiteIcon, open }: NavListItemProps) => {
  const { pathname } = useLocation();
  const classes = useLeftDrawerStyles();
  return (
    <ListItem className={classes.listItem}>
      <Tooltip title={open ? "" : label} placement="right" arrow>
        <NavLink to={path} activeClassName={classes.activeListItemIcon}>
          <Box className={classes.listItemIcon}>{pathname.includes(path) ? blackIcon : whiteIcon}</Box>
        </NavLink>
      </Tooltip>
      <NavLink to={path} className={classes.NavLinknoUnderline}>
        <Fade in={open} timeout={{ enter: 300, exit: 10 }}>
          <Box className={classes.listItemText} style={{ display: open ? "block" : "none" }}>
            {label}
          </Box>
        </Fade>
      </NavLink>
    </ListItem>
  );
};

export default NavListItem;
