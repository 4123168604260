const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export function formatDateMDY(date: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return new Date(date).toLocaleString("en-GB", options);
}

export function formatDateDM(date: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };

  return new Date(date).toLocaleString("en-GB", options);
}

export function getLocaleDateString(date: string | Date): string {
  return new Date(date).toLocaleDateString();
}

export function getDatesDiferenceInDays(expireOn: Date, archived: boolean): string {
  const defaultDate = new Date(0);
  const currentDate = new Date();
  const diffInDays = Math.round(Math.abs(expireOn.valueOf() - currentDate.valueOf()) / (ONE_DAY_IN_MILLISECONDS));
  
  if(expireOn.getTime() === defaultDate.getTime()){
    return '';
  }

  if(expireOn > currentDate && !archived && diffInDays > 1){
    return `${diffInDays} days`
  }

  if(expireOn > currentDate && !archived && diffInDays === 1){
    return `${diffInDays} day`
  }

  if(!diffInDays && !archived){
    return 'Today'
  }

  return 'Archived'
}

export function isDateInNextNDays(date: Date, days: number): boolean {
  return date < new Date(Date.now() + days * ONE_DAY_IN_MILLISECONDS);
}

export function formatDateDMY(date: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return new Date(date).toLocaleString("en-GB", options).replaceAll("/", ".");
}

export function formatDateYMD(date: string | Date | undefined): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  if (date === undefined) 
    return '';

  return new Date(date).toLocaleDateString("en-GB", options).split("/").reverse().join("-");
}
