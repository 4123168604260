// external lib dependencies
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { FC, useState, ClipboardEvent } from "react";
import useStyles from "./styles";

// icons
import { RemoveRedEyeRounded } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import { CheckBox } from "@material-ui/icons";

// absolute path dependencies
import Button from "components/Button";
import useScreenSize from "hooks/useScreenSize";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmitPassword: (password: string) => void;
}

const PasswordSetDialog: FC<Props> = ({ open, handleClose, handleSubmitPassword }) => {
  const classes = useStyles();
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const { isXS } = useScreenSize();
  const [inputType, setInputType] = useState("password");
  const [inputTypeConfirm, setInputTypeConfirm] = useState("password");

  // validations
  const [lengthValidated, setLengthValidated] = useState(false);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [matchValidated, setMatchValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [noSpecialCharactersValidated, setNoSpecialCharactersValidated] = useState(false);

  const [canSendPasswordForm, setCanSendPasswordForm] = useState(true);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleSubmitPassword(passwordTwo);
    setPasswordOne("");
    setPasswordTwo("");
    setLengthValidated(false);
    setLowerValidated(false);
    setUpperValidated(false);
    setMatchValidated(false);
    setNumberValidated(false);
    setNoSpecialCharactersValidated(false);
  };

  // regex
  const lower = new RegExp("(?=.*[a-z])");
  const upper = new RegExp("(?=.*[A-Z])");
  const number = new RegExp("(?=.*[0-9])");
  const length = new RegExp("^(?=.{8,64}$).*");
  const special = new RegExp("^[a-zA-Z0-9]{1,100}$");

  const handlePasswordOneChange = (event: any) => {
    if (lower.test(event.target.value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }

    if (upper.test(event.target.value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }

    if (number.test(event.target.value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (length.test(event.target.value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }

    if (special.test(event.target.value)) {
      setNoSpecialCharactersValidated(true);
    } else {
      setNoSpecialCharactersValidated(false);
    }

    if (event.target.value === passwordTwo) {
      setMatchValidated(true);
    } else {
      setMatchValidated(false);
    }

    setPasswordOne(event.target.value);

    if (lowerValidated && upperValidated && numberValidated && lengthValidated && event.target.value === passwordTwo) {
      setCanSendPasswordForm(false);
    } else {
      setCanSendPasswordForm(true);
    }
  };

  const handlePasswordTwoChange = (event: any) => {
    setPasswordTwo(event.target.value);

    if (event.target.value === passwordOne) {
      setMatchValidated(true);
    } else {
      setMatchValidated(false);
    }

    if (lowerValidated && upperValidated && numberValidated && lengthValidated && event.target.value === passwordOne) {
      setCanSendPasswordForm(false);
    } else {
      setCanSendPasswordForm(true);
    }
  };

  const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <label className={classes.passwordFormLabels} htmlFor="passwordOne">
              Add Password
            </label>
            <div className={classes.buttonsContainer}>
              <input
                className={classes.fieldInput}
                type={inputType}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
                name="passwordOne"
                value={passwordOne}
                onChange={handlePasswordOneChange}
              />
              {inputType === "password" ? (
                <span className={classes.eyeIcon} onClick={() => setInputType("text")}>
                  <RemoveRedEyeRounded></RemoveRedEyeRounded>
                </span>
              ) : (
                <span className={classes.eyeIcon} onClick={() => setInputType("password")}>
                  <VisibilityOff></VisibilityOff>
                </span>
              )}
            </div>
          </Grid>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <label className={classes.passwordFormLabels} htmlFor="passwordTwo">
              Repeat Password
            </label>
            <div className={classes.buttonsContainer}>
              <input
                className={classes.fieldInput}
                type={inputTypeConfirm}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
                name="passwordTwo"
                value={passwordTwo}
                onChange={handlePasswordTwoChange}
              />
              {inputTypeConfirm === "password" ? (
                <span className={classes.eyeIcon} onClick={() => setInputTypeConfirm("text")}>
                  <RemoveRedEyeRounded></RemoveRedEyeRounded>
                </span>
              ) : (
                <span className={classes.eyeIcon} onClick={() => setInputTypeConfirm("password")}>
                  <VisibilityOff></VisibilityOff>
                </span>
              )}
            </div>
          </Grid>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <main>
              <div className={classes.passwordCheckContainer}>
                {lengthValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Password must be betweem 8 and 64 characters long.</div>
              </div>
              <div className={classes.passwordCheckContainer}>
                {lowerValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Password must contain at least one lowercase character.</div>
              </div>
              <div className={classes.passwordCheckContainer}>
                {upperValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Password must contain at least one uppercase character.</div>
              </div>
              <div className={classes.passwordCheckContainer}>
                {numberValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Password must contain at least one number character.</div>
              </div>
              <div className={classes.passwordCheckContainer}>
                {noSpecialCharactersValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Password must not contain special characters.</div>
              </div>
              <div className={classes.passwordCheckContainer}>
                {matchValidated === false ? <CheckBoxOutlineBlank /> : <CheckBox />}
                <div className={classes.passwordCheckText}>Passwords must match.</div>
              </div>
            </main>
          </Grid>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <span className={classes.mediumTextSpan}>This document will be protected by this password.</span>
            <br />
            <span className={classes.mediumTextSpan}>For downloading it later, you must enter the set password.</span>
          </Grid>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <div className={classes.buttonsContainer}>
              <Button className={classes.sensitiveDataButtonSubmit} type="submit" disabled={canSendPasswordForm}>
                Save Password
              </Button>
              <Button className={classes.sensitiveDataButtonClose} onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordSetDialog;
