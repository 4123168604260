import { SetStateAction, Dispatch, useState } from 'react';
import { Box, Modal, Button, Typography, Collapse, styled, Dialog } from '@material-ui/core';
import { useGdprModalStyles } from './styles';
// import { styled } from '@material-ui/material/styles';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
}));

interface GdprModalProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    handleSubmit: () => void | undefined,
}

/***
 * this is a Modal pop up for file upload confirmation about GDPR policy
 * @param open if its true it opens the modal and if its  false it closes it
 * @param setOpen is a function that handles the opening and closing the modal
 * @param handleSubmit handles the submition of the file
 */
export default function GdprModal({
    open,
    setOpen,
    handleSubmit,
}: GdprModalProps) {
    const [collapseGdprText, setCollapseGdprText] = useState<boolean>(false);
    const classes = useGdprModalStyles();
    const handleReadMore = () => {
        setCollapseGdprText(prev => !prev);
    }

    return (<div>
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalContainerStyle}>
                <Typography className={classes.leftCorner}>GDPR</Typography>
                <Typography className={classes.titleStyle}>
                    GDPR privacy notice
                </Typography>
                <Collapse in={collapseGdprText} collapsedSize={20} timeout={{enter: 400, exit: 200}}>
                    <Box className={classes.gdprTextContainer}>
                        {/* <Typography style={{ marginTop: 2 }} align="justify" className={collapseGdprText ? '' : classes.gdprTextStyle}>
                            Before you upload any data to out portal, we want to inform you
                            about how we will be handling your personal data in accordance with
                            the General Data Protection Regulation (GDPR).<br /><br />

                            When you upload data to our portal, we will collect and process
                            your personal data, uncluding but not limited to your name, email
                            address, and any other information that you choose to providers us.
                            We will use this data for othe sole purpose of providing you with
                            the services that you have requested and to ensure the proper
                            functioning of the portal. We will not share your personal data
                            with any third parties without your explicit consent, except
                            where we are required by law to do so.
                        </Typography> */}
                        <Typography style={{ marginTop: 2 }} align="justify" className={collapseGdprText ? '' : classes.gdprTextStyle}>
                            I acknowledge the terms of the <b><a href='https://amdaris.com/privacy-policy/'>Privacy Notice set out on the Amdaris website</a></b>, as may be
                            updated from time to time. I also <br /> accept   that Amdaris accepts no responsibility for the data <br />
                            being uploaded to the portal.
                        </Typography>
                    </Box>
                </Collapse>
                <Button onClick={handleReadMore} style={{ color: '#5780CA' }}>{collapseGdprText ? 'Read Less' : 'Read More'}</Button>
                <Box className={classes.buttonsContainer}>
                    <Button onClick={() => {
                        setCollapseGdprText(false)
                        setOpen(false)
                    }}
                        variant='contained'
                        color='secondary'
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                    >
                        AGREE & UPLOAD
                    </Button>
                </Box>
            </Box>
        </Modal>
    </div>
    );
}