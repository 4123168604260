import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { User } from "config/types";
import { getCurrentUser } from "services/currentUserService";

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (_, {rejectWithValue}) => {
        try{
            const request = await getCurrentUser()
            return request
        }
        catch{
            return rejectWithValue('Something happens')
        }
    }
)

interface IInitialState{
    user: User | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: IInitialState = {
    user: null,
    status: 'idle',
    error: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {  
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUser.pending, (state: any, action: PayloadAction<any>) => {
                state.status = 'loading'
            })
            .addCase(fetchUser.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.user = action.payload
                state.status = 'succeeded'
            })
            .addCase(fetchUser.rejected, (state: any, action: PayloadAction<any>) => {
                state.status = 'error'
                state.error = action.payload
            })
    }
})

export default userSlice.reducer