import React, { FC, SetStateAction, Dispatch } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import useStyles from "./styles";
import { RequestTeam } from 'config/types';

interface WarningDialogProps {
    open: boolean;
    setOpenWarning: Dispatch<SetStateAction<boolean>>;
    handleConfirm?: () => void;
    contentText: string;
    onDelete?: (request: RequestTeam) => void;
    request?: RequestTeam;
}

const WarningDialog: FC<WarningDialogProps> = ({
    open,
    setOpenWarning,
    handleConfirm,
    contentText,
    onDelete,
    request
}) => {

    const classes = useStyles();

    const handleCancel = () => {
        setOpenWarning(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography className={classes.dialogTitle}>Warning</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className={classes.dialogContent}>
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleCancel} className={classes.dialogCancel} color="primary">
                    Cancel
                </Button>
                {request ? (
                    <Button onClick={() => onDelete?.(request)} className={classes.dialogSend} color="primary" autoFocus>
                        Confirm
                    </Button>
                    ) 
                    :
                    (<Button onClick={handleConfirm} className={classes.dialogSend} color="primary" autoFocus>
                        Confirm
                    </Button>)}
            </DialogActions>
        </Dialog>
    )
}

export default WarningDialog;
