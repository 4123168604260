// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    engagement: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
    },

    container: {
      paddingBottom: theme.spacing(7.5),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },

    teamSectionMainBlock: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    sideBarMain: {
      boxShadow: "none",
      border: "1px solid #ededed",
      width: "calc( 20% - 24px)",
      borderRadius: "6px",
      overflow: "auto",
    },

    sideBarListItem: {
      padding: "0px 10px 0px 10px",
      cursor: "pointer",
    },

    sideBarListItemOffset: {
      marginTop: "20px",
    },

    sideBarListItemContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      padding: "12px",
    },

    listItemActive: {
      backgroundColor: "#FAFAFA",
      "& $listItemIcon": {
        opacity: "0.1",
      },
    },

    listItemIcon: {},

    listItemChangeIcon: {
      paddingLeft: "5px",
      "&:hover": {
        opacity: "50%",
      },
    },

    typographyText: {
      letterSpacing: "0.1px",
      fontStyle: "normal",
      lineHeight: "19px",
      fontFamily: "Roboto",
      fontSize: "16px",
      color: "#050505",
      fontWeight: 500,
    },

    typographyTextOffset: {
      paddingLeft: "8px",
    },

    rightSide: {
      display: "flex",
    },

    avatarRoot: {
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
      marginRight: theme.spacing(1.7),
    },

    avatarList: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: "820px",
      overflowY: "auto",
      boxSizing: "border-box",
      gap: theme.spacing(2),
      padding: theme.spacing(1),
    },

    avatarBackgroundDefault: {
      background: "#e0e0e0",
    },

    avatarMainBackgroundColor: {
      background: "#003366",
      color: theme.palette.common.white,
    },

    divider: {
      alignItems: "center",
      marginBottom: theme.spacing(3),
      //padding: "24px !important",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },

    filterBtn: {
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
      backgroundColor: "#FAFAFA",
    },

    filterBtnVisibility: {
      visibility: "hidden",
    },

    error: {
      color: theme.palette.error.main,
    },

    mainBody: {
      flexDirection: "column",
    },

    mainBodyRow: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        "& > .MuiBox-root": {
          flexBasis: "50%",
          maxWidth: "100%",
        },
      },
    },

    overflow: {
      overflow: "hidden",
      "-ms-text-overflow": "ellipsis",
      textOverflow: "ellipsis",
    },

    gutters: {
      padding: theme.spacing(1, 0, 0, 0),
    },

    teamSection: {
      padding: theme.spacing(3),
      borderRadius: "6px",
      minHeight: theme.spacing(70.75),
      boxShadow: "none",
      border: "1px solid #ededed",
      //display: "flex",
      //flexDirection: "column",
      "& .MuiGrid-root > .MuiGrid-item": {
        //padding: theme.spacing(0, 3.75, 3.75, 3.75),
      },
      width: "calc(80% - 0px)",
    },

    root: {
      width: "auto",
    },

    listItemText: {
      fontSize: "14px",
    },

    tabs: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },

    teamFeedbackChip: {
      backgroundColor: "#FF0070",
    },
  }),
);

export default useStyles;
