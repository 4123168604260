// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

export const useNavBarStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    whiteSpace: "nowrap",
    maxHeight: "70px",
  },

  "@global": {
    ".MuiAppBar-positionFixed": {
      position: "sticky !important",
    },
  },

  navBar: {
    backgroundColor: "#FFFFFF",
    padding: "21px 24px",
    position: "relative",
    justifyContent: "end",
  },

  navBarLogo: {
    width: theme.spacing(12.5),
  },

  navBarList: {
    display: "flex",
  },

  clientPortalItem: {
    width: theme.spacing(12.5),
    marginLeft: theme.spacing(2.5),
  },

  divider: {
    width: 1,
    backgroundColor: theme.palette.common.white,
  },

  linkhover: {
    textDecoration: "none",
    color: theme.palette.common.white,
    "&:hover": {
      color: "#c5c5c5",
    },
  },

  rightButtonArea: {
    height: "29px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
  },

  buttonAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "22px",
    "& .MuiButton-root": {
      minWidth: "initial",
      padding: "initial",
    },
  },

  userNameTypography: {
    color: "#333333",
    marginRight: "40px",
  },

  logoTypography: {
    fontFamily: "Barlow Condensed, sans-serif",
    fontSize: "30px",
    color: "#050505",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },

  themeIcon: {
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    marginRight: theme.spacing(2.5),
  },

  burgerIcon: {
    position: "absolute",
    left: 0,
    color: "#333333",
  },

  drawerPapper: {
    top: "auto",
    width: "100%",
    background: theme.palette.background.default,
    "& .MuiPaper-root": {
      borderRadius: 14,
    },
  },
  notification: {
    "& .MuiBadge-badge": {
      right: "-8%",
      top: "27%",
      height: "11px",
      minWidth: "11px",
      borderRadius: "6px",
    },
  },
}));
