import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Tab, Tabs } from "@material-ui/core";
import FeedbackTable from "./FeedbackTable/FeedbackTable";
import TeamFeedbackTable from "./FeedbackTable/TeamFeedbackTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsContainer: {
      overflow: "hidden",
      marginTop: theme.spacing(2),
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "0",
        width: "100%",
        height: "1.52px",
        backgroundColor: "rgb(204,204,204)",
      },
      /*
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "0",
        left: "-24px",
        width: "24px",
        height: "1.52px",
        backgroundColor: "rgb(204,204,204)",
      },
      */
    },
    root: {
      display: "inline-block",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    },
    tabs: {
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderTop: "2px solid rgb(204,204,204)",
      borderRight: "2px solid rgb(204,204,204)",
      borderLeft: "2px solid rgb(204,204,204)",
      "& .MuiTabs-indicator": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiTab-root": {
        backgroundColor: theme.palette.primary.main,
        textTransform: "none",
      },
      "& .MuiTab-textColorInherit": {
        opacity: 1,
        color: "#FFFFFF",
      },
      "& .MuiTab-root.Mui-selected": {
        backgroundColor: "#FFFFFF",
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        color: "#000000",
      },
    },
    tab: {
      borderRight: "2px solid rgb(204,204,204)",
    },
  }),
);

export default function TabsComponent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={classes.tabsContainer}>
        <Box className={classes.root}>
          <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab className={classes.tab} label="Team feedback" {...a11yProps(0)} />
            <Tab label="Individual feedback" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <TeamFeedbackTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FeedbackTable />
      </TabPanel>
    </>
  );
}
