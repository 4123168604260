// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      marginTop: theme.spacing(7.5),
      marginBottom: theme.spacing(10),
    },
  })
);

export default useStyles;
