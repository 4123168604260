import { jsPDF } from "jspdf";
import { fontSize } from "./utils/configurations/font-constants";
import { elementPosition } from "./utils/configurations/element-position";
import { imageDimension } from "./utils/configurations/image-dimension";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import amdarisHeader from "../../assets/images/survey-summary-pdf/amdaris-header.png";
import globeImg from "../../assets/images/survey-summary-pdf/globe.png";
import mailImg from "../../assets/images/survey-summary-pdf/mail.png";
import phoneImg from "../../assets/images/survey-summary-pdf/phone.png";

export default function generateAmdarisMeta(doc: jsPDF, pageNumber: number) {
  // Amdaris image on header
  doc.addImage(
    amdarisHeader,
    "png",
    elementPosition.amdarisHeader.getX(),
    elementPosition.amdarisHeader.getY(),
    imageDimension.logoImage.getWidth(),
    imageDimension.logoImage.getHeight()
  );

  // Globe image on footer
  doc.addImage(
    globeImg,
    "png",
    elementPosition.globe.getX(),
    elementPosition.globe.getY(),
    imageDimension.footerGlobe.getWidth(),
    imageDimension.footerGlobe.getHeight()
  );

  // Site static text
  doc.setTextColor("black");
  doc.setFontSize(fontSize.footerText);
  doc.text(
    surveyPdfContent.amdarisSite,
    elementPosition.site.getX(),
    elementPosition.site.getY()
  );

  // Mail image on footer
  doc.addImage(
    mailImg,
    "png",
    elementPosition.mail.getX(),
    elementPosition.mail.getY(),
    imageDimension.footerMail.getWidth(),
    imageDimension.footerMail.getHeight()
  );

  // Email static text
  doc.setFontSize(fontSize.footerText);
  doc.text(
    surveyPdfContent.askEmail,
    elementPosition.email.getX(),
    elementPosition.email.getY()
  );

  // Phone image on footer
  doc.addImage(
    phoneImg,
    "png",
    elementPosition.phone.getX(),
    elementPosition.phone.getY(),
    imageDimension.footerPhone.getWidth(),
    imageDimension.footerPhone.getHeight()
  );

  // Phone static text
  doc.setFontSize(fontSize.footerText);
  doc.text(
    surveyPdfContent.amdarisPhone,
    elementPosition.phoneNumber.getX(),
    elementPosition.phoneNumber.getY()
  );

  // Address static text
  doc.setFontSize(fontSize.footerAddress);
  doc.text(
    surveyPdfContent.footerAddress,
    elementPosition.footerAddress.getX(),
    elementPosition.footerAddress.getY()
  );

  // Add page number
  doc.setFontSize(fontSize.pageNumber);
  doc.text(
    pageNumber.toString(),
    elementPosition.pageNumber.getX(),
    elementPosition.pageNumber.getY()
  );
}
