// external lib dependencies
import { useCallback } from "react";

// local dependencies
import useBarPercent from "./useBarPercent";

const useSortByPercentage = () => {
  const { getTimePassedPercent } = useBarPercent();

  const sortByPercentage = useCallback((array: Array<any>): any[] => {
    let sortedList: any[] = [];

    sortedList = [...array].sort(
      (a: any, b: any) =>
        getTimePassedPercent(new Date(b.start_date), new Date(b.end_date)) -
        getTimePassedPercent(new Date(a.start_date), new Date(a.end_date)),
    );

    return sortedList;
  }, []);

  return sortByPercentage;
};
export default useSortByPercentage;
