import { Avatar, Box, Grid, IconButton, Typography } from "@material-ui/core";
import { FILES_BASE_URL } from "config/constants";
import { getEmployeesNameInitials } from "helpers/getEmployeesNameInitials";
import ListAltIcon from '@material-ui/icons/ListAlt';
import useStyles from "./styles";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { useEffect, useState } from "react";
import TagPopper from "pages/Team/components/HeaderTeam/components/HeaderMemberCard/TagPopper";
import { Engagement } from "config/types";

interface GridItemProps {
    subHeaderText: any;
    icon: any;
    value: any;
    actionAtIcon?: (arg1: any) => void;
}

const GridItem = (props: GridItemProps) => {
    const classes = useStyles();
    return (
        <Grid item>
            <Typography variant="subtitle2" component="span">{props.subHeaderText}</Typography>
            <Box className={classes.profileGroupFields}>
                {props.actionAtIcon ? (
                    <IconButton onClick={props.actionAtIcon} className={classes.profileIconButton}>
                        {props.icon}
                    </IconButton>
                ) : props.icon}
                <Typography variant="subtitle1" component="span">{props.value}</Typography>
            </Box>
        </Grid>
    )
}

interface IPortfolioDirectorProps{
    role: string,
    photo_id: string | null,
    engagement: Engagement,
    tags: any[],
    setEngagement: () => void,
}

const PortfolioDirectorProfileCard = (props: IPortfolioDirectorProps) =>{
    
    const classes = useStyles()

    const [directorTags, setDirectorTags] = useState([])
    const [anchorTagMenu, setAnchorTagMenu] = useState<null | Element>(null)
    const [selectedItem, setSelectedItem] = useState<any>({
        employee_name: "",
        tags: [],
        member_role: "",
        type: "",
      });

    const handleOpenTagPopper = (e: React.MouseEvent) => {
        setAnchorTagMenu(e.currentTarget)
        setSelectedItem({ 
            employee_name: props.engagement.portfolio_director_name,
            tags: props.engagement.header_tags.portfolio_director,
            member_role: "Portfolio Director",
            type: "portfolio_director",
        })
    }
    
    const handleCloseTagPopper = () => {
        setAnchorTagMenu(null)
        setSelectedItem({ employee_name: "", tags: [], member_role: "", type: "" });
    };

    useEffect(()=>{
        if(props.engagement)
        {
            setDirectorTags(props.engagement.header_tags.portfolio_director.map((t: { name: any; }) => t.name))
        }
    },[props.engagement])

    return(
        <Box className={classes.memberProfile}>
            <Box className={classes.memberAvatar}>
                <Avatar
                    src={props?.photo_id ?
                        `${FILES_BASE_URL}/images/${props?.photo_id}/thumbnail/blob` : ""}
                    classes={{
                        root: classes.avatarRoot,
                        colorDefault: props.engagement?.portfolio_director_name?
                            classes.avatarMainBackgroundColor : classes.avatarBackgroundDefault,
                    }}
                >
                    {getEmployeesNameInitials(props.engagement?.portfolio_director_name)}
                </Avatar>
            </Box>
            <Box className={classes.memberDetails}>
                <Box className={classes.profileNameField}>
                    <Typography variant="h5" component="span">{props.engagement.portfolio_director_name}</Typography>
                </Box>
                <Box className={classes.memberDetailsContent}>
                    <Box className={classes.memberDetailsColumn}>
                        <Grid container direction="column" spacing={2}>
                            <GridItem
                                subHeaderText={"Role"}
                                value={props?.role}
                                icon={<AccountBoxIcon/>}
                            />
                            <GridItem
                                subHeaderText={"Project"}
                                value={props.engagement?.title}
                                icon={<ListAltIcon/>}
                            />
                           
                        </Grid>
                    </Box>
                    <Box className={classes.memberDetailsColumn}>

                        <Grid container direction="column" spacing={2}>
                            <GridItem
                                subHeaderText={"Email"}
                                value={props.engagement?.portfolio_director_email}
                                icon={<MailOutlineIcon/>}
                            />
                            
                            <GridItem
                                subHeaderText={"Tags"}
                                value={directorTags.join(", ")}
                                icon={<LocalOfferIcon/>}
                                actionAtIcon={handleOpenTagPopper}
                            />
                           
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {anchorTagMenu && (
                <TagPopper
                    item={selectedItem}
                    tags={props?.tags}
                    anchorRef={anchorTagMenu}
                    openTagMenu={Boolean(anchorTagMenu)}
                    engagement={props.engagement}
                    setEngagement={props.setEngagement} 
                    handleCloseTagPopper={handleCloseTagPopper}
                />
            )}
        </Box>
    )
}

export default PortfolioDirectorProfileCard