// external lib dependencies
import clsx from "clsx";
import { Paper, List, ListItem, Typography, IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import useStyles from "./styles";


const AuditsTableSkeleton = () => {
  const classes = useStyles();
  const { isSM } = useScreenSize();

  return isSM ? (
    <Paper elevation={0}>
      <List>
        {[...Array(4)].map((_, index) => (
          <ListItem key={index} className={clsx(classes.listHeader, classes.paddTopBottom)}>
            <div className={classes.listHeaderRow}>
              <Typography variant="body1">
                <Skeleton width={100} />
              </Typography>
              <div className={classes.headerCell}>
                <Typography variant="body1">
                  <Skeleton />
                </Typography>
                <IconButton>
                  <Skeleton variant="circle" />
                </IconButton>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </Paper>
  ) : (
    <Paper elevation={0} className={classes.chartContainer}>
      <Skeleton variant="rect" width="100%" height="100%" data-testid="AuditsTableSkeleton" />
    </Paper>
  );
};

export default AuditsTableSkeleton;
