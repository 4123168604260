//local dependencies
import { getClientInfoAboutCurrentUser, invokeLogout } from "./clientDataService";


interface Auth {
  user: any | null;
  isAuthenticated: boolean;
  authenticate: (_?: (_: any | null) => void) => void;
  logout: (_?: (_: any | null) => void) => void;
  forceLogout: () => void;
  addLogoutHandler: (_: () => void) => void;
  removeLogoutHandler: (_: () => void) => void;
  logoutHandlers: (() => void)[];
}

export const auth: Auth = {
  user: null,

  get isAuthenticated() {
    return this.user != null;
  },

  authenticate(cb?: (user: any) => void) {
    getClientInfoAboutCurrentUser()
      .then((res) => { this.user = res })
      .catch(() => { this.user = null })
      .finally(() => { if (cb) cb(this.user) });
  },

  logout(cb?: (user: any) => void) {
    invokeLogout()
      .then(r => {
        const oauth = localStorage.getItem('oauth');
        if (Boolean(oauth) && r) {
          window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${r}`
        }
      }).catch(() => null)
      .finally(() => {
        this.user = null;
        localStorage.removeItem('oauth');

        if (cb) cb(null);

        this.logoutHandlers.forEach((handler: () => void) => handler());
      });
  },

  forceLogout() {
    this.user = null;
    this.logoutHandlers.forEach((handler: () => void) => handler());
  },

  addLogoutHandler(handler: () => void) {
    this.logoutHandlers.push(handler);
  },

  removeLogoutHandler(handler: () => void) {
    this.logoutHandlers = this.logoutHandlers.filter((h) => h !== handler);
  },

  logoutHandlers: [],
};
