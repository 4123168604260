// external lib dependencies
import { useRef, useState, useEffect } from "react";
import { Chip, Typography, MenuItem, TextField, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// absolute path dependencies
import Button from "components/Button";

// local dependencies
import useStyles from "./styles";
import { categories } from "pages/FilesSharing/categories";
import PasswordSetDialog from "./PasswordSetDialog";
import { useAppSelector } from "store/hook";
import GdprModal from "pages/FilesSharing/components/GdprModal";

interface DropZoneProps {
  isLoading?: boolean;
  onSubmitFiles?: (files: File[], category: string, password: string, expireOn: number | null) => void;
  onSelectFile?: (files: File | null, name: string | undefined) => void;
  variant?: string;
  message?: string;
  name?: string;
  accept?: string;
  uploadErrorMessage?: string;
  uploadSuccessMessage?: string;
}

const DropzoneFiles = ({
  isLoading = false,
  onSubmitFiles = () => {},
  onSelectFile = () => {},
  variant = "regular",
  message = "Drag & Drop files here or click to select file(s)",
  uploadSuccessMessage = "",
  name,
  accept,
}: DropZoneProps) => {
  const classes = useStyles();
  const { user } = useAppSelector((state) => state.user);

  const containerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [gdprModalState, setGdprModalState] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [dropDepth, setDropDepth] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedExpiration, setSelectedExpiration] = useState<number | null>(6);
  const [selectFieldError, setSelectFieldError] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [passwordEnabled, setPasswordEnabled] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const allowedExtensions = [
    ".dot",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".csv",
    ".pdf",
    ".jfif",
    ".jpeg",
    ".pjpeg",
    ".pjp",
    ".jpg",
    ".png",
  ];
  const history = useHistory();

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [history.length]);

  useEffect(() => {
    setSuccessMessage(uploadSuccessMessage);
  }, [uploadSuccessMessage]);

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const handleSubmitPassword = (password: string) => {
    setPassword(password);
    setOpenAlertDialog(false);
  };

  const handleCompanySensitiveDataClicked = () => {
    setOpenAlertDialog(true);
  };

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.style.border = dropDepth > 0 ? "4px dashed #76FF03" : "4px dashed #3F51B5";
    }
  }, [dropDepth]);

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    setDropDepth((prev) => prev + 1);
  };

  const dragLeave = (e: any) => {
    setDropDepth((prev) => prev - 1);
  };

  const checkFile = (file: File) => {
    const fileExtension: string = "." + file.name.split(".").pop()?.toLocaleLowerCase();
    const isCorrectExtension: boolean = allowedExtensions.includes(fileExtension);
    if (!isCorrectExtension) {
      setErrorStatus(true);
      return;
    }

    const fileSize: number = file.size / (1024 * 1024);
    if (fileSize > 10) {
      setErrorMessage("File too large. Maximum size: 10 MB");
      return;
    }

    if (fileSize === 0) {
      setErrorMessage("Wrong file size");
      return;
    }

    setErrorStatus(false);
    setErrorMessage("");
  };

  const handleFiles = (files: FileList) => {
    setSelectedFiles([files[0]]);
    onSelectFile(files[0], name);
    setPasswordEnabled(true);
    setSuccessMessage("");

    checkFile(files[0]);
  };

  const removeFile = () => {
    setSelectedFiles([]);
    onSelectFile(null, name);
    setPasswordEnabled(false);

    setErrorStatus(false);
    setErrorMessage("");
  };

  const fileDrop = (e: any) => {
    e.preventDefault();
    setDropDepth(0);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const fileSize = (size: number) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  };

  const filesSelected = () => {
    if (fileInputRef?.current && fileInputRef.current.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef && fileInputRef.current && fileInputRef.current.click();
  };

  const openGdprModal = () => {
    if (selectedCategory === "") {
      setSelectFieldError(true);
      return;
    }

    if (selectedFiles.length === 0) {
      setErrorStatus(true);
      return;
    }

    setGdprModalState((prev) => !prev);
  };

  const handleSubmit = () => {
    onSubmitFiles(selectedFiles, selectedCategory, password, selectedExpiration);
    setSelectedFiles([]);
    setSelectedCategory("");
    setPassword("");
    setGdprModalState((prev) => !prev);
    if(fileInputRef?.current && fileInputRef.current.files?.length){
      fileInputRef.current.value = ''
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectFieldError(false);
    setSelectedCategory(event.target.value);
  };

  const handleExpirationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedExpiration(Number(event.target.value));
  };

  if (variant === "small") {
    return (
      <div
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        ref={containerRef}
        data-testid="DZContainer"
      >
        <div className={classes.dropMessage}>
          <Typography variant="subtitle2" data-testid="DZMessage">
            {selectedFiles.length === 0 && message}{" "}
          </Typography>
          {selectedFiles.length !== 0 &&
            selectedFiles.map((data: File, index: any) => (
              <Chip
                color="primary"
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                key={index}
                label={`${data.name} (${fileSize(data.size)})`}
                onDelete={removeFile}
              />
            ))}
        </div>
        <input
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          accept={accept}
          onChange={filesSelected}
          data-testid="DZFileInput"
        />
      </div>
    );
  }

 

  return (
    <div className={classes.dropMainContainer}>
      <PasswordSetDialog
        open={openAlertDialog}
        handleClose={handleCloseAlertDialog}
        handleSubmitPassword={handleSubmitPassword}
      />
      <div
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        ref={containerRef}
        data-testid="DZContainer"
        style={{ border: errorStatus ? "4px dashed red" : "4px dashed #3F51B5" }}
      >
        <Box>
          <Typography className={classes.dropMessage} data-testid="DZMessage">
            {"Drag & Drop files here or click to select file(s)"}
          </Typography>
          <Typography style={{ marginTop: "10px", marginBottom: "10px" }} className={classes.dropRestrictions}>
            {"Maximum upload file size: 10 MB"}
          </Typography>
          <Typography style={{ fontWeight: "bold" }} className={classes.dropRestrictions}>
            {"Supported file extensions:"}
          </Typography>
          <Typography className={classes.dropRestrictions}>
            {"dot, doc, docx, xls, xlsx, csv, pdf, jfif, pjpeg, jpeg, pjp, jpg, png"}
          </Typography>
        </Box>
        <input
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          accept="
            .dot,
            .doc,
            .docx,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            text/csv,
            application/pdf,
            image/jfif,
            image/jpeg,
            image/pjpeg,
            image/pjp,
            image/jpg,
            image/png
          "
          onChange={filesSelected}
          data-testid="DZFileInput"
        />
      </div>
      <div className={classes.dropFilesContainer} data-testid="DZFilesContainer">
        {selectedFiles &&
          selectedFiles.map((data: File, index: any) => (
            <Chip
              color={errorStatus ? "secondary" : "primary"}
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              key={index}
              label={errorStatus ? `Wrong file format` : `${data.name} (${fileSize(data.size)})`}
              onDelete={removeFile}
            />
          ))}
        {selectedFiles.length === 0 && (
          <span style={{ color: errorStatus ? "red" : "" }}>No files selected for upload!</span>
        )}
        <br />
        <br />
        {errorMessage && <div className={classes.alertError}>{errorMessage}</div>}
        {successMessage && <div className={classes.alertSuccess}>{successMessage}</div>}
      </div>

      <TextField
        required
        label="Category"
        select
        className={classes.inputSelectItem}
        variant="outlined"
        value={selectedCategory}
        onChange={handleCategoryChange}
        error={selectFieldError}
        helperText={selectFieldError ? "Please select a category!" : ""}
      >
        {categories.map((category, i) => (
          <MenuItem key={i} value={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>
      <span style={{ margin: "20px" }}>
        Select expiration date (default is 6 months from now). Files will be archived after the expiration date
      </span>
      <TextField
        required
        select
        className={classes.inputSelectItem}
        variant="outlined"
        value={selectedExpiration}
        onChange={handleExpirationChange}
        error={selectFieldError}
      >
        <MenuItem key={6} value={6}>
          6 months
        </MenuItem>
        <MenuItem key={3} value={3}>
          3 months
        </MenuItem>

        {user && !user.is_stakeholder ? (
          <MenuItem key={0} value={0}>
            No expiration date
          </MenuItem>
        ) : null}
      </TextField>

      <Button
        colorVariant="black"
        className={classes.sensitiveDataButton}
        onClick={handleCompanySensitiveDataClicked}
        disabled={!passwordEnabled}
      >
        Company sensitive data*
      </Button>
      <span className={classes.smallTextSpan}>*Click to protect your document with a password.</span>
      <Button
        // disabled={errorStatus || Boolean(errorMessage)}
        colorVariant="black"
        className={classes.dropButton}
        // onClick={handleSubmit}
        onClick={openGdprModal}
        loading={isLoading}
        data-testid="DZSubmitButton"
      >
        Submit
      </Button>
      <GdprModal open={gdprModalState} setOpen={setGdprModalState} handleSubmit={handleSubmit} />
    </div>
  );
};

export default DropzoneFiles;
