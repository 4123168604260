// external lib dependencies
import { FC, MouseEvent, useState } from "react";
import { Avatar, Box, Button, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as TagIcon } from "../../../../assets/icons/memberCardIcons/tag.svg";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory, useParams } from "react-router-dom";

// absolute path dependencies
import { FILES_BASE_URL } from "config/constants";

// local dependencies
import useStyles from "./styles";
import ProvideFeedbackDialog from "../../../MemberProfile/components/ProvidedFeeback/ProvideFeedbackDialog";
import SelectFeedbackOptionDialog from "../../../MemberProfile/components/ProvidedFeeback/SelectFeedbackOptions";
import { getEmployeesNameInitials } from "helpers/getEmployeesNameInitials";
import { MainMenu } from "./components/MainMenu/MainMenu";
import { ManageTags } from "components/ManageTags/ManageTags";
import { useAppSelector } from "store/hook";
import { canEditTeamsAndTags } from "helpers/canEditTeamsAndTags";

interface Props {
  item: any;
  group?: object;
  ungroupedMember?: boolean;
  groupedMember?: boolean;
  handleOpenDeleteMemberPopup?: any;
  handleOpenAddMemberPopup?: any;
  canEdit: boolean;
}

interface Params {
  id: string;
}

const MemberCard: FC<Props> = ({
  item,
  group,
  ungroupedMember,
  groupedMember,
  handleOpenDeleteMemberPopup,
  handleOpenAddMemberPopup,
  canEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [openFeedbackDialog, setOpenFeedbackDialog] = useState<boolean>(false);

  const [anchorSelectFeedbackOption, setAnchorSelectFeedbackOption] = useState<null | Element>(null);
  const [anchorMainMenu, setAnchorMainMenu] = useState<null | Element>(null);
  const [anchorTagMenu, setAnchorTagMenu] = useState<null | Element>(null);

  const { id } = useParams<Params>();
  const { employee_id } = item;
  const { user } = useAppSelector((state) => state.user);

  const handleOpenMenu = (e: MouseEvent) => {
    setAnchorMainMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMainMenu(null);
    setAnchorSelectFeedbackOption(null);
  };

  const handleOpenTagMenu = (el: null | Element = null) => {
    setAnchorTagMenu(el);
  };

  const handleCloseTagMenu = () => {
    setAnchorTagMenu(null);
  };

  const handleAddFeedback = (e: MouseEvent) => {
    setAnchorSelectFeedbackOption(e.currentTarget);
  };

  const addMemberPopup = () => {
    handleOpenAddMemberPopup(item);
    handleCloseMenu();
  };

  const handleViewProfile = () => {
    handleCloseMenu();
    history.push(`/engagements/${id}/employees/${employee_id}`);
  };

  const deleteMemberPopup = () => {
    handleOpenDeleteMemberPopup(item, group);
    handleCloseMenu();
  };

  const openMemberProfile = () => {
    history.push(`/engagements/${id}/employees/${employee_id}`);
  };

  return (
    <Box className={classes.memberCard}>
      <Box className={classes.meberCardWrap}>
        <Avatar
          src={item.employee_photo_id ? `${FILES_BASE_URL}/images/${item.employee_photo_id}/thumbnail/blob` : ""}
          classes={{
            root: classes.avatarRoot,
            colorDefault: item.employee_name ? classes.avatarMainBackgroundColor : classes.avatarBackgroundDefault,
          }}
          onClick={openMemberProfile}
        >
          {getEmployeesNameInitials(item.employee_name)}
        </Avatar>
        <Box className={classes.memberDetails}>
          <Box className={classes.memberInfoContent}>
            <Typography variant="body2">{item.employee_name}</Typography>
            <Typography variant="body2">{item.member_role}</Typography>
          </Box>
        </Box>
        <IconButton className={classes.moreIcon} onClick={handleOpenMenu}>
          <MoreHorizIcon />
        </IconButton>
      </Box>
      <Box className={classes.tags}>
        <Button onClick={(e: MouseEvent) => handleOpenTagMenu(e.currentTarget)} className={classes.iconButton}>
          <TagIcon />
        </Button>
        <Typography variant="body2">{item.tags && item.tags.map((i: any) => i.name).join(", ")}</Typography>
      </Box>

      {anchorMainMenu && (
        <MainMenu
          openMoreMenu={Boolean(anchorMainMenu)}
          anchorEl={anchorMainMenu}
          handleCloseMenu={handleCloseMenu}
          handleViewProfile={handleViewProfile}
          handleOpenTagMenu={handleOpenTagMenu}
          setOpenFeedbackDialog={setOpenFeedbackDialog}
          ungroupedMember={ungroupedMember}
          addMemberPopup={addMemberPopup}
          groupedMember={groupedMember}
          deleteMemberPopup={deleteMemberPopup}
          canEdit={canEdit}
        />
      )}

      {anchorTagMenu && (
        <ManageTags
          openTagMenu={Boolean(anchorTagMenu) && canEditTeamsAndTags(user, parseInt(id))}
          anchorRef={anchorTagMenu}
          handleCloseTagMenu={handleCloseTagMenu}
          item={item}
        />
      )}
      {anchorSelectFeedbackOption && (
        <SelectFeedbackOptionDialog
          open={Boolean(anchorSelectFeedbackOption)}
          anchorEl={anchorSelectFeedbackOption}
          setOpenFeedbackDialog={setOpenFeedbackDialog}
        />
      )}
      <ProvideFeedbackDialog
        open={openFeedbackDialog}
        setOpen={setOpenFeedbackDialog}
        projectId={id}
        employeeId={employee_id}
      />
    </Box>
  );
};

export default MemberCard;
