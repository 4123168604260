// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainBody: {
      flexDirection: "column",
    },
    mainBodyRow: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        "& > .MuiBox-root": {
          flexBasis: "100%",
        },
      },
    },

    listItemGutters: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    listItemRoot: {
      width: "auto",
    },

    avatarRoot: {
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
      marginRight: theme.spacing(1.7),
    },

    avatarBackgroundDefault: {
      background: "#e0e0e0",
    },

    avatarMainBackgroundColor: {
      background: "#003366",
      color: theme.palette.common.white,
    },

    overflow: {
      overflow: "hidden",
      "-ms-text-overflow": "ellipsis",
      textOverflow: "ellipsis",
    },

    listItemText: {
      fontSize: "14px",
    },

    avatarList: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: "820px",
      boxSizing: "border-box",
      gap: theme.spacing(3),
      width: "100%",
      alignItems: "flex-start",
      paddingRight: theme.spacing(0.75),
      paddingLeft: theme.spacing(0.75),
    },

    menuIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

export default useStyles;
