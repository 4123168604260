// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
      "& .MuiCardHeader-title":{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#333333",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "21px",
        letterSpacing: "0.15px",
      },
      "& .MuiCardHeader-subheader":{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#333333",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.1px",
      }
    },
    dialogContent: {
      overflowX: "hidden",
    },
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
      minWidth: "100%",
    },
    groupList: {
      width: "46%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
      paddingTop: "15px",
    },
    checkbox: {
      "&.Mui-checked": {
        color: "#333333",
      },
    },
    editListItem: {
      padding: "0px",
      "& .MuiListItemIcon-root":{
        minWidth: "0px",
      }
    },
    listItemText: {
      "& .MuiTypography-body2":{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#333333",
        fontSize: "16px",
        fontWeight: 400, 
        lineHeight: "24px",
      }
    },
  })
);
