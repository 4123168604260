// external lib dependencies
import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import useStyles from "../styles";

// absolute path dependencies
//import Button from "components/Button";

// local dependencies
import useSubmitHandler from "./useSubmitHandler";
import { GroupTeams } from "config/types";
import { TeamViews } from "pages/Team/types";

interface AddGroupPopupInterface {
  open: boolean;
  handleClose: any;
  groupTeams: Array<GroupTeams>;
  setGroupTeams: any;
}

const AddGroupPopup = (props: AddGroupPopupInterface) => {
  const generalClasses = useStyles();
  const { open, handleClose, groupTeams, setGroupTeams } = props;
  const [nameInput, setNameInput] = useState<string>("");
  const { callback, loading, error, isSuccess, objectId } = useSubmitHandler();
  const [isErrorTextField, setIsErrorTextField] = useState<boolean>(false);
  const [errorTextField, setErrorTextField] = useState<string>("");
  const { id: projectId } = useParams<{ id?: string | undefined }>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isErrorTextField) return;

    const data = {
      name: nameInput.trim(),
    };

    callback(data, projectId);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setGroupTeams([
        {
          id: objectId,
          name: nameInput,
          project_id: projectId,
          member_assignments: [],
        },
        ...groupTeams,
      ]);
    }
  }, [objectId]);

  const isWhiteSpace = (input: string): boolean => {
    if (input.trim().length === 0) {
      setIsErrorTextField(true);
      setErrorTextField("Team name cannot be an empty string");
      return true;
    }
    return false;
  };

  const isTeamNameExists = (input: string): boolean =>{
    const lowerCaseInput = input.toLocaleLowerCase();
    const isTeamExists = groupTeams.some(group => group.name.toLocaleLowerCase() === lowerCaseInput);
    const isDefaultTeam = [TeamViews.Implementation, TeamViews.Ungrouped, TeamViews.OversightAndEscalation].map(team => team.toLocaleLowerCase()).includes(lowerCaseInput);
    return isTeamExists || isDefaultTeam;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.value;
    const forbiddenCharacters = /[<>?\-*\/\\&^%$#]/i;
    const hasForbidden = forbiddenCharacters.test(name);
    const whiteSpaceInput = isWhiteSpace(name);

    if(whiteSpaceInput) return

    if(isTeamNameExists(name)){
      setIsErrorTextField(true);
      setErrorTextField("This team name already exists");
      return
    }

    if(name.length > 20){
      setIsErrorTextField(true);
      setErrorTextField("The team name must be less than 20 characters");
      return
    }

    if(hasForbidden){
      setIsErrorTextField(true);
      setErrorTextField("The team name has forbidden characters");
      return
    }

    setIsErrorTextField(false);
    setErrorTextField("");
    setNameInput(name);
  };

  const onCloseDialog = () =>{
    setIsErrorTextField(false);
    setErrorTextField("");
    handleClose();
  }

  return (
    <Dialog open={open} onClose={onCloseDialog} className={generalClasses.dialogModal }>
      <Box sx={{width:"480px"}}>
        <DialogTitle className={generalClasses.dialogTitle} id="form-dialog-title">Create New Team</DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <DialogContent className={generalClasses.dialogContent}>
            <DialogContentText className={generalClasses.dialogContentText}>To create a new team, please enter a team name.</DialogContentText>
            <TextField
              required
              variant="outlined"
              autoFocus={true}
              margin="dense"
              label="Team Name"
              fullWidth
              onChange={handleChange}
              error={isErrorTextField}
              helperText={errorTextField}
            />
          </DialogContent>
          <DialogActions className={generalClasses.dialogActionBlock}>
            <Button variant="contained" color="secondary" onClick={onCloseDialog}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>Create</Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default AddGroupPopup;
