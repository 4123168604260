import {
  Grid,
  Paper,
  ListItem,
  List,
  Button,
  Avatar,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import SearchField from "./SearchField";
import { Stakeholder, UserAccessLevels, mapUserAccessLevels } from "config/types";
import InfoToolTip from "./InfoToolTip";
import { ReactComponent as Globe } from "../../../assets/icons/commonIcons/globe.svg";
import { ReactComponent as Email } from "../../../assets/icons/commonIcons/email.svg";
import { ReactComponent as Project } from "../../../assets/icons/commonIcons/project.svg";
import { ReactComponent as Phone } from "../../../assets/icons/commonIcons/phone.svg";
import { ReactComponent as Role } from "../../../assets/icons/commonIcons/role.svg";
import { ReactComponent as Invited } from "../../../assets/icons/commonIcons/invited.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/commonIcons/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/commonIcons/edit.svg";
import EditStakeholderModal from "./EditStakeholderModal";
import DeleteStakeholderModal from "./DeleteStakeholderModal";
import { checkUserAccess } from "helpers/userHelpers/userHelpers";
import { getEmployeesNameInitials } from "helpers/getEmployeesNameInitials";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useActions, useAppSelector } from "store/hook";
import useStyles from "../styles";
import SnackbarMessage from "components/SnackbarMessage/SnackbarMessage";

const InfoChip = withStyles({
  root: {
    backgroundColor: "#E5F3F9",
    borderRadius: "6px",
    padding: "3px 6px",
    height: "auto",
    alignItems: "self-end",
    marginBottom: "10px",
  },
  label: {
    paddingLeft: "4px",
    paddingRight: "initial",
  },
  icon: {
    marginLeft: "initial",
    marginRight: "initial",
  },
})(Chip);

const InvitedStatusChip = withStyles({
  root: {
    borderRadius: "14px",
    color: "#FFFFFF",
    height: "auto",
    padding: "4px 10px",
  },
})(Chip);

const AccessChip = withStyles((theme) => ({
  root: {
    marginRight: "12px",
    background: "#384A71",
    borderRadius: "4px",
    padding: "7px 35px",
    color: "#ffffff",
    [theme.breakpoints.down("xs")]: {
      padding: "7px 15px",
    },
  },
}))(Chip);

const RegularTypography = withStyles({
  root: {
    fontWeight: 400,
  },
})(Typography);

const SemiBoldTypography = withStyles({
  root: {
    fontWeight: 600,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0.25px",
  },
})(Typography);

const AdaptiveGridSpacing = withStyles((theme) => ({
  "spacing-xs-5": {
    "& .MuiGrid-item": {
      [theme.breakpoints.down("xs")]: {
        padding: "12px",
      },
    },
  },
}))(Grid);

const StakeholdersPageContent = () => {
  const classes = useStyles();

  const { user } = useAppSelector((state) => state.user);
  const [stakeholderId, setStakeholderId] = useState<number>();
  const [inputValue, setInputValue] = useState<string>("");

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const { engagement, loading, error } = useAppSelector((state) => state.engagement);
  const { setDefaultError } = useActions();

  const filteredStakeholders = useMemo(() => {
    return engagement?.stakeholders
      ?.filter((v: Stakeholder) => v.full_name?.toLowerCase().includes(inputValue.toLowerCase()))
      .sort((a: Stakeholder, b: Stakeholder) => a.full_name!.toLowerCase().localeCompare(b.full_name!.toLowerCase()));
  }, [engagement?.stakeholders, inputValue]);

  let selectedStakeholder =
    filteredStakeholders?.find((item) => item.id === stakeholderId) ||
    (filteredStakeholders && filteredStakeholders[0]);

  const accessStatus = checkUserAccess(
    user,
    Boolean(engagement?.user_has_extra_access),
    UserAccessLevels.AccessLevel_1,
  );

  const selectStakeholder = (id: number) => {
    setStakeholderId(id);
  };

  const abortSubmit = (controllerRef: AbortController | null) => {
    if (controllerRef) {
      controllerRef.abort();
    }
  };

  const handleAddModal = (controllerRef: AbortController | null = null) => {
    abortSubmit(controllerRef);
    setOpenAddModal((prev) => !prev);
  };

  const handleEditModal = (controllerRef: AbortController | null = null) => {
    abortSubmit(controllerRef);
    setOpenEditModal((prev) => !prev);
  };

  const handleDeleteModal = (controllerRef: AbortController | null = null) => {
    abortSubmit(controllerRef);
    setOpenDeleteModal((prev) => !prev);
  };

  const handleClose = () => {
    setDefaultError();
  };

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" className={classes.circularProgressContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Paper elevation={0} className={classes.scrollAutoPaper}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <SearchField inputValue={inputValue} setInputValue={setInputValue} />
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {filteredStakeholders?.map((stakeholder: Stakeholder) => (
                      <ListItem
                        button
                        key={stakeholder.id}
                        onClick={() => selectStakeholder(stakeholder.id)}
                        className={
                          selectedStakeholder?.id === stakeholder.id ? classes.listItemActive : classes.listItem
                        }
                      >
                        <Avatar className={classes.avatar}>{getEmployeesNameInitials(stakeholder.full_name)}</Avatar>

                        <Typography noWrap={true}>{stakeholder.full_name}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Paper elevation={0} className={classes.minHeightPaper}>
              <Typography variant="h6" className={classes.stakeholderDetails}>
                Stakeholder Details
              </Typography>
              {selectedStakeholder && (
                <Grid container spacing={5} className={classes.stakeholderDetailsContent}>
                  <Grid item container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                      <Avatar className={classes.stakeholderAvatar}>
                        {getEmployeesNameInitials(selectedStakeholder.full_name)}
                      </Avatar>
                    </Grid>
                    <Grid item sm={12} container className={classes.stakeholderAction}>
                      <Grid item>
                        <RegularTypography align="center" noWrap={true} variant="h5">
                          {selectedStakeholder.full_name}
                        </RegularTypography>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} container className={classes.stakeholderAccess}>
                      <Grid item>
                        <AccessChip
                          label={
                            <SemiBoldTypography variant="body2">{`${mapUserAccessLevels[selectedStakeholder.access_level]
                              }`}</SemiBoldTypography>
                          }
                        />
                      </Grid>
                      <Grid item>
                        <InfoToolTip />
                      </Grid>
                    </Grid>
                  </Grid>

                  <AdaptiveGridSpacing item container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Globe />} label={<Typography variant="subtitle1">Company:</Typography>} />
                      <Typography noWrap={true} variant="body1">
                        {selectedStakeholder.company_name ? selectedStakeholder.company_name : "..."}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Role />} label={<Typography variant="subtitle1">Role:</Typography>} />
                      <Typography noWrap={true} variant="body1">
                        {selectedStakeholder.role ? selectedStakeholder.role : "..."}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Email />} label={<Typography variant="subtitle1">Email:</Typography>} />
                      <Typography noWrap={true} variant="body1">
                        {selectedStakeholder.email ? selectedStakeholder.email : "..."}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Project />} label={<Typography variant="subtitle1">Project:</Typography>} />
                      <Typography noWrap={true} variant="body1">
                        {engagement?.title}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Phone />} label={<Typography variant="subtitle1">Phone:</Typography>} />
                      <Typography noWrap={true} variant="body1">
                        {selectedStakeholder.phone ? selectedStakeholder.phone : "..."}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <InfoChip icon={<Invited />} label={<Typography variant="subtitle1">Invited by:</Typography>} />
                      <div>
                        <InvitedStatusChip
                          classes={{
                            root:
                              selectedStakeholder.invited_by === "Advance"
                                ? classes.advanceLabel
                                : classes.invitedLabel,
                          }}
                          label={
                            <SemiBoldTypography noWrap={true} variant="body2">
                              {selectedStakeholder.invited_by}
                            </SemiBoldTypography>
                          }
                        />
                      </div>
                    </Grid>
                  </AdaptiveGridSpacing>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}

      <SnackbarMessage
        open={Boolean(error)}
        autoHideDuration={4000}
        offset={-0.3}
        handleClose={handleClose}
        severity="error"
        variant="filled"
        position={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <span>
          Sorry, something went wrong. For more information, please contact Amdaris IT team at{" "}
          <Link style={{ color: "black" }} to="mailto:client@amdaris.com">
            <Typography> client@amdaris.com</Typography>
          </Link>
        </span>
      </SnackbarMessage>
    </>
  );
};

export default StakeholdersPageContent;
