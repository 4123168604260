import {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import Button from "components/Button";
import useStyles from "./styles";
import {MemberWorkOrder} from "../../types";

interface Props {
  data: any[] | null;
}

const WorkOrder = ({data}: Props) => {
  const classes = useStyles();

  const [hiddenPast, setHiddenPast] = useState<boolean>(true);

  return (
    <Grid container direction="row">
      <Grid item xs={11}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="span">Work Orders</Typography>
          <Button size="medium" className={classes.button} onClick={() => setHiddenPast(!hiddenPast)}>
            {hiddenPast ? "Show past work orders" : "Hide past work orders"}
          </Button>
        </Box>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell variant="head" width={100}>Reference No</TableCell>
                <TableCell width={100}>Project Name</TableCell>
                <TableCell width={100}>Start Date</TableCell>
                <TableCell width={100}>End Date</TableCell>
                <TableCell width={100}>Allocation</TableCell>
                <TableCell width={100}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item: MemberWorkOrder, idx: number) => {
                if (hiddenPast && item.status === 'Past') {
                  return
                }

                return (
                  <TableRow key={idx}>
                    <TableCell>{item.work_order_name}</TableCell>
                    <TableCell>{item.project_name}</TableCell>
                    <TableCell>{item.start_date}</TableCell>
                    <TableCell>{item.end_date}</TableCell>
                    <TableCell>{item.allocation}%</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default WorkOrder;
