import * as StakeholderActionCreators from '../reducers/engagement/engagementSlice'
import * as UserActionCreators from '../reducers/user/userSlice'
import * as MembersActionCreators from '../reducers/engagementMembers/engagementMembersSlice'
import * as TagsActionCreators from '../reducers/tag/tagSlice'
import * as EngagementsListActionCreators from '../reducers/engagementList/engagementListSlice'

export default {
    ...StakeholderActionCreators,
    ...UserActionCreators,
    ...MembersActionCreators,
    ...TagsActionCreators,
    ...EngagementsListActionCreators,
}