import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";

import { Container, Typography, Box, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import SectionTitleBar from "components/SectionTitleBar";
import ProfileCardSkeleton from "./components/ProfileCard/ProfileCardSkeleton";
import useStyles from "./styles";
import Button from "../../components/Button";
import ProvidedFeedbackSkeleton from "./components/ProvidedFeeback/ProvidedFeedbackSkeleton";
import { Params } from "./types";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchTags } from "store/reducers/tag/tagSlice";
import { useEngagementData } from 'providers/EngagementDataContext';
import PortfolioDirectorProfileCard from './components/ProfileCard/PortfolioDirectorProfileCard';
import { FeedbackData } from 'config/types';
import ProvidedFeedback from './components/ProvidedFeeback/ProvidedFeedback';

const PortfolioDirector = () => {
    const classes = useStyles();

    const { loadingEngagement, engagementLoadingError, initEngagement, engagement, setEngagement } = useEngagementData();
    const tags = useAppSelector(state => state.tags.tags)
    const [feedbackList, setFeedbackList] = useState<FeedbackData[]>([]);
    const dispatch = useAppDispatch();
    const { id } = useParams<Params>();

    useEffect(() => {
        if (!id) return;

        if (!engagement || engagement.id !== Number(id)) {
            initEngagement(Number(id));
        }
        dispatch(fetchTags(id))
    }, [id]);

    const handleFeedbackSubmit = (data: FeedbackData) => {
        setFeedbackList((prev: FeedbackData[]) => [data, ...prev]);
    }

    return (
        <div className={classes.engagement}>
            <SectionTitleBar
                title={loadingEngagement ? <Skeleton width="30%" /> : engagement.title ?? ''}
                upSmBoxShadow={true}
            />
            <Container className={classes.container} maxWidth="xl">
                <Box className={classes.buttonGoBack}>
                    <Link to={"/engagements/" + id + "/team"}>
                        <Button colorVariant="black">Go back</Button>
                    </Link>
                </Box>
                <Paper elevation={0} className={classes.profileSection}>
                    <Box className={classes.profileSectionTitle}>
                        <Typography variant="h5" component="span">Portfolio Director profile</Typography>
                    </Box>
                    <Box className={classes.profileSectionBox}>
                        {loadingEngagement ?
                            (<ProfileCardSkeleton />) :
                            engagementLoadingError ? engagementLoadingError
                                : (<PortfolioDirectorProfileCard
                                    role={'Portfolio Director'}
                                    photo_id={null}
                                    engagement={engagement}
                                    setEngagement={setEngagement}
                                    tags={tags}
                                />)
                        }
                    </Box>
                    <Box className={classes.feedbackSectionBox}>
                        {loadingEngagement ?
                            (<ProvidedFeedbackSkeleton />) :
                            engagementLoadingError ?
                                <Typography variant='h4'>{engagementLoadingError}</Typography>
                                : (<ProvidedFeedback />)
                        }
                    </Box>

                </Paper>
            </Container>
        </div>
    );
}

export default PortfolioDirector