// external lib dependencies
import { Fragment, useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

// local dependencies
import useStyles from "./styles";


interface CountdownTimerProps {
  countdownSeconds: number;
  onCountdownExpiration: () => void;
}

const CountdownTimer = ({ countdownSeconds, onCountdownExpiration }: CountdownTimerProps) => {
  const classes = useStyles();
  const [countdown, setCountdown] = useState(countdownSeconds);

  useEffect(() => {
    setCountdown(countdownSeconds);
  }, [countdownSeconds]);

  useEffect(() => {
    if (countdown < 1) {
      onCountdownExpiration();
      return;
    }

    // Normally it's required to set a new state based on a previous one using a function as an argument
    // to the setter, but being inside of useEffect it's fine to set the state directly using a value.
    // The logic of useEffect() says that it will be executed after 'countdown' changes, this way we have the
    // guarantee that countdown is a new value and it's not using a stale state.
    setTimeout(() => setCountdown(countdown - 1), 1000);
  }, [countdown, onCountdownExpiration]);

  return (
    <Fragment>
      <Typography variant="body2" data-testid="CountdownTimer" className={classes.timerMsg}>
        You can resend another access link in {countdown} {countdown > 1 ? "seconds" : "second"}
      </Typography>
    </Fragment>
  );
};

export default CountdownTimer;
