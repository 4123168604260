// external lib dependencies
import { useState, useCallback } from "react";

// absolute path dependencies
import { sendCustomerSurveyData } from "services/customerSurveyService";


const useSubmitHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);

  const postData = useCallback((data: any) => {
    setLoading(true);
    sendCustomerSurveyData(data)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch(() => {
        setLoading(false);
        setError("Well this is embarrassing... We couldn't send your data. Can you please try again later ?");
      });
  }, []);

  return { postData, loading, sent, error };
};

export default useSubmitHandler;
