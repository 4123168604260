import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "./styles";
import Button from "components/Button";

const ProvidedFeedbackSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="span">
            Provided Feedback
          </Typography>
          <Button>Provide Feedback</Button>
        </Box>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Feedback from</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width={50} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ProvidedFeedbackSkeleton;
