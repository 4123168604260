// external lib dependencies
import { useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {Provider} from 'react-redux'

// absolute path dependencies
import { getSavedTheme } from "themes";
import { EngagementDataProvider } from "providers/EngagementDataContext";
import { FilesProvider } from "providers/FilesContext";
import { AuthProvider } from "providers/AuthContext";
import RouteList from "components/RouteList";
import Login from "pages/Login";

import {store} from "./store";

const App = () => {
  const [theme, setTheme] = useState(getSavedTheme());

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <FilesProvider>
          <EngagementDataProvider>
            <>
              <CssBaseline />
              <BrowserRouter>
                <AuthProvider>
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route exact path="/" component={Login} />
                    <RouteList setTheme={setTheme} />
                  </Switch>
                </AuthProvider>
              </BrowserRouter>
            </>
          </EngagementDataProvider>
        </FilesProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
