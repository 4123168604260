import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import { MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles";

interface Props {
  openMoreMenu: boolean;
  anchorEl: null | Element;
  handleCloseMenu: () => void;
  handleViewProfile: any;
  handleOpenTagMenu: any;
  ungroupedMember: any;
  addMemberPopup: any;
  groupedMember: any;
  deleteMemberPopup: any;
  canEdit: boolean;
  setOpenFeedbackDialog: any;
}

export const MainMenu = (props: Props) => {
  const classes = useStyles();

  return (
    <Popper
      open={props.openMoreMenu}
      anchorEl={props.anchorEl}
      transition
      placement="right"
      style={{ zIndex: 2 }}
      disablePortal
      modifiers={{
        preventOverflow: {
          escapeWithReference: true,
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={props.handleCloseMenu}>
              <MenuList>
                <MenuItem onClick={props.handleViewProfile}>
                  <AccountCircleIcon fontSize="small" className={classes.menuIcon} />
                  View profile
                </MenuItem>

                {props.canEdit && (
                  <MenuItem onClick={() => props.handleOpenTagMenu(props.anchorEl)}>
                    <LocalOfferOutlinedIcon fontSize="small" className={classes.menuIcon} />
                    Manage tags
                  </MenuItem>
                )}

                <MenuItem onClick={() => props.setOpenFeedbackDialog(true)}>
                  <EditOutlinedIcon fontSize="small" className={classes.menuIcon} />
                  Provide feedback
                </MenuItem>

                {props.ungroupedMember && props.canEdit && (
                  <MenuItem onClick={props.addMemberPopup}>
                    <PeopleOutlineIcon fontSize="small" className={classes.menuIcon} />
                    Add to a team
                  </MenuItem>
                )}

                {props.groupedMember && props.canEdit && (
                  <MenuItem onClick={props.deleteMemberPopup}>
                    <DeleteIcon fontSize="small" className={classes.menuIcon} />
                    Remove from team
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
