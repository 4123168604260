// external lib dependencies
import { Box, ListItem, Typography, Container, Popover } from "@material-ui/core";
import { useState } from "react";
// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import useBarPercent from "../hooks/useBarPercent";
import useTitleTrim from "../hooks/useTitleTrim";
import { useEngagementStyles, useMemberStyles } from "./styles";
import { checkUserAccessLevel } from "helpers/userHelpers/userHelpers";
import { UserAccessLevels } from "config/types";
import { useAppSelector } from "store/hook";

interface MemberProps {
  memberName: string;
  startDate: string;
  endDate: string;
  role: string | null;
  photoId: number | null;
  selectedYear: number;
  pricePerDay?: number;
}

const Member = ({ memberName, startDate, endDate, role, pricePerDay }: MemberProps) => {
  const { trimSuspension } = useTitleTrim();
  const { getTimePassedPercent } = useBarPercent();
  const classes = useMemberStyles();
  const classesEng = useEngagementStyles();
  const { isSM, isXL } = useScreenSize();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user } = useAppSelector(state => state.user);
  /*
  const barPercents = useMemo(() => {
    return getBarPercents(startDate, endDate, selectedYear);
  }, [startDate, endDate, selectedYear, getBarPercents]);
  */
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPersonAssigned = Boolean(memberName)
  const open = Boolean(anchorEl) && !isPersonAssigned;

  return (
    <ListItem disableGutters className={classes.member} data-testid="memberListItem">
      <Container className={classes.sideBoxContainer}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box className={classes.leftSideBox}>
          <Box className={classes.memberInfoBox}>
            <Box className={classes.title}>
              <Typography variant="subtitle2" className={classes.memberName} data-testid="memberNameTrimmed">
                {trimSuspension(memberName, 16)}
              </Typography>
              <Typography variant="subtitle1" data-testid="memberRoleTrimmed">
                {trimSuspension(role, 17)}
              </Typography>
            </Box>

          </Box>
          <Box className={classes.costsBox}>
            {
              checkUserAccessLevel(user, UserAccessLevels.AccessLevel_1) || !user?.is_stakeholder ?
              <Typography variant="subtitle2">
                Daily Rate: {isPersonAssigned ? `£${pricePerDay}` : ""}
              </Typography>
              : 
              null
            }
            {/* Cost burndown should be inserted here */}
          </Box>
          <span className="last-child-span-divider"></span>
        </Box>
        {isXL && !isSM && (
          <Box className={classes.rightSideBox}>
            <Box
              className={classes.memBar}
              style={{ width: "75%" }}
              data-testid="memberBar"
            >
              <Box
                className={classesEng.engBarBlured}
                style={{
                  width: `${getTimePassedPercent(new Date(startDate), new Date(endDate))}%`,
                  backgroundColor: "#4A6094"
                }}
              >
              </Box>
              <Typography variant="subtitle1">
                {getTimePassedPercent(new Date(startDate), new Date(endDate))}%
              </Typography>
              {/* <span className={classes.startLine} data-testid="startLine" />
              <span className={classes.endLine} data-testid="endLine" /> */}
            </Box>
          </Box>

        )}
      </Container>
      {!isPersonAssigned &&
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <Typography>The person will be assigned soon</Typography>
        </Popover>
      }
    </ListItem>
  );
};

export default Member;
