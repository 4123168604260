// external lib dependencies
import { useState, useCallback } from "react";

// absolute path dependencies
import { deleteGroupTeam } from "services/groupTeam";

const useDeleteHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const callback = useCallback(async (projectId: any, groupId: any) => {
    setLoading(true);
    setIsSuccess(false);
    setError("");
    const errorMessage = "Error!";
    try {
      const fetch = await deleteGroupTeam(projectId, groupId);
      setIsSuccess(fetch.ok);

      if (!fetch.ok) {
        const isJson = fetch.headers.get("content-type")?.includes("application/json");
        const response = isJson ? await fetch.json() : await fetch.text();
        setError(response.detail ? response.detail : response ?? errorMessage);
      }
    } catch (err) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { callback, loading, error, isSuccess };
};

export default useDeleteHandler;
