// external lib dependencies
import { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

// absolute path dependencies
import { changeFileCategory } from "services";
import { useFilesContext } from "providers/FilesContext";

// local dependencies
import { categories } from "../categories";
import { ScrollbarContext } from "components/RouteList";

export interface Props {
  open: boolean;
  onClose: (value: string, fileID: string) => void;
  categoryName: string;
  fileID: string;
}

const ChangeCategoryDialog: FC<Props> = ({ open, onClose, categoryName, fileID }) => {
  const { id } = useParams<{ id?: string }>();
  const { fetchFiles } = useFilesContext();

  const {scrollbar} = useContext(ScrollbarContext)
  
  const handleListItemClick = (value: string) => {
    changeFileCategory(fileID, value).then(() => {
      fetchFiles(Number(id))
      if (scrollbar.current) {
        scrollbar.current.scrollTop = 0;
        scrollbar.current.scrollLeft = 0;
      }
    });
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Change File Category</DialogTitle>
      <List>
        {categories
          .filter((category) => category !== categoryName)
          .map((category) => (
            <ListItem button onClick={() => handleListItemClick(category)} key={category}>
              <ListItemText primary={category} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
};

export default ChangeCategoryDialog;
