// external lib dependencies
import { useState, useCallback, FC } from "react";
import { Box, Typography, Modal, Button } from "@material-ui/core";

// absolute path dependencies
import { UrlParams, UserAccessLevels, VoidFunc } from "config/types";

// local dependencies
import MessageFormFields from "./MessageFormFields";
import useStyles from "./styles";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "store/hook";
import Launch from "@material-ui/icons/Launch";
import { Skeleton } from "@material-ui/lab";
import { checkUserAccessLevel } from "helpers/userHelpers/userHelpers";

interface Prop {
  engagementName: string;
  loadingEngagement: boolean;
}

const TopHeader: FC<Prop> = ({ engagementName, loadingEngagement }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const params = useParams<UrlParams>();
  const { user } = useAppSelector((state) => state.user);

  const setDrawerOpenHandler: VoidFunc<any> = useCallback(() => {
    setDrawerOpen((prev: boolean) => !prev);
  }, []);

  const handleTeamRequest = useCallback(() => {
    const { id } = params;
    history.push(`/engagements/${id}/request-team`);
  }, [history, params]);

  return (
    <Box className={classes.headerContainer}>
      {loadingEngagement ? (
        <Skeleton width="200px" height="40px" />
      ) : (
        <Typography variant="h5" className={classes.engagementName}>
          {engagementName}
        </Typography>
      )}

      {user?.is_stakeholder && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.messageButton}
            onClick={() => setDrawerOpen(true)}
          >
            <Typography variant="button">Message</Typography>
            <Launch htmlColor="white"></Launch>
          </Button>

          {checkUserAccessLevel(user, UserAccessLevels.AccessLevel_1) && (
            <Button variant="contained" color="secondary" className={classes.requestButton} onClick={handleTeamRequest}>
              <Typography variant="button">Request A Team</Typography>
            </Button>
          )}
        </Box>
      )}
      <Modal onClose={setDrawerOpenHandler} open={drawerOpen} data-testid="contactForm">
        <MessageFormFields onCloseModal={setDrawerOpenHandler} />
      </Modal>
    </Box>
  );
};

export default TopHeader;
