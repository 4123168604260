// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => createStyles({

    dropMainContainer: {
      display: "flex",
      flexDirection: "column",
    },

    dropFilesContainer: {
      margin: theme.spacing(2),
      textAlign: "center",
    },

    dropButton: {
      margin: theme.spacing(2),
      alignItems: "center"
    },

    dropContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 20,
        // border: "4px dashed #3f51b5",
        padding: theme.spacing(1),
        transition: "border 0.3s",
        cursor: "pointer",

        "&:hover": {
          border: "4px dashed #003366 !important",
        },
    },
    
    dropMessage: {
      color: "#4aa1f3",
      textAlign: "center",
      fontFamily: "Arial",
      fontSize: "18px",
      whiteSpace:'normal',
      width:'100%',
      height:'100%'
    },

    dropRestrictions: {
      color: "#4aa1f3",
      fontSize: "12px",
      textAlign: "center",
    },
    
    fileInput: {
        display: "none",
    },

    chipRoot: { 
      display: 'flex', 
    }, 

    chipLabel: { 
      overflowWrap: 'break-word', 
      whiteSpace: 'nowrap', 
      textOverflow: 'ellipsis' ,
      fontSize:'10px',
      [theme.breakpoints.down("md")]: {
        fontSize:'15px',

      },
    },

    eyeIcon: {
      marginLeft: "5px"
    },

    passwordCheckContainer: {
      display: "flex",
    },

    passwordCheckText: {
      lineHeight: "24px"
    },

    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between"
    },

    inputSelectItem: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },

    smallTextSpan: {
      fontSize: "10px",
      marginLeft: "1rem",
      marginRight: "1rem",
      marginBottom: "1rem"
    },

    mediumTextSpan: {
      fontSize: "15px",
      marginBottom: "1rem"
    },

    passwordFormLabels: {
      fontSize: "17px"
    },

    spanErrorMessage: {
      color: "red",
      fontSize: "14px"
    },

    spanSuccessMessage: {
      color: "green",
      fontSize: "14px"
    },

    alertSuccess: {
      width: "100%",
      padding: "12px 16px",
      borderRadius: "4px",
      borderStyle: "solid",
      borderWidth: "1px",
      marginBottom: "12px",
      fontSize: "16px",
      backgroundColor: "rgba(227, 253, 235, 1)",
      borderColor: "rgba(38, 179, 3, 1)",
      color: "rgba(60, 118, 61, 1)"
    },
    
    alertError: {
      width: "100%",
      padding: "12px 16px",
      borderRadius: "4px",
      borderStyle: "solid",
      borderWidth: "1px",
      marginBottom: "12px",
      fontSize: "12px",
      backgroundColor: "rgba(248, 215, 218, 1)",
      borderColor: "rgba(220, 53, 69, 1)",
      color: "rgba(114, 28, 36,1)"
    },

    sensitiveDataButton: {
      margin: theme.spacing(2),
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#D9D9D9",
      color: "black",
      "&:hover": {
        backgroundColor: "#363636",
        color: "white"
      },
    },

    sensitiveDataButtonSubmit: {
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#D9D9D9",
      color: "black",
      "&:hover": {
        backgroundColor: "#363636",
        color: "white"
      },
    },

    sensitiveDataButtonClose: {
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#363636",
      color: "white",
      "&:hover": {
        backgroundColor: "#D9D9D9",
        color: "black"
      },
    },

    //Password field styles
    inputLabelFont: {
      color: theme.palette.type === "dark" ? "#FFF" : "black",
      fontSize: "13px",
    },

    field: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },

    fieldContainer: {
      width: theme.spacing(60),
      padding: "16px 32px",
      overflow: "hidden",
    },

    fieldContainerXS: {
      padding: "16px 32px",
      width: "75vw",
      overflow: "hidden",
    },

    // InputField styles
    fieldInput: {
      width: "100%",
    },

    // MessageForm styles
    sectionContainer: {
      paddingTop: "16px",
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      borderTop: "1px solid #e0e0e0",
    },

  })
);

export default useStyles;
