import { Member, WorkOrder } from "config/types";
import { useMemo } from "react";

export function useFilterByCurrentYear(array: Member[], selectedYear: number): Member[];
export function useFilterByCurrentYear(array: WorkOrder[], selectedYear: number): WorkOrder[];
export function useFilterByCurrentYear(array: (Member | WorkOrder)[], selectedYear: number): (Member | WorkOrder)[] {
  const filteredArrayByCurrentYear = useMemo(() => {
    return array.filter(({ start_date, end_date }) => {
      const startYear = new Date(start_date).getFullYear();
      const endYear = new Date(end_date).getFullYear();
      return startYear <= selectedYear && endYear >= selectedYear;
    });
  }, [array, selectedYear]);

  return filteredArrayByCurrentYear;
}
