import { Engagement } from "config/types";
import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import { getEngagementsPaginated } from "services";
import { getEngagementStatusByWorkOrders } from "helpers/engagementStatus";

interface IFetchEngagements {
  project_sate: string;
  my_engagements_only: boolean;
  page_number_trigger: number;
  setData: (arg0: (prev: Engagement[]) => Engagement[]) => void;
  setMore: (arg0: boolean) => void;
  search_input?: string;
}

function fetchEngagements({
  project_sate,
  my_engagements_only,
  page_number_trigger,
  setData,
  setMore,
  search_input,
}: IFetchEngagements) {
  getEngagementsPaginated(project_sate, page_number_trigger, search_input, my_engagements_only)
    .then((engagementData: Engagement[]) => {
      let EngagementsWithStatus: Engagement[] = engagementData.map(
        (engagement) =>
          ({
            ...engagement,
            status: getEngagementStatusByWorkOrders(engagement.work_orders),
          } as Engagement),
      );

      setData((prev) => {
        return Array.from(new Map([...prev, ...EngagementsWithStatus].map((obj) => [obj.id, obj])).values());
      });
      setMore(!Boolean(engagementData.length < 5));
    })
    .catch((err) => {
      /*do something */
    });
}

export default function useInfiniteScroll(
  project_sate: string,
  my_engagements_only: boolean,
  page_number_trigger: number,
  search_input?: string,
) {
  const [engagementsData, setEngagementsData] = useState<Engagement[]>([]);
  const [hasMoreScroll, setHasMore] = useState<boolean>(true);

  const debouncedSearch = useDebounce((search_input?: string) => {
    const params: IFetchEngagements = {
      project_sate,
      my_engagements_only,
      page_number_trigger,
      setData: setEngagementsData,
      setMore: setHasMore,
      search_input,
    };

    fetchEngagements(params);
  }, 800);

  useEffect(() => {
    setEngagementsData([]);
    setHasMore(true);
  }, [search_input]);

  useEffect(() => {
    debouncedSearch(search_input);
  }, [search_input, page_number_trigger]);

  return { engagementsData, hasMoreScroll };
}
