import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) => ({
    sectionHeader: {
      paddingTop: "1rem",
      paddingBottom: "1rem"
    },

    sectionSubtitle: {
      fontSize: "19px",
      marginTop: "0.5rem"
    },

    bar: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 76,
      [theme.breakpoints.up("xs")]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "0 0 14px 14px",
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
      },
    },

    boxShadow: {
      boxShadow: theme.shadows[3],
    },

    upSmallBoxShadow: {
      [theme.breakpoints.up("sm")]: {
        boxShadow: theme.shadows[3],
      },
    },

    title: {
      [theme.breakpoints.down("xs")]: {
        "& .MuiTypography-root": {
          fontFamily: "Libre Franklin",
          fontSize: 20,
          fontWeight: 600,
        },
      },
    },

    container: {
      paddingTop: "25px",
      marginRight: "1rem",
    },
    
    dialogButton: {
        width: "300px",
    },

  })
)

export default useStyles;
