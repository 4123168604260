// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

/*
   "&:not(:first-child)": {
     paddingTop: theme.spacing(4),
   },
   "&:last-child": {
     paddingBottom: theme.spacing(4),
   },
   */
export const useEngagementStyles = makeStyles((theme: Theme) => ({
  engagement: {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: theme.spacing(11.5),
  },
  gridColumn: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    padding: 0,
    // "&:not(:last-child)": {
    //   borderRight: "1px solid #e0e0e0",
    // },
    backgroundColor: "#FFFFFF",
  },
  sideBoxContainer: {
    padding: 0,
    maxWidth: "initial",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftSideBox: {
    width: "25%",
    padding: theme.spacing(0, 2, 0, 1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconButton: {
    border: 0,
    padding: theme.spacing(1),
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    "& .MuiButton-label": {
      borderBottom: "2px solid #EDEDED",
      left: "8px",
      marginLeft: "8px",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      color: "#000",
    },
  },
  titleBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    zIndex: 350,
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    padding: theme.spacing(1.2, 1.2, 2.9, 0.95),
    marginBottom: theme.spacing(1.1),
  },
  engagementName: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21.09px",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  icon: {
    backgroundColor: "#003366",
    color: "#fff",
    height: "25px",
    width: "25px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    textAlign: "start",
    color: theme.palette.text.primary,
  },
  date: {
    lineHeight: 1,
  },
  rightSideBox: {
    width: "68%",
    borderBottom: "1px solid #EDEDED",
    paddingRight: theme.spacing(2),
    height: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    marginRight: "25px",
  },
  engBar: {
    width: "948px",
    height: "70%",
    borderRadius: "5px",
    position: "relative",
    zIndex: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  engBarBlured: {
    backgroundColor: "#071E4E",
    borderRadius: "5px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "white",
      fontWeight: "bold",
      padding: "0 5px",
      fontSize: "10px",
    },
  },
  engBarEndingSoon: {
    backgroundColor: "#710015",
    borderRadius: "5px",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& span": {
      color: "white",
      fontWeight: "bold",
      padding: "0 5px",
      fontSize: "10px",
    },
  },
  startLine: {
    zIndex: 350,
    height: "150%",
    left: 0,
    position: "absolute",
    borderLeft: "1px",
    borderLeftColor: theme.palette.text.primary,
    borderLeftStyle: "dashed",
  },
  endLine: {
    zIndex: 350,
    height: "150%",
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRight: "1px",
    borderRightColor: theme.palette.text.primary,
    borderRightStyle: "dashed",
  },
  startDate: {
    position: "absolute",
    left: "1em",
    bottom: "-20px",
    whiteSpace: "nowrap",
  },
  endDate: {
    position: "absolute",
    right: "1em",
    top: "-20px",
    whiteSpace: "nowrap",
  },
  collapse: {
    width: "100%",
  },
}));
export const useWOStyles = makeStyles((theme: Theme) => ({
  WOList: {
    padding: 0,
  },
  workOrder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: 0,
  },

  extensionRequestedText: {
    width: "56.6vw",
    height: "16px",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 11.24,
    textAlign: "right",
    letterSpacing: "1.5px",
    position: "absolute",
    bottom: "40px",
    flex: "none",
    order: 6,
    alignSelf: "stretch",
    flexGrow: 0,
    color: "#333333",
  },
  extensionRequestedTextLeftSide: {
    display: "flex",
    height: "16px",
    weight: "330px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 11.24,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    alignSelf: "stretch",
    flexGrow: 0,
    textTransform: "uppercase",
    color: "#333333",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px",
  },
  sideBoxContainer: {
    padding: 0,
    maxWidth: "initial",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftSideBox: {
    width: "25%",
    padding: theme.spacing(0, 2, 0, 1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  iconButton: {
    width: "100%",
    maxWidth: "100%",
    padding: theme.spacing(1),
    height: "100%",
    boxSizing: "border-box",
    border: "0",
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    "& .MuiButton-label": {
      paddingBottom: theme.spacing(1.1),
      marginLeft: "8px",
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      color: "#000",
    },
  },
  iconButtonUnfolded: {
    width: "100%",
    maxWidth: "100%",
    padding: theme.spacing(1),
    height: "100%",
    boxSizing: "border-box",
    border: "0",
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    //borderBottom: "2px solid grey"
    "& .MuiButton-label": {
      borderBottom: "2px solid #EDEDED",
      paddingBottom: theme.spacing(1.1),
      marginLeft: "8px",
    },
  },
  titleBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    zIndex: 350,
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    padding: theme.spacing(0, 0, 0.5, 1),
  },
  icon: {
    backgroundColor: "#0A65BB",
    color: "#fff",
    height: "25px",
    width: "25px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    //color: theme.palette.text.primary,
  },
  WOName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 500,
    textAlign: "start",
    fontSize: "16px",
    lineHeight: "18.75px",
  },
  date: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "start",
  },
  rightSideBox: {
    width: "70%",
    paddingRight: theme.spacing(2),
    minHeight: theme.spacing(5),
    display: "flex",
    gap: theme.spacing(1),
  },
  extendWO: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: "23.5%",
  },
  extendWOBtn: {
    "&.MuiButton-root": {
      color: "#fff",
      maxWidth: "170px",
      height: theme.spacing(3.5),
      textTransform: "none",
      justifyContent: "center",
      backgroundColor: "#071E4E",
      "&:hover": {
        backgroundColor: "#0A65BB",
      },
    },
  },
  extendWOBtnAfterRequest: {
    "&.MuiButton-root": {
      color: "#333333",
      maxWidth: "170px",
      height: theme.spacing(3.5),
      textTransform: "none",
      justifyContent: "center",
      backgroundColor: "#F5F6F8",
      "&:hover": {
        backgroundColor: "#F5F6F8",
      },
    },
  },
  woBar: {
    backgroundColor: "#F5F5F5",
    height: theme.spacing(3.5),
    borderRadius: "5px",
    position: "relative",
  },
  woBarEndingSoon: {
    backgroundColor: "#F5F5F5",
  },
  startLine: {
    zIndex: 350,
    height: "150%",
    left: 0,
    position: "absolute",
    borderLeft: "1px",
    borderLeftColor: theme.palette.text.primary,
    borderLeftStyle: "dashed",
  },
  endLine: {
    zIndex: 350,
    height: "150%",
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRight: "1px",
    borderRightColor: theme.palette.text.primary,
    borderRightStyle: "dashed",
  },
  startDate: {
    position: "absolute",
    left: "1em",
    bottom: "-20px",
    whiteSpace: "nowrap",
  },
  endDate: {
    position: "absolute",
    right: "1em",
    top: "-20px",
    whiteSpace: "nowrap",
  },
  endingSoon: {
    color: "#fff",
    paddingRight: "1rem",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    //gap: "10px",
  },
  extend: {
    "& svg": {
      width: "30px",
      height: "30px",
    },
    margin: 0,
    padding: 0,

    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  extendLarge: {
    "& svg": {
      width: "40px",
      height: "40px",
    },
    margin: 0,
    padding: 0,

    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  collapse: {
    width: "100%",
  },
}));

export const useMemberStyles = makeStyles((theme: Theme) => ({
  memberList: {
    padding: 0,
    "& li:last-child .last-child-span-divider": {
      borderBottom: "2px solid #EDEDED",
      width: "100%",
    },
  },
  member: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
  },
  sideBoxContainer: {
    padding: 0,
    margin: 0,
    maxWidth: "initial",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftSideBox: {
    width: "25%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  memberInfoBox: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2, 1, 0),
    width: "60%",
  },
  costsBox: {
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
    width: "40%",
    textAlign: "center",
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(2),
    backgroundColor: "#4DAFC2",
    color: "#fff",
    height: "25px",
    width: "25px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1.1, 1, 1),
  },
  memberName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18,75",
  },
  rightSideBox: {
    width: "70%",
    paddingRight: theme.spacing(2),
    height: theme.spacing(5),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  rightSideDivider: {
    paddingTop: theme.spacing(2.2),
    width: "80%",
  },
  memBar: {
    backgroundColor: "#F5F5F5",
    height: "70%",
    borderRadius: "5px",
    position: "relative",
    marginBottom: theme.spacing(1.4),
  },
  startLine: {
    zIndex: 350,
    height: "150%",
    left: 0,
    position: "absolute",
    borderLeft: "1px",
    borderLeftColor: theme.palette.text.primary,
    borderLeftStyle: "dashed",
  },
  endLine: {
    zIndex: 350,
    height: "150%",
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRight: "1px",
    borderRightColor: theme.palette.text.primary,
    borderRightStyle: "dashed",
  },
  startDate: {
    position: "absolute",
    left: "1em",
    bottom: "-20px",
    whiteSpace: "nowrap",
  },
  endDate: {
    position: "absolute",
    right: "1em",
    top: "-20px",
    whiteSpace: "nowrap",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    backgroundColor: "black",
    color: "white",
    padding: theme.spacing(1),
  },
}));
export const useGridItemListStyles = makeStyles((theme: Theme) => ({
  gridItemList: {
    overflow: "hidden",
    width: "100%",
    paddingBottom: theme.spacing(2.2),
    border: 0,
  },
}));

export const useWOPageNavStyles = makeStyles((theme: Theme) => ({
  WOPageNavbar: {
    width: "100%",
    maxWidth: "initial",
    marginTop: theme.spacing(3),
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  navOptionsBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    width: "25%",
    padding: theme.spacing(0, 1.7),
    "& > h5": {
      margin: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  yearSelectBox: {
    display: "flex",
    flexDirection: "row",
  },
  iconButtons: {
    padding: theme.spacing(0, 0.7),
  },
}));

export const useMonthGridStyles = makeStyles((theme: Theme) => ({
  monthGridContainter: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2, 2, 2, 0),
    height: "100%",
    maxHeight: "100%",
    width: "calc(70% + 11px)",
    maxWidth: "initial",
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
  gridColumn: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    padding: 0,
    // "&:not(:last-child)": {
    //   borderRight: "1px solid #e0e0e0",
    // },
    backgroundColor: "#FFFFFF",
  },
  headingText: {
    borderBottom: "1px solid #e0e0e0",
    width: "100%",
    textAlign: "center",
  },
}));

export const useMessagePopupStyles = makeStyles((theme: Theme) => ({
  messagePopupPaper: {
    marginTop: theme.spacing(2),
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    innerWidth: 0,
    innerHeight: 0,
    width: 480,
    height: 260,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "24",
    padding: 20,
  },
  messagePopup: {
    width: "100%",
    position: "relative",
    zIndex: 400,
    padding: theme.spacing(1),
  },
  messageHeading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  buttonsBox: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0, 1, 0),
  },
  sendButton: {
    color: "white",
    textTransform: "none",
    backgroundColor: "#071E4E",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#071E4E",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "8px",
    width: "82px",
    height: "36px",
    borderRadius: "4px",
    borderStyle: "none",
    flex: "none",
    order: 0,
    flexГrow: 0,
  },
  closeButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "8px",
    textTransform: "none",
    width: "82px",
    height: "36px",
    backgroundColor: "#F5F6F8",
    "&:hover": {
      //you want this to be the same as the backgroundColor above.
      backgroundColor: "#F5F6F8",
      color: "black",
    },
    borderRadius: "4px",
    borderStyle: "none",
    flex: "none",
    order: 0,
    flexГrow: 0,
  },
  messageLabel: {
    width: "150px",
    height: "24px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    color: "#333333",
    lineHeight: "24px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  inputField: {
    width: "100%",
    "& div": {
      padding: `${theme.spacing(0.5, 1)}!important`,
    },

    "& textarea": {
      fontSize: "13px",
    },
  },
  successMessage: {
    position: "relative",
    padding: theme.spacing(1),
    zIndex: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  erroMesage: {
    color: "#ff0033",
  },
}));

export const useConfirmationMessageModalStyles = makeStyles((theme: Theme) => ({
  buttonsBox: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0, 1, 0),
  },
  closeButton: {
    // marginTop: "16px",
    color: "white",
    textTransform: "none",
    backgroundColor: "#071E4E",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#071E4E",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "8px",
    width: "82px",
    height: "36px",
    borderRadius: "4px",
    borderStyle: "none",
    flex: "none",
    order: 0,
    flexГrow: 0,
  },
  messageLabel: {
    marginBottom: "16px",
    width: "150px",
    height: "24px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#333333",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  messageText: {
    width: "272px",
    height: "24px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 0,
  },
  boxStyle: {
    gap: 16,
    alignItems: "flex-start",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    height: 164,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #EDEDED",
    padding: "24px",
    boxShadow: "24",
  },
}));

export const useWOPageHeaderStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    width: "480px",
    marginRight: "7rem",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.common.white,
    border: "1px solid #EDEDED",
    padding: "27px",
    height: "80px",
    borderRadius: "6px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.04)",
  },
  checkBoxAndSearchContainer: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
}));

export const useLoadMoreButtonStyle = makeStyles((theme: Theme) => ({
  leftButtonStyle: {
    borderBottom: "1px solid black",
    transition: "padding 0.3s ease-in-out",
    // borderRadius: '0px',
    "&:hover": {
      paddingBottom: "10px",
      backgroundColor: "white",
    },
    paddingBottom: "0px",
    position: "absolute",
    left: "9%",
    bottom: "60%",
    "@media only screen and (min-width: 1536px)": {
      left: "10%",
    },
  },
  rightButtonStyle: {
    borderBottom: "1px solid black",
    transition: "padding 0.3s ease-in-out",
    // borderRadius: '0px',
    "&:hover": {
      paddingBottom: "10px",
      backgroundColor: "white",
    },
    paddingBottom: "0px",
    position: "absolute",
    left: "60%",
    bottom: "60%",
    "@media only screen and (min-width: 1600px)": {
      left: "61.3%",
    },
  },
  boxStyle: {
    position: "relative",
    height: "65px",
  },
}));

export const useStylesDarkerTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
