import { Box, Button, List, ListItem, Paper, Typography } from "@material-ui/core";
import useStyles from "pages/Team/styles";
import { useState } from "react";
import { ReactComponent as ChevronsRight } from "../../../../assets/icons/teamSideBarIcons/ChevronsRight.svg";
import { ReactComponent as CornerDownRight } from "../../../../assets/icons/teamSideBarIcons/CornerDownRight.svg";
import { ReactComponent as AddNewTeam } from "../../../../assets/icons/teamSideBarIcons/AddNewTeam.svg";
import { ReactComponent as DeleteTeam } from "../../../../assets/icons/teamSideBarIcons/DeleteTeam.svg";
import { ReactComponent as EditTeam } from "../../../../assets/icons/teamSideBarIcons/EditTeam.svg";
import { TeamViews } from "pages/Team/types";
import AddGroupPopup from "../GroupVIew/components/AddGroupPopup";
import DeleteGroupPopup from "../GroupVIew/components/DeleteGroupPopup";
import EditGroupPopup from "../GroupVIew/components/EditGroupPopup/EditGroupPopup";
import { Skeleton } from "@material-ui/lab";

interface TeamSideBarProps {
  teamView: string;
  setTeamView: (arg0: string) => void;
  loadingGroupTeams: boolean;
  setGroupTeams: any;
  groupTeams: any;
  ungroupedTeams: any;
  canEdit: boolean;
}

export default function TeamSideBar({
  teamView,
  setTeamView,
  setGroupTeams,
  groupTeams,
  ungroupedTeams,
  loadingGroupTeams,
  canEdit,
}: TeamSideBarProps) {
  const classes = useStyles();

  const checkTeamView = (view: string): string => {
    return teamView === view
      ? classes.sideBarListItemContainer + " " + classes.listItemActive
      : classes.sideBarListItemContainer;
  };

  const [openAddGroupPopup, setOpenAddGroupPopup] = useState<boolean>(false);
  const [openOpenDeleteGroupPopup, setOpenOpenDeleteGroupPopup] = useState<boolean>(false);
  const [openOpenEditGroupPopup, setOpenOpenEditGroupPopup] = useState<boolean>(false);

  const [selectedTeam, setSelectedTeam] = useState<any>({ name: null, groupId: null });

  const handleOpenAddGroupPopup = () => setOpenAddGroupPopup(true);
  const handleCloseAddGroupPopup = () => setOpenAddGroupPopup(false);

  const handleCloseDeleteGroupPopup = () => {
    setOpenOpenDeleteGroupPopup(false);
  };

  const handleOpenDeleteGroupPopup = (groupId: string, name: string) => {
    setSelectedTeam({ name: name, groupId: groupId });
    setOpenOpenDeleteGroupPopup(true);
  };

  const handleOpenEditGroupPopup = (groupId: string, name: string) => {
    setSelectedTeam({ name: name, groupId: groupId });
    setOpenOpenEditGroupPopup(true);
  };

  const handleCloseEditGroupPopup = () => {
    setSelectedTeam({ name: null, groupId: null });
    setOpenOpenEditGroupPopup(false);
  };

  return (
    <Paper className={classes.sideBarMain}>
      <List style={{ paddingTop: "10px" }}>
        <ListItem
          className={classes.sideBarListItem}
          style={{ marginBottom: "32px" }}
          onClick={() => setTeamView(TeamViews.OversightAndEscalation)}
        >
          <Box className={checkTeamView(TeamViews.OversightAndEscalation)}>
            <Typography className={classes.typographyText}>{TeamViews.OversightAndEscalation}</Typography>
            <Box className={classes.listItemIcon}>
              <ChevronsRight />
            </Box>
          </Box>
        </ListItem>
        <ListItem className={classes.sideBarListItem} onClick={() => setTeamView(TeamViews.Implementation)}>
          <Box className={checkTeamView(TeamViews.Implementation)}>
            <Typography className={classes.typographyText}>{TeamViews.Implementation}</Typography>
            <Box className={classes.listItemIcon}>
              <ChevronsRight />
            </Box>
          </Box>
        </ListItem>
        <ListItem
          className={`${classes.sideBarListItem} ${classes.sideBarListItemOffset}`}
          onClick={() => setTeamView(TeamViews.Ungrouped)}
        >
          <Box className={checkTeamView(TeamViews.Ungrouped)}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CornerDownRight />
              <Typography className={`${classes.typographyText} ${classes.typographyTextOffset}`}>
                {TeamViews.Ungrouped}
              </Typography>
            </Box>
            <Box className={classes.listItemIcon}>
              <ChevronsRight />
            </Box>
          </Box>
        </ListItem>
        {loadingGroupTeams ? (
          <ListItem>
            <Skeleton width="240px" height="60px" />
          </ListItem>
        ) : (
          groupTeams?.map((group: any, index: number) => {
            return (
              <ListItem
                key={index}
                className={`${classes.sideBarListItem} ${classes.sideBarListItemOffset}`}
                onClick={() => setTeamView(group.name)}
              >
                <Box className={checkTeamView(group.name)}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CornerDownRight />
                    <Typography className={`${classes.typographyText} ${classes.typographyTextOffset}`}>
                      {group.name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {canEdit && (
                      <>
                        <Box
                          className={classes.listItemChangeIcon}
                          onClick={() => handleOpenEditGroupPopup(group.id, group.name)}
                        >
                          <Box>
                            <EditTeam />
                          </Box>
                        </Box>
                        <Box
                          className={classes.listItemChangeIcon}
                          onClick={() => handleOpenDeleteGroupPopup(group.id, group.name)}
                        >
                          <Box>
                            <DeleteTeam />
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box className={classes.listItemIcon}>
                      <ChevronsRight />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            );
          })
        )}
        {canEdit && (
          <ListItem
            className={`${classes.sideBarListItem} ${classes.sideBarListItemOffset}`}
            onClick={handleOpenAddGroupPopup}
          >
            <Button className={classes.sideBarListItemContainer}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AddNewTeam />
                <Typography style={{ paddingLeft: "8px" }} className={classes.typographyText}>
                  {TeamViews.NewTeam}
                </Typography>
              </Box>
            </Button>
          </ListItem>
        )}
      </List>

      <AddGroupPopup
        open={openAddGroupPopup}
        handleClose={handleCloseAddGroupPopup}
        setGroupTeams={setGroupTeams}
        groupTeams={groupTeams}
      />

      <DeleteGroupPopup
        setTeamView={setTeamView}
        open={openOpenDeleteGroupPopup}
        handleClose={handleCloseDeleteGroupPopup}
        setGroupTeams={setGroupTeams}
        groupTeams={groupTeams}
        groupId={selectedTeam.groupId}
        nameGroupTeam={selectedTeam.name}
      />

      <EditGroupPopup
        open={openOpenEditGroupPopup}
        handleClose={handleCloseEditGroupPopup}
        setGroupTeams={setGroupTeams}
        groupTeams={groupTeams}
        groupId={selectedTeam.groupId}
        ungroupedTeams={ungroupedTeams}
      />
    </Paper>
  );
}
