// external lib dependencies
import { FC, useEffect, useRef } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { TextField, Container, FormLabel } from "@material-ui/core";

// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";
import { User } from "config/types";

// local dependencies
import useStyles from "../styles";

interface Props {
  key?: number;
  placeholder?: string;
  required?: boolean;
  question: string;
  label: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  isTextArea?: boolean;
  rules?: object;
  currentUser?: User;
}

const InputTextField: FC<Props> = ({
  key,
  placeholder,
  required,
  question,
  label,
  multiline = false,
  maxRows,
  minRows,
  isTextArea,
  rules,
  currentUser,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { register } = useForm();
  const { isXS } = useScreenSize();

  let textInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (`${label}` in errors && textInputRef.current) {
      register(textInputRef.current);
      textInputRef.current.focus();
    }
  }, [errors, label, register]);

  return (
    <Container key={key} className={classes.wrapper} data-testid="textInput">
      <FormLabel error={Boolean(errors[`${label}`])} className={classes.inputLabelFont}>
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      {currentUser && (
        <Controller
          as={
            <TextField
              inputRef={(e) => {
                register(e);
                textInputRef.current = e;
              }}
              name={`${label}`}
              error={Boolean(errors[`${label}`])}
              helperText={errors[`${label}`] ? errors[`${label}`]?.message : ""}
              variant="outlined"
              size="small"
              placeholder={placeholder}
              className={isTextArea ? classes.textAreaField : classes.inputField}
              multiline={multiline}
              maxRows={isXS ? 8 : maxRows}
              minRows={isXS ? 6 : minRows}
              inputProps={{
                maxLength: isTextArea && 400,
              }}
              autoComplete="off"
              disabled={question === "Name"}
            />
          }
          name={`${label}`}
          defaultValue={() => {
            if (question === "Name" && currentUser.first_name && currentUser.last_name) {
              return `${currentUser.first_name} ${currentUser.last_name}`;
            } else if (question === "Job Title" && currentUser.role) {
              return `${currentUser.role}`;
            } else {
              return "";
            }
          }}
          control={control}
          rules={
            required
              ? {
                  required: `We require this information`,
                  ...rules,
                }
              : {
                  ...rules,
                }
          }
        />
      )}
    </Container>
  );
};

export default InputTextField;
