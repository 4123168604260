import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

import { CustomTableRow, TableColumn } from "../../types";
import FilterBox, { FilteredItems } from "./components/FilterBox/FilterBox";
import Row from "./components/Row/Row";
import useStyles from "./styles";

interface Props {
  columns: TableColumn[];
  rows: CustomTableRow[];
}

export const FilterMenu = {
  names: "names",
  projects: "projects",
  companies: "companies",
} as const;

export type FilterBy = (typeof FilterMenu)[keyof typeof FilterMenu];

const CustomTable = ({ columns, rows }: Props) => {
  const classes = useStyles();
  const scrollbarRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [filterColumns, setFilterColumns] = useState<FilteredItems>({
    names: [],
    projects: [],
    companies: [],
  });
  const [tableRows, setTableRows] = useState(rows);
  const [filterBy, setFilterBy] = useState<FilterBy | null>(null);
  const openFilterMenu = Boolean(anchorEl);

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  const getUnique = (array: string[]) => Array.from(new Set([...array]));

  const setStateKeys = (data: string[], state: FilteredItems, key: FilterBy) => {
    return data.map((name) => ({
      name,
      checked: state[key].find((item) => item.name === name)?.checked || false,
    }));
  };

  const resetAllFilters = () => {
    setTableRows(rows);
    syncMenuWithFilteredRows(rows);
  };

  const setFilters = () => {
    const fullNameDataMenu = tableRows.map((row) => row.user_info.user_full_name);
    const projectNameData = tableRows.map((row) => row.data[2].answer);
    const companyData = tableRows.map((row) => row.data[3].answer);

    const fullNames = getUnique(fullNameDataMenu);
    const projectNames = getUnique(projectNameData);
    const companyNames = getUnique(companyData);

    setFilterColumns((prevState) => ({
      ...prevState,
      names: setStateKeys(fullNames, prevState, FilterMenu.names),
      projects: setStateKeys(projectNames, prevState, FilterMenu.projects),
      companies: setStateKeys(companyNames, prevState, FilterMenu.companies),
    }));
  };

  const removeFilters = (key: FilterBy) => {
    setFilterColumns((prevState) => ({
      ...prevState,
      [key]: prevState.projects.map((item) => ({ ...item, checked: false })),
    }));
  };

  const handleOpenFilterMenu = (e: React.MouseEvent, columnToFilter: FilterBy) => {
    setFilters();
    setFilterBy(columnToFilter);
    setAnchorEl(e.currentTarget);
  };

  const getFilteredRows = (rowToFilter: CustomTableRow[], filterBy: FilterBy) => {
    const filteredRows = rowToFilter.filter((row) => {
      const filterColumn = filterColumns[filterBy];
      const filterItem = filterColumn.find((item) => item.name === getFilterBy(row, filterBy));
      return filterItem?.checked;
    });
    return filteredRows;
  };

  const getFilterBy = (row: CustomTableRow, filterBy: FilterBy) => {
    switch (filterBy) {
      case FilterMenu.names:
        return row.user_info.user_full_name;
      case FilterMenu.projects:
        return row.data[2].answer;
      case FilterMenu.companies:
        return row.data[3].answer;
    }
  };

  const getRows = (filter: FilterBy) => {
    const hasNamesFilter = filterColumns.names.some((name) => name.checked);
    const hasProjectsFilter = filterColumns.projects.some((project) => project.checked);
    const hasCompaniesFilter = filterColumns.companies.some((company) => company.checked);

    const rowsFilteredByNames = getFilteredRows(rows, FilterMenu.names);
    const rowsFilteredByProjects = getFilteredRows(rows, FilterMenu.projects);
    const rowsFilteredByCompanies = getFilteredRows(rows, FilterMenu.companies);

    switch (filter) {
      case FilterMenu.names: {
        if (filterBy === FilterMenu.names) {
          removeFilters(FilterMenu.projects);
          removeFilters(FilterMenu.companies);
        }
        return hasNamesFilter ? rowsFilteredByNames : rows;
      }
      case FilterMenu.projects: {
        if (filterBy === FilterMenu.projects) {
          removeFilters(FilterMenu.companies);
        }
        if (hasProjectsFilter && hasNamesFilter) {
          return getFilteredRows(rowsFilteredByNames, FilterMenu.projects);
        }
        if (hasNamesFilter) {
          return rowsFilteredByNames;
        }
        if (hasProjectsFilter) {
          return rowsFilteredByProjects;
        }
        return rows;
      }
      case FilterMenu.companies: {
        if (hasProjectsFilter && hasNamesFilter && hasCompaniesFilter) {
          const rowsFilteredByProjectsAndNames = getFilteredRows(rowsFilteredByNames, FilterMenu.projects);
          return getFilteredRows(rowsFilteredByProjectsAndNames, FilterMenu.companies);
        }
        if (hasProjectsFilter && hasNamesFilter) {
          return getFilteredRows(rowsFilteredByNames, FilterMenu.projects);
        }
        if (hasProjectsFilter && hasCompaniesFilter) {
          return getFilteredRows(rowsFilteredByProjects, FilterMenu.companies);
        }
        if (hasNamesFilter && hasCompaniesFilter) {
          return getFilteredRows(rowsFilteredByNames, FilterMenu.companies);
        }
        if (hasNamesFilter) {
          return rowsFilteredByNames;
        }
        if (hasProjectsFilter) {
          return rowsFilteredByProjects;
        }
        if (hasCompaniesFilter) {
          return rowsFilteredByCompanies;
        }
        return rows;
      }
    }
  };

  const syncMenuWithFilteredRows = (newRows: CustomTableRow[]) => {
    setFilterColumns((prevState) => ({
      ...prevState,
      projects: setStateKeys(getUnique(newRows.map((row) => row.data[2].answer)), prevState, FilterMenu.projects),
      companies: setStateKeys(getUnique(newRows.map((row) => row.data[3].answer)), prevState, FilterMenu.companies),
    }));
  };

  const applyFilters = () => {
    const newRows = getRows(filterBy as FilterBy);
    syncMenuWithFilteredRows(newRows);
    setTableRows(newRows);
  };

  const getFilterColor = (filterBy: FilterBy | undefined) => {
    if (!filterBy) return false;
    return filterColumns[filterBy]?.some((item) => item.checked);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer className={classes.tableContainer} component={Paper} ref={scrollbarRef}>
      <Table stickyHeader style={{ minWidth: "950px" }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <TableCell key={column.label}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">{column.label}</Typography>
                    {column.filterable && (
                      <IconButton
                        onClick={(e) => handleOpenFilterMenu(e, column.filterBy as FilterBy)}
                        color={getFilterColor(column.filterBy) ? "primary" : "default"}
                        classes={{ colorPrimary: classes.colorPrimaryIcon }}
                      >
                        <FilterListIcon />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <Row key={row._id} item={row} />
          ))}
        </TableBody>
      </Table>
      <FilterBox
        open={openFilterMenu}
        handleClose={handleCloseFilterMenu}
        anchorRef={anchorEl}
        dataForFilterMenu={filterColumns}
        applyFilters={applyFilters}
        filterBy={filterBy}
        setFilterColumns={setFilterColumns}
        resetAllFilters={resetAllFilters}
      />
    </TableContainer>
  );
};

export default CustomTable;
