// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
    },

    formContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },

    closeButton: {
      textDecoration: "none",
      minWidth: 150,
      justifySelf: "flex-end",
      marginLeft: "auto",
      "&:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 2, 0),
      },
    },

    submitButton: {
      marginTop: theme.spacing(1.5),
      margin: theme.spacing(2),
    },

    inputLabelFont: {
      color: theme.palette.type === "dark" ? "#FFF" : "black",
    },

    wrapper: {
      marginLeft: "0",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    inputsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    dropZoneContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      "& div div:hover": {
        cursor: "pointer",
      },
      "& div": {
        height: "auto",
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      },
    },
    paperMargin: {
      marginTop: theme.spacing(1.5),
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    dropzoneText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        width: "100%",
        margin: "20px",
        color: "#054c91",
        "& span": {
            textAlign: "center",
            fontSize: "14px",
        }
    }
  })
);

export default useStyles;
