// external lib dependencies
import { FC } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// absolute path dependencies
import { Survey } from "config/types";

// local dependencies
import useStyles from "./styles";

interface Props {
  survey: Survey | null;
}

const AuditsReport: FC<Props> = ({ survey }) => {
  const classes = useStyles();

  return (
    <Box>
      {!survey ? (
        <Typography>Click on report to display here...</Typography>
      ) : (
        <Box className={classes.auditsReportBox}>
          {survey.sections.map((section: any) => (
            <Accordion className={classes.accordion} key={section.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Typography>{`${section.value} ${section.percentageScore}%`}</Typography>
              </AccordionSummary>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.reportTableCell} colSpan={3}>
                        Details
                      </TableCell>
                      <TableCell className={classes.reportTableCell} colSpan={2} style={{ textAlign: "center" }}>
                        Score
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.answeredQuestions.map((answeredQuestion: any) => (
                      <TableRow key={answeredQuestion.options[0].questionId}>
                        <TableCell component="th" scope="row" className={classes.reportTableCell} colSpan={3}>
                          <span style={{ fontWeight: "bold" }}>Q: </span>
                          {answeredQuestion.questionTitle}
                          <br />
                          <span style={{ fontWeight: "bold" }}>A: </span>
                          {answeredQuestion.options[0].value}
                        </TableCell>
                        <TableCell className={classes.reportTableCell} colSpan={2} style={{ textAlign: "center" }}>
                          {answeredQuestion.totalScore === 0 ? "-" : answeredQuestion.totalScore}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AuditsReport;
