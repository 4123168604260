export const surveyPdfContent = {
  confidentialityTitle: "Confidentiality & Disclaimer Notice",

  confidentialityOne:
    "This document has been prepared by Amdaris for the sole use of the recipient.",

  confidentialityTwo:
    "The information contained in this documentation is proprietary and confidential" +
    " to Amdaris and should be kept confidential and not disclosed by the recipient to any other person unless" +
    " such person has a need to know such information in order to evaluate the contents of this document.",

  confidentialityThree:
    "Amdaris has prepared this document in good faith based on the information made" +
    " available by the recipient to Amdaris and Amdaris reserves the right to make amendments and to correct any " +
    "errors that are identified.",

  confidentialityFour:
    "Nothing in this document or in any related correspondence, discussions or other" +
    " materials shall be construed as the basis of any contract or a representation which may be relied upon" +
    " by the recipient unless agreed by Amdaris in the context of a legally binding agreement." +
    " No warranty, assurance or undertaking (express or implied) is or will be made, and no responsibility" +
    " or liability is or will be accepted by Amdaris or any persons employed or engaged by Amdaris in " +
    " relation to the adequacy, accuracy, completeness or reasonableness of the contents of this" +
    " document, or of any other information (whether written or oral), notice or document supplied" +
    " or otherwise made available to the receipt and, to the maximum extent permitted by applicable" +
    " law, all and any such responsibility and liability is expressly disclaimed.",

  confidentialityFive:
    "The following notice applies to this document and should be reproduced by the" +
    " recipient on any copies which have been made in accordance with this notice.",

  footerAddress:
    "© Amdaris Group LTD a private company" +
    "incorporated in England (Company number 10485133) " +
    "and having a registered office at Aurora, Studio A," +
    " Finzels Reach, Counterslip, Bristol BS1 6BX",

  askEmail: "ask@amdaris.com",

  amdarisPhone: "+ 44 (0) 117 9353 444",

  amdarisSite: "Amdaris.com",

  score: "Score:",

  template: "Template ",

  providedBy: " | Provided by ",

  surveySummary: "Audit Summary",

  contents: "Contents",

  sections: "Sections",

  charts: "Score progress",

  emptyTableRow: "No risks or impacts.",

  tableColumnNames: ["Risks", "Score", "Impacts", "Score"],

  notes: "Notes",

  emptyNotes: "No notes",

  survey: "Audit",

  tableSurveyInfoColumnNames: [
    "Question",
    "Total Score",
    "Answer(s)",
    "Answer Score",
    "Risks",
    "Impacts",
  ],

  canvasContainerMaxWidth: "680px",

  canvasContainerHeight: "400px",

  canvasParentDimension: "100%",

  newLine: "\n",

  dash: "-",

  brief: " risks only",

  draft: "DRAFT",

  abbreviated: "Abbreviated ",

  includedScores:
    "For the abbreviated report the following scores where included:",

  excludedScores: "And the following scores where excluded:",

  scoreSectionDescription:
    "Amdaris generate a score for this report." +
    "The score generated is a measure of progress or risk. Where risk is present it is important that the appropriate" +
    " mitigation is identified and that a collaborative approach is applied to reduce and/or eliminate risk.",
};
