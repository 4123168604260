// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

export const useSideDrawerStyles = makeStyles(
  (theme: Theme) => ({
    listItem: {
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        width: "1.2em",
        height: "1.2em",
        color: !theme.palette.background.default,
      },
    },

    sideMenuItem: {
      fontSize: 18,
      fontWeight: 300,
    },

    removePadding: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    noDecoration: {
      textDecoration: "none",
      marginBottom: "1.2em",
      "&, &:hover, .MuiSvgIcon-root": {
        height: "1.2em",
        color: theme.palette.text.primary,
        textDecoration: "none",
      },
    },
  })
);
