import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: theme.spacing(1),
    },

    popoverBox: {
      padding: theme.spacing(3),
      width: "300px",
    },

    tagsBox: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1),
      flexWrap: "wrap",
    },

    addTagsLabel: {
      fontWeight: 500,
      marginBottom: theme.spacing(2),
    },

    tag: {
      backgroundColor: theme.palette.background.default,
      "&:focus": {
        backgroundColor: theme.palette.background.default,
      }
    },

    checkedTag: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      }
    }
  })
);

export default useStyles;
