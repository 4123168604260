import { useState, useRef, Fragment } from "react";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import SearchField from "./components/SearchField";
import InfiniteScroll from "react-infinite-scroll-component";
import { Engagement } from "config/types";
import EngagementCard from "../components/EngagementCard";

interface Props {
  activeCheckbox: boolean;
  engagementsType: string;
  engagements: Engagement[];
  searchType: string;
  innerRef?: any;
}

const EngagementsGrid = ({ activeCheckbox, engagementsType, engagements, innerRef, searchType }: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchPageTrigger, setSearchPageTrigger] = useState<number>(1);
  const checkLoadingStatusRef = useRef<boolean>(true);

  const { engagementsData, hasMoreScroll } = useInfiniteScroll(
    engagementsType,
    activeCheckbox,
    searchPageTrigger,
    searchValue,
  );

  function loadEngagements() {
    if (hasMoreScroll && !engagementsData.length && searchValue) {
      return;
    }

    if (hasMoreScroll && !engagementsData.length && !searchValue) {
      return;
    }

    if (hasMoreScroll) {
      setSearchPageTrigger((prev) => prev + 1);
    }
  }

  function handleSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
    setSearchPageTrigger(1);
  }

  function handleResetSearch() {
    setSearchValue("");
    setSearchPageTrigger(1);
  }

  function checkLoading() {
    if (Boolean(engagementsData.length)) checkLoadingStatusRef.current = false;
  }

  checkLoading();

  return (
    <Fragment>
      <SearchField
        handleSearchInput={handleSearchInput}
        handleResetSearch={handleResetSearch}
        type={searchType}
        searchValue={searchValue}
      />
      <InfiniteScroll
        dataLength={engagementsData.length}
        next={loadEngagements}
        hasMore={hasMoreScroll}
        loader={<h4>Loading more...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>You have reached the end of the list.</b>
          </p>
        }
        height={800}
      >
        <div className="flex flex-col" ref={innerRef}>
          {checkLoadingStatusRef.current
            ? engagements.map((value: Engagement, index: number) => (
                <div key={index}>
                  <EngagementCard key={value.id} engagement={value} />
                </div>
              ))
            : engagementsData.map((value: Engagement, index: number) => (
                <div key={index}>
                  <EngagementCard key={value.id} engagement={value} />
                </div>
              ))}
        </div>
      </InfiniteScroll>
    </Fragment>
  );
};

export default EngagementsGrid;
