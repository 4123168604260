// external lib dependencies
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Chip, TextField, InputAdornment, IconButton, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

// local dependencies
import useStyles from "./styles";
import useEditHeaderTeamTags from "../../../../hooks/useEditHeaderTeamTags";
import AddIcon from "@material-ui/icons/Add";
import { createTagAsync } from "../../../../../../store/reducers/tag/tagSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hook";
import { canEditTeamsAndTags } from "helpers/canEditTeamsAndTags";

interface TagPopoverProps {
  item: any;
  tags: any[];
  openTagMenu: boolean;
  anchorRef: any;
  handleCloseTagPopper: () => void;
  engagement: any;
  setEngagement: any;
}

const TagPopper = (props: TagPopoverProps) => {
  const classes = useStyles();
  const params = useParams<any>();
  const dispatch = useAppDispatch();

  const [checkedTags, setCheckedTags] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [messageInputError, setMessageInputError] = useState<string>("");

  let searchedTags = props.tags.filter((v) => {
    return v.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const { user } = useAppSelector((state) => state.user);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  useEffect(() => {
    setCanEdit(canEditTeamsAndTags(user, parseInt(params.id)));
  }, [user]);

  const handleChange = (e: any) => {
    e.preventDefault();
    const value = e.currentTarget.value;

    if (value.length > 10) {
      setIsInputError(true);
      setMessageInputError("The value must be unique and less than 10 characters");
    } else {
      setIsInputError(false);
      setMessageInputError("");
    }

    setSearchInput(e.target.value);
  };

  const {
    callback: editTagCallback,
    /*loading: editTagLoading,
    error: editTagError,
    isSuccess: editTagIsSuccess,*/
  } = useEditHeaderTeamTags();

  const toggleTag = (tag: any) => {
    const index = checkedTags.indexOf(tag.id);
    const newChecked = [...checkedTags];

    let newTags: any[];

    if (index === -1) {
      newChecked.push(tag.id);
      newTags = props.engagement.header_tags[props.item.type].concat({ id: tag.id, name: tag.name });
      props.setEngagement({
        ...props.engagement,
        header_tags: {
          ...props.engagement.header_tags,
          [props.item.type]: newTags,
        },
      });
    } else {
      newChecked.splice(index, 1);
      newTags = props.engagement.header_tags[props.item.type].filter((currentTag: any) => currentTag.id != tag.id);
      props.setEngagement({
        ...props.engagement,
        header_tags: {
          ...props.engagement.header_tags,
          [props.item.type]: newTags,
        },
      });
    }

    let data = {
      client_partner: props.item.type === "client_partner" ? newTags : props.engagement.header_tags.client_partner,
      portfolio_director:
        props.item.type === "portfolio_director" ? newTags : props.engagement.header_tags.portfolio_director,
    };

    editTagCallback(params.id, data);
    setCheckedTags(newChecked);
  };

  const handleAddTag = () => {
    setMessageInputError("");
    setIsInputError(false);

    const isExist = props.tags.some((item) => item.name.toLowerCase() === searchInput.toLowerCase());
    if (!searchInput.length || searchInput.length > 10 || isExist) {
      setMessageInputError("The value must be unique and less than 10 characters");
      setIsInputError(true);
      return;
    }

    const data = {
      project_id: params.id,
      data: {
        name: searchInput,
      },
    };
    dispatch(createTagAsync(data));
    setSearchInput("");
  };

  useEffect(() => {
    let checkedList: any = [];
    for (let v of props.tags) {
      let isExistMember = props.item.tags.some((t: any) => t.id == v.id);
      if (isExistMember) {
        checkedList.push(v.id);
      }
    }
    setCheckedTags(checkedList);
  }, []);

  if (canEdit) {
    return (
      <Popper
        open={props.openTagMenu}
        anchorEl={props.anchorRef}
        disablePortal
        placement="right"
        style={{ zIndex: 2 }}
        modifiers={{
          preventOverflow: {
            escapeWithReference: true,
          },
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={props.handleCloseTagPopper}>
            <Box className={classes.popoverBox}>
              <Typography variant="h6" className={classes.addTagsLabel}>Add tags</Typography>
              <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <TextField
                  variant="outlined"
                  style={{ fontSize: "16px" }}
                  size="small"
                  placeholder="Search"
                  onChange={handleChange}
                  value={searchInput}
                  error={isInputError}
                  helperText={messageInputError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {canEdit && (
                  <IconButton onClick={handleAddTag} style={{ height: "40px", width: "40px" }}>
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
              <Box className={classes.tagsBox}>
                {!searchedTags.length ? (
                  <>
                    <Typography variant="body2" align="left">
                      No tags were found.
                    </Typography>
                    {canEdit && <Typography>Click + to create new tag</Typography>}
                  </>
                ) : (
                  searchedTags.map((tag) => (
                    <Chip
                      variant={checkedTags.indexOf(tag.id) == -1 ? "outlined" : "default"}
                      label={tag.name}
                      size="small"
                      clickable
                      key={tag.id}
                      onClick={() => toggleTag(tag)}
                      className={checkedTags.indexOf(tag.id) == -1 ? classes.tag : classes.checkedTag}
                    />
                  ))
                )}
              </Box>
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>
    );
  } else {
    return <IconButton />;
  }
};

export default TagPopper;
