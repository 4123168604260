// external lib dependencies
import { Container, Grid, Typography, Link } from "@material-ui/core";

// absolute path dependencies
import SectionTitleBar from "components/SectionTitleBar";

// local dependencies
import useStyles from "./styles";

export default function NoEngagements() {
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="column">
        <SectionTitleBar title={"YOUR ENGAGEMENTS"} />
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant="h5">Hi, it looks like we need to set-up your engagements for you.</Typography>
          <Typography variant="h5">
            Please, contact your Client Partner or alternatively email our IT Team at{" "}
            <Link href="mailto:client@amdaris.com">client@amdaris.com</Link> to get access to your engagements.
          </Typography>
        </Container>
        <SectionTitleBar title={"COMPLETED ENGAGEMENTS"} />
        <Container maxWidth="xl" className={classes.container}>
          <Typography variant="h5">Sorry, you don't have completed engagements yet</Typography>
        </Container>
      </Grid>
    </div>
  );
}
