// external lib dependencies
import clsx from "clsx";
import { Redirect } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";
import { useAuth } from "providers/AuthContext";
import { ReactComponent as Logo } from "assets/images/logo.svg";

// local dependencies
import LoginForm from "./LoginForm";
import useStyles from "./styles";
import "./login-page.scss";
import ExpiredPage from "./ExpiredPage";
import { useEffect, useState } from "react";

export function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const { isXS } = useScreenSize();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Redirect to="/engagements" />;

  const stateHistory: any = history.location?.state;

  return (
    <Container className={clsx(classes.container, classes.backgroundImage)}>
      <img src="/images/logo-mini.svg" alt="logo-mini" className={classes.logoMiniLoginPage} />
      <Logo className={isXS ? classes.logoLoginPageXS : classes.logoLoginPage} />
      {stateHistory?.expired_email ? (
        <ExpiredPage />
      ) : stateHistory?.redirect === "true" ? (
        <Typography variant="body1" style={{ color: "#FFF" }}>
          Please contact Amdaris to verify your access to Client Portal.
        </Typography>
      ) : (
        <LoginForm />
      )}
    </Container>
  );
}
