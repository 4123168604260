//local dependencies
import { FetchData } from "./FetchData";


export async function verifyAccessCodeService(accessCode: string) {
  return await new FetchData(`/access-code/${accessCode}`).GET();
}

export async function loginSendEmailService(email: string, referrerLink: any=null) {
  return await fetch("/authorize/access-code", {
    method: "POST",
    body: JSON.stringify({ email, referrer_link: referrerLink })
  })
}
