// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import AuditsSmallTable from "./AuditsSmallTable";
import AuditsLargeTable from "./AuditsLargeTable";
import { AuditTableProps } from "./types";


const AuditsTable = (props: AuditTableProps) => {
  const { isSM } = useScreenSize();

  return isSM ? <AuditsSmallTable {...props} /> : <AuditsLargeTable {...props} />;
};

export default AuditsTable;
