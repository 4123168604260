import { GroupTeams, Member } from "config/types";

export function findUngroupedMembers(allMembers: Array<any>, groups: Array<any>) {
    let result: any[] = [];
    let memberAssignments: any[] = [];
  
    for (let group of groups) {
      memberAssignments.push(group?.member_assignments);
    }
  
    const flatMemberAssignments = memberAssignments?.flat();
  
    if (!flatMemberAssignments.length) return allMembers;
    for (let a of allMembers) {
      const isExistInResult = result.some((item) => item.employee_id == a?.employee_id);
      if (isExistInResult) continue;
  
      const isExistInMember = flatMemberAssignments.some((item) => item.employee_id == a.employee_id);
      if (!isExistInMember) result.push(a);
    }
  
    return result;
  }
  
export function addMembersToGroup(groups: GroupTeams[], uniqueMembers: Member[]) {
    return groups?.map(group => {
      let groupMembers = uniqueMembers?.filter( member => {
        return group.member_assignments?.some(m => m.employee_id === member.employee_id);
      });
    
      return { ...group, member_assignments: groupMembers };
    });
}
  
export function addTagsToMemberInGroup(groups: any[], tags: any[]) {
    return groups?.map((item: any) => {
      let groupMembers = item.member_assignments.map((member: any) => {
        let newTags: any = [];
  
        for (let tag of tags) {
          for (let tagMember of tag?.member_assignments) {
            if (tagMember.employee_id == member.employee_id) {
              newTags.push({
                id: tag.id,
                name: tag.name,
              });
            }
          }
        }
  
        return { ...member, tags: newTags };
      });
      return { ...item, member_assignments: groupMembers };
    });
  }

export  function addTagsToMember(members: any[], tags: any[]) {
    return members.map((item: any) => {
      let memberTags = [];
      for (let tag of tags) {
        for (let tagMember of tag?.member_assignments) {
          if (tagMember.employee_id == item.employee_id) {
            memberTags.push({
              id: tag.id,
              name: tag.name,
            });
          }
        }
      }
  
      return { ...item, tags: memberTags };
    });
  }
  
export function filterMembersByTag(members: Member[], checkedTagsForFilter: string[]) {
    return members.filter( member  => {
      if (!checkedTagsForFilter.length) return true;
      return member.tags?.some( tag  => checkedTagsForFilter.includes(tag.id));
  });
}

export function getUniqueMemberList (members: Array<Member>){
  const now: Date = new Date();

  const activeMembers = members.filter((member) => {
    return new Date(member.end_date) >= now && member.employee_name;
  });

  const sortedActiveMembers = activeMembers.sort((member_one, member_two) =>
    member_one.employee_name.localeCompare(member_two.employee_name)
  );

  const uniqueMembers = Array.from(
    new Map(
      sortedActiveMembers.map((member) => [member.employee_id, member])
    ).values()
  );
  return uniqueMembers;
}