// external lib dependencies
import * as dateMath from "date-arithmetic";
import { useCallback } from "react";
import useBarPercent from "./useBarPercent";
import { WorkOrder } from "config/types";
// absolute path dependencies
// local dependencies

const useListSort = () => {
  const { getTimePassedPercent } = useBarPercent();

  const sortList = useCallback(
    (
      array: Array<any>,
      type: "engagements" | "workOrders" | "members",
      descending?: true | undefined,
      activeWO?: boolean,
      selectedYear?: number,
    ): any[] => {
      let sortedList: any[] = [];
      if (type === "workOrders") {
        sortedList = [...array].sort((a: any, b: any) => {
          if (descending) {
            return Date.parse(b.end_date) - Date.parse(a.end_date);
          } else {
            return Date.parse(a.end_date) - Date.parse(b.end_date);
          }
        });
      } else if (type === "engagements") {
        sortedList = [...array].sort((a: any, b: any) => {
          const aWO: WorkOrder[] = a.work_orders;
          const bWO: WorkOrder[] = b.work_orders;

          const timePassedPercentA: number[] = filterAndMapWorkOrdersByTimePassedPercent(
            aWO,
            activeWO!,
            selectedYear!,
            getTimePassedPercent,
          );
          const timePassedPercentB: number[] = filterAndMapWorkOrdersByTimePassedPercent(
            bWO,
            activeWO!,
            selectedYear!,
            getTimePassedPercent,
          );

          const timePassedPercentAMax: number = Math.max(...timePassedPercentA);
          const timePassedPercentBMax: number = Math.max(...timePassedPercentB);

          const dateDifferA: number[] = filterAndMapWorkOrdersByDateDifference(aWO);
          const dateDifferB: number[] = filterAndMapWorkOrdersByDateDifference(bWO);

          const maxDateDiffA = Math.max(...dateDifferA);
          const maxDateDiffB = Math.max(...dateDifferB);

          // the first two if statements are for ordering by the date difference of the work orders (date difference between start and end date)
          // the second two if statements are for ordering by the progress of the work orders (ending soon above others)
          // and the last two if statements are for alphabetical order by the title of the engagement

          if (maxDateDiffA > maxDateDiffB) {
            return -1;
          }

          if (maxDateDiffA < maxDateDiffB) {
            return 1;
          }

          if (timePassedPercentAMax > timePassedPercentBMax) {
            return -1;
          }

          if (timePassedPercentAMax < timePassedPercentBMax) {
            return 1;
          }

          if (a.title > b.title) {
            return 1;
          }

          if (a.title < b.title) {
            return -1;
          }

          return 0;
        });
      } else if (type === "members") {
        if (descending) {
          sortedList = [...array].sort(
            (a: any, b: any) => new Date(b.end_date).valueOf() - new Date(a.end_date).valueOf(),
          );
        } else {
          sortedList = [...array].sort(
            (a: any, b: any) => new Date(a.end_date).valueOf() - new Date(b.end_date).valueOf(),
          );
        }
        sortedList = Array.from(new Map(sortedList.map((member) => [member.employee_id, member])).values());
      } else {
        sortedList = [];
      }

      return sortedList;
    },
    [],
  );

  return sortList;
};

export default useListSort;

/**
 * This function first will filter the array by selected year. After that it will be mapped and if
 * activeWO parametar is true it will return time passed procent between start date and end date
 * only if it is less then 99%, else it will return those that are 100% as well.
 *
 * @param woArray An array of WorkOrders.
 * @param activeWO A boolean that is true or false if the checkbox Active Work Orders Only is checked or unchecked.
 * @param selectedYear Number that contains the year on which the array of Work Order will be filtered.
 * @param getTimePassedPercent Function that gets the time passed between dates by percentage.
 * @returns An array of type number that contains time passed between dates from all work orders of woArray.
 */
const filterAndMapWorkOrdersByTimePassedPercent = (
  woArray: WorkOrder[],
  activeWO: boolean,
  selectedYear: number,
  getTimePassedPercent: (startDate: Date, endDate: Date) => number,
): number[] => {
  return woArray
    .filter((x: any) => {
      if (new Date(x.end_date).getFullYear() >= selectedYear!) {
        return x;
      }
    })
    .map((x: any) => {
      const timePassedProcent = getTimePassedPercent(new Date(x.start_date), new Date(x.end_date));
      if (activeWO) {
        return timePassedProcent < 100 ? timePassedProcent : 0;
      } else {
        return timePassedProcent;
      }
    });
};

/**
 * This function first will map the array of work orders to take difference between start and end date
 * only if it is in the range > 0 && <= 31 else it will return undefined. After that it will filter those that are
 * undefined and it will return only those that have value of type number.
 *
 * @param wo An array of type WorkOrder.
 * @returns An array of type number which represents the difference between start and end date in every work order.
 */
const filterAndMapWorkOrdersByDateDifference = (wo: WorkOrder[]): number[] => {
  return wo
    .map((x) => {
      const dateDifference = dateMath.diff(new Date(), new Date(x.end_date), "day");
      if (dateDifference > 0 && dateDifference <= 31) {
        return dateDifference;
      }
    })
    .filter((x) => x) as number[];
};
