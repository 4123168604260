import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Engagement, Stakeholder } from "config/types";
import { IStakeholderFormState } from "pages/Stakeholders/types";
import {
  getEngagement,
  addStakeholder as addStakeholderService,
  editStakeholder as editStakeholderService,
  deleteStakeholder as deleteStakeholderService,
} from "services";

interface InitialState {
  engagement: Engagement | null;
  loading: boolean;
  error: string | null;
  sendingStatus: "idle" | "loading" | "succeeded" | "failed";
  sendingErrorMessage: string | null;
}

interface FetchEngagementByIdParams {
  id: number;
  signal?: AbortSignal;
}

interface AddNewStakeholderParams {
  data: IStakeholderFormState;
  project_id: number;
  signal: AbortSignal;
}

interface EditStakeholderParams {
  data: IStakeholderFormState;
  stakeholder_id: number;
  project_id: number;
  signal: AbortSignal;
}

interface DeleteStakeholderParams {
  stakeholder_id: number;
  signal: AbortSignal;
  project_id: number;
}

export const fetchEngagementById = createAsyncThunk<Engagement, FetchEngagementByIdParams, { rejectValue: string }>(
  "engagement/fetchEngagementById",
  async ({ id, signal }, { rejectWithValue }) => {
    try {
      const result = await getEngagement(id, signal);
      return result;
    } catch (error: any) {
      return rejectWithValue("Something went wrong");
    }
  },
);

export const addNewStakeholder = createAsyncThunk<void, AddNewStakeholderParams, { rejectValue: string }>(
  "engagement/addnNewStakeholder",
  async ({ data, project_id, signal }: AddNewStakeholderParams, { rejectWithValue, dispatch }) => {
    try {
      const result = await addStakeholderService(data, project_id, signal);

      const newStakeholder: Stakeholder = {
        id: result._id,
        ...result,
      };

      dispatch(addStakeholder(newStakeholder));
    } catch (error: any) {
      if (error instanceof Response) {
        const message = await error.json();
        return rejectWithValue(`Unable to add stakeholder to the project: ${message.detail}`);
      }
      return rejectWithValue("Unable to add stakeholder to the project. Please try again later.");
    }
  },
);

export const editStakeHolderById = createAsyncThunk<void, EditStakeholderParams, { rejectValue: string }>(
  "engagement/editStakeholder",
  async ({ data, stakeholder_id, project_id, signal }: EditStakeholderParams, { rejectWithValue, dispatch }) => {
    try {
      const result = await editStakeholderService(data, stakeholder_id, project_id, signal);

      const updatedStakeholder: Stakeholder = {
        id: result._id,
        ...result,
      };

      dispatch(editStakeHolder({ id: updatedStakeholder.id, data: updatedStakeholder }));
    } catch (error: any) {
      return rejectWithValue(error.statusText);
    }
  },
);

export const deleteStakeHolderById = createAsyncThunk<void, DeleteStakeholderParams, { rejectValue: string }>(
  "engagement/deleteStakeholder",
  async ({ stakeholder_id, project_id, signal }: DeleteStakeholderParams, { rejectWithValue, dispatch }) => {
    try {
      await deleteStakeholderService(stakeholder_id, project_id, signal);

      dispatch(deleteStakeHolder({ id: stakeholder_id }));
    } catch (error: any) {
      return rejectWithValue(error.statusText);
    }
  },
);

const initialState: InitialState = {
  engagement: null,
  loading: false,
  error: null,
  sendingStatus: "idle",
  sendingErrorMessage: null,
};

const engagementSlice = createSlice({
  name: "engagement",
  initialState,
  reducers: {
    addStakeholder(state: InitialState, action: PayloadAction<Stakeholder>) {
      state.engagement?.stakeholders?.push(action.payload);
    },
    editStakeHolder(state: InitialState, action: PayloadAction<{ id: number; data: Stakeholder }>) {
      const { id, data } = action.payload;
      const stakeholderToUpdate = state.engagement?.stakeholders?.find((stakeholder) => stakeholder.id === id);
      if (stakeholderToUpdate) {
        Object.assign(stakeholderToUpdate, data);
      }
    },
    deleteStakeHolder(state: InitialState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      if (state.engagement && state.engagement.stakeholders) {
        state.engagement.stakeholders = state.engagement.stakeholders.filter((stakeholder) => stakeholder.id !== id);
      }
    },
    setDefaultSendStatus(state: InitialState) {
      state.sendingStatus = "idle";
    },
    setDefaultError(state: InitialState) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchEngagementById cases
      .addCase(fetchEngagementById.pending, (state: InitialState, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEngagementById.fulfilled, (state: InitialState, action: PayloadAction<Engagement>) => {
        state.engagement = action.payload;
        state.loading = false;
      })
      .addCase(fetchEngagementById.rejected, (state: InitialState, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      //addNewStakeholder cases
      .addCase(addNewStakeholder.pending, (state: InitialState, action) => {
        state.sendingStatus = "loading";
      })
      .addCase(addNewStakeholder.fulfilled, (state: InitialState, action) => {
        state.sendingStatus = "succeeded";
      })
      .addCase(addNewStakeholder.rejected, (state: InitialState, action: PayloadAction<any>) => {
        state.sendingErrorMessage = action.payload;
        state.sendingStatus = "failed";
      })

      //editNewStakeholder cases
      .addCase(editStakeHolderById.pending, (state: InitialState, action) => {
        state.sendingStatus = "loading";
      })
      .addCase(editStakeHolderById.fulfilled, (state: InitialState, action) => {
        state.sendingStatus = "succeeded";
      })
      .addCase(editStakeHolderById.rejected, (state: InitialState, action) => {
        state.sendingStatus = "failed";
      })

      //deleteNewStakeholder cases
      .addCase(deleteStakeHolderById.pending, (state: InitialState, action) => {
        state.sendingStatus = "loading";
      })
      .addCase(deleteStakeHolderById.fulfilled, (state: InitialState, action) => {
        state.sendingStatus = "succeeded";
      })
      .addCase(deleteStakeHolderById.rejected, (state: InitialState, action) => {
        state.sendingStatus = "failed";
      });
  },
});

export const { editStakeHolder, addStakeholder, deleteStakeHolder, setDefaultSendStatus, setDefaultError } =
  engagementSlice.actions;
export default engagementSlice.reducer;
