import { Box, CircularProgress, Slide, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";

import SectionTitleBar from "components/SectionTitleBar";

import { getCustomerSurveyData, getEngagement } from "services";

import { UrlParams } from "config/types";
import { useParams } from "react-router-dom";
import CustomTable from "./components/CustomTable/CustomTable";
import { columns } from "./config";
import useStyles from "./styles";
import { CustomTableRow } from "./types";
import { useAppSelector } from "store/hook";

const ViewFeedback = () => {
  const classes = useStyles();
  const { id } = useParams<UrlParams>();

  const [feedback, setFeedback] = useState<CustomTableRow[]>([]);
  const [loadingFeedback, setLoadingFeedback] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const rows: CustomTableRow[] = feedback.map((item) => item);

  const { user } = useAppSelector((state) => state.user);

  const getEngagementById = useCallback(async (id: number) => {
    try {
      const surveyData = await getCustomerSurveyData();

      const sortedFeedback = surveyData.sort((a, b) => {
        return new Date(b.user_info.survey_date).getTime() - new Date(a.user_info.survey_date).getTime();
      });

      const engagement = id ? await getEngagement(Number(id)) : null;
      const filteredFeedback = sortedFeedback.filter((item) => item.data[2].answer === engagement?.title);

      setFeedback(engagement ? filteredFeedback : surveyData);
    } catch (error) {
      setErrorMsg("Something went wrong...");
    } finally {
      setLoadingFeedback(false);
    }
  }, []);

  useEffect(() => {
    getEngagementById(Number(id));
  }, [id, getEngagementById]);

  return (
    <Box>
      <SectionTitleBar title={"List of Provide Feedback"} feedbackPage={true} isStakeholder={user?.is_stakeholder} />

      {loadingFeedback ? (
        <Box className={classes.loadingBox}>
          <CircularProgress />
        </Box>
      ) : !errorMsg ? (
        <Slide in direction="up">
          <Box className={classes.viewFeedback}>
            <Box className={classes.feedbackContent}>
              <CustomTable rows={rows} columns={columns} />
            </Box>
          </Box>
        </Slide>
      ) : (
        <Box className={classes.errorMsgBox}>
          <Typography variant="h5">{errorMsg}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ViewFeedback;
