import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { API_BASE_URL } from "config/constants";
import { RequestContent, RequestTeam } from "config/types";


const baseUrl: string = `${API_BASE_URL}/projects`;

interface InitialState {
    teamRequests: RequestTeam[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: InitialState = {
    teamRequests: [],
    status: 'idle',
    error: null
};


export interface RequestTeamParams {
    request_content: RequestContent[];
    request_comment: string;
    project_id?: number;
    request_id?: string;
    is_new?: boolean;
}

export const createTeamRequestAsync = createAsyncThunk(
    "teamRequest/createTeamRequestAsync",
    async(data: RequestTeamParams, {rejectWithValue, dispatch}) => {
        const { 
            project_id, 
            request_content, 
            request_comment 
        } = data;

        const teamRequest = {
            request_content,
            request_comment
        };

        const request = await fetch(`${baseUrl}/${project_id}/request-team`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(teamRequest)
        });
        if (request.ok) {
            const result = await request.json() as RequestTeam;
            dispatch(createTeamRequest(result));
        }
        else {
            rejectWithValue('Error creating request');
        }
    }
)

export const fetchTeamRequestsAsync = createAsyncThunk(
    "teamRequest/fetchTeamRequestsAsync",
    async(projectId: string, {rejectWithValue, dispatch}) => {
        const request = await fetch(`${baseUrl}/${projectId}/request-team`);
        if (request.ok) {
            return (await request.json()) as RequestTeam[];
        }
    }
)

export const updateTeamRequestAsync = createAsyncThunk(
    "teamRequest/updateTeamRequestAsync",
    async(data: RequestTeam, {rejectWithValue, dispatch}) => {

        const { 
            project_id, 
            id,
            is_new,
            request_content, 
            request_comment 
        } = data;

        const teamRequest = {
            project_id,
            request_id: id,
            is_new,
            request_content,
            request_comment
        };

        const request = await fetch(`${baseUrl}/${data.project_id}/group-teams/request-team/${data.id}?is_new=${is_new}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(teamRequest)
        });

        if (request.ok) {
            dispatch(updateTeamRequest(data));
        }
    }
)

export const deleteTeamRequestAsync = createAsyncThunk(
    "teamRequest/deleteTeamRequestAsync",
    async(data: RequestTeam, {rejectWithValue, dispatch}) => {
        const { id, project_id } = data;
        
        if ((!id) || (!project_id)) 
            return;

        const request = await fetch(`${baseUrl}/${project_id}/request-team/${id}`, {
            method: "DELETE"
        });

        if (request.ok) {
            dispatch(deleteTeamRequest(data));
        } else {
            rejectWithValue("Error deleting team request");
        }
    }
)

const teamRequestSlice = createSlice({
    name: 'teamRequestSlice',
    initialState,
    reducers: {
        createTeamRequest(state: InitialState, action: PayloadAction<RequestTeam>) {
            const request = action.payload;
            state.teamRequests.push(request);
        },
        deleteTeamRequest(state: InitialState, action: PayloadAction<RequestTeam>) {
            const request = action.payload;
            state.teamRequests = state.teamRequests.filter((req: RequestTeam) => req.id !== request.id);
        },
        updateTeamRequest(state: InitialState, action: PayloadAction<RequestTeam>) {
            const teamRequest = action.payload;
            state.teamRequests = state.teamRequests.map((item: RequestTeam) => {
                if (item.id === teamRequest.id) {
                    const { request_content, request_comment, is_new } = teamRequest;
                    return { ...item, request_content, request_comment, is_new };
                }
                return { ...item };
            })
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchTeamRequestsAsync.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
            state.teamRequests = action.payload;
            state.status = 'succeeded';
        });
        builder.addCase(fetchTeamRequestsAsync.pending, (state: InitialState, action: PayloadAction<any>) => {
            state.status = 'loading';
        });
        builder.addCase(fetchTeamRequestsAsync.rejected, (state: InitialState, action: PayloadAction<any>) => {
            state.status = 'failed';
            state.error = 'Some error happened';
        });
        builder.addCase(deleteTeamRequestAsync.rejected, (state: InitialState, action: PayloadAction<any>) => {
            state.status = 'failed';
            state.error = 'Error deleting team request';
        })
    }
})

export const { deleteTeamRequest, updateTeamRequest, createTeamRequest } = teamRequestSlice.actions;

export default teamRequestSlice.reducer;
