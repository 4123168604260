// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.background.default,
      },
    },

    container: {
      marginTop: theme.spacing(7.5),
      paddingBottom: theme.spacing(7.5),
    },

    chartContainer: {
      borderRadius: theme.spacing(1.75),
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
      height: 350,
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        height: 200,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 1, 1, 0),
        borderRadius: 14,
        backgroundColor: theme.palette.background.paper,
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
        height: 400,
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4),
        height: 420,
      },
    },

    reportContainer: {
      padding: theme.spacing(3),
      height: "744px",
      maxHeight: "744px",
      overflowY: "auto",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2),
        height: "728px",
        maxHeight: "728px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
        height: "auto",
        maxHeight: "none",
      },
      [theme.breakpoints.up("md")]: {
        borderRadius: theme.spacing(1.75),
      },
    },

    closeButton: {
      textDecoration: "none",
      minWidth: 150,
      justifySelf: "flex-end",
      marginLeft: "auto",
      "&:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 2, 0),
      },
    },
  })
);

export default useStyles;
