// external lib dependencies
import clsx from "clsx";
import { Typography } from "@material-ui/core";

// absolute path dependencies
import { EngagementStatus } from "config/types";

// local dependencies
import useStyles from "./styles";

const mapStatusToClass = {
  [EngagementStatus.SETTING_UP]: "setting-up",
  [EngagementStatus.ONBOARDING]: "onboarding",
  [EngagementStatus.ACTIVE]: "active",
  [EngagementStatus.COMPLETED]: "completed",
};

interface StatusChipProps {
  status: EngagementStatus;
}

const StatusChip = ({ status }: StatusChipProps) => {
  const classes = useStyles();
  const statusClass = mapStatusToClass[status];

  return (
    <div className={clsx(classes.chip, statusClass)}>
      <Typography variant="body2">{status?.valueOf()}</Typography>
    </div>
  );
};

export default StatusChip;
