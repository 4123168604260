import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { Typography, Container, Paper, Box } from "@material-ui/core";
import useStyles from "./styles";

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <SentimentDissatisfiedIcon className={classes.sadIcon} />
        <Box component="span" className={classes.textBox}>
          <Typography variant="h3">Page not found</Typography>
          <Typography variant="h5">We're sorry, we couldn't find the page you requested</Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PageNotFound;
