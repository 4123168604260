import { dimension } from "./dimension";

export const imageDimension = {
  // general
  fullPageImagePortrait: dimension(210, 297),
  fullPageImageLandscape: dimension(297, 210),

  // survey
  exceptionalSoftwareTeams: dimension(160, 14),
  chartImage: dimension(170, 100),

  // lastPageAddress
  lastPageAddress: dimension(160, 30),

  // profile overview
  profileImage: dimension(50, 50),
  profilePatternImage: dimension(108, 210),

  // engagement status report
  ragStatusImage: dimension(8, 8),
  auditImage: dimension(8, 8),

  // logo
  logoImage: dimension(35, 9),
  logoFirstPageImage: dimension(40, 15.5),

  // footer
  footerGlobe: dimension(3, 3),
  footerMail: dimension(4, 3),
  footerPhone: dimension(3, 3),
};
