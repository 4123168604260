// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

export const useWOPageStyles = makeStyles((theme: Theme) => ({
  WOMainContainer: {
    margin: 0,
    width: "100%",
    maxWidth: "initial",
    padding: 0,
  },
  WOContentLeftPaper: {
    display: "flex",
    flexDirection: "row",
    height: `calc(100% - ${theme.spacing(4)}px)`,
    maxHeight: "100%",
    width: "25%",
    maxWidth: "initial",
    margin: theme.spacing(2, 1),
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  WOContentContainer: {
    minHeight: "50vh",
    padding: 0,
    maxWidth: "initial",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 50,
  },
  workOrderRightContainer: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2, 2, 2, 0),
    height: "100%",
    maxHeight: "100%",
    width: "calc(70% + 11px)",
    maxWidth: "initial",
    margin: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export const useWOPageSkeletonStyles = makeStyles((theme: Theme) => ({
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    width: '100%',
    maxWidth:'initial',
    margin: 0
  },
  itemsContainer: {
    height: "100%",
    width:'100%',
    maxWidth:'initial',
    display: "flex",
    flexDirection: "row",
    justifyContent:'space-between',
    padding: 0,
    margin: 0

  },
  leftSideBox: {
    width: "26%",
    height: "100%",
    padding: theme.spacing(2,1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(2,0,3,0)
    },
  },
  rightSideBox: {
    width: "70%",
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: "white",
    marginTop: theme.spacing(2.12),
    paddingBottom: theme.spacing(0.6),
    paddingTop: theme.spacing(6.2),
    borderRadius: theme.spacing(0.6),
  },
  leftItems: {
    height: "100%",
    width: "100%",
  },
  yearSelectBoxSkeleton: {
    display: "flex",
    padding: theme.spacing(0.5),
  },
  yearsSkeleton: {
    marginTop: theme.spacing(0.8),
  },
  skeletonNavBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandIconsSkeleton: {
    padding: theme.spacing(2, 3),
  },
}));
