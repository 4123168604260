import { EngagementStatus, WorkOrder } from "../config/types";

export function getEngagementStatusByWorkOrders(workOrders: WorkOrder[]) {
  if (!workOrders || !workOrders.length) return EngagementStatus.SETTING_UP;

  const now = new Date();

  if (!workOrders.find((wo) => now < new Date(wo.end_date))) {
    return EngagementStatus.COMPLETED;
  }

  if (!workOrders.find((wo) => now > new Date(wo.start_date))) {
    return EngagementStatus.ONBOARDING;
  }

  if (
    workOrders.find(
      (wo) => now > new Date(wo.start_date) && now < new Date(wo.end_date)
    )
  ) {
    return EngagementStatus.ACTIVE;
  }

  return EngagementStatus.SETTING_UP;
}
