import {configureStore} from '@reduxjs/toolkit';
import tagReducer from "./reducers/tag/tagSlice";
import groupReducer from "./reducers/group/groupSlice";
import releaseNoteReducer from "./reducers/releaseNotes/releaseNoteSlice";
import teamRequestSlice from './reducers/teamRequest/teamRequestSlice';
import userSlice from './reducers/user/userSlice';
import engagementSlice from './reducers/engagement/engagementSlice';
import engagementMembersSlice from './reducers/engagementMembers/engagementMembersSlice';
import engagementsListSlice from './reducers/engagementList/engagementListSlice';

export const store = configureStore({
    reducer: {
        group: groupReducer,
        tags: tagReducer,
        releaseNotes: releaseNoteReducer,
        teamRequest: teamRequestSlice,
        user: userSlice,
        engagement: engagementSlice,
        engagementMembers: engagementMembersSlice,
        engagementsList: engagementsListSlice,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
