
export async function getProfile(projectId: string, employeeId: string) {
    return await fetch(`/api/projects/${projectId}/employees/${employeeId}`)
}

export async function getWorkOrders(projectId: string, employeeId: string) {
    return await fetch(`/api/projects/${projectId}/employees/${employeeId}/work-orders`)
}



