import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Member } from "config/types";
import { getUniqueMemberList } from "helpers/teamSectionHelpers/teamSectionHelpers";
import { getEngagementMembers } from "services";

interface MemberWithTag extends Member {
    tags?: any;
}

interface IInitialState {
    members: Member[]
    filterMembers: Member[]
    loading: boolean
    error: null | string
}

export const fetchEngagementMembers = createAsyncThunk<Member[], string, { rejectValue: string }>(
    'engagementMembers/fetchEngagementMembers',
    async (projectId: string, { rejectWithValue, dispatch }) => {
       try {
        const result = await getEngagementMembers(parseInt(projectId))
        return result
       } catch (error: any) {
        return rejectWithValue(error.statusText)
       }
    }
)

const initialState: IInitialState = {
    members: [],
    filterMembers: [],
    loading: false,
    error: null,
}

const engagementMembersSlice = createSlice({
    name: 'engagementMembers',
    initialState,
    reducers: {
        refreshTagToMembers(state: any, action: PayloadAction<any>) {
            const tags = action.payload

            state.members = state.members.map((item: MemberWithTag) => {
                let memberTags = [];
                for (let tag of tags) {
                    for (let tagMember of tag?.member_assignments) {
                        if (tagMember.employee_id === item.employee_id) {
                            memberTags.push({
                                id: tag.id,
                                name: tag.name,
                            });
                        }
                    }
                }

                return { ...item, tags: memberTags };
            });
        },
        filterMembersByTag(state: any, action: PayloadAction<any>) {
            state.filterMembers = state.members.filter((member: MemberWithTag) => {
                if (!action.payload.length) return true;
                return member.tags?.some((tag: any) => action.payload.includes(tag.id));
            });
        },
        setUniqueMembers(state: IInitialState, action: PayloadAction<Member[]>){
            state.members = getUniqueMemberList(action.payload)
        },
    },
    extraReducers: build => {
        build
            .addCase(fetchEngagementMembers.pending, (state: IInitialState, action) => {
                state.loading = true
            })
            .addCase(fetchEngagementMembers.fulfilled, (state: IInitialState, action: PayloadAction<Member[]>) => {
                state.members = getUniqueMemberList(action.payload)
                state.loading = false
            })
            .addMatcher(isError, (state: IInitialState, action: PayloadAction<string>)=>{
                state.loading = false
                state.error = action.payload
           })
    }
})

const isError = (action: AnyAction) =>{
    return action.type.endsWith('rejected')
}

export const { filterMembersByTag, refreshTagToMembers, setUniqueMembers } = engagementMembersSlice.actions
export default engagementMembersSlice.reducer
