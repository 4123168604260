export const ACCESS_CODE_RESENDING_TIMEOUT = 30; // seconds
export const ACCESS_CODE_LENGTH = 8; // characters

export const EXPIRED_PROJECT_STATUS: string = "Expired%20Work%20Orders";
export const ACTIVE_PROJECT_STATUS: string = "Active%20Work%20Orders";

//alternative endpoint constants
export const EXPIRED_PROJECTS: string = "expired";
export const ACTIVE_PROJECTS: string = "active";

export const WORK_ORDERS_PATH: string = "/work-orders";
export const ENGAGEMENTS_PATH: string = "/engagements";
export const RELEASE_NOTES_PATH: string = "/release-notes";
export const NOTIFICATION_PATH: string = "/notification";
export const VIEW_FEEDBACK_PATH: string = "/view-feedback";
export const PEOPLE_FEEDBACK_PATH: string = "/people-feedback";

export const AMDARIS_MEDIA_URLS = {
  twitter: "https://twitter.com/Amdaris",
  facebook: "https://www.facebook.com/thisisamdaris",
  linkedin: "https://www.linkedin.com/company/amdaris-group",
  youtube: "https://www.youtube.com/channel/UCwpLjiweqqE9Ts7vao7VqZg",
};

export const API_BASE_URL = "/api";
export const FILES_BASE_URL = window.location.href.includes("client.amdaris.com")
  ? "https://projectplan.amdaris.com/files"
  : "https://projectplanappweb-stage.azurewebsites.net/files";
