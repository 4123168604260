// external lib dependencies
import { Container, Paper, Box, IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import { useWOPageSkeletonStyles } from "./styles";
import { useWOPageNavStyles } from "./components/styles";

const WOPageSkeleton = () => {
  const classes = useWOPageSkeletonStyles();
  const navClasses = useWOPageNavStyles();
  const { isMD, isXL } = useScreenSize();

  return (
    <Box data-testid="workOrdersSkeleton">
      <Container className={classes.itemsContainer}>
        <Box className={classes.leftSideBox}>
          <Paper elevation={1} className={classes.leftItems}>
            <Box className={classes.skeletonNavBar}>
              <Box className={classes.yearSelectBoxSkeleton} data-testid="WOPageYearSelector">
                <IconButton
                  disabled
                  data-testid="leftArrowButton"
                  className={navClasses.iconButtons}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Skeleton width="45px" height="30px" className={classes.yearsSkeleton} />
                <IconButton
                  disabled
                  data-testid="rightArrowButton"
                  className={navClasses.iconButtons}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>

              </Box>
              <IconButton className={classes.expandIconsSkeleton} data-testid="unfoldButton">
                <UnfoldMoreIcon />
              </IconButton>
            </Box>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
          </Paper>
        </Box>
        {isXL && !isMD && (
          <Box className={classes.rightSideBox}>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
            <Skeleton animation="pulse" width="100%" height="60px"></Skeleton>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default WOPageSkeleton;
