// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  resetButton: {
    marginTop: "6px !important",
    alignItems: "center",
    backgroundColor: "#D9D9D9",
    color: "black",
    "&:hover": {
      backgroundColor: "#363636",
      color: "white",
    },
  },

  checkbox: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: "0",
      marginRight: "0",
      marginBottom: "0",
    },
  },
}));

export default useStyles;
