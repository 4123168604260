// external lib dependencies
import { Box, Container, Link, Grid } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

// absolute path dependencies
import { AMDARIS_MEDIA_URLS } from "config/constants";

// local dependencies
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box px={{ xs: 3, sm: 3 }} py={{ xs: 3, sm: 3 }}>
        <Container maxWidth="lg">
          <Box textAlign="center" pb={{ xs: 3, sm: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Link href={AMDARIS_MEDIA_URLS.twitter} target="_blank" className={classes.icon}>
                  <TwitterIcon />
                </Link>
                <Link href={AMDARIS_MEDIA_URLS.facebook} target="_blank" className={classes.icon}>
                  <FacebookIcon />
                </Link>
                <Link href={AMDARIS_MEDIA_URLS.linkedin} target="_blank" className={classes.icon}>
                  <LinkedInIcon />
                </Link>
                <Link href={AMDARIS_MEDIA_URLS.youtube} target="_blank" className={classes.icon}>
                  <YouTubeIcon />
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box textAlign="center" pb={{ xs: 3, sm: 2 }} className={classes.footerText}>
            Client Portal Cookies | Privacy policy © Amdaris Group LTD {new Date().getFullYear()}. All rights reserved.
          </Box>
          <Box textAlign="center" pb={{ xs: 3, sm: 0 }} className={classes.footerText}>
            <Link href="/submit-bug" className={classes.footerText}>
              Report a bug
            </Link>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
