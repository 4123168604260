import Typography from "@material-ui/core/Typography";
import { useCallback } from "react";
import useStyles from "../components/Row/styles";

export const useAnswerScore = () => {

  const classes = useStyles();

  const chooseAnswerScore = useCallback((answer: string) => {
    if (answer === "Consistently meets or exceeds expectations") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #4dbcb1" }}>
          <Typography style={{ color: "#4dbcb1" }}>5</Typography>
        </div>
      );
    } else if (answer === "Meets expectations almost all the time") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #92d7b8" }}>
          <Typography style={{ color: "#92d7b8" }}>4</Typography>
        </div>
      );
    } else if (answer === "Mostly meets expectations") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #fac063" }}>
          <Typography style={{ color: "#fac063" }}>3</Typography>
        </div>
      );
    } else if (answer === "Falls below expectations") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #fbb07b" }}>
          <Typography style={{ color: "#fbb07b" }}>2</Typography>
        </div>
      );
    } else {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #f77970" }}>
          <Typography style={{ color: "#f77970" }}>1</Typography>
        </div>
      );
    }
  }, [classes.scoreBox]);

  const firstQuestionScore = useCallback((answer: string) => {
    if (answer === "Very satisfied") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #4dbcb1" }}>
          <Typography style={{ color: "#4dbcb1" }}>5</Typography>
        </div>
      );
    } else if (answer === "Satisfied") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #92d7b8" }}>
          <Typography style={{ color: "#92d7b8" }}>4</Typography>
        </div>
      );
    } else if (answer === "Neutral") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #fac063" }}>
          <Typography style={{ color: "#fac063" }}>3</Typography>
        </div>
      );
    } else if (answer === "Unsatisfied") {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #fbb07b" }}>
          <Typography style={{ color: "#fbb07b" }}>2</Typography>
        </div>
      );
    } else {
      return (
        <div className={classes.scoreBox} style={{ border: "2px solid #f77970" }}>
          <Typography style={{ color: "#f77970" }}>1</Typography>
        </div>
      );
    }
  }, [classes.scoreBox])

  return {
    chooseAnswerScore,
    firstQuestionScore
  }
}
