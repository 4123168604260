// external lib dependencies
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Button,
  IconButton,
  TextField,
  Box,
  InputAdornment,
  Checkbox,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import WarningIcon from "@material-ui/icons/Warning";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

// local dependencies
import useStyles from "./styles";
import { useAppDispatch, useAppSelector } from "store/hook";
import { createTagAsync, deleteTagAsync, updateTagsForFiltering } from "store/reducers/tag/tagSlice";
import useEditHeaderTeamTags from "../../hooks/useEditHeaderTeamTags";
import { canEditTeamsAndTags } from "helpers/canEditTeamsAndTags";

interface TagPopoverProps {
  open: any;
  anchorRef: any;
  handleClose: () => void;
  engagement: any;
  setEngagement: any;
  canEdit: boolean;
}

const FilterPopper = (props: TagPopoverProps) => {
  const classes = useStyles();
  const params = useParams<any>();
  const dispatch = useAppDispatch();

  const tags = useAppSelector((state) => state.tags.tags);
  const checkedTags = useAppSelector((state) => state.tags.tagsForFiltering);

  const [inputValue, setInputValue] = useState<string>("");
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [messageInputError, setMessageInputError] = useState<string>("");

  const [openConfirmationDeleteDialog, setOpenConfirmationDeleteDialog] = useState<boolean>(false);
  const [countMembersToDelete, setCountMembersToDelete] = useState<number>(0);
  const [selectedToDelete, setSelectedToDelete] = useState<string>("");
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const { user } = useAppSelector((state) => state.user);

  let searchedTags = tags.filter((v) => v.name.toLowerCase().includes(inputValue.toLowerCase()));

  const { callback: editHeaderTagCallback } = useEditHeaderTeamTags();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (value.length > 10) {
      setIsInputError(true);
      setMessageInputError("The value must be unique and less than 10 characters");
    } else {
      setIsInputError(false);
      setMessageInputError("");
    }

    setInputValue(value);
  };

  const handleCloseConfirmationDeleteDialog = () => {
    setOpenConfirmationDeleteDialog(false);
  };

  const handleDeleteTag = () => {
    dispatch(
      deleteTagAsync({
        project_id: params.id,
        tag_id: selectedToDelete,
      }),
    );

    let newClientPartnerHeaderTags = props.engagement?.header_tags.client_partner.filter((v: any) => {
      return v.id !== selectedToDelete;
    });
    let newPortfolioDirectorTags = props.engagement?.header_tags.portfolio_director.filter((v: any) => {
      return v.id !== selectedToDelete;
    });
    props.setEngagement({
      ...props.engagement,
      header_tags: {
        ...props.engagement.header_tags,
        client_partner: newClientPartnerHeaderTags,
        portfolio_director: newPortfolioDirectorTags,
      },
    });
    let data = {
      client_partner: newClientPartnerHeaderTags ?? [],
      portfolio_director: newPortfolioDirectorTags ?? [],
    };

    editHeaderTagCallback(params.id, data);

    setOpenConfirmationDeleteDialog(false);
    setSelectedToDelete("");
    setCheckedList(checkedList.filter((item) => item != selectedToDelete));
  };

  const handleAddTag = () => {
    setMessageInputError("");
    setIsInputError(false);

    const isExist = tags.some((item) => item.name.toLowerCase() === inputValue.toLowerCase());
    if (!inputValue.length || inputValue.length > 10 || isExist) {
      setMessageInputError("The value must be unique and less than 10 characters");
      setIsInputError(true);
      return;
    }

    const data = {
      project_id: params.id,
      data: {
        name: inputValue,
      },
    };
    dispatch(createTagAsync(data));
    setInputValue("");
  };

  const handleToggleAll = (items: any[]) => {
    if (checkedList.length === items.length) {
      setCheckedList([]);
    } else {
      setCheckedList(tags.map((item) => item.id));
    }
  };

  const handleClickItem = (option: any) => {
    const currentIndex = checkedList.indexOf(option.id);
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked.push(option.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedList(newChecked);
  };

  useEffect(() => {
    setCheckedList(checkedTags);
  }, []);

  useEffect(() => {
    dispatch(updateTagsForFiltering(checkedList));
  }, [checkedList]);

  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorRef}
      transition
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          escapeWithReference: true,
        },
      }}
      placement={"bottom-end"}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={props.handleClose}>
              <Box className={classes.boxWrapper}>
                <Box style={{ display: "flex", alignItems: "flex-start", gap: "1rem", marginBottom: "1rem" }}>
                  <TextField
                    classes={{ root: classes.inputField }}
                    variant="outlined"
                    margin="normal"
                    size="small"
                    placeholder="Search tags"
                    onChange={handleChangeInput}
                    value={inputValue}
                    error={isInputError}
                    helperText={messageInputError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {props.canEdit && (
                    <IconButton onClick={handleAddTag} style={{ height: "40px", width: "40px" }}>
                      <AddIcon />
                    </IconButton>
                  )}
                </Box>
                {tags.length > 2 && searchedTags.length > 0 && (
                  <Box>
                    <MenuItem
                      onClick={() => {
                        handleToggleAll(tags);
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedList.length === tags.length && tags.length !== 0}
                          indeterminate={checkedList.length !== tags.length && checkedList.length !== 0}
                          disabled={tags.length === 0}
                          classes={{ checked: classes.checkboxColor }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  </Box>
                )}

                <MenuList classes={{ root: classes.menuList }}>
                  {!searchedTags.length || !searchedTags.length ? (
                    <Typography variant="body2" align="left">
                      No tags were found.
                    </Typography>
                  ) : (
                    searchedTags.map((option: any) => (
                      <MenuItem
                        key={option.id}
                        value={option.name}
                        classes={{ root: classes.MenuItem }}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          const target = e.target as Element;
                          if (target.closest(".menu-item__delete-button")) return;
                          handleClickItem(option);
                        }}
                      >
                        <Box className={classes.menuItemWrapper}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ListItemIcon>
                              <Checkbox
                                checked={checkedList.includes(option.id)}
                                classes={{ checked: classes.checkboxColor }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                          </Box>
                          {canEditTeamsAndTags(user, parseInt(params.id)) && (
                            <Box className="menu-item__delete-button" sx={{ zIndex: 1000 }}>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    let countClientPartnerTags = 0;
                                    if ("header_tags" in props.engagement) {
                                      for (let v of props.engagement.header_tags.client_partner) {
                                        if (v.id == option.id) {
                                          countClientPartnerTags++;
                                        }
                                      }
                                    }
                                    setCountMembersToDelete(option.member_assignments.length + countClientPartnerTags);
                                    setOpenConfirmationDeleteDialog(true);
                                    setSelectedToDelete(option.id);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </Box>
            </ClickAwayListener>
            <Dialog open={openConfirmationDeleteDialog} onClose={handleCloseConfirmationDeleteDialog} maxWidth="xs">
              <DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <WarningIcon style={{ color: "#ff9966" }} />
                </Box>
                <Typography variant="h6" align="center" style={{ marginTop: "0.5rem" }}>
                  This tag is assigned to {countMembersToDelete} members. <br /> Are you sure you want to delete it?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteTag}>Delete</Button>
                <Button onClick={handleCloseConfirmationDeleteDialog}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default FilterPopper;
