import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Dispatch, FC, SetStateAction } from "react";
import useStyles from "./styles";

interface WarningModalProps {
  open: boolean;
  setOpenWarning: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
  title: string;
  contentText: string;
}

const WarningModal: FC<WarningModalProps> = ({ open, title, contentText, setOpenWarning, handleConfirm }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpenWarning(false);
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Alert severity="warning" className={classes.alert}>
          {title}
        </Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
