import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";

interface MemberAssignment {
    employee_id: string;
    employee_name: string;
}

interface Tag {
    id: string;
    project_id: number;
    name: string;
    member_assignments: MemberAssignment[];
}

interface InitialState {
    tags: Tag[];
    tagsForFiltering: any[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: null | string;
}

const initialState: InitialState = {
    tags: [],
    tagsForFiltering: [],
    status: 'idle',
    error: null,
}

export const fetchTags = createAsyncThunk('tags/fetchTags', async (projectId: any, {rejectWithValue}) => {
    const errorMessage = 'Something happen'
    const request = await fetch(`/api/projects/${projectId}/tags`)

    if (!request.ok) {
        return rejectWithValue(errorMessage)
    }
    const response = await request.json()
    if (request.status !== 200) {
        return rejectWithValue(response.detail ?? errorMessage)
    }

    return response
})

export const createTagAsync = createAsyncThunk(
    'tags/createTagAsync',
    async (data: any, {rejectWithValue, dispatch}) => {
        const request = await fetch(`/api/projects/${data.project_id}/tags`, {
            method: "POST",
            body: JSON.stringify(data.data),
        })

        const response = await request.json();

        if (!request.ok) {
            return rejectWithValue(response.detail)
        }
        dispatch(createTag(response.data))
    }
)

export const deleteTagAsync = createAsyncThunk(
    'tags/deleteTagAsync',
    async (data: any, {rejectWithValue, dispatch}) => {
        const request = await fetch(`/api/projects/${data.project_id}/tags/${data.tag_id}`, {
            method: 'DELETE'
        })

        if (!request.ok) {
            return rejectWithValue('Something happens')
        }

        dispatch(deleteTag(data.tag_id))
    }
)

export const updateTagAsync = createAsyncThunk(
    'tags/updateTagAsync',
    async (data: any, {rejectWithValue, dispatch}) => {
        try {
            fetch(`/api/projects/${data.project_id}/tags/${data.tag_id}`, {
                method: "PATCH",
                body: JSON.stringify(data.body),
            })
    
            dispatch(updateTagToMember(data))
        } catch (error: any) {
            rejectWithValue(error.statusText)
        }
    }
)

const tagSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        createTag(state: any, action: PayloadAction<any>) {
            state.tags.push(action.payload)
        },
        deleteTag(state: any, action: PayloadAction<any>) {
            state.tags = state.tags.filter((item: Tag) => item.id != action.payload)
        },
        updateTagToMember(state: any, action: PayloadAction<any>) {
            state.tags = state.tags.map((v: any) => {
                if (v.id == action.payload.tag_id) {
                    return { ...v, member_assignments: action.payload.body };
                }
                return { ...v };
            })
        },
        updateTagsForFiltering(state: any, action: PayloadAction<any>) {
            state.tagsForFiltering = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTags.pending, (state: any, action: PayloadAction<any>) => {
                state.status = 'loading'
            })
            .addCase(fetchTags.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.tags = action.payload
                state.status = 'succeeded'
            })
            .addCase(fetchTags.rejected, (state: any, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.error = action.payload
            })
    }
})

export const {createTag, deleteTag, updateTagToMember, updateTagsForFiltering} = tagSlice.actions

export const getTagsByMemberId = (state: any, employeeId: number | string) => {
    let result: string[] = []
    state.tags.tags.forEach((item: Tag) => {
        let isFind = false
        for (let member of item.member_assignments) {
            if (member.employee_id == employeeId) {
                isFind = true
                break
            }
        }
        if (isFind) {
            result.push(item.name)
        }
    })

    return result
}

export default tagSlice.reducer
