// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => ({
    loadingCenter: {
      position: "relative",
      top: `calc(50vh - ${theme.spacing(12.5)})`,
      textAlign: "center",
    },

    loadingSpinner: {
      maxWidth: theme.spacing(25),
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  })
);

export default useStyles;
