import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";


export const fetchGroups = createAsyncThunk(
    'group/fetchGroups',
    async (projectId: string, {rejectWithValue}) => {
        const request = await fetch(`/api/projects/${projectId}/group-teams`)

        if (!request.ok) {
            return rejectWithValue('Something happens')
        }

        return request.json()
    }
)

const initialState = {
    groups: [],
    status: 'idle',
    error: null
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        filterGroupsMembersAndAllMembers(state: any, action: PayloadAction<any>) {
            state.groups = state.groups.map((item: any) => {
                let groupMembers = item.member_assignments.filter((member: any) => {
                    return action.payload.some((v: any) => v.employee_id == member.employee_id);
                });
                return {...item, member_assignments: groupMembers};
            });
        },
        addTagsToMemberInGroup(state: any, action: PayloadAction<any>) {
            state.groups = state.groups.map((item: any) => {
                let groupMembers = item.member_assignments.map((member: any) => {
                    let newTags: any = [];

                    for (let tag of action.payload) {
                        for (let tagMember of tag?.member_assignments) {
                            if (tagMember.employee_id == member.employee_id) {
                                newTags.push({
                                    id: tag.id,
                                    name: tag.name,
                                });
                            }
                        }
                    }
                    return {...member, tags: newTags};
                });
                return {...item, member_assignments: groupMembers};
            });
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchGroups.pending, (state: any, action: PayloadAction<any>) => {
                state.status = 'loading'
            })
            .addCase(fetchGroups.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.groups = action.payload
                state.status = 'succeeded'
            })
            .addCase(fetchGroups.rejected, (state: any, action: PayloadAction<any>) => {
                state.status = 'error'
                state.error = action.payload
            })
    }
})

export const {filterGroupsMembersAndAllMembers, addTagsToMemberInGroup} = groupSlice.actions

export default groupSlice.reducer