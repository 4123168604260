// external lib dependencies
import { useState } from "react";

// local dependencies
import AccessCodeForm from "./AccessCodeForm";
import EmailForm from "./EmailForm";

const LoginForm = () => {
  const [email, setEmail] = useState<string>("");

  const handleClick = async () => {
    const request = await fetch("/authorize/oauth2/link");

    if (!request.ok) {
      return;
    }

    const response = await request.json();
    window.location.href = response.auth_uri;
    localStorage.setItem('oauth', String(true));
  };

  return (
    <>
      {email ? (
        <AccessCodeForm email={email} handleClick={handleClick} />
      ) : (
        <EmailForm setEmail={setEmail} handleClick={handleClick} />
      )}
    </>
  );
};

export default LoginForm;
