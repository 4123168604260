import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";

import Button from "components/Button";
import useStyles from "./styles";
import ProvideFeedbackDialog from "./ProvideFeedbackDialog";
import { FeedbackData, UrlParams } from "config/types";
import { useParams } from "react-router-dom";
import useFetchIndividualFeedback from "pages/MemberProfile/hooks/useFetchIndividualFeedback";
import { Pagination } from "@material-ui/lab";

const ProvidedFeedback = () => {
  const classes = useStyles();

  const { id, employee_id } = useParams<UrlParams>();
  const [open, setOpen] = useState<boolean>(false);
  const { fetch, error, data, loading } = useFetchIndividualFeedback();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (!id) {
      return;
    }
    fetch(id, employee_id, page);
  }, [id, employee_id, page]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFeedbackSubmit = () => {
    fetch(id, employee_id, 1);
  };
  const handlePage = (e: any, p: any) => {
    setPage(p);
  };
  return (
    <Grid container direction="row">
      <Grid item xs={11}>
        <Box className={classes.feedbackButtonBox}>
          <Typography variant="h5" component="span" className={classes.feedbackTitle}>
            Provided Feedback
          </Typography>
          <Button size="medium" className={classes.buttonFeedback} onClick={handleClickOpen}>
            Provide Feedback
          </Button>
        </Box>
        <ProvideFeedbackDialog
          open={open}
          setOpen={setOpen}
          handleFeedbackSubmit={handleFeedbackSubmit}
          projectId={id}
          employeeId={employee_id}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell variant="head" width={190} className={classes.headerCell}>
                    Feedback from
                  </TableCell>
                  <TableCell width={230} className={classes.headerCell}>
                    Role
                  </TableCell>
                  <TableCell width={210} className={classes.headerCell}>
                    Date
                  </TableCell>
                  <TableCell className={classes.headerCell}>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.item_list.map((item: FeedbackData, idx: number) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.cellData}>{item.provider_full_name}</TableCell>
                    <TableCell className={classes.cellData}>{item.provider_role}</TableCell>
                    <TableCell className={classes.cellData}>{item.date}</TableCell>
                    <TableCell className={classes.cellData}>{item.feedback}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {error && (
          <Typography variant="subtitle2" component="span">
            {error}
          </Typography>
        )}
        <Pagination count={data?.total_pages} className={classes.pagination} onChange={handlePage} />
      </Grid>
    </Grid>
  );
};

export default ProvidedFeedback;
