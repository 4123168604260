// external lib dependencies
import { FC, useEffect, useRef } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { TextField, Container, FormLabel } from "@material-ui/core";

// local dependencies
import useStyles from "../styles";

interface Props {
  key?: number;
  placeholder?: string;
  required?: boolean;
  question: string;
  label: string;
  multiline?: boolean;
  maxRows?: number;
  rows?: number;
  rules?: object;
}

const InputTextField: FC<Props> = ({
  key,
  placeholder,
  required,
  question,
  label,
  multiline = false,
  maxRows,
  rows,
  rules,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { register } = useForm();

  let textInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (`${label}` in errors && textInputRef.current) {
      register(textInputRef.current);
      textInputRef.current.focus();
    }
  }, [errors, label, register]);

  return (
    <Container key={key} className={classes.wrapper} data-testid="ITFContainer">
      <FormLabel error={Boolean(errors[`${label}`])} className={classes.inputLabelFont} data-testid="ITFLabel">
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      <Controller
        as={
          <TextField
            inputRef={(e) => {
              register(e);
              textInputRef.current = e;
            }}
            name={`${label}`}
            error={Boolean(errors[`${label}`])}
            helperText={errors[`${label}`] ? errors[`${label}`]?.message : ""}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            autoComplete="off"
            data-testid="ITFTextField"
          />
        }
        name={`${label}`}
        defaultValue=""
        control={control}
        rules={{
          required: `This field is required`,
        }}
      />
    </Container>
  );
};

export default InputTextField;
