import React, { useEffect, useState } from "react";
import SectionTitleBar from "components/SectionTitleBar";
import { Checkbox, Container, FormControl, FormControlLabel, FormGroup, Typography } from "@material-ui/core";
import useStyles from "./styles";

import useFetchData from "./useFetchData";
import Skeleton from "@material-ui/lab/Skeleton";
import useUpdateData from "./useUpdateData";

interface NotificationsType {
  work_order_renewal: boolean;
  file_sharing: boolean;
  audit_creation: boolean;
}

const Notification = () => {
  const classes = useStyles();

  const { callback: fetchData, loading, data /*error*/ } = useFetchData();
  const { callback: updateData } = useUpdateData();

  const [notificationsType, setNotificationsType] = useState<NotificationsType | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (notificationsType) {
      const tmpNotificationsType = { ...notificationsType, [event.target.name]: event.target.checked };
      setNotificationsType(tmpNotificationsType);
      const data = {
        type: event.target.name,
        is_notify: event.target.checked,
      };
      updateData(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      setNotificationsType(data);
    }
  }, [data]);

  return (
    <main>
      <SectionTitleBar title={"Subscribe to Notifications"} />
      <Container maxWidth="xl">
        <Typography variant="body1" className={classes.typographyText}>
          By subscribing to the notifications you will receive an email for each activity.
        </Typography>
        <Typography variant="body1">By unticking the checkbox you can unsubscribe from the notifications.</Typography>

        <FormControl component="fieldset" className={classes.formControl}>
          {!loading ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ root: classes.rootCheckbox }}
                    checked={notificationsType ? notificationsType["work_order_renewal"] : false}
                    onChange={handleChange}
                    name="work_order_renewal"
                    color="default"
                  />
                }
                label="Notification to work order renewal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ root: classes.rootCheckbox }}
                    checked={notificationsType ? notificationsType["file_sharing"] : false}
                    onChange={handleChange}
                    name="file_sharing"
                    color="default"
                  />
                }
                label="Notification to file sharing"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{ root: classes.rootCheckbox }}
                    checked={notificationsType ? notificationsType["audit_creation"] : false}
                    onChange={handleChange}
                    name="audit_creation"
                    color="default"
                  />
                }
                label="Notification to new audit"
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Skeleton width="300px" />
              <Skeleton width="300px" />
              <Skeleton width="300px" />
            </FormGroup>
          )}
        </FormControl>
      </Container>
    </main>
  );
};

export default Notification;
