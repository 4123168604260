// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => ({
    timerMsg: {
      marginBottom: 0,
      marginTop: theme.spacing(1),
      color: theme.palette.common.white,
      width: theme.spacing(25),
      textAlign: "center",
    },
  })
);

export default useStyles;
