// external lib dependencies
import { useCallback } from "react";
// absolute path dependencies
// local dependencies

const useTitleTrim = () => {
  const getInitials = useCallback((title: string | undefined, length: number): string | undefined => {
    if (title) {
      const initials = title.split(" ").reduce((acc, curr) => {
        acc = acc + curr.slice(0, 1);
        return acc;
      }, "");
      return initials.toUpperCase().substring(0, length);
    }
  }, []);

  const trimSuspension = useCallback((title: string | null, length: number): string | null => {
    if (title && title.length >= length) {
      const suspension = title.substring(0, length) + "...";
      return suspension;
    } else return title;
  }, []);

  return { getInitials, trimSuspension };
};

export default useTitleTrim;
