import { Badge, Box, Fade, List, ListItem, Tooltip } from "@material-ui/core";
import { useLeftDrawerStyles } from "./styles";
import { ReactComponent as Aletter } from "../../assets/icons/leftDrawerIcons/A.svg";
import { ReactComponent as AmdarisLogo } from "../../assets/icons/leftDrawerIcons/AMDARIS.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/leftDrawerIcons/chevron-right.svg";
import { ReactComponent as ChevronLeftBlack } from "../../assets/icons/leftDrawerIcons/chevron-right-black.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { ENGAGEMENTS_PATH, RELEASE_NOTES_PATH } from "config/constants";
import { useAppDispatch, useAppSelector } from "store/hook";
import { viewNotes } from "store/reducers/releaseNotes/releaseNoteSlice";
import NavListItem from "./NavListItem";
import navListData from "./NavList";

export default function LeftDrawer() {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useLeftDrawerStyles();
  const { pathname } = useLocation();
  const { noteAdded } = useAppSelector((state) => state.releaseNotes);
  const dispatch = useAppDispatch();

  const handleViewNotes = () => {
    dispatch(viewNotes());
  };

  const handleDrawerToggle = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleDrawerClose = (event: any) => {
    /*
    event.preventDefault();
    setOpen(false);
    */
  };

  return (
    <Box sx={{ width: open ? "232px" : "72px" }} className={classes.mainBlockDrawer} onBlur={handleDrawerClose}>
      <NavLink to={ENGAGEMENTS_PATH}>
        <Box className={classes.drawerLogoBlock}>
          <Box className={classes.drawerLogoMain}>{open ? <AmdarisLogo /> : <Aletter />}</Box>
        </Box>
      </NavLink>
      <List className={classes.navList}>
        {navListData.map((navItem, index) => {
          if (navItem.path === RELEASE_NOTES_PATH) {
            return (
              <ListItem key={navItem.path} className={classes.listItem}>
                <NavLink to={navItem.path} activeClassName={classes.activeListItemIcon}>
                  {noteAdded ? (
                    <Box className={classes.listItemIcon} onClick={handleViewNotes}>
                      <Badge color="error" variant="dot" overlap="circular" className={classes.notification}>
                        {pathname.includes(navItem.path) ? navItem.blackIcon : navItem.whiteIcon}
                      </Badge>
                    </Box>
                  ) : (
                    <Tooltip title={open ? "" : navItem.label} placement="right" arrow>
                      <Box className={classes.listItemIcon} onClick={handleViewNotes}>
                        {pathname.includes(navItem.path) ? navItem.blackIcon : navItem.whiteIcon}
                      </Box>
                    </Tooltip>
                  )}
                </NavLink>
                <NavLink to={RELEASE_NOTES_PATH} className={classes.NavLinknoUnderline}>
                  <Fade in={open} timeout={{ enter: 300, exit: 10 }}>
                    <Box
                      className={classes.listItemText}
                      onClick={handleViewNotes}
                      style={{ display: open ? "block" : "none" }}
                    >
                      {navItem.label}
                    </Box>
                  </Fade>
                </NavLink>
              </ListItem>
            );
          }
          return (
            <NavListItem
              key={navItem.path}
              label={navItem.label}
              path={navItem.path}
              blackIcon={navItem.blackIcon}
              whiteIcon={navItem.whiteIcon}
              open={open}
            />
          );
        })}
        <ListItem className={classes.listItem}>
          <Box
            tabIndex={0}
            onClick={handleDrawerToggle}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleDrawerToggle(event);
              }
            }}
            className={classes.buttonToggle + " " + classes.listItemIcon}
            style={{ backgroundColor: open ? "#FAFAFA" : "", borderRadius: open ? "5px 5px 5px 5px " : "" }}
          >
            {open ? <ChevronLeftBlack style={{ transform: "matrix(-1, 0, 0, 1, 0, 0)" }} /> : <ChevronRight />}
          </Box>
        </ListItem>
      </List>
    </Box>
  );
}
