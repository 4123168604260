import { jsPDF } from "jspdf";
import { elementPosition } from "./utils/configurations/element-position";
import { imageDimension } from "./utils/configurations/image-dimension";
import lastPageBackground from "../../assets/images/survey-summary-pdf/last-page-background.png";
import exceptionalSoftwareTeams from "../../assets/images/survey-summary-pdf/exceptional-software-teams.png";
import lastPageAddress from "../../assets/images/survey-summary-pdf/last-page-address.png";

export default function generateFinalPage(doc: jsPDF) {
  doc.addPage();

  doc.addImage(
    lastPageBackground,
    "png",
    elementPosition.lastPageBackground.getX(),
    elementPosition.lastPageBackground.getY(),
    imageDimension.fullPageImagePortrait.getWidth(),
    imageDimension.fullPageImagePortrait.getHeight()
  );

  // Exceptional. Software. Teams. image
  doc.addImage(
    exceptionalSoftwareTeams,
    "png",
    elementPosition.exceptionalSoftwareTeams.getX(),
    elementPosition.exceptionalSoftwareTeams.getY(),
    imageDimension.exceptionalSoftwareTeams.getWidth(),
    imageDimension.exceptionalSoftwareTeams.getHeight()
  );

  // Address image

  doc.addImage(
    lastPageAddress,
    "png",
    elementPosition.lastPageAddress.getX(),
    elementPosition.lastPageAddress.getY(),
    imageDimension.lastPageAddress.getWidth(),
    imageDimension.lastPageAddress.getHeight()
  );
}
