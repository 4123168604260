import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { FeedbackFormInput } from 'config/types';
import { useSubmitHandler } from 'pages/MemberProfile/hooks/useSubmitHandler';
import useStyles from "./styles";
import WarningDialog from './WarningDialog';

interface FeedbackDialogProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    handleFeedbackSubmit?: () => void;
    projectId: string;
    employeeId: string;
}

const ProvideFeedbackDialog: FC<FeedbackDialogProps> = ({
    open,
    setOpen,
    handleFeedbackSubmit,
    projectId,
    employeeId
}) => {

    const [openWarning, setOpenWarning] = useState<boolean>(false);

    const classes = useStyles();

    const { control, handleSubmit, getValues, formState: { errors } } = useForm<FeedbackFormInput>();
    const { submitFeedback, error } = useSubmitHandler();

    const onSubmit = async (text: FeedbackFormInput) => {
        const feedbackInfo = await submitFeedback(text, projectId, employeeId);

        if (feedbackInfo) {
            handleFeedbackSubmit?.();
            setOpen(false);
        }
    }

    const handleClose = () => {
        const data = getValues("data");

        if (!data.trim().length) {
            setOpen(false);
        } else {
            setOpenWarning(true);
        }
    }

    const handleConfirm = () => {
        setOpenWarning(false);
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth className={classes.paper}>
            <DialogTitle id="form-dialog-title">
                <Typography className={classes.dialogTitle}>Provide feedback</Typography>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} >
                <DialogContent>
                    <Controller
                        control={control}
                        name="data"
                        rules={{
                            required: {
                                value: true,
                                message: "Feedback field is required"
                            },
                            maxLength: {
                                value: 1500,
                                message: "Maximum allowed characters - 1500"
                            },
                            validate: value => !!value.trim() || "Adding only whitespaces is not allowed"

                        }}
                        defaultValue=""
                        render={({ value, onChange, onBlur, name }) => (
                            <TextField
                                {...{ value, onChange, onBlur, name }}
                                error={!!errors.data}
                                helperText={errors.data ? errors?.data.message : error ? error : ""}
                                autoFocus
                                margin='dense'
                                variant='outlined'
                                id='feedback'
                                type="text"
                                multiline
                                minRows={5}
                                fullWidth
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant='contained' color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button type="submit" variant='contained' color="primary">Send</Button>
                </DialogActions>
            </form>
            <WarningDialog
                open={openWarning}
                setOpenWarning={setOpenWarning}
                handleConfirm={handleConfirm}
                contentText={"Are you sure you want to cancel? Your feedback won't be saved"}
            />
        </Dialog>
    )
}

export default ProvideFeedbackDialog;
