// absolute path dependencies
import { API_BASE_URL } from "config/constants";

//local dependencies
import { FetchData } from "./FetchData";

// Upload all files to backend
export async function sendFiles(payload: FormData, engagementId: number, category: string, expireOn: number | null) {
  return await new FetchData(
    `/sharing-area/files?project_id=${engagementId}&category=${category}&expire_on=${expireOn}`
  ).POST(undefined, undefined, {
    method: "POST",
    body: payload,
    credentials: "include",
  });
}

export async function getFilesByEngagementId(engagementId: number) {
  return await new FetchData(`/sharing-area/files?project_id=${engagementId}`).GET();
}

export async function getFileByFileId(payload: object, file_id: string) {
  return await new FetchData(`/sharing-area/files/${file_id}`).POST(undefined, undefined, {
    method: "POST",
    body: JSON.stringify(payload),
  });
}

export async function deleteFile(file_id: string, optional_note: string) {
  return await fetch(`${API_BASE_URL}/sharing-area/files/${file_id}?optional_note=${optional_note}`, { method: "DELETE" });
}

export async function changeFileCategory(file_id: string, category: string) {
  return await fetch(`${API_BASE_URL}/sharing-area/update-file/${file_id}?category=${category}`, { method: "PATCH" });
}

export async function unarchiveFile(file_id: string) {
  return await fetch(`${API_BASE_URL}/sharing-area/unarchive-file/${file_id}`, { method: "PATCH" });
}

