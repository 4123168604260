// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      "& .MuiPaper-root": {
        width: "100%",
        maxWidth: "480px",
      },
      "& .MuiDialogContent-root": {
        padding: "0px 24px 0px 24px",
      },
      "& .MuiDialogTitle-root": {
        padding: "24px 24px 10px 24px",
      },
    },
    table: {
      minWidth: 650,
      "& .MuiTableCell-head": {
        fontWeight: 700,
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
        paddingLeft: "unset",
      },
    },
    buttonFeedback: {
      backgroundColor: `rgba(0, 0, 0, 0.79)`,
      color: "white",
      width: "18.75rem",
      height: "2.5rem",

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "14rem",
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        width: "9rem",
        height: "2.75rem",
      },

      "&:hover": {
        backgroundColor: `rgba(0, 0, 0, 0.79)`,
      },
    },
    feedbackButtonBox: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    feedbackSection: {
      marginTop: "3.5rem",
      height: "17.87rem",
    },
    feedbackTitle: {
      [theme.breakpoints.down("sm")]: {},
    },
    dialogTitle: {
      width: "155px",
      height: "24px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      color: "#333333",
      lineHeight: "24px",
      flex: "none",
      order: 0,
      flexGrow: 0,
    },
    dialogActions: {
      display: "flex",
      justifyContent: "end",
      alignItems: "end",
      padding: "15px 23px 23px 15px",
    },
    dialogCancel: {
      width: "7.875rem",
      height: "1.99rem",
      backgroundColor: `rgba(0, 0, 0, 0.22)`,
      fontWeight: 800,
      fontSize: "15px",
      lineHeight: "18px",
      padding: "1.2rem",
      textTransform: "capitalize",
      marginLeft: "1rem",

      "&:hover": {
        backgroundColor: `rgba(0, 0, 0, 0.22)`,
      },
    },
    dialogSend: {
      width: "7.975rem",
      height: "1.99rem",
      backgroundColor: `rgba(0, 0, 0, 0.79)`,
      color: "white",
      fontWeight: 800,
      fontSize: "15px",
      lineHeight: "18px",
      padding: "1.2rem",
      textTransform: "capitalize",
      marginRight: "1rem",

      "&:hover": {
        backgroundColor: `rgba(0, 0, 0, 0.79)`,
      },
    },
    dialogContent: {
      color: "black",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
    form: {
      width: "420px",
    },
    headerCell: {
      fontSize: "1rem",
      color: "black",
      fontWeight: 700,
      lineHeight: "",
    },
    cellData: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "19px",
      whiteSpace: "normal",
      wordWrap: "break-word",
      maxWidth: 5,
    },
    textField: {
      padding: 0,
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
  }),
);

export default useStyles;
