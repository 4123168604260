// external lib dependencies
import { GroupTeams } from "config/types";
import { useState, useCallback } from "react";

// absolute path dependencies
import { getGroupTeamsByEngagementId } from "services/groupTeam";

const useFetchGroupTeams = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<GroupTeams[]>();

  const callback = useCallback(async (projectId: string) => {
    setLoading(true);

    const errorMessage = "Error!";
    try {
      const fetch = await getGroupTeamsByEngagementId(projectId);
      const response = await fetch.json();

      setData(response);

      if (fetch.status !== 200) {
        setError(response.detail ?? errorMessage);
      }
    } catch (err) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { callback, loading, error, data };
};

export default useFetchGroupTeams;
