// external lib dependencies
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { FC } from "react";
import { useParams } from "react-router-dom";

// absolute path dependencies
import { useFilesContext } from "providers/FilesContext";
import { unarchiveFile } from "services";

interface Props {
  open: boolean;
  onClose: () => void;
  fileID: string;
}

const UnarchiveFileDialog: FC<Props> = ({ open, onClose, fileID }) => {
  const { id } = useParams<{ id?: string }>();
  const { fetchFiles } = useFilesContext();

  const handleUnarchive = () => {
    unarchiveFile(fileID).then(() => {
      fetchFiles(Number(id));
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to unarchive this file?</DialogTitle>

      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
        <Button variant="contained" color="primary" onClick={handleUnarchive} autoFocus>Unarchive</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnarchiveFileDialog;
