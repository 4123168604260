// external lib dependencies
import { FormEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import useStyles from "../styles";

// local dependencies
import useDeleteHandler from "./useDeleteHandler";
import { TeamViews } from "pages/Team/types";
import {Button} from "@material-ui/core";

interface DeleteGroupPopupInterface {
  open: boolean;
  handleClose: any;
  groupTeams: Array<any>;
  setGroupTeams: any;
  nameGroupTeam: string;
  groupId: string;
  setTeamView: any,
}

const DeleteGroupPopup = (props: DeleteGroupPopupInterface) => {
  const generalClasses = useStyles();
  const { open, handleClose, groupTeams, setGroupTeams, nameGroupTeam, groupId, setTeamView } = props;
  const { callback, loading, error, isSuccess } = useDeleteHandler();

  const { id: projectId } = useParams<{ id?: string | undefined }>();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    callback(projectId, groupId);
    localStorage.removeItem(groupId);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      handleDelete(groupId);
      setTeamView(TeamViews.Ungrouped)
    }
  }, [isSuccess, error]);

  const handleDelete = (groupId: string) => {
    setGroupTeams(groupTeams.filter((item) => item.id !== groupId));
  };

  return (
    <Dialog className={generalClasses.dialogModal} open={open} onClose={handleClose}>
        <DialogTitle className={generalClasses.dialogTitle} id="form-dialog-title">Delete Team</DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <DialogContent className={generalClasses.dialogContent}>
            <DialogContentText className={generalClasses.dialogContentText}>Are you sure you want to delete "{nameGroupTeam}"?</DialogContentText>
          </DialogContent>
          <DialogActions className={generalClasses.dialogActionBlock}>
            <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit" disabled={loading}>Delete</Button>
          </DialogActions>
        </form>
    </Dialog>
  );
};

export default DeleteGroupPopup;
