import { User, UserAccessLevels, UserLoginMethod } from "config/types";

export function checkUserAccessLevel(user: User | null, ...expectedAccessLevels: UserAccessLevels[]): boolean {
  if (!user) {
    return false;
  }

  if (user.login_method !== UserLoginMethod.external) {
    return false;
  }

  return expectedAccessLevels.some((lvl) => user.access_level === lvl);
}

export function checkUserAccess(
  user: User | null,
  user_has_extra_access: boolean,
  ...expectedAccessLevels: UserAccessLevels[]
): boolean {
  if (!user) return false;

  if (user.login_method === UserLoginMethod.external) {
    return expectedAccessLevels.some((lvl) => user.access_level === lvl);
  }

  if (user.login_method === UserLoginMethod.internal) {
    return user_has_extra_access;
  }

  return false;
}
