import {useDispatch, useSelector, TypedUseSelectorHook} from "react-redux";
import type {RootState, AppDispatch} from "./index";
import { bindActionCreators } from "redux";
import ActionCreators from './actions/index'

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useActions = () =>{
    const dispatch = useAppDispatch()
    return bindActionCreators(ActionCreators, dispatch)
}
