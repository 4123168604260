export const COLORS = [
  "#75d98a",
  "#66cfff",
  "#9873b9",
  "#ffb480",
  "#a1dd70",
  "#ffff9d",
  "#714288",
  "#99dfff",
  "#ccefff",
  "#ffcccc",
  "#d52484",
  "#f18a9b",
  "#5AE9C8",
  "#0ea5c6",
  "#26baee",
  "#5bf7f1",
  "#f5eee6",
  "#005792",
  "#32bfff",
  "#f3d7ca",
  "#a26ea1",
  "#e5f7ff",
  "#e6a4b4",
  "#c86b85",
  "#e0ffcd",
];
