import {
  Box,
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useState } from "react";

import useScreenSize from "hooks/useScreenSize";
import useStyles from "./styles";
import { FilterBy } from "../../CustomTable";

interface Props {
  open: boolean;
  anchorRef: any;
  handleClose: () => void;
  dataForFilterMenu: FilteredItems;
  applyFilters: () => void;
  filterBy: FilterBy | null;
  setFilterColumns: React.Dispatch<React.SetStateAction<FilteredItems>>;
  resetAllFilters: () => void;
}

type Item = {
  name: string;
  checked: boolean;
};

export interface FilteredItems {
  names: Item[];
  projects: Item[];
  companies: Item[];
}

const FilterBox = (props: Props) => {
  const { open, anchorRef, filterBy, handleClose, dataForFilterMenu, applyFilters, setFilterColumns, resetAllFilters } =
    props;

  const classes = useStyles();

  const [elementWidth, setElementWidth] = useState<number>(0);

  const { isXS } = useScreenSize();

  const handleCheckboxChange = (option: Item, type: FilterBy) => {
    const newFilterColumns = { ...dataForFilterMenu };
    newFilterColumns[type] = newFilterColumns[type].map((item) =>
      item.name === option.name ? { ...item, checked: !item.checked } : item,
    );
    setFilterColumns(newFilterColumns);
  };

  const getAllChecked = () => {
    if (!filterBy) return false;
    return dataForFilterMenu[filterBy].every((item) => item.checked);
  };

  const handleToggleAll = () => {
    const newFilterColumns = { ...dataForFilterMenu };
    newFilterColumns[filterBy!] = newFilterColumns[filterBy!].map((item) => ({
      ...item,
      checked: !getAllChecked(),
    }));
    setFilterColumns(newFilterColumns);
  };

  const handleClearFilter = () => {
    const newFilterColumns = { ...dataForFilterMenu };
    newFilterColumns[filterBy!] = newFilterColumns[filterBy!].map((item) => ({
      ...item,
      checked: false,
    }));
    setFilterColumns(newFilterColumns);
    resetAllFilters();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorRef}
      onClose={handleClose}
      className={classes.filterPopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuList className={classes.menuList}>
        <MenuItem onClick={handleToggleAll} dense>
          <ListItemIcon>
            <Checkbox color="default" checked={getAllChecked()} />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </MenuItem>
        {filterBy &&
          dataForFilterMenu[filterBy].map((option, index) => (
            <MenuItem key={index} value={option.name} onClick={() => handleCheckboxChange(option, filterBy)} dense>
              <Box className={classes.menuItemWrapper}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon>
                    <Checkbox checked={option.checked} color="default" />
                  </ListItemIcon>
                  <Tooltip title={elementWidth > 209 && !isXS ? option.name : ""}>
                    <Typography
                      variant="body2"
                      onMouseEnter={(e) => setElementWidth(e.currentTarget.offsetWidth)}
                      className={classes.listItemText}
                    >
                      {option.name}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </MenuItem>
          ))}
      </MenuList>
      <Box className={classes.filterButtonsBox}>
        <Button
          variant={"contained"}
          onClick={applyFilters}
          className={classes.filterButton}
          disableElevation
          style={{ backgroundColor: "#e0e0e0", color: "#000" }}
        >
          Apply filter
        </Button>
        <Button
          variant={"outlined"}
          onClick={handleClearFilter}
          className={classes.filterButton}
          disableElevation
          style={{ backgroundColor: "#363636", color: "#fff" }}
        >
          Clear filter
        </Button>
      </Box>
    </Popover>
  );
};

export default FilterBox;
