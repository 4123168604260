// external lib dependencies
import { Dispatch, SetStateAction } from "react";
import { Box, IconButton, Container, Typography } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";

// local dependencies
import { useWOPageNavStyles } from "./styles";

interface WOPageNavbarProps {
  unfoldAll: boolean;
  setUnfoldAll: Dispatch<SetStateAction<boolean>>;
  selectedYear: number;
  setSelectedYear: Dispatch<SetStateAction<number>>;
  yearSpan: number[];
}

const WOPageNavbar = ({ unfoldAll, setUnfoldAll, selectedYear, setSelectedYear, yearSpan }: WOPageNavbarProps) => {
  const classes = useWOPageNavStyles();

  return (
    <Container className={classes.WOPageNavbar} data-testid="WOPageNavbar">
      <Box className={classes.navOptionsBox}>
        <Box className={classes.yearSelectBox} data-testid="WOPageYearSelector">
          <IconButton
            disabled={selectedYear === yearSpan[0]}
            onClick={() => setSelectedYear((prev) => prev - 1)}
            data-testid="leftArrowButton"
            className={classes.iconButtons}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="h5" data-testid="WOPageSelectedYear">
            {selectedYear}
          </Typography>
          <IconButton
            disabled={selectedYear === yearSpan[yearSpan.length - 1]}
            onClick={() => setSelectedYear((prev) => prev + 1)}
            data-testid="rightArrowButton"
            className={classes.iconButtons}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
        <IconButton onClick={() => setUnfoldAll((prev) => !prev)} data-testid="unfoldButton">
          {unfoldAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </IconButton>
      </Box>
    </Container>
  );
};

export default WOPageNavbar;
