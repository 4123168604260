import { jsPDF } from "jspdf";
import generateFirstPage from "./generate-first-page";
import generateAmdarisMeta from "./generate-amdaris-meta";
import generateConfidentialityPage from "./generate-confidentiality-page";
import generateFinalPage from "./generate-final-page";
import generateChartPage from "./generate-chart-page";
import generateSectionsPage from "./generate-sections-page";
import { Survey } from "../../config/types";
import generateContentsPage from "./generate-contents-page";
import { ContentItem } from "./types";
import { getMonthYear } from "./utils/time-utils";

// The original version of generating a report in pdf:
// https://amdaris.visualstudio.com/ProjectPlan/_git/ProjectPlan?version=GBmaster&path=%2Fsrc%2FProjectPlan.Angular%2Fsrc%2Fapp%2Fservices%2Ffile-generators%2Faudit-survey-pages

export default function generateAndSaveFile(survey: Survey, dataScore: any[]) {
  const doc = new jsPDF();
  let contents: ContentItem[] = [];

  generateFirstPage(doc, survey);

  contents = contents.concat(generateChartPage(doc, survey, dataScore));
  contents = contents.concat(generateSectionsPage(doc, survey));
  contents = contents.concat(generateConfidentialityPage(doc));

  generateFinalPage(doc);
  generateContentsPage(doc, contents);

  for (let i = 2; i < doc.getNumberOfPages(); i++) {
    doc.setPage(i);
    generateAmdarisMeta(doc, i);
  }

  const fileName = `${survey.entityName}_${getMonthYear(survey.createdAt)}_Report.pdf`;

  doc.save(fileName);
}
