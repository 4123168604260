// external lib dependencies
import { useEffect } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import { Container, Typography, Slide, Box } from "@material-ui/core";

// absolute path dependencies
import { useEngagementData } from "providers/EngagementDataContext";

// local dependencies
import TeamSection from "./TeamSection";
import useStyles from "./styles";
import TopHeader from "./components/MessageForm/TopHeader";

interface Params {
  id?: string;
}

export default function Team() {
  const classes = useStyles();
  const { engagement, initEngagement, engagementLoadingError, loadingEngagement } = useEngagementData();
  const { id } = useParams<Params>();

  useEffect(() => {
    if (!id) return;

    initEngagement(Number(id));
  }, [id, initEngagement]);

  if (!id) return <Redirect to="/engagements" />;

  if (!engagementLoadingError) {
    return (
      <div className={classes.engagement}>
        <TopHeader loadingEngagement={loadingEngagement} engagementName={engagement.title}></TopHeader>
        <Container className={classes.container} maxWidth="xl">
          <Slide in direction="right">
            <Box>
              <TeamSection engagementTitle={engagement.title} />
            </Box>
          </Slide>
        </Container>
      </div>
    );
  } else {
    return (
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h5">Sorry, you cannot access this engagement.</Typography>
        <Typography variant="h5">
          Please, contact Amdaris IT team at <Link to="mailto:client@amdaris.com">client@amdaris.com</Link> about
          getting access to the engagement.
        </Typography>
      </Container>
    );
  }
}
