// external lib dependencies
import { Button, Dialog, DialogContent, Grid, DialogTitle } from "@material-ui/core";
import useScreenSize from "hooks/useScreenSize";
import { FC, useRef, useState } from "react";
import { useStyles } from "./styles";

// icons
import { RemoveRedEyeRounded } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSubmitPassword: (password: string) => void;
  errorMessage: string;
}

const EnterPassword: FC<Props> = ({ open, handleClose, handleSubmitPassword, errorMessage }) => {
  const { isXS } = useScreenSize();
  const classes = useStyles();

  const [inputType, setInputType] = useState("password");
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const password = passwordRef.current?.value;
    if(password){
      handleSubmitPassword(password)
    }
    else handleSubmitPassword('')
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Enter password to download file.</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <label className={classes.passwordFormLabels} htmlFor="password">
              Enter Password
            </label>
            <div className={classes.buttonsContainer}>
              <input
                className={classes.fieldInput}
                type={inputType}
                name="password"
                ref={passwordRef}
              />
              {inputType === "password" ? (
                <span className={classes.eyeIcon} onClick={() => setInputType("text")}>
                  <RemoveRedEyeRounded />
                </span>
              ) : (
                <span className={classes.eyeIcon} onClick={() => setInputType("password")}>
                  <VisibilityOff />
                </span>
              )}
            </div>
          </Grid>
          <Grid>
            {errorMessage && (<span className={classes.mediumTextSpan}>{errorMessage}</span>)}
          </Grid>
          <Grid item className={isXS ? classes.fieldContainerXS : classes.fieldContainer}>
            <div className={classes.buttonsContainer}>
              <Button className={classes.sensitiveDataButtonSubmit} type="submit">
                Download file
              </Button>
              <Button className={classes.sensitiveDataButtonClose} onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EnterPassword;
