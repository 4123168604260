import { Theme, createStyles, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelFont: {
      alignItems: "center",
      display: "flex",
      fontWeight: 500,
      fontSize: "16px",
      marginLeft: "6px",
      color: "black",
      "& div > svg": {
        marginLeft: "5px",
      },
    },
  }),
);

export default useStyles;
