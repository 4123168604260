//external lib dependencies
import { useMemo, useState, useEffect, useRef } from "react";
import { Box, Paper, Typography, Grid, Fade, Button, IconButton, Chip, withStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

//absolute path dependencies
import { useEngagementData } from "providers/EngagementDataContext";

//local dependencies
import TeamSectionSkeleton from "./TeamSectionSkeleton";
import useStyles from "./styles";
import GroupView from "./components/GroupVIew/GroupView";
import EveryoneView from "./components/EveryoneView/EveryoneView";
import FilterPopper from "./components/FilterBox/FilterPopper";
import HeaderTeam from "./components/HeaderTeam/HeaderTeam";
import { useActions, useAppSelector } from "store/hook";
import TeamSideBar from "./components/TeamSideBar/TeamSideBar";
import { TeamViews } from "./types";
import {
  addTagsToMember,
  addMembersToGroup,
  findUngroupedMembers,
  filterMembersByTag,
  getUniqueMemberList,
  addTagsToMemberInGroup,
} from "helpers/teamSectionHelpers/teamSectionHelpers";
import useFetchGroupTeams from "./components/GroupVIew/useFetchGroupTeams";
import { canEditTeamsAndTags } from "helpers/canEditTeamsAndTags";
import { GroupTeamWithTags } from "config/types";
import { TeamFeedbackModal } from "./components/TeamFeedbackModal/TeamFeedbackModal";
import { ISelectOptions } from "components/SelectField/types";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";

interface Params {
  id: string;
}

interface TeamSectionProps {
  engagementTitle: string;
}

const TeamFeedbackChip = withStyles({
  root: {
    marginRight: "20px",
    background: "#FF0070",
    padding: "5px 10px",
    height: "29px",
  },
  clickableColorSecondary: {
    "&:hover": {
      backgroundColor: "#FF0070",
    },
    "&:focus": {
      backgroundColor: "#FF0070",
    },
  },
  label: {
    paddingLeft: "4px",
    paddingRight: "initial",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "initial",
  },
  icon: {
    paddingLeft: "initial",
    marginLeft: "initial",
    marginRight: "initial",
    width: "16px",
    height: "16px",
  },
})(Chip);

export default function TeamSection({ engagementTitle }: TeamSectionProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { id } = useParams<Params>();
  const { fetchTags } = useActions();
  const { user } = useAppSelector((state) => state.user);
  const isTagDialogOpen = Boolean(anchorEl);
  const [ungroupedTeams, setUngroupedTeams] = useState<any>([]);
  const [groupTeams, setGroupTeams] = useState<GroupTeamWithTags[]>([]);
  const { tags, tagsForFiltering } = useAppSelector((state) => state.tags);
  const [teamView, setTeamView] = useState<string>("Oversight and Escalation");
  const { members, loadingMembers: loading, engagement, setEngagement } = useEngagementData();
  const { callback: fetchGroupTeams, loading: loadingGroupTeams, data: dataGroupTeams } = useFetchGroupTeams();
  const [openFeedback, setOpenFeedback] = useState<boolean>(false);

  const canEdit = canEditTeamsAndTags(user, parseInt(id));

  //fetching data from server about groups and tags
  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction) {
      fetchGroupTeams(id);
      fetchTags(id);
    }
    return () => {
      cleanupFunction = true;
      setUngroupedTeams([]);
      setGroupTeams([]);
    };
  }, [id]);

  //calculating unique members from members array
  const uniqueMembers = useMemo(() => {
    return getUniqueMemberList(members);
  }, [members]);

  //adding tags to unique members by tag id
  const uniqueMembersWithTags = useMemo(() => {
    return addTagsToMember(uniqueMembers, tags);
  }, [tags, uniqueMembers]);

  //filtering unique members by checked tags
  const filteredUniqueMembersWithTags = useMemo(() => {
    return filterMembersByTag(uniqueMembersWithTags, tagsForFiltering);
  }, [tagsForFiltering, uniqueMembersWithTags]);

  //first initiation grouped members, adding unique members
  const isFirstRender = useRef<boolean>(true);
  useEffect(() => {
    if (dataGroupTeams?.length && uniqueMembersWithTags.length && isFirstRender.current) {
      setGroupTeams(addMembersToGroup(dataGroupTeams, uniqueMembersWithTags));
      isFirstRender.current = false;
    }
  }, [dataGroupTeams, uniqueMembersWithTags]);

  //add tags to members after changing the state of the tags
  useMemo(() => {
    setGroupTeams((prev) => addTagsToMemberInGroup(prev, tags));
  }, [tags]);

  //initiation ungrouped members
  useMemo(() => {
    setUngroupedTeams(findUngroupedMembers(uniqueMembersWithTags, groupTeams));
  }, [uniqueMembersWithTags, groupTeams]);

  const handleOpenTagDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTagDialog = () => {
    setAnchorEl(null);
  };

  const handleTeamFeedbackDialog = () => {
    setOpenFeedback((prev) => !prev);
  };

  const teamNames: ISelectOptions[] = groupTeams.map((item) => ({ label: item.name, value: item.id }));

  const checkTeamView = () => {
    if (
      teamView === TeamViews.Ungrouped ||
      teamView === TeamViews.Implementation ||
      teamView === TeamViews.OversightAndEscalation
    ) {
      return false;
    }
    return true;
  };

  return (
    <Box className={classes.teamSectionMainBlock}>
      <TeamSideBar
        teamView={teamView}
        setTeamView={setTeamView}
        setGroupTeams={setGroupTeams}
        groupTeams={groupTeams}
        ungroupedTeams={ungroupedTeams}
        loadingGroupTeams={loadingGroupTeams}
        canEdit={canEdit}
      />
      <Paper className={classes.teamSection}>
        {loading ? (
          <TeamSectionSkeleton />
        ) : (
          <Fade in>
            <Grid>
              <Grid item container className={classes.divider} justifyContent={"space-between"}>
                <Typography className={classes.typographyText}>{teamView}</Typography>

                <Box display="flex" alignItems="center" justifyContent="center">
                  {checkTeamView() && (
                    <TeamFeedbackChip
                      onClick={handleTeamFeedbackDialog}
                      label="Team feedback"
                      color="secondary"
                      icon={<FeedbackOutlinedIcon color="primary" />}
                    />
                  )}
                  <Button
                    disableRipple
                    onClick={(e) => handleOpenTagDialog(e)}
                    className={
                      teamView === TeamViews.OversightAndEscalation
                        ? `${classes.filterBtn} ${classes.filterBtnVisibility}`
                        : classes.filterBtn
                    }
                  >
                    <span style={{ textTransform: "none" }}>Filter By Tags</span>
                  </Button>
                </Box>
              </Grid>
              {teamView === TeamViews.OversightAndEscalation && (
                <Grid item>
                  <HeaderTeam
                    engagement={engagement}
                    tags={tags}
                    loadingEngagement={loading}
                    setEngagement={setEngagement}
                    canEdit={canEdit}
                  />
                </Grid>
              )}
              {teamView === TeamViews.Implementation && (
                <Grid item>
                  <EveryoneView members={filteredUniqueMembersWithTags} canEdit={canEdit} />
                </Grid>
              )}
              <Grid item>
                <GroupView
                  teamView={teamView}
                  groupTeams={groupTeams}
                  setGroupTeams={setGroupTeams}
                  ungroupedTeams={ungroupedTeams}
                  canEdit={canEdit}
                />
              </Grid>
            </Grid>
          </Fade>
        )}
      </Paper>

      <FilterPopper
        open={isTagDialogOpen}
        handleClose={handleCloseTagDialog}
        anchorRef={anchorEl}
        engagement={engagement}
        setEngagement={setEngagement}
        canEdit={canEdit}
      />

      {openFeedback && (
        <TeamFeedbackModal
          projectName={engagementTitle}
          teamView={teamView}
          providerName={`${user?.first_name} ${user?.last_name}`}
          teamNames={teamNames}
          openFeedback={openFeedback}
          handleTeamFeedbackDialog={handleTeamFeedbackDialog}
        />
      )}
    </Box>
  );
}
