// external lib dependencies
import { UploadedFile } from "config/types";
import { createContext, FC, useContext, useMemo, useCallback, useState } from "react";

// absolute path dependencies
import { sendFiles, getFilesByEngagementId, getFileByFileId } from "services";

interface FilesState {
  uploadFiles: (_: File[], id: number, category: string, password: string, expireOn: number | null) => void;
  fetchFiles: (id: number) => void;
  fetchFile: (file_id: string, file_name: string, password: string) => Promise<any>;
  files: UploadedFile[];
  loadingFiles: boolean;
  loadingUploadFiles: boolean;
  errorMessage: string;
  isModalClosed: boolean;
  setIsModalClosed: (value: boolean) => void;
  uploadErrorMessage: string;
  uploadSuccessMessage: string;
}

export const FilesContext = createContext<FilesState>({
  uploadFiles: (_: File[], id: number, category: string) => {},
  fetchFiles: (id: number) => {},
  fetchFile: (file_id: string, file_name: string, password: string) => new Promise((resolve, reject) => {}),
  files: [],
  loadingFiles: false,
  loadingUploadFiles: false,
  errorMessage: "",
  isModalClosed: false,
  setIsModalClosed: (value: boolean) => {},
  uploadErrorMessage: "",
  uploadSuccessMessage: "",
});

export const FilesProvider: FC = ({ children }) => {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [loadingUploadFiles, setLoadingUploadFiles] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isModalClosed, setIsModalClosed] = useState<boolean>(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>("");
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<string>("");

  const fetchFiles = useCallback(
    (id: number) => {
      setLoadingFiles(true);

      getFilesByEngagementId(id)
        .then((res) => {
          setFiles(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoadingFiles(false));
    },
    [setFiles]
  );

  const fetchFile = useCallback(
    async (file_id: string, file_name: string, password: string) => {
      var payload = {
        file_password: password,
      };
      setErrorMessage("");
      setIsModalClosed(true);
      return getFileByFileId(payload, file_id)
        .then((res) => {
          setIsModalClosed(false);
          return res.blob();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([blob]));
          link.setAttribute("download", file_name);

          document.body.appendChild(link);
          link.click();

          if (link && link.parentNode) {
            link.parentNode.removeChild(link);
          }
        })
        .catch(async (err) => {
          await err.json().then((res: any) => {
            setIsModalClosed(true);
            setErrorMessage(res.detail);
          });
        });
    },
    [setErrorMessage]
  );

  const uploadFiles = useCallback(
    (files: File[], id: number, category: string, password: string, expireOn: number | null) => {
      setLoadingUploadFiles(true);
      setUploadErrorMessage("");
      setUploadSuccessMessage("File uploaded successfully!");
      var payload = new FormData();
      payload.append("file", files[0]);
      // If password is not empty, add it to the payload
      if (password !== "") payload.append("data", '{"file_password" : "' + password + '"}');

      sendFiles(payload, id, category, expireOn)
        .then((res) => {
          fetchFiles(id);
        })
        .catch(async (err) => {
          await err.json().then((res: any) => {
            setUploadErrorMessage(res.detail);
            setUploadSuccessMessage("");
          });
        })
        .finally(() => setLoadingUploadFiles(false));
    },
    [fetchFiles, setUploadErrorMessage, setUploadSuccessMessage]
  );

  const value = useMemo(() => {
    return {
      uploadFiles,
      fetchFiles,
      fetchFile,
      files,
      loadingFiles,
      loadingUploadFiles,
      errorMessage,
      isModalClosed,
      setIsModalClosed,
      uploadErrorMessage,
      uploadSuccessMessage,
    };
  }, [
    uploadFiles,
    fetchFiles,
    fetchFile,
    files,
    loadingFiles,
    loadingUploadFiles,
    errorMessage,
    isModalClosed,
    setIsModalClosed,
    uploadErrorMessage,
    uploadSuccessMessage,
  ]);

  return <FilesContext.Provider value={value}>{children}</FilesContext.Provider>;
};

export const useFilesContext = () => {
  return useContext(FilesContext);
};
