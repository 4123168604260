import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStylesRelease = makeStyles((theme: Theme) =>
  createStyles({
    //circularProgress
    circularProgressContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "calc(100vh - 450px)",
    },

    //releaseNoteHeader
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    headerTitle: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: "17px",
      },
    },

    //releaseNoteContent
    chipContainer: {
      display: "flex",
      gap: "45px",
      [theme.breakpoints.down("xs")]: {
        gap: "initial",
        justifyContent: "space-between",
        "& .MuiTypography-h6": {
          fontSize: "14px",
          fontWeight: 700,
        },
      },
    },

    //releaseNoteCard
    cardContainer: {
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },

    cardDate: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    cardContent: {
      width: "100%",
      padding: "0px 0px 0px 45px",

      [theme.breakpoints.down("xs")]: {
        "& h1, h2, h3, h4, h5, h6": {
          textAlign: "center",
        },
        padding: "30px 0px 0px 0px",
      },
    },

    cardIcon: {
      display: "flex",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        alignItems: "center",
      },
    },

    //releaseNoteDialog
    editor: {
      border: "1px solid rgb(215, 215, 215)",
      borderRadius: "4px",
      marginTop: "1rem",
      "& .rdw-editor-main": {
        height: "300px",
      },
    },

    dialogActions: {
      justifyContent: "center",
    },

    dialogTitle: {
      textAlign: "center",
    },
  }),
);

export default useStylesRelease;
