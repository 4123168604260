import { Slide, Table, TableContainer, Paper, TableHead, TableRow, TableCell, Box, Typography, IconButton, TableBody, CircularProgress } from "@material-ui/core";
import { AlertTitle, Alert } from "@material-ui/lab";
import { teamColumns } from "pages/PeopleFeedback/config";
import { FeedbacksState, TeamFilterBy, TeamCheckedFilters, TeamFeedback, TeamFeedbackFilters } from "pages/PeopleFeedback/types";
import { useState, useRef, useCallback, useEffect } from "react";
import useStyles from "./styles";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import TeamRow from "./TeamRow";
import TeamFilterBox from "./TeamFilterBox";
import { getTeamFeedbacks, getTeamFeedbacksFilters } from "services/peopleFeedbackService";

const TeamFeedbackTable = () => {
    const classes = useStyles();
    const [filterBy, setFilterBy] = useState<TeamFilterBy>("team_filters");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [feedbacksState, setFeedbacksState] = useState<FeedbacksState>("idle");
    const [filters, setFilters] = useState<TeamFeedbackFilters | null>(null);
    const [teamFeedbacks, setTeamFeedbacks] = useState<TeamFeedback[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const observer = useRef<any>();
    const open = Boolean(anchorEl);
    const scrollbarRef = useRef<any>(null);

    const [checkedFilters, setCheckedFilters] = useState<TeamCheckedFilters>({
        team_filters: [],
        project_filters: [],
        provider_full_filters: []
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, filter: TeamFilterBy | undefined) => {
        if (filter) {
            setFilterBy(filter);
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchInitialData = useCallback(async (pageNumber: number, abortSignal: AbortSignal) => {
        setFeedbacksState("loading");
        try {
            const [feedbacks, filters]: [TeamFeedback[], TeamFeedbackFilters] = await Promise.all([
                getTeamFeedbacks(pageNumber, abortSignal),
                getTeamFeedbacksFilters(abortSignal),
            ]);

            batchedUpdates(() => {
                setTeamFeedbacks(feedbacks);
                setFilters(filters);
                setHasMore(feedbacks.length > 9);
                setFeedbacksState("success");
            });
        } catch (error) {
            setFeedbacksState("error");
        }
    }, []);

    const scrollFetchData = async (pageNumber: number, abortSignal: AbortSignal) => {
        if (feedbacksState === "error") return;
        setFeedbacksState("loading");
        try {
            const feedbacks = (await getTeamFeedbacks(
                pageNumber,
                abortSignal,
                checkedFilters.team_filters,
                checkedFilters.project_filters,
                checkedFilters.provider_full_filters,
            )) as TeamFeedback[];

            batchedUpdates(() => {
                setTeamFeedbacks((prev) => [...prev, ...feedbacks]);
                setHasMore(feedbacks.length > 9);
                setFeedbacksState("success");
            });
        } catch (error) {
            setFeedbacksState("error");
        }
    };

    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
        const abortController = new AbortController();

        if (isFirstRender.current === false) {
            scrollFetchData(pageNumber, abortController.signal);
        }

        if (isFirstRender.current) {
            fetchInitialData(1, abortController.signal);
            isFirstRender.current = false;
        }

        return () => {
            abortController.abort();
            setFeedbacksState("idle");
        };
    }, [fetchInitialData, pageNumber]);

    const lastElementRef = useCallback(
        (node) => {
            if (feedbacksState === "loading") return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [feedbacksState, hasMore],
    );

    return (
        <>
            <Slide in direction="up">
                <TableContainer className={classes.tableContainer} component={Paper} ref={scrollbarRef}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {teamColumns.map((column, index) => {
                                    return (
                                        <TableCell className={classes.tableCell} key={index}>
                                            <Box className={classes.tableCellBox}>
                                                <Typography variant="body2"> {column.label}</Typography>
                                                {Boolean(column.teamFilterBy) && (
                                                    <IconButton
                                                        disabled={feedbacksState !== "success"}
                                                        onClick={(e) => handleClick(e, column.teamFilterBy)}
                                                    >
                                                        <FilterListIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamFeedbacks.map((row, index) => {
                                if (teamFeedbacks.length === index + 1) {
                                    return <TeamRow elementRef={lastElementRef} row={row} key={row.id} />;
                                }
                                return <TeamRow row={row} key={row.id} />;
                            })}
                            {feedbacksState === "loading" && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box className={classes.centerBox}>
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {!hasMore && feedbacksState !== "loading" && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box className={classes.centerBox}>
                                            <Typography>You have reached the end of the list.</Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {feedbacksState === "error" && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            {"Something went wrong..."}
                                        </Alert>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Slide>
            {anchorEl && (
                <TeamFilterBox
                    feedbacksState={feedbacksState}
                    checkedFilters={checkedFilters}
                    setCheckedFilters={setCheckedFilters}
                    setFeedbacksState={setFeedbacksState}
                    setTeamFeedbacks={setTeamFeedbacks}
                    setHasMore={setHasMore}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    scrollbarRef={scrollbarRef}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    filters={filters}
                    filterBy={filterBy}
                />
            )}
        </>
    );
}

export default TeamFeedbackTable;