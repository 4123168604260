import { useState } from "react";

function createStore<T>(
  data: T[],
  initialVal: boolean = false
): { [index: string]: boolean } {
  return data.reduce((acc, _, index) => {
    acc = {
      ...acc,
      [`row${index}`]: initialVal,
    };
    return acc;
  }, {});
}

export default function useAccordionStore<T>(data: T[]) {
  const [state, setState] = useState(createStore(data));

  const handleStateUpdates = (index: number) => {
    return () => {
      setState({
        ...state,
        [`row${index}`]: !state[`row${index}`],
      });
    };
  };

  return {
    state,
    handleStateUpdates,
  };
}
