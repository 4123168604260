import {
  LinearProgress,
  Slide,
  SlideProps,
  Snackbar,
  SnackbarOrigin,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import React, { FC, useEffect, useRef, useState } from "react";

interface SnackbarMessageProps {
  open: boolean;
  autoHideDuration: number;
  handleClose: () => void;
  children: React.ReactNode;
  severity: Color | undefined;
  variant: "outlined" | "filled" | "standard" | undefined;
  offset?: number;
  position?: SnackbarOrigin | undefined;
  transition?: "left" | "up" | "right" | "down";
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "15px",
      height: 7,
    },
    colorPrimary: {
      backgroundColor: "#c4b9b9",
    },
    bar: {
      backgroundColor: theme.palette.common.white,
    },
  }),
)(LinearProgress);

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

/**
 *  The SnackbarMessage component is a wrapper for the Material-UI Snackbar that allows displaying custom error messages,
 *  warnings, or providing users with any other kind of information
 *
 * @param {boolean} open This boolean parameter controls the visibility of the SnackbarMessage. When set to true, the SnackbarMessage appears, and when set to false, it disappears.
 * @param {number}  autoHideDuration The number parameter that controls the duration of displaying the SnackbarMessage
 * @param {Function}  handleClose The callback function that will be invoked after the expiration of the autoHideDuration time
 * @param {React.ReactNode} children The parameter of type ReactNode that provides the information to be displayed in the SnackbarMessage
 * @param {Color} severity The parameter for configuring the MUI Alert severity level, indicating the severity of the message
 * @param {string} variant The parameter for configuring the MUI Alert fill, which controls the background color of the inner content
 * @param {number} offset The numeric parameter that allows more precise configuration of the duration of LinearProgress to synchronize with the autoHideDuration time. It can be a negative number.
 * @param {SnackbarOrigin} position The object responsible for positioning the SnackbarMessage within the browser window
 * @param {string} transition The string parameter used to configure the animation for the selected position when the SnackbarMessage appears
 * @returns MUI React Component Snackbar with Alert message and LinearProgress.
 */

const SnackbarMessage: FC<SnackbarMessageProps> = ({
  open,
  autoHideDuration,
  handleClose,
  children,
  variant,
  severity,
  offset = 0,
  position = { vertical: "bottom", horizontal: "center" },
  transition = "right",
}) => {
  const [progress, setProgress] = useState(100);
  const timerRef = useRef<any>(null);

  function mySetInterval(callback: any, interval: number) {
    function intervalWrapper() {
      callback();
      timerRef.current = setTimeout(intervalWrapper, interval);
    }

    // start first interval
    timerRef.current = setTimeout(intervalWrapper, interval);
  }

  useEffect(() => {
    const intervalTime = 100;
    const steps = autoHideDuration / intervalTime;
    const diff = Math.round(100 / steps) + offset;

    if (open) {
      mySetInterval(() => {
        setProgress((oldProgress) => {
          return Math.max(oldProgress - diff, 0);
        });
      }, intervalTime);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [autoHideDuration, offset, open]);

  const setTransition = () => {
    switch (transition) {
      case "right":
        return TransitionLeft;

      case "left":
        return TransitionRight;

      case "down":
        return TransitionDown;

      case "up":
        return TransitionUp;

      default:
        break;
    }
  };

  return (
    <Snackbar
      anchorOrigin={position}
      TransitionComponent={setTransition()}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert variant={variant} severity={severity} elevation={6}>
        {children}
        <BorderLinearProgress variant="determinate" value={progress} />
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
