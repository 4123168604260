// external lib dependencies
import { useState, useEffect, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  Collapse,
  IconButton,
  Container,
  Fade,
  Tooltip,
  Button as MuiBtn,
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import * as dateMath from "date-arithmetic";

// absolute path dependencies
import { Member as memberType } from "config/types";
import { formatDateDM, formatDateMDY } from "helpers/datetime";
import useScreenSize from "hooks/useScreenSize";
import Button from "components/Button";

// local dependencies
import { useWOStyles, useMemberStyles, useEngagementStyles, useStylesDarkerTooltip } from "./styles";
import useBarPercent from "../hooks/useBarPercent";
import Member from "./Member";
import useSortByPercentage from "../hooks/useSortByPercentage";
import MessagePopupModal from "./MessagePopupModal";
import { useFilterByCurrentYear } from "../hooks/useFilterByCurrentYear";

interface WOProps {
  WOName?: string;
  requestSentDate: Date | null;
  WOEndDate: Date;
  WOStartDate: Date;
  WOId: number;
  members: memberType[];
  unfoldAll: boolean;
  selectedYear: number;
  engagementName: string | undefined;
  checked: boolean;
  setRequestExtensionDate: (id: number, requestDate: Date) => void;
}

const WorkOrder = ({
  WOName,
  members,
  unfoldAll,
  WOId,
  requestSentDate,
  WOEndDate,
  WOStartDate,
  selectedYear,
  engagementName,
  checked,
  setRequestExtensionDate,
}: WOProps) => {
  const [openExtend, setOpenExtend] = useState<boolean>(false);
  const [unfold, setUnfold] = useState<boolean>(false);
  const { isXS, isSM, isXL } = useScreenSize();
  const { getTimePassedPercent } = useBarPercent();
  const classesMem = useMemberStyles();
  const classesWO = useWOStyles();
  const classesEng = useEngagementStyles();
  const classesTooltip = useStylesDarkerTooltip();
  const sortByPercentage = useSortByPercentage();

  const [localMember, setLocalMember] = useState<memberType[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const queryWorkOrder = queryParams.get("wo");

  useEffect(() => {
    setUnfold(unfoldAll);
  }, [unfoldAll, requestSentDate]);

  useEffect(() => {
    setUnfold((!!queryWorkOrder && WOName === queryWorkOrder) || unfoldAll);
    return () => {
      setUnfold(false);
    };
  }, []);

  const _localMembersCurrentYear = useFilterByCurrentYear(members, selectedYear);

  const _activeLocalMembers = useMemo(
    () =>
      _localMembersCurrentYear.filter(
        ({ start_date, end_date }) => getTimePassedPercent(new Date(start_date), new Date(end_date)) < 100,
      ),
    [_localMembersCurrentYear, getTimePassedPercent],
  );

  const _notActiveLocalMembers = useMemo(
    () =>
      _localMembersCurrentYear.filter(
        ({ start_date, end_date }) => getTimePassedPercent(new Date(start_date), new Date(end_date)) >= 100,
      ),
    [_localMembersCurrentYear, getTimePassedPercent],
  );

  const _uniqueMembersActive = useMemo(
    () =>
      Array.from(new Map(_activeLocalMembers.map(({ employee_id, ...rest }) => [employee_id, { ...rest }])).values()),
    [_activeLocalMembers],
  );

  const _uniqueMembersNotActive = useMemo(
    () =>
      Array.from(
        new Map(_notActiveLocalMembers.map(({ employee_id, ...rest }) => [employee_id, { ...rest }])).values(),
      ),
    [_notActiveLocalMembers],
  );

  const localMembers = useMemo(
    () =>
      checked
        ? sortByPercentage(_uniqueMembersActive)
        : sortByPercentage([..._uniqueMembersActive, ..._uniqueMembersNotActive]),
    [_uniqueMembersActive, _uniqueMembersNotActive, checked, sortByPercentage],
  );

  useEffect(() => {
    setLocalMember(localMembers);
    return () => {
      setLocalMember([]);
    };
  }, [localMembers]);

  let canRequest: boolean = false;
  const timePassedProcent = getTimePassedPercent(new Date(WOStartDate), new Date(WOEndDate));
  const dateDifference = dateMath.diff(new Date(), new Date(WOEndDate), "day");

  if (timePassedProcent >= 90 || (dateDifference > 0 && dateDifference <= 31)) {
    canRequest = true;
  }

  const endingText = timePassedProcent === 100 ? "Ended" : "Ending soon!";

  return (
    <ListItem disableGutters className={classesWO.workOrder} data-testid="WOListItem">
      <Container className={classesWO.sideBoxContainer}>
        <Box className={classesWO.leftSideBox}>
          {requestSentDate && (
            <Typography
              variant="subtitle1"
              data-testid="alreadyRequestedMessageLeft"
              className={classesWO.extensionRequestedTextLeftSide}
            >
              EXTENSION REQUESTED: {formatDateDM(requestSentDate.toString().toUpperCase()).toUpperCase()}
            </Typography>
          )}
          <Container className={classesWO.buttonsContainer} disableGutters>
            <Button onClick={() => setUnfold((prev) => !prev)} className={classesWO.iconButton}>
              <Box className={classesWO.titleBox}>
                <Box className={classesWO.title} style={{ minWidth: "0" }}>
                  <Typography variant="subtitle2" className={classesWO.WOName}>
                    {WOName}
                  </Typography>
                  <Typography variant="caption" style={{ textAlign: "start" }} data-testid="WODurationDate">
                    {`${formatDateMDY(WOStartDate.toString())} - ${formatDateMDY(WOEndDate.toString())}`}
                  </Typography>
                </Box>
              </Box>
              {unfold ? <ExpandLess /> : <ExpandMore />}
            </Button>

            {!openExtend && canRequest && isSM && (
              <Fade in={!openExtend} unmountOnExit mountOnEnter>
                <Tooltip title="Extend request end date">
                  <IconButton
                    onClick={() => setOpenExtend((prev) => !prev)}
                    className={isXS ? classesWO.extendLarge : classesWO.extend}
                    data-testid="WOExtendButton"
                    disableRipple
                    style={{ marginBottom: "16px" }}
                  >
                    <ControlPointIcon />
                  </IconButton>
                </Tooltip>
              </Fade>
            )}
          </Container>

          {isSM && openExtend && (
            <MessagePopupModal
              WOId={WOId}
              setOpenExtend={setOpenExtend}
              engagementName={engagementName}
              WOName={WOName}
              open={openExtend}
              setRequestExtensionDate={setRequestExtensionDate}
            />
          )}
        </Box>
        {isXL && !isSM && (
          <Box className={classesWO.rightSideBox}>
            <Box
              onClick={() => setUnfold((prev) => !prev)}
              className={classesWO.woBar}
              style={{
                width: "75%",
              }}
              data-testid="WOBar"
            >
              {requestSentDate && (
                <Typography
                  variant="subtitle1"
                  data-testid="alreadyRequestedMessageRight"
                  className={classesWO.extensionRequestedText}
                >
                  EXTENSION REQUESTED: {formatDateDM(requestSentDate.toString().toUpperCase()).toUpperCase()}
                </Typography>
              )}
              {canRequest && timePassedProcent < 15 && (
                <Tooltip
                  arrow
                  classes={classesTooltip}
                  title="Ending soon!"
                  placement="top"
                  data-testid="endingMessage"
                >
                  <Box
                    className={canRequest ? classesEng.engBarEndingSoon : classesEng.engBarBlured}
                    style={{
                      width: `${timePassedProcent}%`,
                    }}
                  ></Box>
                </Tooltip>
              )}
              {canRequest && timePassedProcent >= 15 && (
                <Box
                  className={canRequest ? classesEng.engBarEndingSoon : classesEng.engBarBlured}
                  style={{
                    width: `${timePassedProcent}%`,
                  }}
                >
                  <Typography variant="subtitle2" className={classesWO.endingSoon} data-testid="endingMessage">
                    {endingText}
                  </Typography>
                </Box>
              )}
              {!canRequest && (
                <Box
                  className={canRequest ? classesEng.engBarEndingSoon : classesEng.engBarBlured}
                  style={{
                    width: `${timePassedProcent}%`,
                  }}
                ></Box>
              )}
              <Typography variant="subtitle1">{timePassedProcent}%</Typography>
            </Box>
            <Box className={classesWO.extendWO}>
              <Box style={{ height: "28px", position: "relative" }}>
                {canRequest && (
                  <>
                    <MuiBtn
                      variant="contained"
                      size="small"
                      data-testid="WOExtendButton"
                      onClick={() => setOpenExtend((prev) => (requestSentDate ? prev : !prev))}
                      className={requestSentDate ? classesWO.extendWOBtnAfterRequest : classesWO.extendWOBtn}
                    >
                      Request Extension
                    </MuiBtn>
                    {openExtend && (
                      <MessagePopupModal
                        data-testid="messageBox"
                        WOId={WOId}
                        setOpenExtend={setOpenExtend}
                        open={openExtend}
                        engagementName={engagementName}
                        WOName={WOName}
                        setRequestExtensionDate={setRequestExtensionDate}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Container>
      <Collapse in={unfold} className={classesWO.collapse}>
        <List className={classesMem.memberList} data-testid="memberList">
          {unfold &&
            localMember &&
            localMember.map((member, index) => (
              <Member
                key={index}
                memberName={member.employee_name}
                startDate={member.start_date}
                endDate={member.end_date}
                role={member.member_role}
                photoId={member.employee_photo_id}
                selectedYear={selectedYear}
                pricePerDay={member.price_per_day}
              />
            ))}
        </List>
      </Collapse>
    </ListItem>
  );
};

export default WorkOrder;
