import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  CircularProgress,
  IconButton,
  Slide,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { columns } from "pages/PeopleFeedback/config";
import useStyles from "./styles";
import Row from "./Row";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CheckedFilters,
  FeedbacksState,
  FilterBy,
  IndividualFeedback,
  IndividualFeedbacksFilters,
} from "pages/PeopleFeedback/types";
import { getIndividualFeedbacks, getIndividualFeedbacksFilters } from "services/peopleFeedbackService";
import { Alert, AlertTitle } from "@material-ui/lab";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import FilterBox from "./FilterBox";

const FeedbackTable = () => {
  const classes = useStyles();
  const [filterBy, setFilterBy] = useState<FilterBy>("name_filters");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [feedbacksState, setFeedbacksState] = useState<FeedbacksState>("idle");
  const [filters, setFilters] = useState<IndividualFeedbacksFilters | null>(null);
  const [individualFeedbacks, setIndividualFeedbacks] = useState<IndividualFeedback[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const observer = useRef<any>();
  const open = Boolean(anchorEl);
  const scrollbarRef = useRef<any>(null);

  const [checkedFilters, setCheckedFilters] = useState<CheckedFilters>({
    name_filters: [],
    project_name: [],
    company_name: [],
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, filter: FilterBy | undefined) => {
    if (filter) {
      setFilterBy(filter);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchInitialData = useCallback(async (pageNumber: number, abortSignal: AbortSignal) => {
    setFeedbacksState("loading");
    try {
      const [feedbacks, filters]: [IndividualFeedback[], IndividualFeedbacksFilters] = await Promise.all([
        getIndividualFeedbacks(pageNumber, abortSignal),
        getIndividualFeedbacksFilters(abortSignal),
      ]);

      batchedUpdates(() => {
        setIndividualFeedbacks(feedbacks);
        setFilters(filters);
        setHasMore(feedbacks.length > 9);
        setFeedbacksState("success");
      });
    } catch (error) {
      setFeedbacksState("error");
    }
  }, []);

  const scrollFetchData = async (pageNumber: number, abortSignal: AbortSignal) => {
    if (feedbacksState === "error") return;
    setFeedbacksState("loading");
    try {
      const feedbacks = (await getIndividualFeedbacks(
        pageNumber,
        abortSignal,
        checkedFilters.name_filters,
        checkedFilters.project_name,
        checkedFilters.company_name,
      )) as IndividualFeedback[];

      batchedUpdates(() => {
        setIndividualFeedbacks((prev) => [...prev, ...feedbacks]);
        setHasMore(feedbacks.length > 9);
        setFeedbacksState("success");
      });
    } catch (error) {
      setFeedbacksState("error");
    }
  };

  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    const abortController = new AbortController();

    if (isFirstRender.current === false) {
      scrollFetchData(pageNumber, abortController.signal);
    }

    if (isFirstRender.current) {
      fetchInitialData(1, abortController.signal);
      isFirstRender.current = false;
    }

    return () => {
      abortController.abort();
      setFeedbacksState("idle");
    };
  }, [fetchInitialData, pageNumber]);

  const lastElementRef = useCallback(
    (node) => {
      if (feedbacksState === "loading") return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [feedbacksState, hasMore],
  );

  return (
    <>
      <Slide in direction="up">
        <TableContainer className={classes.tableContainer} component={Paper} ref={scrollbarRef}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell className={classes.tableCell} key={index}>
                      <Box className={classes.tableCellBox}>
                        <Typography variant="body2"> {column.label}</Typography>
                        {Boolean(column.filterBy) && (
                          <IconButton
                            disabled={feedbacksState !== "success"}
                            onClick={(e) => handleClick(e, column.filterBy)}
                          >
                            <FilterListIcon />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {individualFeedbacks.map((row, index) => {
                if (individualFeedbacks.length === index + 1) {
                  return <Row elementRef={lastElementRef} row={row} key={row.id} />;
                }
                return <Row row={row} key={row.id} />;
              })}
              {feedbacksState === "loading" && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box className={classes.centerBox}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!hasMore && feedbacksState !== "loading" && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box className={classes.centerBox}>
                      <Typography>You have reached the end of the list.</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {feedbacksState === "error" && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {"Something went wrong..."}
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Slide>
      {anchorEl && (
        <FilterBox
          feedbacksState={feedbacksState}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setFeedbacksState={setFeedbacksState}
          setIndividualFeedbacks={setIndividualFeedbacks}
          setHasMore={setHasMore}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          scrollbarRef={scrollbarRef}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          filters={filters}
          filterBy={filterBy}
        />
      )}
    </>
  );
};

export default FeedbackTable;
