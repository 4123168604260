// external lib dependencies
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  TableContainer,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

// absolute path dependencies
import { Survey } from "config/types";
import { useThemeStruct } from "themes";
import Button from "components/Button";

// local dependencies
import { getLocaleDateString } from "helpers/datetime";
import { AuditTableProps } from "./types";
import useStyles from "./styles";

const AuditsLargeTable = ({ surveys, onDownload, setActiveSurvey, activeSurvey }: AuditTableProps) => {
  const classes = useStyles();
  const themeStruct = useThemeStruct();

  return (
    <Fade in>
      <TableContainer component={Paper} elevation={1} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableCell }}>
                <Typography variant="body2" classes={{ root: "semibold" }}>
                  Date
                </Typography>
              </TableCell>
              <TableCell align="left" classes={{ root: classes.tableCell }}>
                <Typography variant="body2" classes={{ root: "semibold" }}>
                  Provided by
                </Typography>
              </TableCell>
              <TableCell align="left" classes={{ root: classes.tableCell }}>
                <Typography variant="body2" classes={{ root: "semibold" }}>
                  Notes
                </Typography>
              </TableCell>
              <TableCell align="left" classes={{ root: classes.tableCell }}>
                <Typography variant="body2" classes={{ root: "semibold" }}>
                  Score
                </Typography>
              </TableCell>
              <TableCell align="left" classes={{ root: classes.tableCell }}>
                <Typography variant="body2" classes={{ root: "semibold" }}>
                  Download PDF
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.map((survey: Survey, index) => (
              <TableRow
                key={index}
                onClick={() => setActiveSurvey(survey)}
                className={survey.id === activeSurvey?.id ? classes.tableRowActive : classes.tableRow}
              >
                <TableCell component="th" scope="row" classes={{ root: classes.tableCell }}>
                  <Typography variant="body2">{getLocaleDateString(survey.createdAt)}</Typography>
                </TableCell>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <Typography variant="body2">{survey.createdBy}</Typography>
                </TableCell>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <Typography variant="body2">{survey.notes ?? "-"}</Typography>
                </TableCell>
                <TableCell align="left" classes={{ root: classes.tableCell }}>
                  <Typography variant="body2">{survey.percentageScore}%</Typography>
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  <div className={classes.downloadActionWrapper}>
                    <Button
                      category="icon"
                      colorVariant={themeStruct.auditCellButtonColor}
                      onClick={() => onDownload(survey.id)}
                    >
                      <GetAppIcon />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
};

export default AuditsLargeTable;
