import { Theme, createStyles, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      backgroundColor: "rgb(255, 244, 229)",
      color: "rgb(102, 60, 0)",
      "& .MuiAlert-icon": {
        color: "#ff9800",
      },
    },
  }),
);

export default useStyles;
