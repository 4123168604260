import { Popper, Paper, Grow, MenuList, MenuItem } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";

interface ISelectFeedbackOption {
  open: boolean;
  anchorEl: Element | null;
  setOpenFeedbackDialog: Dispatch<SetStateAction<boolean>>;
}

const SelectFeedbackOptions = ({ open, anchorEl, setOpenFeedbackDialog }: ISelectFeedbackOption) => {
  const id = open ? "simple-popover" : undefined;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      placement="right"
      modifiers={{
        preventOverflow: {
          escapeWithReference: true,
        },
      }}
      style={{
        zIndex: 2,
      }}
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <MenuList>
              <MenuItem onClick={() => setOpenFeedbackDialog(true)}>Short text feedback</MenuItem>
              <MenuItem>Detailed survey</MenuItem>
            </MenuList>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default SelectFeedbackOptions;
