// external lib dependencies
import {
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import { Fragment, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button as MUIButton } from "@material-ui/core";
import { useActions, useAppSelector } from "store/hook";

// absolute path dependencies
import { VoidFunc } from "config/types";
import useScreenSize from "hooks/useScreenSize";
import { useAuth } from "providers/AuthContext";
import { useNextTheme } from "themes";

// local dependencies
import SideDrawer from "./components/SideDrawer";
import { useNavBarStyles } from "./styles";

import { ReactComponent as LogOutButton } from "../../assets/icons/navBarIcons/logout-button.svg";
import { ReactComponent as AdvanceNotifications } from "../../assets/icons/navBarIcons/advance-notifications.svg";
import { ReactComponent as NotificationSettings } from "../../assets/icons/navBarIcons/notification-settings.svg";
import { ReactComponent as ClientPortalLogo } from "../../assets/icons/navBarIcons/ClientPortalLogo.svg";

interface NavBarProps {
  setTheme: VoidFunc<Theme>;
}

const NavBar = ({ setTheme }: NavBarProps) => {
  const [open, setOpen] = useState(false);
  const { isSM } = useScreenSize();
  const { logout } = useAuth();
  const classes = useNavBarStyles();
  const nextTheme = useNextTheme();
  const { user } = useAppSelector((state) => state.user);
  const { fetchUser } = useActions();

  useEffect(() => {
    !isSM && setOpen(false);
  }, [isSM]);

  useEffect(() => {
    //dispatch(isNewNotes())
    fetchUser();
  }, []);

  const toggleMenuBurger = useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      event.preventDefault();

      if ("key" in event) {
        if (event.key !== "Enter" && event.key !== "Space") return;
      }

      setOpen((prev) => !prev);
    },
    [setOpen],
  );

  function checkUser() {
    if (!user) return "";
    return `${user?.first_name} ${user?.last_name}`;
  }

  return (
    <Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.navBar}>
          <Typography className={classes.logoTypography}>
            <ClientPortalLogo />
          </Typography>
          <Hidden smDown>
            <Box className={classes.rightButtonArea}>
              <Typography variant="body1" className={classes.userNameTypography}>
                {checkUser()}
              </Typography>
              <Box className={classes.buttonAction}>
                <MUIButton disabled={true}>
                  <AdvanceNotifications />
                </MUIButton>
                <NavLink to={"/notification"}>
                  <MUIButton>
                    <NotificationSettings />
                  </MUIButton>
                </NavLink>
                <MUIButton onClick={() => logout()}>
                  <LogOutButton />
                </MUIButton>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <IconButton aria-label="menu" color="inherit" className={classes.burgerIcon} onClick={toggleMenuBurger}>
              {!open ? <MenuIcon /> : <ClearIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      {isSM && (
        <Drawer anchor="left" open={open} classes={{ paper: classes.drawerPapper }}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.navBar}>
              <img src="/images/logo-mini.svg" alt="logo-mini" className={classes.navBarLogo}></img>
              <List role="nav" aria-labelledby="main navigation" className={classes.navBarList}>
                <ListItem>
                  <ListItemText className={classes.clientPortalItem} primary="Client Portal" />
                </ListItem>
              </List>
              <IconButton aria-label="menu" color="inherit" className={classes.burgerIcon} onClick={toggleMenuBurger}>
                {!open ? <MenuIcon /> : <ClearIcon />}
              </IconButton>
            </Toolbar>
          </AppBar>
          <Paper elevation={0}>
            <SideDrawer toggleMenu={toggleMenuBurger} changeTheme={() => setTheme(nextTheme)} />
          </Paper>
        </Drawer>
      )}
    </Fragment>
  );
};

export default NavBar;
