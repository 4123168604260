// external lib dependencies
import { useState, useCallback } from "react";

// absolute path dependencies
import { createGroupTeam } from "services/groupTeam";

const useSubmitHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [objectId, setObjectId] = useState<string>("");

  const callback = useCallback(async (data: any, projectId: any) => {
    setLoading(true);
    setIsSuccess(false);
    setError("");
    const errorMessage = "Error!";
    try {
      const fetch = await createGroupTeam(data, projectId);
      setIsSuccess(fetch.ok);
      const response = await fetch.json();

      if(fetch.ok) setObjectId(response.data.object_id);
      
      if (!fetch.ok) {
        setError(response.detail ? response.detail : response ?? errorMessage);
      }
    } catch (err) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  return { callback, loading, error, isSuccess, objectId };
};

export default useSubmitHandler;
