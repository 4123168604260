import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@material-ui/core";
import { useMessagePopupStyles } from "./styles";
import { SubmitHandler, useForm } from "react-hook-form";
import sendWOExtendMessage from "services/WOExtendService";
import InputField from "components/FormInputField/InputField";

interface FormValues {
  extendMessage: string;
}

interface IMessagePopupModal {
  setOpenExtend: Dispatch<SetStateAction<boolean>>;
  WOId: number;
  engagementName: string | undefined;
  WOName: string | undefined;
  open: any;
  setRequestExtensionDate: (id: number, requestDate: Date) => void;
}

type FormState = "success" | "error" | "sending" | "idle";

export default function MessagePopupModal({
  WOId,
  setOpenExtend,
  engagementName,
  WOName,
  open,
  setRequestExtensionDate,
}: IMessagePopupModal) {
  const classes = useMessagePopupStyles();
  const controllerRef = useRef<AbortController | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ mode: "onChange" });
  const urlRegex = new RegExp("^(?!.*(?:http|https|ftp):\\/\\/.*)(?!.*<script>.*<\\/script>.*).+$");

  const [formState, setFormState] = useState<FormState>("idle");

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const controller = new AbortController();
    controllerRef.current = controller;

    const formattedData = {
      message: data.extendMessage.trim(),
      engagementName: engagementName,
      WOName: WOName,
    };

    setFormState("sending");
    try {
      await sendWOExtendMessage(formattedData, WOId, controllerRef.current.signal);
      setFormState("success");
      setRequestExtensionDate(WOId, new Date());
    } catch {
      setFormState("error");
    }
  };

  const handleClose = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    setOpenExtend(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth={true}>
      <DialogContent data-testid="messageBox">
        {(formState === "idle" || formState === "sending") && (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.messagePopup}>
            <InputField
              renderFormLabel={true}
              name="extendMessage"
              labelText="Request Extension"
              required={true}
              control={control}
              textFieldPlaceholder={"Maximum limit of characters: 150"}
              minRows={5}
              multiline={true}
              errors={errors?.extendMessage?.message}
              validation={{ maxLength: { value: 150 }, minLength: { value: 2 }, regexPattern: { pattern: urlRegex } }}
              inputPropsObj={{ maxLength: 150, style: { fontSize: "13px" } }}
            />
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={formState === "sending"}>
                Send
              </Button>
            </DialogActions>
          </form>
        )}
        {formState === "success" && (
          <Box>
            <Typography style={{ fontWeight: "500" }}>Request Extension</Typography>
            <DialogContentText style={{ padding: "20px 0px" }}>Your Request has been sent!</DialogContentText>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenExtend(false);
                }}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Box>
        )}
        {formState === "error" && (
          <Box>
            <Typography style={{ fontWeight: "500", padding: "20px 0px" }}>
              Service unavailable, please contact support
            </Typography>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenExtend(false);
                }}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
