// external lib dependencies
import { SubmitHandler, Controller, useForm } from "react-hook-form";
import { Container, Fade, Box, Button, Checkbox, FormControlLabel, Typography, Grid, Tooltip } from "@material-ui/core";

// absolute path dependencies
import { VoidFunc } from "config/types";
import { useEngagementData } from "providers/EngagementDataContext";

// local dependencies
import useSubmitHandler from "./useSubmitHandler";
import ResponseMessage from "./ResponseMessage";
import InputField from "../../../../components/FormInputField/InputField";
import useStyles, { useStylesDarkerTooltip } from "./styles";
import { useState } from "react";
import React from "react";

interface MessageFormFieldsProps {
  onCloseModal: VoidFunc<any>;
}

interface FormValues {
  message: string;
  subject: string;
  clientPartner: boolean;
  deliveryLead: boolean;
}

const MessageFormFields = React.forwardRef((props: MessageFormFieldsProps, ref) => {
  const classesTooltip = useStylesDarkerTooltip();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm();
  const {
    engagement: { project_lead_name, client_partner_name, default_client_partner_name, id },
  } = useEngagementData();
  const { sendData, error, sent, loading } = useSubmitHandler();
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(true);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const toList = [];

    if (data.clientPartner) {
      toList.push("client_partner");
    } else if (!project_lead_name) {
      toList.push("client_partner");
    }

    if (data.deliveryLead) {
      toList.push("project_lead");
    } else if (!client_partner_name && !default_client_partner_name) {
      toList.push("project_lead");
    }

    let finalData = {
      data: {
        message: data.message.trim(),
        subject: data.subject.trim(),
      },
      to: toList,
    };

    if (toList.length > 0) {
      setCheckboxChecked(true);
    } else {
      setCheckboxChecked(false);
    }

    if (finalData.to.length > 0) {
      sendData(id, finalData);
    } else {
      setError("selection", { type: "required" });
    }
  };

  if (!error && !sent)
    return (
      <Fade in>
        <div data-testid="formFields">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container className={classes.messageFormFieldsContainer}>
              <Box className={classes.selectedField}>
                <Typography
                  //error={Boolean(errors.selection)}
                  variant="h6"
                  className={classes.heading}
                  data-testid="selectionLabel"
                  style={{ alignSelf: "baseline" }}
                >
                  Send message
                </Typography>
                <Box className={classes.field} data-testid="selectionField">
                  {(client_partner_name || default_client_partner_name) && project_lead_name ? (
                    <Typography
                      variant="subtitle2"
                      data-testid="clientPartnerCheckbox"
                      className={classes.checkboxFields}
                    >
                      <Controller
                        control={control}
                        name="clientPartner"
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={(!project_lead_name && true) || Boolean(value)}
                                onChange={(e) => {
                                  clearErrors("selection");
                                  onChange(e.target.checked);
                                }}
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "16px", lineHeight: "24px" }}>Client Partner</Typography>
                            }
                          />
                        )}
                      />
                    </Typography>
                  ) : (
                    <Tooltip arrow classes={classesTooltip} title="Person will be assigned soon!">
                      <Typography
                        variant="subtitle2"
                        data-testid="clientPartnerCheckbox"
                        className={classes.checkboxFields}
                      >
                        <FormControlLabel
                          control={<Checkbox color="primary" disabled />}
                          label={
                            <Typography style={{ fontSize: "16px", lineHeight: "24px" }}>Client Partner</Typography>
                          }
                        />
                      </Typography>
                    </Tooltip>
                  )}
                  {/* {(client_partner_name || default_client_partner_name) && !project_lead_name && (
                    <Typography data-testid="clientPartnerWithoutCheckbox" variant="body2" className={classes.checkboxText}>
                      Delivery Lead
                    </Typography>
                  )} */}
                  {project_lead_name && (client_partner_name || default_client_partner_name) ? (
                    <Typography
                      variant="subtitle2"
                      data-testid="deliveryLeadCheckbox"
                      className={classes.checkboxFields}
                    >
                      <Controller
                        control={control}
                        name="deliveryLead"
                        defaultValue=""
                        render={({ onChange, value }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={Boolean(value)}
                                onChange={(e) => {
                                  clearErrors("selection");
                                  onChange(e.target.checked);
                                }}
                              />
                            }
                            className={classes.labelFont}
                            label={
                              <Typography style={{ fontSize: "16px", lineHeight: "24px" }}>Delivery Lead</Typography>
                            }
                          />
                        )}
                      />
                    </Typography>
                  ) : (
                    <Tooltip arrow classes={classesTooltip} title="Person will be assigned soon!">
                      <Typography
                        variant="subtitle2"
                        data-testid="deliveryLeadCheckbox"
                        className={classes.checkboxFields}
                      >
                        <FormControlLabel
                          control={<Checkbox color="primary" disabled />}
                          className={classes.labelFont}
                          label={
                            <Typography style={{ fontSize: "16px", lineHeight: "24px" }}>Delivery Lead</Typography>
                          }
                        />
                      </Typography>
                    </Tooltip>
                  )}
                  {/* {project_lead_name && !client_partner_name && !default_client_partner_name && (
                    <Typography data-testid="deliveryLeadWithoutCheckbox" variant="body2">
                      Delivery Lead
                    </Typography>
                  )} */}
                </Box>
                {!checkboxChecked && (
                  <Typography variant="body2" className={classes.selectRecipientMessage}>
                    Please select recipient
                  </Typography>
                )}
              </Box>
              <div data-testid="subjectField">
                <Grid item className={classes.fieldContainer}>
                  <InputField
                    name="subject"
                    required={true}
                    labelText="Subject"
                    validation={{ minLength: { value: 1 }, maxLength: { value: 150 } }}
                    errors={errors.subject?.message}
                    control={control}
                    requiredSymbol={true}
                  />
                </Grid>
              </div>
              <div data-testid="messageField">
                <Grid item className={classes.fieldContainer}>
                  <InputField
                    name="message"
                    required={true}
                    labelText="Message"
                    multiline={true}
                    minRows={10}
                    maxRows={14}
                    validation={{ minLength: { value: 1 }, maxLength: { value: 1000 } }}
                    errors={errors.message?.message}
                    control={control}
                    requiredSymbol={true}
                  />
                </Grid>
              </div>
              <Box className={classes.messageButtons}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.cancelButton}
                  onClick={props.onCloseModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-testid="submitButton"
                  className={classes.sendButton}
                >
                  Send
                </Button>
              </Box>
            </Container>
          </form>
        </div>
      </Fade>
    );
  else if (error && !sent) {
    return (
      <div data-testid="errorMessage1">
        <ResponseMessage messageType="error" message={error} loading={loading} onButtonClick={props.onCloseModal} />
      </div>
    );
  } else if (sent) {
    return (
      <div data-testid="successMessage">
        <ResponseMessage
          messageType="success"
          message="Your message has been sent"
          loading={loading}
          onButtonClick={props.onCloseModal}
        />
      </div>
    );
  }

  return null;
});

export default MessageFormFields;
