import { useEffect, useState } from 'react';
import { Redirect, useParams, Link } from "react-router-dom";

import { Container, Typography, Box, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import useFetchProfileData from "./hooks/useFetchProfileData";
import SectionTitleBar from "components/SectionTitleBar";
import ProfileCard from "./components/ProfileCard/ProfileCard";
import ProfileCardSkeleton from "./components/ProfileCard/ProfileCardSkeleton";
import useStyles from "./styles";
import Button from "../../components/Button";
import ProvidedFeedbackSkeleton from "./components/ProvidedFeeback/ProvidedFeedbackSkeleton";
import ProvidedFeedback from "./components/ProvidedFeeback/ProvidedFeedback";
import { MemberWorkOrder, Params } from "./types";
import { useAppDispatch, useAppSelector } from "store/hook";
import { fetchTags } from "store/reducers/tag/tagSlice";
import WorkOrderSkeleton from "./components/WorkOrder/WorkOrderSkeleton";
import WorkOrder from "./components/WorkOrder/WorkOrder";
import useFetchWorkOrders from "./hooks/useFetchWorkOrders";


const MemberProfile = () => {
    const classes = useStyles();
    const { id, employee_id } = useParams<Params>();
    const dispatch = useAppDispatch()

    const tagState = useAppSelector(state => state.tags)

    const { fetch, loading, error, data } = useFetchProfileData();
    const {
        fetch: fetchWorkOrders,
        loading: loadingWorkOrders,
        error: errorWorkOrders,
        data: dataWorkOrders
    } = useFetchWorkOrders();
    const [profileData, setProfileData] = useState<any>();
    const [workOrders, setWorkOrders] = useState<MemberWorkOrder[]>([])

    useEffect(() => {
        if (tagState.status === 'succeeded' || tagState.status === 'idle') {
            dispatch(fetchTags(id))
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            return
        }
        fetch(id, employee_id);
        fetchWorkOrders(id, employee_id);

    }, [id, employee_id])

    useEffect(() => {
        if (data && !error) {
            setProfileData(data)
        }
    }, [data]);

    useEffect(() => {
        if (dataWorkOrders && !error) {
            setWorkOrders(dataWorkOrders)
        }
    }, [dataWorkOrders])


    if (!id || !employee_id) {
        return <Redirect to="/engagements" />;
    }

    return (
        <div className={classes.engagement}>
            <SectionTitleBar
                title={loading ? <Skeleton width="30%" /> : profileData?.project_title ?? ''}
                upSmBoxShadow={true}
            />
            <Container className={classes.container} maxWidth="xl">
                <Box className={classes.buttonGoBack}>
                    <Link to={"/engagements/" + id + "/team"}>
                        <Button colorVariant="black">Go back</Button>
                    </Link>
                </Box>
                <Paper elevation={0} className={classes.profileSection}>
                    <Box className={classes.profileSectionTitle}>
                        <Typography variant="h5" component="span">Team member's profile</Typography>
                    </Box>
                    <Box className={classes.profileSectionBox}>
                        {loading ?
                            (<ProfileCardSkeleton />) :
                            error ? error : (<ProfileCard profileData={profileData} />)
                        }
                        {/*Badges*/}
                        <Box></Box>
                    </Box>
                    <Box className={classes.feedbackSectionBox}>
                        {loading ?
                            (<ProvidedFeedbackSkeleton />) :
                            error ?
                                <Typography variant='h4'>{error}</Typography>
                                : (<ProvidedFeedback />)
                        }
                    </Box>
                    <Box className={classes.workOrderSectionBox}>
                        {loadingWorkOrders ?
                            (<WorkOrderSkeleton />) :
                            errorWorkOrders ?
                                <Typography variant='h4'>{errorWorkOrders}</Typography>
                                : (<WorkOrder data={workOrders} />)
                        }
                    </Box>

                </Paper>
            </Container>
        </div>
    );
};

export default MemberProfile;
