import { jsPDF } from "jspdf";
import { ContentItem } from "./types";
import { writeText } from "./utils/text-utils";
import { fontSize, fontStyle } from "./utils/configurations/font-constants";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import { elementPosition } from "./utils/configurations/element-position";

export default function generateContentsPage(
  doc: jsPDF,
  contents: ContentItem[]
) {
  doc.insertPage(2);

  // Contents static text
  writeText({
    doc,
    color: [34, 84, 118],
    fontSize: fontSize.contentsTitle,
    fontStyle: fontStyle.bold,
    text: surveyPdfContent.contents,
    x: elementPosition.contents.getX(),
    y: elementPosition.contents.getY(),
  });

  doc.setTextColor("black");
  doc.setFont("Helvetica", fontStyle.normal);
  doc.setFontSize(fontSize.contentsText);

  const y = elementPosition.sections.getY() + fontSize.auditSection;
  const contentsFirstPageHeight =
    elementPosition.globe.getY() - elementPosition.surveyInfoMargin - y;
  const contentsRestPageHeight =
    elementPosition.globe.getY() -
    elementPosition.surveyInfoMargin -
    elementPosition.sections.getY();
  const contentsHeight = getContentsHeight(contents);
  const contentsRestHeight = contentsHeight - contentsFirstPageHeight;

  if (contentsRestHeight > 0) {
    const contentsPageCount =
      Math.ceil(contentsRestHeight / contentsRestPageHeight) + 1;
    writeLinks(
      doc,
      y,
      elementPosition.contents.getX(),
      0,
      contents,
      contentsPageCount,
      2
    );
  } else {
    writeLinks(doc, y, elementPosition.contents.getX(), 0, contents, 1, 2);
  }
}

function writeLinks(
  doc: jsPDF,
  y: number,
  x: number,
  tab: number,
  contents: ContentItem[],
  contentsPageCount: number,
  contentsPage: number
): number {
  contents.forEach((c) => {
    if (y >= elementPosition.globe.getY() - elementPosition.surveyInfoMargin) {
      contentsPage++;
      doc.insertPage(contentsPage);
      y = elementPosition.sections.getY();
    }

    if (c.title.length > 75) {
      const substring = c.title.substring(0, 75);
      const lastSpaceIndex = substring.lastIndexOf(" ");
      c.title =
        lastSpaceIndex > 0 ? c.title.substring(0, lastSpaceIndex) : substring;
    }

    const pageStr = `${c.page + contentsPageCount}`;
    const mmWidthFactor = (fontSize.contentsText / 72) * 25.6; // 72 - points per inch; 25.6 - mm in inch;
    const titleWidth = doc.getStringUnitWidth(c.title) * mmWidthFactor;
    const dotWidth = doc.getStringUnitWidth(".") * mmWidthFactor;
    const pageNumberWidth = doc.getStringUnitWidth(pageStr) * mmWidthFactor;

    doc.textWithLink(
      generateDottedRow(
        c.title,
        x + tab + titleWidth,
        x + elementPosition.contentsMaxRowWidth - pageNumberWidth,
        dotWidth
      ),
      x + tab,
      y,
      { pageNumber: c.page + contentsPageCount, magFactor: "XYZ", top: c.y }
    );

    doc.textWithLink(
      pageStr,
      x + elementPosition.contentsMaxRowWidth - pageNumberWidth,
      y,
      { pageNumber: c.page + contentsPageCount, magFactor: "XYZ", top: c.y }
    );

    y = writeLinks(
      doc,
      y + elementPosition.contentsLineSpacing,
      x,
      tab + elementPosition.contentsTreeTab,
      c.children,
      contentsPageCount,
      contentsPage
    );
  });

  return y;
}

function generateDottedRow(
  title: string,
  start: number,
  end: number,
  dotWidth: number
): string {
  const halfDotWidth = dotWidth / 2;
  while (start < end && end - start > halfDotWidth) {
    title += ".";
    start += dotWidth;
  }
  return title;
}

function getContentsHeight(contents: ContentItem[]): number {
  let acc = 0;
  contents.forEach((c) => {
    acc += elementPosition.contentsLineSpacing;
    acc += getContentsHeight(c.children);
  });
  return acc;
}
