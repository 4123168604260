// external lib dependencies
import React, { ReactNode, useCallback } from "react";
import clsx from "clsx";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";

// local dependencies
import useStyles from "./styles";
import Button from "components/Button";
import { NavLink, useParams } from "react-router-dom";
import { UrlParams } from "config/types";

interface SectionTitleBarProps {
  title: ReactNode;
  description?: ReactNode;
  descriptionColor?: string;
  boxShadow?: boolean;
  upSmBoxShadow?: boolean;
  renderAction?: () => ReactNode;
  feedbackPage?: boolean;
  feedbackFormPage?: boolean;
  peopleFeedbackPage?: boolean;
  workOrdersPage?: boolean;
  checked?: any;
  setChecked?: any;
  setActiveChecked?: any;
  projectName?: string;
  loadingEngagement?: boolean;
  engagementLoadingError?: boolean;
  engagementsPage?: boolean;
  engagementsTopBar?: boolean;
  isExpanded?: boolean;
  isStakeholder?: boolean;
  handleShowCompleted?: () => void;
}

const SectionTitleBar = ({
  title,
  boxShadow = true,
  upSmBoxShadow = false,
  renderAction,
  feedbackPage,
  feedbackFormPage,
  peopleFeedbackPage,
  workOrdersPage,
  checked,
  setChecked,
  setActiveChecked,
  projectName,
  loadingEngagement,
  engagementLoadingError,
  engagementsPage,
  engagementsTopBar,
  handleShowCompleted,
  isExpanded,
  isStakeholder,
}: SectionTitleBarProps) => {
  const { isXS } = useScreenSize();
  const { id } = useParams<UrlParams>();
  const classes = useStyles();
  const boxShadowClass = upSmBoxShadow ? classes.upSmallBoxShadow : boxShadow ? classes.boxShadow : "";

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleActiveEngagementsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveChecked(event.target.checked);
  };

  const lg = engagementsPage && 9;
  const md = engagementsPage && 9;
  const sm = engagementsPage && 7;

  const selectMediaStyle = useCallback(() => {
    return (
      <Grid alignItems="center" justifyContent={isXS ? "center" : "space-between"} container>
        <Grid item className={classes.title} lg={lg} md={md} sm={sm}>
          <Typography variant="h5">
            <Box textAlign="left" className="overflowText" title={title as string}>
              {!loadingEngagement &&
                !engagementLoadingError &&
                (Boolean(projectName) ? `${title} - ${projectName}` : title)}
            </Box>
          </Typography>
        </Grid>
        {engagementsPage && (
          <Grid item lg={3} md={3} sm={5}>
            <Box className={classes.expandContainer}>
              <Typography variant="body2">
                {isExpanded ? "Hide completed engagements" : "Show completed engagements"}
              </Typography>
              <IconButton aria-label="show-completed-engagements" size="medium" onClick={handleShowCompleted}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </Grid>
        )}
        <Grid {...(isXS && { justifyContent: "center" })} container={isXS ? true : false}>
          {renderAction && renderAction()}
        </Grid>
        {feedbackPage && (
          <Box className={classes.buttonsBox}>
            {isStakeholder && (
              <NavLink
                to={id ? `/feedback/${id}` : "/feedback"}
                style={{ textDecoration: "none" }}
                activeClassName={classes.headerLinkActive}
              >
                <Button colorVariant="black">Provide Feedback</Button>
              </NavLink>
            )}
          </Box>
        )}
        {feedbackFormPage && (
          <Box className={classes.buttonsBox}>
            <NavLink to="/view-feedback" style={{ textDecoration: "none" }} activeClassName={classes.headerLinkActive}>
              <Button colorVariant="black">View Feedback</Button>
            </NavLink>
          </Box>
        )}
        {workOrdersPage && (
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} color="primary" />}
            label="Show Active WO Only"
            labelPlacement="start"
            className={classes.checkbox}
          />
        )}
        {engagementsTopBar && !isStakeholder && (
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleActiveEngagementsChange} color="primary" />}
            label="Show only my engagements"
            labelPlacement="start"
            className={classes.checkbox}
          />
        )}
        {peopleFeedbackPage && (
          <Box className={classes.buttonsBox}>
            <Button colorVariant="black">Provide Team Feedback</Button>
            <Button colorVariant="white">Provide Individual Feedback</Button>
          </Box>
        )}
      </Grid>
    );
  }, [
    isXS,
    title,
    renderAction,
    classes.title,
    checked,
    isExpanded,
    engagementLoadingError,
    engagementsPage,
    feedbackPage,
    peopleFeedbackPage,
    handleChange,
    loadingEngagement,
    workOrdersPage,
    handleShowCompleted,
    projectName,
  ]);

  return (
    <div className={clsx(classes.bar, boxShadowClass, "section-title-bar")}>
      <Container maxWidth="xl">{selectMediaStyle()}</Container>
    </div>
  );
};

export default SectionTitleBar;
