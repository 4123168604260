//local dependencies
import { Engagement, EngagementList, Member } from "config/types";
import { FetchData } from "./FetchData";

// GET all engagements for current user/stakeholder
export async function getAllEngagements() {
  return await new FetchData("/projects/all/").GET();
}

// GET all engagements for current user/stakeholder
export async function getEngagementsPaginated(
  project_sate: string,
  page_number: number,
  search_input?: string,
  my_engagements_only: boolean = false,
  items_per_page: number = 5,
) {
  let url: string = `/projects?project_state=${project_sate}&my_engagements_only=${my_engagements_only}&page_number=${page_number}&items_per_page=${items_per_page}`;

  if (search_input) {
    url = url + `&search_input=${search_input}`;
  }

  return await new FetchData(url).GET();
}

// GET engagement (project) info by engagement_id (project_id)
export async function getEngagement(engagementId: number, signal?: AbortSignal): Promise<Engagement> {
  return await new FetchData(`/projects/${engagementId}`).GET(signal);
}

export async function getAudits(engagementId: number) {
  return await new FetchData(`/projects/${engagementId}/audits`).GET();
}

export async function getRisks(engagementId: number) {
  return await new FetchData(`/projects/${engagementId}/risks`).GET();
}

// GET engagement (project) Members by project_id
export async function getEngagementMembers(engagementId: number): Promise<Member[]> {
  return await new FetchData(`/projects/${engagementId}/members`).GET();
}

// POST data from engagement (project) contact form
export async function sendEngagementFormData(engagementId: number, data: object) {
  return await new FetchData(`/projects/${engagementId}/contact-form`).POST(data);
}

//alternative endpoints service
export async function getEngagementsList(
  project_sate: string,
  page_number: number,
  items_per_page: number = 5,
  my_engagements_only: boolean = false,
  search_input?: string,
): Promise<EngagementList[]> {
  let url: string = `/projects/engagements_list?project_state=${project_sate}&my_engagements_only=${my_engagements_only}&page_number=${page_number}&items_per_page=${items_per_page}&order=${"ascending"}`;

  if (search_input) {
    url = url + `&search_input=${search_input}`;
  }

  return await new FetchData(url).GET();
}
