import { jsPDF } from "jspdf";
import {
  fontStyle,
  fontSize,
  hAlign,
} from "./utils/configurations/font-constants";
import { elementPosition } from "./utils/configurations/element-position";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import { ContentItem } from "./types";
import { writeText } from "./utils/text-utils";

export default function generateConfidentialityPage(doc: jsPDF): ContentItem[] {
  doc.addPage();
  // generateNewPage(doc, pageNumber);
  const contents: ContentItem[] = [];

  // Confidentiality title static text
  writeText({
    doc,
    color: [34, 84, 118],
    fontSize: fontSize.confidentialityTitle,
    fontStyle: fontStyle.bold,
    text: surveyPdfContent.confidentialityTitle,
    x: elementPosition.confidentialityTitle.getX(),
    y: elementPosition.confidentialityTitle.getY(),
  });

  contents.push({
    title: surveyPdfContent.confidentialityTitle,
    page: doc.getNumberOfPages(),
    y: elementPosition.confidentialityTitle.getY(),
    children: [],
  });

  // Confidentiality static text - 1
  writeText({
    doc,
    color: "black",
    fontSize: fontSize.confidentialityText,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.confidentialityOne,
    x: elementPosition.confidentialityOne.getX(),
    y: elementPosition.confidentialityOne.getY(),
    options: {
      maxWidth: elementPosition.confidentialityMaxWidth,
      align: hAlign.left,
    },
  });

  // Confidentiality static text - 2
  writeText({
    doc,
    color: "black",
    fontSize: fontSize.confidentialityText,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.confidentialityTwo,
    x: elementPosition.confidentialityTwo.getX(),
    y: elementPosition.confidentialityTwo.getY(),
    options: {
      maxWidth: elementPosition.confidentialityMaxWidth,
      align: hAlign.left,
    },
  });

  // Confidentiality static text - 3
  writeText({
    doc,
    color: "black",
    fontSize: fontSize.confidentialityText,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.confidentialityThree,
    x: elementPosition.confidentialityThree.getX(),
    y: elementPosition.confidentialityThree.getY(),
    options: {
      maxWidth: elementPosition.confidentialityMaxWidth,
      align: hAlign.left,
    },
  });

  // Confidentiality static text - 4
  writeText({
    doc,
    color: "black",
    fontSize: fontSize.confidentialityText,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.confidentialityFour,
    x: elementPosition.confidentialityFour.getX(),
    y: elementPosition.confidentialityFour.getY(),
    options: {
      maxWidth: elementPosition.confidentialityMaxWidth,
      align: hAlign.left,
    },
  });

  // Confidentiality static text - 5
  writeText({
    doc,
    color: "black",
    fontSize: fontSize.confidentialityText,
    fontStyle: fontStyle.normal,
    text: surveyPdfContent.confidentialityFive,
    x: elementPosition.confidentialityFive.getX(),
    y: elementPosition.confidentialityFive.getY(),
    options: {
      maxWidth: elementPosition.confidentialityMaxWidth,
      align: hAlign.left,
    },
  });

  return contents;
}
