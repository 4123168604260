import React, { FC, memo } from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import Button from "../Button";
import useScreenSize from "hooks/useScreenSize";
import useStyles from "./styles";
import clsx from "clsx";

interface TitleBarProps {
  boxShadow?: boolean;
  upSmBoxShadow?: boolean;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  dialog?: JSX.Element;
  openDialog?: () => void;
  isStakeholder: boolean;
  isSuperUser?: boolean;
  adminControlls?: boolean;
}

const TitleBar: FC<TitleBarProps> = memo(
  ({
    boxShadow = true,
    upSmBoxShadow = false,
    title,
    subtitle,
    buttonText,
    dialog,
    openDialog,
    isStakeholder,
    isSuperUser,
    adminControlls,
  }) => {
    const classes = useStyles();
    const { isXS } = useScreenSize();

    const boxShadowClass = upSmBoxShadow ? classes.upSmallBoxShadow : boxShadow ? classes.boxShadow : "";

    if (adminControlls) {
      return (
        <div className={clsx(classes.bar, boxShadowClass, "section-title-bar")}>
          <Container maxWidth="xl">
            <Grid alignItems="center" justifyContent={isXS ? "center" : "space-between"} container>
              <Grid item className={classes.title}>
                <div className={classes.sectionHeader}>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="subtitle1">{subtitle}</Typography>
                </div>
              </Grid>
              {isSuperUser && (
                <Box className={classes.container}>
                  <Button colorVariant="black" onClick={openDialog} className={classes.dialogButton}>
                    {buttonText}
                  </Button>
                </Box>
              )}
            </Grid>
            {dialog}
          </Container>
        </div>
      );
    } else {
      return (
        <div className={clsx(classes.bar, boxShadowClass, "section-title-bar")}>
          <Container maxWidth="xl">
            <Grid alignItems="center" justifyContent={isXS ? "center" : "space-between"} container>
              <Grid item className={classes.title}>
                <div className={classes.sectionHeader}>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="subtitle1" className={classes.sectionSubtitle}>
                    {subtitle}
                  </Typography>
                </div>
              </Grid>
              {isStakeholder && (
                <Box className={classes.container}>
                  <Button colorVariant="black" onClick={openDialog} className={classes.dialogButton}>
                    {buttonText}
                  </Button>
                </Box>
              )}
            </Grid>
            {dialog}
          </Container>
        </div>
      );
    }
  },
);

export default TitleBar;
