import { Control, Controller } from "react-hook-form";
import { FormLabel, TextField } from "@material-ui/core";
import { HTMLInputTypeAttribute } from "react";

interface ValidationProps {
  minLength?: {
    value: number;
    message?: string;
  };
  maxLength?: {
    value: number;
    message?: string;
  };
  regexPattern?: {
    pattern: string | RegExp;
    message?: string;
  };
}

interface InputFieldProps {
  renderFormLabel?: boolean;
  labelText?: string;
  control: Control<any>;
  required?: boolean;
  errors?: string;
  name?: string;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  requiredSymbol?: boolean;
  validation?: ValidationProps;
  inputType?: HTMLInputTypeAttribute;
  defaultValue?: string;
  textFieldClassName?: string;
  formLabelClassName?: string;
  textFieldPlaceholder?: string;
  inputPropsObj?: any;
  disabled?: boolean;
  validationMessage?: string;
  autoFocus?: boolean;
}
/**
 * TextField component that is wrapped inside React Form Contoller with custom validations
 *
 * @param {boolean} renderFormLabelt Is a boolean parametar that if its true it will render a label for the form. Default value is true.
 * @param {string} labelText String parametar that represents the text of the label.
 * @param {string} errors String parametar that contains the error message after a validation.
 * @param {Control<any>} control An object of type Control for the React Component Controller.
 * @param {number} minRows Property of type number for the attribute minRows of TextField component.
 * @param {number} maxRows Property of type number for the attribute maxRows of TextField component.
 * @param {boolean} requiredSymbol Property of type boolean that if it's true it will render a span element that contains the symbol (*) for the FormLabel component.
 * @param {ValidationProps} validation Object of type ValidationProps which contains the rules for the validation of the Controller component.
 * @param {any} inputPropsObj
 * @param {string} name
 * @param {boolean} multiline Property of type boolean. If it's true it will alow multiline on the input field.
 * @param {boolean} required Property of type boolean. If it's true it will make the filed required.
 * @param {string} inputType Property of type HTMLInputTypeAttribute. Reperesents the type of the TextField component.
 * @param {string} defaultValue Property of type string. It represents the default value of the TextField component.
 * @param {string} textFieldClassName Propertu of type string. It is the value for the className attribute of TextField component.
 * @param {string} textFieldPlaceholder Propertu of type string. It is the placeholder of the TextFild component.
 * @returns React Component InputField.
 */
const InputField = ({
  renderFormLabel = true,
  labelText,
  errors,
  control,
  minRows,
  maxRows,
  requiredSymbol,
  validation,
  inputPropsObj,
  name = "",
  multiline = false,
  required = false,
  inputType = "text",
  defaultValue = "",
  textFieldClassName = undefined,
  formLabelClassName,
  textFieldPlaceholder = undefined,
  disabled = false,
  validationMessage,
  autoFocus = false,
}: InputFieldProps) => {
  const validateWhiteSpace = (value: string) => {
    if (value.trim() === "" && required) {
      return "Adding only whitespaces is not allowed";
    }

    return true;
  };

  const validateEmailForInternalMember = (value: string) => {
    const regex = new RegExp(/[a-zA-Z].+@amdaris.[a-zA-Z]+/);

    if (regex.test(value) && name === "email") {
      return `Internal members can not be added as a stakeholders`;
    }
    return true;
  };

  const validateScripts = (value: string) => {
    const regex = new RegExp("^(?!.*(?:http|https|ftp):\\/\\/.*)(?!.*<script>.*<\\/script>.*).+$");

    if (!regex.test(value)) {
      return "Invalid input. Please avoid URLs and script tags";
    }

    return true;
  };

  const defaultRules = {
    required: {
      value: required,
      message: "We require this information",
    },
    validate: {
      validateWhiteSpace,
      validateEmailForInternalMember,
      validateScripts,
    },
    minLength: {
      value: validation?.minLength?.value ?? NaN,
      message: `${validationMessage ? validationMessage : labelText} can not be less than ${validation?.minLength?.value
        } characters`,
    },
    maxLength: {
      value: validation?.maxLength?.value ?? NaN,
      message: `${labelText} can not be more than ${validation?.maxLength?.value} characters`,
    },
    pattern: {
      value: new RegExp(validation?.regexPattern?.pattern ?? ""),
      message: validation?.regexPattern?.message! || `Invalid ${labelText}`,
    }
  }

  return (
    <>
      {renderFormLabel && (
        <FormLabel
          style={{ color: "black" }}
          error={Boolean(errors)}
          data-testid="subjectLabel"
          className={formLabelClassName}
        >
          {labelText}
          {requiredSymbol && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={
          name === "phone" ? {
            required: {
              value: required,
              message: "We require this information",
            },
            validate: {
              validateWhiteSpace,
              validateEmailForInternalMember,
            },
            minLength: {
              value: validation?.minLength?.value ?? NaN,
              message: `${validationMessage ? validationMessage : labelText} can not be less than ${validation?.minLength?.value
                } characters`,
            },
            maxLength: {
              value: validation?.maxLength?.value ?? NaN,
              message: `${labelText} can not be more than ${validation?.maxLength?.value} characters`,
            },
            pattern: {
              value: new RegExp(validation?.regexPattern?.pattern ?? ""),
              message: validation?.regexPattern?.message! || `Invalid ${labelText}`,
            },
          } : defaultRules
        }
        as={
          <TextField
            fullWidth
            autoFocus={autoFocus}
            disabled={disabled}
            required={required}
            size="small"
            minRows={minRows}
            maxRows={maxRows}
            multiline={multiline}
            autoComplete="off"
            variant="outlined"
            error={Boolean(errors)}
            helperText={errors || ""}
            data-testid={`${labelText?.toLowerCase()}TextField`}
            className={textFieldClassName}
            type={inputType}
            placeholder={textFieldPlaceholder}
            inputProps={inputPropsObj}
          />
        }
      />
    </>
  );
};

export default InputField;
