import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogStyle: {
      "& .MuiDialog-paper": {
        display: "contents",
      },
    },

    modalContainer: {
      width: "35%",
      backgroundColor: "white",
      padding: theme.spacing(3.1, 6.25),
      marginTop: theme.spacing(1.1),
      borderRadius: theme.spacing(2.1),
    },

    title: {
      margin: theme.spacing(0, 1, 2, 1),
      fontWeight: 500,
      fontSize: "23.25px",
    },

    message: {
      margin: theme.spacing(1),
      fontWeight: 500,
    },

    formContainer: {
      width: "100%",
    },

    accessLevelPaper: {
      boxShadow: "none",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    accessLevel: {
      height: theme.spacing(5.1),
      width: "100%",
      display: "flex",
      "& :focus": {
        backgroundColor: "initial",
      },
      "& .MuiSelect-outlined": {
        marginLeft: theme.spacing(3.8),
      },
      "& .MuiSelect-iconOutlined": {
        left: 0,
        marginLeft: theme.spacing(0.2),
        height: "100%",
        borderRight: "1px solid #d0cdcd",
        fontSize: "32px",
        top: "0",
      },

      "& .MuiOutlinedInput-root": {
        height: theme.spacing(5.3),
      },
    },

    selectLabel: {
      fontWeight: 500,
      fontSize: "16px",
      color: "black",
      marginLeft: theme.spacing(0.75),
      display: "flex",
      alignItems: "center",
    },

    buttonsContainer: {
      width: "100%",
      paddingTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },

    addButton: {
      padding: theme.spacing(1.1),
      margin: theme.spacing(0.5, 0, 0.5, 1),
    },

    cancelButton: {
      padding: theme.spacing(1.1, 2),
      margin: theme.spacing(0.5, 1),
    },

    tooltipStyle: {
      width: "30px",
      height: "20px",
    },

    tooltipContent: {
      color: "black",
      padding: theme.spacing(1),
      maxWidth: "300px",
      fontSize: 15,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    dialogContainer: {
      margin: theme.spacing(1, 0),
    },

    dialogText: {
      color: "black",
    },

    spanMessage: {
      display: "block",
      paddingTop: theme.spacing(1),
      textAlign: "end",
    },

    field: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },

    fieldContainer: {
      backgroundColor: "#FFFFFF",
      width: "100%",
      padding: "10px 0px",
      overflow: "hidden",
    },

    //dialog modal general styles
    dialogModal: {
      "& .MuiDialog-paper": {
        borderRadius: "8px",
      },
    },

    dialogModalOffset: {
      "& .MuiDialog-paper": {
        padding: theme.spacing(1.875),
      },
    },

    dialogTitle: {
      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "20px",
      },
    },

    dialogContentText: {
      color: "#000000",
    },

    dialogActionContent: {
      padding: theme.spacing(1.25, 0, 0, 0),
    },
  }),
);

export const useConfirmAccessLevelModalStyle = makeStyles((theme: Theme) => ({
  buttonsBox: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: theme.spacing(1, 0, 0, 0),
  },

  messageLabel: {
    marginBottom: "16px",
    width: "150px",
    height: "24px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#333333",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },

  messageText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    flex: "none",
    flexGrow: 0,
  },

  boxStyle: {
    gap: 16,
    alignItems: "flex-start",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "520px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #EDEDED",
    padding: "24px",
    boxShadow: "24",
  },

  cancelButton: {
    padding: theme.spacing(1.1, 2),
    margin: theme.spacing(0.5, 1),
  },

  addButton: {
    padding: theme.spacing(1.1),
    margin: theme.spacing(0.5, 0, 0.5, 1),
  },
}));

export default useStyles;
