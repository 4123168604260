//local dependencies
import { FetchData } from "./FetchData";


// GET data about Client by stakeholders email
export async function getClientInfoAboutCurrentUser() {
  return await new FetchData("/clients/my").GET();
}

export async function invokeLogout() {
  return await new FetchData("/auth/logout").POST();
}
