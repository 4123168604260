import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  typography: {
    fontFamily: "League Gothic, sans-serif",
    fontSize: "36px",
    lineHeight: "48px",
  },

  typographyXS: {
    lineHeight: "28px",
  },
  container: {
    marginBottom: theme.spacing(0.75)
  },
}));

export default useStyles;
