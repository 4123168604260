// external lib dependencies
import { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Link, useParams } from "react-router-dom";
import { Container, Grid, Paper, Typography, Slide, Fade } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

// absolute path dependencies
import { Survey } from "config/types";
import Button from "components/Button";
import LineChart from "components/LineChart";
import SectionTitleBar from "components/SectionTitleBar";
import { useEngagementData } from "providers/EngagementDataContext";
import generateAndSaveFile from "services/pdf";
import useUpdatedAt from "hooks/useUpdatedAt";

// local dependencies
import AuditsTable from "./components/AuditsTable";
import AuditsTableSkeleton from "./components/AuditsTableSkeleton";
import useStyles from "./styles";
import AuditsReport from "./components/AuditsReport";

type AuditPageParams = {
  engagementId?: string;
  auditId?: string;
};

const Audit = () => {
  const classes = useStyles();
  const { engagementId, auditId } = useParams<AuditPageParams>();
  const { initEngagement, engagement, audits, loadingAudits, loadingEngagement, engagementLoadingError } =
    useEngagementData();
  const [activeSurvey, setActiveSurvey] = useState<Survey | null>(null);

  useEffect(() => {
    if (!engagementId) return;

    if (!engagement || engagement.id !== Number(engagementId) || !audits.length) {
      initEngagement(Number(engagementId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditId]);

  const audit = useMemo(() => {
    return audits.find((aud) => String(aud.id) === auditId);
  }, [audits, auditId]);

  const completedSortedSurveys = useMemo(() => {
    const surveys = audit?.aphiSurveys as Survey[];

    return surveys
      ?.filter((survey: Survey) => survey.isCompleted)
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [audit]);

  const updatedAt = useUpdatedAt(audit?.dataScore[0].data ?? "");

  const renderCloseButton = useCallback(
    () => (
      <Link className={classes.closeButton} to={`/engagements/`}>
        <Button colorVariant="black" fullWidth>
          Close
        </Button>
      </Link>
    ),
    [engagementId, classes]
  );

  if (!engagementId) return <Redirect to="/engagements" />;
  if (!auditId) return <Redirect to={`/engagements/${engagementId}`} />;

  const downloadPdfReport = (id: number) => {
    const survey: Survey | undefined = audit.aphiSurveys.find((s: Survey) => s.id === id);

    if (!survey) return;

    generateAndSaveFile(survey, audit.dataScore);
  };

  return (
    <div className={classes.background}>
      <SectionTitleBar
        title={audit?.title ?? ""}
        description={`Last updated ${updatedAt}`}
        descriptionColor="#989090"
        boxShadow={false}
        renderAction={renderCloseButton}
        projectName={engagement.title}
        loadingEngagement={loadingEngagement}
        engagementLoadingError={engagementLoadingError}
      />
      <Container className={classes.container} maxWidth="xl">
        {!audit && !loadingAudits ? (
          <div data-testid="noAuditMessage">
            <Paper className={classes.chartContainer} elevation={0}>
              <Typography variant="body2">Unfortunately, the audit is not available at the moment.</Typography>
            </Paper>
          </div>
        ) : (
          <Grid container spacing={3}>
            <Slide in direction="up">
              <Grid item xs={12} md={9}>
                <Paper className={classes.chartContainer} elevation={0}>
                  {loadingAudits ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <LineChart data={audit.dataScore[0].data} limits={audit.limits} />
                  )}
                </Paper>

                {loadingAudits ? (
                  <AuditsTableSkeleton data-testid="AuditsTableSkeleton" />
                ) : (
                  <Fade in>
                    <AuditsTable
                      surveys={completedSortedSurveys}
                      onDownload={downloadPdfReport}
                      setActiveSurvey={setActiveSurvey}
                      activeSurvey={activeSurvey}
                    />
                  </Fade>
                )}
              </Grid>
            </Slide>
            <Slide in direction="up">
              <Grid item xs={12} md={3}>
                <Paper className={classes.reportContainer} elevation={0} style={{ height: "100%" }}>
                  {loadingAudits ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <AuditsReport survey={activeSurvey} />
                  )}
                </Paper>
              </Grid>
            </Slide>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default Audit;
