// external lib dependencies
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Typography, Slide } from "@material-ui/core";

// absolute path dependencies
import { loginSendEmailService, verifyAccessCodeService } from "services";
import { ACCESS_CODE_RESENDING_TIMEOUT } from "config/constants";
import Button from "components/Button";
import CountdownTimer from "components/CountdownTimer";

// local dependencies
import useStyles from "./styles";


const ExpiredPage = () => {
    const history = useHistory();
    const classes = useStyles();
    const [invalidCodeMessage, setInvalidCodeMessage] = useState("");
    const [resendCodeLoading, setResendCodeLoading] = useState(false);
    const [prohibitResendCode, setProhibitResendCode] = useState(false);
    const [resendCodeMsg, setResendCodeMsg] = useState("");

    const enableAccessCodeResending = useCallback(() => setProhibitResendCode(false), []);

    const stateHistory: any = history.location?.state
    const expiredEmail: any = stateHistory.expired_email
    const referrerLink: any = stateHistory.referrerLink

    const resendAccessCode = useCallback(() => {
        setResendCodeLoading(true);
        setResendCodeMsg("");

        loginSendEmailService(expiredEmail, referrerLink)
            .then(() => {
                setResendCodeMsg("Access link was successfully resent");
                setTimeout(() => setResendCodeMsg(""), 4000);
            })
            .catch(() => {
                setInvalidCodeMessage("Please contact Amdaris to verify your access to Client Portal.");
                setTimeout(() => setInvalidCodeMessage(""), 4000);
            })
            .finally(() => {
                setProhibitResendCode(true);
                setResendCodeLoading(false);
            });
    }, [expiredEmail]);

    return (
        <Slide in direction={"up"}>
            <Form className={classes.accessCodeForm}>
                <Typography classes={{root: classes.expiredTitle}} variant="h3" align="center">Login Link Expired</Typography>
                <Button
                    disabled={prohibitResendCode}
                    loading={resendCodeLoading}
                    className={classes.resendAccessCodeBtn}
                    onClick={resendAccessCode}
                >
                    <Typography variant="body1">Send me a new link</Typography>
                </Button>
                {resendCodeMsg && (
                    <Typography variant="body2" className={classes.successfullyResendCodeMsg}>
                        {resendCodeMsg}
                    </Typography>
                )}
                {prohibitResendCode && (
                    <CountdownTimer
                        countdownSeconds={ACCESS_CODE_RESENDING_TIMEOUT}
                        onCountdownExpiration={enableAccessCodeResending}
                    />
                )}
            </Form>
        </Slide>
    );
};

export default ExpiredPage;
