// external lib dependencies
import { useEffect, useState, useRef } from "react";
import { Container, Grid, Typography, Fade, Slide, Link, Box } from "@material-ui/core";

// absolute path dependencies
import SectionTitleBar from "components/SectionTitleBar";

// local dependencies
import EngagementsGrid from "./EngagementListScroll/EngagementsGrid";
import EngagementsGridSkeleton from "./components/EngagementsGridSkeleton";
import useStyles from "./styles";
import { useActions, useAppSelector } from "store/hook";
import { EXPIRED_PROJECT_STATUS, ACTIVE_PROJECT_STATUS } from "config/constants";
import { UserLoginMethod } from "config/types";

export default function EngagementList() {
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(false);
  const classes = useStyles();
  const completedRef = useRef(null);

  const { user } = useAppSelector((state) => state.user);
  const { activeEngagementList, expiredEngagementList, loadingActiveEngagements, loadingExpiredEngagements, error } =
    useAppSelector((state) => state.engagementsList);
  const { fetchActiveEngagementList, fetchExpiredEngagementList } = useActions();

  useEffect(() => {
    let storedCheckbox = sessionStorage.getItem("activeCheckbox");
    if (storedCheckbox !== null) {
      setActiveCheckbox(Boolean(JSON.parse(storedCheckbox)));
    }

    fetchActiveEngagementList({
      page_number: 1,
      my_engagements_only: Boolean(JSON.parse(storedCheckbox ?? "false")),
    });
    fetchExpiredEngagementList({
      page_number: 1,
      my_engagements_only: Boolean(JSON.parse(storedCheckbox ?? "false")),
    });
  }, []);

  const scrollToCompletedRef = (ref: any) => {
    if (expiredEngagementList?.length !== 0) {
      ref.current.scrollIntoView(true);
    }
  };

  const handleShowCompleted = () => {
    setShowCompleted((prev) => !prev);
    setTimeout(() => {
      if (!showCompleted) {
        scrollToCompletedRef(completedRef);
      }
    }, 500);
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    sessionStorage.setItem("activeCheckbox", JSON.stringify(!activeCheckbox));
    setActiveCheckbox(!activeCheckbox);

    fetchActiveEngagementList({
      page_number: 1,
      my_engagements_only: !activeCheckbox,
    });

    fetchExpiredEngagementList({
      page_number: 1,
      my_engagements_only: !activeCheckbox,
    });
  };

  function checkStakeholderStatus() {
    if (!user) return true;
    if (user.login_method === UserLoginMethod.external) {
      return true;
    }
    return false;
  }

  return (
    <div className={classes.engagements}>
      <Grid container direction="column">
        <SectionTitleBar
          title={"Your Engagements"}
          engagementsTopBar
          checked={activeCheckbox}
          setActiveChecked={handleChangeActive}
          isStakeholder={checkStakeholderStatus()}
        />
        <Container maxWidth="xl" className={classes.container}>
          {loadingActiveEngagements ? (
            <Slide in direction="right">
              <div>
                <EngagementsGridSkeleton />
              </div>
            </Slide>
          ) : activeEngagementList?.length ? (
            <Fade in>
              <Box className={classes.cardsContainer}>
                <EngagementsGrid
                  engagements={activeEngagementList}
                  engagementsType={ACTIVE_PROJECT_STATUS}
                  activeCheckbox={activeCheckbox}
                  searchType={"current"}
                />
              </Box>
            </Fade>
          ) : (
            <Fade in>
              <Container maxWidth="xl" className={classes.container}>
                <Typography variant="h5">Hi, it looks like we need to set-up your engagements for you.</Typography>
                <Typography variant="h5">
                  Please, contact your Client Partner or alternatively email our IT Team at{" "}
                  <Link href="mailto:client@amdaris.com">client@amdaris.com</Link> to get access to your engagements.
                </Typography>
              </Container>
            </Fade>
          )}
        </Container>
        <SectionTitleBar
          title={"Completed Engagements"}
          engagementsPage
          isExpanded={showCompleted}
          handleShowCompleted={handleShowCompleted}
        />
        <Container maxWidth="xl" className={classes.container}>
          {loadingExpiredEngagements ? (
            <Slide in direction="right">
              <div>
                <EngagementsGridSkeleton />
              </div>
            </Slide>
          ) : showCompleted && expiredEngagementList?.length ? (
            <Fade in>
              <Box className={classes.cardsContainer}>
                <EngagementsGrid
                  innerRef={completedRef}
                  engagements={expiredEngagementList}
                  engagementsType={EXPIRED_PROJECT_STATUS}
                  activeCheckbox={activeCheckbox}
                  searchType={"completed"}
                />
              </Box>
            </Fade>
          ) : (
            !expiredEngagementList?.length && (
              <Fade in>
                <Container maxWidth="xl" className={classes.container}>
                  <Typography variant="h5">Sorry, you don't have completed engagements yet</Typography>
                </Container>
              </Fade>
            )
          )}
        </Container>
      </Grid>
    </div>
  );
}
