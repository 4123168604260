import { Box, Button, Typography } from "@material-ui/core";
import HeaderBar from "components/HeaderBar/HeaderBar";
import { FC, useState } from "react";

import useStyles from "../styles";
import ReleaseNoteDialog from "./ReleaseNoteDialog";

interface ReleaseNotesHeaderProps {
  isSuperUser: boolean;
}

const ReleaseNotesHeader: FC<ReleaseNotesHeaderProps> = ({ isSuperUser }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <HeaderBar>
        <Box className={classes.headerContainer}>
          <Typography className={classes.headerTitle} variant="h5">
            What's new on Client Portal?
          </Typography>
          {isSuperUser && (
            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
              Add new release note
            </Button>
          )}
        </Box>
      </HeaderBar>
      {open && <ReleaseNoteDialog open={open} handleClose={handleCloseDialog} />}
    </>
  );
};

export default ReleaseNotesHeader;
