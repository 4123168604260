export function getEmployeesNameInitials(employeeName: string | null): string {
  return employeeName
    ? employeeName.split(" ").reduce((acc, curr, index) => {

      const length: number = employeeName.split(" ").length - 1;
      if (index === 0 || index === length) {
        acc = acc + curr.slice(0, 1);
      }

      return acc;
    }, "")
    : "";
}
