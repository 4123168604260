// external lib dependencies
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// local dependencies
import { FC } from "react";

interface Props {
  value: number;
}

const PercentageScore: FC<Props> = (props) => {
  return (
    <Box
      position="relative"
      display="inline-flex"
    >
      <CircularProgress variant="determinate" value={props.value} size={65} style={{color: '#003366'}}/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

export default PercentageScore;