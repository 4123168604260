// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
      "& .MuiTableCell-root": {
        whiteSpace: "nowrap",
      }
    },

    icon: {
      width: 30,
      margin: theme.spacing(2),
    },

    fileIconNameArea: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    actionsButtons: {
      display: "flex",
      justifyContent: "center",
    },

    tableCell: {
      whiteSpace: "nowrap",
    },

    dropMainContainer: {
      display: "flex",
      flexDirection: "column",
    },

    dropFilesContainer: {
      margin: theme.spacing(2),
      textAlign: "center"
    },

    dropButton: {
      margin: theme.spacing(2),
      alignItems: "center"
    },

    dropContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 20,
      height: '100px!important',
      border: "4px dashed #3f51b5",
      padding: theme.spacing(1),
      transition: "border 0.3s",

      "&:hover": {
        border: "4px dashed #003366 !important",
      },
    },

    dropMessage: {
      textAlign: "center",
      color: "#4aa1f3",
      fontFamily: "Arial",
      fontSize: 20,
      whiteSpace: 'normal',
      width: '100%',
      Height: '100%'
    },

    fileInput: {
      display: "none",
    },

    chipRoot: {
      display: 'flex',
    },

    chipLabel: {
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '10px',
      [theme.breakpoints.down("md")]: {
        fontSize: '15px',

      },
    },

    eyeIcon: {
      marginLeft: "5px"
    },

    passwordCheckContainer: {
      display: "flex",
    },

    passwordCheckText: {
      lineHeight: "24px"
    },

    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between"
    },

    inputSelectItem: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },

    smallTextSpan: {
      fontSize: "10px",
      marginLeft: "1rem",
      marginRight: "1rem",
      marginBottom: "1rem"
    },

    mediumTextSpan: {
      fontSize: "15px",
      width: theme.spacing(60),
      padding: "16px 32px",
      color: "red"
    },

    passwordFormLabels: {
      fontSize: "17px"
    },

    sensitiveDataButton: {
      margin: theme.spacing(2),
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#D9D9D9",
      color: "black",
      "&:hover": {
        backgroundColor: "#363636",
        color: "white"
      },
    },

    sensitiveDataButtonSubmit: {
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#D9D9D9",
      color: "black",
      "&:hover": {
        backgroundColor: "#363636",
        color: "white"
      },
    },

    sensitiveDataButtonClose: {
      marginBottom: "2px !important",
      alignItems: "center",
      backgroundColor: "#363636",
      color: "white",
      "&:hover": {
        backgroundColor: "#D9D9D9",
        color: "black"
      },
    },

    //Password field styles
    inputLabelFont: {
      color: theme.palette.type === "dark" ? "#FFF" : "black",
      fontSize: "13px",
    },

    field: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },

    fieldContainer: {
      width: theme.spacing(60),
      padding: "16px 32px",
      overflow: "hidden",
    },

    fieldContainerXS: {
      padding: "16px 32px",
      width: "75vw",
      overflow: "hidden",
    },

    // InputField styles
    fieldInput: {
      width: "100%",
    },

    // MessageForm styles
    sectionContainer: {
      paddingTop: "16px",
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      borderTop: "1px solid #e0e0e0",
    },
  })
);

const useGdprModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    gdprTextContainer: {
      backgroundImage: 'linear-gradient(to top, transparent 30%, currentColor 100%)',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
    },
    gdprTextStyle: {
      color: 'transparent',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    titleStyle: {
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: '25px',
      textAlign: 'center',
      marginBottom: 'revert',
    },
    leftCorner: {
      border: '2px solid #034DA2',
      padding: '4px',
      width: 'fit-content',
      color: '#034DA2',
      fontFamily: 'sans-serif',
      fontWeight: 800,
      borderRadius: '8%',
    },
    modalContainerStyle: {
      position: 'absolute' as 'absolute',
      width: "580px",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      borderRadius: '20px',
      boxShadow: "24",
      padding: '32px',
    },
  })
);

const useDeleteFileDialogStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      "& .MuiPaper-root": {
        padding: "20px 35px",
        width: "100%",
      },
      '& .MuiDialogTitle-root': {
        padding: '0px 0px 10px 5px',
      }
      
    },
    content: {
      paddingLeft: '5px',
      color: '#000',
    },
    textField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
      },
    },
    buttonsContainer: {
      marginTop: '12px',
      padding: 0,
    }
  })
);

export { useStyles, useGdprModalStyles, useDeleteFileDialogStyle };
