import { createTheme } from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { ThemeStruct } from "../themes";

const muiTheme = createTheme({
  typography: {
    fontFamily: "Roboto",
    h5: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "36px",
    },
    h6: {
      fontSize: "18px",
    },
    subtitle1: {
      color: "#071E4E",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0.25px",
    },
  },

  palette: {
    background: {
      default: "#F5F5F5",
    },
    success: {
      main: "#2DB57140",
    },
    warning: {
      main: "#F9D749",
    },
    primary: {
      main: "#071E4E",
      dark: "#03172B",
      light: "#132536",
    },
    secondary: {
      main: "#333333",
    },
    info: {
      main: "#005EB840",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        backgroundColor: "#071E4E",
        boxShadow: "none",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#071E4E",
          boxShadow: "none",
        },
      },
      containedSecondary: {
        backgroundColor: "rgba(7, 30, 78, 0.04)",
        color: "#333333",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "rgba(7, 30, 78, 0.04)",
          boxShadow: "none",
        },
        marginRight: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },

    MuiChip: {
      colorSecondary: {
        backgroundColor: "rgb(242, 71, 60)",
        color: "#ffffff",
        "& .MuiChip-deleteIconColorSecondary": {
          color: "rgba(255, 255, 255, 0.7)",
        },
        "& .MuiChip-deleteIconColorSecondary:hover": {
          color: "#ffffff",
        },
      },
    },

    MuiContainer: {
      //default configuration for page container
      maxWidthXl: {
        "@media (min-width: 600px)": {
          paddingLeft: "40px",
          paddingRight: "40px",
          paddingTop: "40px",
          paddingBottom: "40px",
        },
        padding: "40px 40px 40px 40px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      },
    },

    MuiPaper: {
      //default configuration for surface
      elevation0: {
        padding: "24px",
        borderRadius: "6px",
        border: "1px solid #EDEDED",
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.04)",
      },
    },

    MuiGrid: {
      //default configuration for grid spacing between components in surface
      "spacing-xs-2": {
        "& > .MuiGrid-item": {
          padding: "6px",
        },
      },
    },

    MuiDialog: {
      //default configuration for modal surface
      paper: {
        borderRadius: "8px",
      },
    },
  },
});

const themeStruct: ThemeStruct = {
  muiTheme: muiTheme,
  isDark: muiTheme.palette.type === "dark",
  tickFillColor: "#000000",
  tickFontSize: 11,
  tooltipBgColor: "#ffffff",
  gradientOffset0Color: "#A3FDD1",
  gradientOffset50Color: "#4DAFC2",
  gradientOffset100Color: "#0A65BB",
  cartesianGridColor: "#d6d6d6",
  legendStrongColor: "#4CAF50",
  legendNeutralColor: "#F9A825",
  legendWeakColor: "#F44336",
  lineFillDefaultColor: "#2196F3",
  lineFillGreenColor: "#4CAF50",
  lineFillYellowColor: "#F9A825",
  lineFillRedColor: "#F44336",
  auditCellButtonColor: "black",
  brightnessIcon: <Brightness4Icon />,
};

export default themeStruct;
