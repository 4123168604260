import { IStakeholderFormState } from "pages/Stakeholders/types";
import { FetchData } from "./FetchData";

interface IServerResponce {
  _id: number;
  full_name: string | null;
  email?: string;
  phone?: string;
  role?: string;
  company_name?: string;
  invited_by?: string | null;
  access_level: number;
  from_advance: boolean;
}

export async function addStakeholder(
  data: IStakeholderFormState,
  project_id: number,
  signal: AbortSignal,
): Promise<IServerResponce> {
  return await new FetchData(`/stakeholders/?project_id=${project_id}`).POST(data, signal);
}

export async function editStakeholder(
  data: IStakeholderFormState,
  stakeholder_id: number,
  project_id: number,
  signal: AbortSignal,
): Promise<IServerResponce> {
  return await new FetchData(`/stakeholders/?stakeholder_id=${stakeholder_id}&project_id=${project_id}`).PATCH(
    data,
    signal,
  );
}

export async function deleteStakeholder(
  stakeholder_id: number,
  project_id: number,
  signal: AbortSignal,
): Promise<IServerResponce> {
  return await new FetchData(`/stakeholders/?stakeholder_id=${stakeholder_id}&project_id=${project_id}`).DELETE(signal);
}
