import React from 'react';
import {Box, Grid} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import useStyles from "./styles";

const GridItem = () => {
    const classes = useStyles();
    return (
        <Grid item>
            <Skeleton variant="text" width={180}/>
            <Box className={classes.profileGroupFields}>
                <Skeleton variant="circle" width={40} height={40}/>
                <Skeleton variant="text" width={100}/>
            </Box>
        </Grid>
    )
}

const ProfileCardSkeleton = () => {
    const classes = useStyles()

    return (
        <Grid container direction="row">
            <Grid item xs={1}>
                <Skeleton variant="circle" className={classes.avatarRoot} />
            </Grid>
            <Grid item xs={10}>
                <Box className={classes.profileNameField}>
                    <Skeleton variant="text" width={300}/>
                </Box>
                <Grid container>
                    <Grid item xs={3}>
                        <Grid container direction="column" spacing={2}>
                            <GridItem />
                            <GridItem />
                            <GridItem />
                            <GridItem />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container direction="column" spacing={2}>
                            <GridItem />
                            <GridItem />
                            <GridItem />
                            <GridItem />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProfileCardSkeleton;