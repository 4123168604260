import { jsPDF } from "jspdf";
import {
  fontStyle,
  fontSize,
  hAlign,
} from "./utils/configurations/font-constants";
import { elementPosition } from "./utils/configurations/element-position";
import { imageDimension } from "./utils/configurations/image-dimension";
import { surveyPdfContent } from "./utils/survey-pdf-content";
import { getShortDate } from "./utils/time-utils";
import backGroundImg from "assets/images/survey-summary-pdf/first-page-background.png";
import amdarisFirstPageImg from "assets/images/survey-summary-pdf/amdaris-first-page.png";
import { Survey } from "../../config/types";
import { writeText } from "./utils/text-utils";

export default function generateFirstPage(doc: jsPDF, survey: Survey) {
  // first page
  // Background image
  doc.addImage(
    backGroundImg,
    "png",
    elementPosition.firstPageBackground.getX(),
    elementPosition.firstPageBackground.getY(),
    imageDimension.fullPageImagePortrait.getWidth(),
    imageDimension.fullPageImagePortrait.getHeight()
  );

  // Amdaris first page logo
  doc.addImage(
    amdarisFirstPageImg,
    "png",
    elementPosition.firstPageAmdaris.getX(),
    elementPosition.firstPageAmdaris.getY(),
    imageDimension.logoFirstPageImage.getWidth(),
    imageDimension.logoFirstPageImage.getHeight()
  );

  doc.setFontSize(fontSize.surveySummary);

  const lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
  let titleText = survey.aphiTemplateTitle;

  const countOfLines = getCountOfLines(doc, titleText);
  let countOfLineShiftsFromStartPoint = 0;
  let titleFontSize = fontSize.surveySummary;

  if (countOfLines < 3) {
    countOfLineShiftsFromStartPoint = 3;
  } else if (countOfLines >= 3 && countOfLines <= 5) {
    countOfLineShiftsFromStartPoint = 1;
  } else if (countOfLines >= 6 && countOfLines <= 8) {
    fontSize.surveySummary = 0;
    titleFontSize -= 6;
  } else if (countOfLines > 8) {
    countOfLineShiftsFromStartPoint = 0;
    titleFontSize -= 8;
  }

  let titleY =
    elementPosition.projectStatusReportProjectName.getY() +
    countOfLineShiftsFromStartPoint * lineHeight;
  let titleLineWidth = elementPosition.startingTitleWidth

  writeText({
    doc,
    color: "white",
    fontStyle: fontStyle.bold,
    fontSize: titleFontSize,
    text: '',
    x: elementPosition.projectStatusReportProjectName.getX(),
    y: titleY,
    options: { maxWidth: titleLineWidth, align: hAlign.left },
  });

  while (titleText && titleLineWidth > 0) {
    if (
      countOfLineShiftsFromStartPoint >= elementPosition.startDecreaseWidthFrom
    ) {
      titleLineWidth -= elementPosition.lineWidthDecreaseFactor;
    } else {
      countOfLineShiftsFromStartPoint++;
    }

    let titleLine = doc.splitTextToSize(titleText, titleLineWidth)[0];
    const nextText = titleText.substring(titleLine.length, titleText.length);
    if (nextText && nextText[0] !== " ") {
      titleLine = titleLine.slice(0, titleLine.length - 1) + "-";
    }

    writeText({
      doc,
      color: "white",
      fontStyle: fontStyle.bold,
      fontSize: titleFontSize,
      text: titleLine.trim(),
      x: elementPosition.projectStatusReportProjectName.getX(),
      y: titleY,
      options: { maxWidth: titleLineWidth, align: hAlign.left },
    });

    titleY += lineHeight;
    const dash = titleLine[titleLine.length - 1] === "-" ? 1 : 0;
    titleText = titleText.substring(titleLine.length - dash, titleText.length);
  }

  const projectNameY = titleY + lineHeight;

  doc.setFont("Helvetica", fontStyle.normal);
  doc.setFontSize(fontSize.projectName);
  doc.text(
    survey.entityName ?? "",
    elementPosition.projectName.getX(),
    projectNameY
  );

  //do not display score if survey is in draft status
  if (survey.isCompleted && survey.percentageScore > 0) {
    // Score static text
    doc.setFontSize(fontSize.projectName);
    doc.text(
      surveyPdfContent.score,
      elementPosition.score.getX(),
      projectNameY + fontSize.tableContent
    );

    // "Score" value
    doc.setFontSize(fontSize.score);
    doc.setFont("Helvetica", fontStyle.bold);
    doc.text(
      survey.percentageScore.toString() + "%",
      elementPosition.scoreNumber.getX(),
      projectNameY + fontSize.tableContent
    );
  }
  doc.setFont("Helvetica", fontStyle.normal);

  // Template details
  doc.setFontSize(fontSize.template);
  doc.text(
    getShortDate(survey.createdAt) +
      surveyPdfContent.providedBy +
      survey.createdBy,
    elementPosition.templateDetails.getX(),
    elementPosition.templateDetails.getY()
  );
}

function getCountOfLines(doc: jsPDF, text: string): number {
  let titleLineWidth = elementPosition.startingTitleWidth;

  let countOfLine = 0;
  let i = 0;
  while (text && titleLineWidth > 0) {
    if (i >= elementPosition.startDecreaseWidthFrom) {
      titleLineWidth -= elementPosition.lineWidthDecreaseFactor;
    } else {
      i++;
    }

    const titleLine = doc.splitTextToSize(text, titleLineWidth)[0];

    text = text.substring(titleLine.length, text.length);
    countOfLine++;
  }

  return countOfLine;
}
