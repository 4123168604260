import { coordinate } from "./coordinate";

export const elementPosition = {
  // Header image
  amdarisHeader: coordinate(159.25, 16),

  // Footer images
  globe: coordinate(24.5, 278.5),
  mail: coordinate(49.5, 278.5),
  phone: coordinate(75, 278.5),

  // Last page images
  lastPageBackground: coordinate(0, 0),
  exceptionalSoftwareTeams: coordinate(25, 130),
  exceptionalSoftwareTeamsLandscape: coordinate(68.5, 85),
  lastPageAddress: coordinate(30, 230),
  lastPageAddressLandscape: coordinate(71, 160),

  // First page images
  firstPageAmdaris: coordinate(19, 33.5),
  firstPageBackground: coordinate(0, 0),
  draft: coordinate(18, 190),

  // First page text
  abbreviated: coordinate(19, 100),
  title: coordinate(19, 121),
  startingTitleWidth: 180,
  lineWidthDecreaseFactor: 13,
  startDecreaseWidthFrom: 3,
  lineShift: 11,
  projectName: coordinate(19, 166.5),
  score: coordinate(19, 178),
  scoreNumber: coordinate(38, 178.5),
  template: coordinate(19.5, 224.5),
  templateDetails: coordinate(19.5, 266.5),

  // Confidentiality page text
  confidentialityTitle: coordinate(22, 50),
  confidentialityOne: coordinate(22, 65),
  confidentialityTwo: coordinate(22, 73),
  confidentialityThree: coordinate(22, 94),
  confidentialityFour: coordinate(22, 111),
  confidentialityFive: coordinate(22, 159),
  confidentialityMaxWidth: 164,

  // Footer text
  site: coordinate(30, 280),
  email: coordinate(55, 280),
  phoneNumber: coordinate(80, 280),
  footerAddress: coordinate(24.5, 285.5),
  pageNumber: coordinate(180, 285.5),

  // Contents text
  contents: coordinate(23.5, 43.75),
  contentsDottedRowMaxWeight: 41.7,
  contentsMaxRowWidth: 165,
  contentsLineSpacing: 6,
  contentsTreeTab: 4,
  dotWidth: 0.28,
  dotHalfWidth: 0.14,

  // Audit Sections text
  sections: coordinate(23.5, 43.75),
  auditSectionsScore: coordinate(195, 43.75),
  sectionNameMaxLength: 140,
  sectionScoreMaxLength: 60,

  // Charts text
  charts: coordinate(23.5, 43.75),
  scoreSectionDescription: coordinate(23.5, 55),
  selectiveScoreDescription: coordinate(23.5, 67),

  // Tables
  tableTopMargin: 5,
  emptyTableRowTopMargin: 3,
  tableTopPageMargin: 30,
  tableBottomPageMargin: 20,

  // Chart image
  chart: coordinate(23.5, 82),

  // Notes
  notesText: coordinate(23.5, 60),
  notesFirstPage: coordinate(23.5, 76),
  notes: coordinate(23.5, 35),
  notesMaxWidth: 164,

  // Engagement Status Report
  projectStatusReport: coordinate(19, 135),
  projectStatusReportProjectName: coordinate(19, 71),
  projectStatusReportProjectNameStartingTitleWidth: 130,
  projectStatusReportProjectNameLineWidthDecreaseFactor: 10,
  projectStatusReportTitle: coordinate(19, 45),
  projectStatusReportContent: coordinate(19, 60),
  projectStatusReportHtml: coordinate(19, 50),
  projectStatusReportRagStatus: coordinate(26, 0),
  projectStatusReportContentWidth: 180,
  projectStatusReportMaxHeight: 250,
  projectStatusReportStatusColumnWidth: 35,

  // Audit survey info max height
  maxHeightSurveyInfo: 250,

  // Audit survey summary max height
  maxHeightSurveySummary: 250,

  // Text dimension
  textDimension: 250,

  // Survey info margins
  surveyInfoMargin: 10,

  // Score progress description
  scoreSectionDescriptionMaxWidth: 170,

  //Audit survey
  spaceBetweenSurveysTables: 15,
  minTableHeight: 30,

  titleMaxWidth: 170,
};
