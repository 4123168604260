import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';


const WorkOrderSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid container direction="row">
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" component="span">Work Orders</Typography>
          <Skeleton width={300} height={40}/>
        </Box>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell variant="head" width={100}>Reference No</TableCell>
                <TableCell width={100}>Project Name</TableCell>
                <TableCell width={100}>Start Date</TableCell>
                <TableCell width={100}>End Date</TableCell>
                <TableCell width={100}>Allocation</TableCell>
                <TableCell width={100}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={50}/>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default WorkOrderSkeleton;