// external lib dependencies
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => createStyles({
    textButton: {
      borderRadius: 0,
    },

    iconWhite: {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#007bff",
        color: theme.palette.common.white,
      },
    },

    iconBlack: {
      color: "#363636",
      "&:hover": {
        color: "#FF6666",
      },
    },

    textWhite: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      "&:hover": {
        backgroundColor: "#007bff",
        color: theme.palette.common.white,
      },
    },

    textBlack: {
      backgroundColor: "#363636",
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: "#FF6666",
      },
    },
  })
);

export default useStyles;
