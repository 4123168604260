// external lib dependencies
import { useCallback, useState } from "react";

// absolute path dependencies
import { sendEngagementFormData } from "services/engagementsService";


const useSubmitHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sent, setSent] = useState(false);

  const sendData = useCallback((engagementId: number, data: object) => {
    sendEngagementFormData(engagementId, data).then(() => {
        setLoading(false); 
        setSent(true)
      })
      .catch(() => {
        setLoading(false);
        setError('Messaging service is unavailable at the moment. Can you please try again later ?');        
      });
  }, []);

  return {sendData, loading, error, sent};
};

export default useSubmitHandler;
