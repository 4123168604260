import { Box, BoxProps, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

interface ISearchBarProps {
  textFieldProps?: TextFieldProps;
  boxProps?: BoxProps;
}

/**
 * It is a custom component that contains TextField component inside a Box component, used as search field.
 * @param {TextFieldProps} textFieldProps It is an object of type TextFieldProps
 * @param {BoxProps} boxProps It is an object of type BoxProps
 * @returns
 */
export default function SearchBar({ textFieldProps, boxProps }: ISearchBarProps) {
  return (
    <Box className={boxProps?.className}>
      <TextField
        style={textFieldProps?.style ?? { width: "100%" }}
        variant={textFieldProps?.variant ?? "outlined"}
        disabled={textFieldProps?.disabled ?? false}
        size={textFieldProps?.size ?? "small"}
        placeholder={textFieldProps?.placeholder}
        value={textFieldProps?.value}
        onChange={textFieldProps?.onChange}
        InputProps={
          textFieldProps?.InputProps ?? {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }
        }
      />
    </Box>
  );
}
