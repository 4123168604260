import { Container } from "@material-ui/core";
import { useAppSelector } from "store/hook";
import ReleaseNotesHeader from "./components/ReleaseNotesHeader";
import ReleaseNotesContent from "./components/ReleaseNotesContent";

const ReleaseNotesPage = () => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <Container maxWidth="xl">
      <ReleaseNotesHeader isSuperUser={Boolean(user?.is_superuser)} />
      <ReleaseNotesContent isSuperUser={Boolean(user?.is_superuser)} />
    </Container>
  );
};

export default ReleaseNotesPage;
