// external lib dependencies
import React, { FC, useState, useRef, useEffect } from "react";
import { Controller, useFormContext, useForm } from "react-hook-form";
import { Container, FormLabel, RadioGroup, FormControlLabel, Radio, FormControl } from "@material-ui/core";

// local dependencies
import { selectOptions } from "./questions";
import useStyles from "../styles";


interface Props {
  key?: number;
  required?: boolean;
  question: string;
  label: string;
  variant?: string;
}

const InputRadio: FC<Props> = ({ key, required = false, question, label, variant }) => {
  const classes = useStyles();

  const { control, formState: { errors } } = useFormContext();
  const { register } = useForm();
  const [selected, setSelected] = useState("");

  let radioInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (`${label}` in errors && radioInputRef.current) {
      register(radioInputRef.current);
      radioInputRef.current.focus();
    }
  }, [errors, label, register]);

  return (
    <Container key={key} className={classes.wrapper} data-testid="radioInput">
      <FormLabel
        error={Boolean(errors[`${label}`])}
        className={classes.inputLabelFont}
      >
        {question}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </FormLabel>
      <Controller
        as={
          <FormControl component="fieldset">
            <RadioGroup name={label} value={selected} onChange={handleChange}>
              {
                variant === "variant3" ? (
                  selectOptions[2].map((option, index) => (
                    <FormControlLabel
                      inputRef={(e) => {
                        register(e);
                        radioInputRef.current = e;
                      }}
                      key={index}
                      value={selectOptions[2][index]}
                      control={<Radio color="primary" />}
                      label={option}
                    />
                  ))
                ) : variant === "variant1" ? (
                  selectOptions[0].map((option, index) => (
                    <FormControlLabel
                      inputRef={(e) => {
                        register(e);
                        radioInputRef.current = e;
                      }}
                      key={index}
                      value={selectOptions[0][index]}
                      control={<Radio color="primary" />}
                      label={option}
                    />
                  ))
                ) : (
                  selectOptions[3].map((option, index) => (
                    <FormControlLabel
                      inputRef={(e) => {
                        register(e);
                        radioInputRef.current = e;
                      }}
                      key={index}
                      value={selectOptions[3][index]}
                      control={<Radio color="primary" />}
                      label={option}
                    />
                  ))
                )
              }
            </RadioGroup>
          </FormControl>
        }
        name={`${label}`}
        defaultValue=""
        control={control}
        rules={{ required: "Please select an option !" }}
      />
    </Container>
  );
};

export default InputRadio;
