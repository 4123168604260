import { createTheme } from "@material-ui/core";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import { ThemeStruct } from "../themes";

const muiTheme = createTheme({
  typography: {
    fontFamily: "Libre Franklin",
    body1: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    h3: {
      fontFamily: "league-gothic, sans-serif",
      fontSize: "40px",
      lineHeight: "48px",
    },
    h4: {
      fontFamily: "league-gothic, sans-serif",
      fontSize: "36px",
      lineHeight: "40px",
    },
    button: {
      fontWeight: 500,
      letterSpacing: "0.1em",
    },
    h5: {
      fontSize: "16px",
      fontWeight: 600,
    },
    h6: {
      fontSize: 18,
      letterSpacing: 0.9,
      lineHeight: "24px",
      fontFamily: "Libre Franklin",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "12px",
      letterSpacing: "1.4px",
      color: "#989090",
    },
    body2: {
      fontSize: "14px",
    },
  },

  palette: {
    type: "dark",
    success: {
      main: "#2DB57140",
    },
    warning: {
      main: "#FFCC6640",
    },
    secondary: {
      main: "#FF666640",
    },
    info: {
      main: "#005EB840",
    },
  },
});

const themeStruct: ThemeStruct = {
  muiTheme: muiTheme,
  isDark: muiTheme.palette.type === "dark",
  tickFillColor: "#ffffff",
  tickFontSize: 11,
  tooltipBgColor: "#424242",
  gradientOffset0Color: "#A3FDD1",
  gradientOffset50Color: "#4DAFC2",
  gradientOffset100Color: "#0A65BB",
  cartesianGridColor: "#d6d6d6",
  legendStrongColor: "#4CAF50",
  legendNeutralColor: "#F9A825",
  legendWeakColor: "#F44336",
  lineFillDefaultColor: "#2196F3",
  lineFillGreenColor: "#4CAF50",
  lineFillYellowColor: "#F9A825",
  lineFillRedColor: "#F44336",
  auditCellButtonColor: "white",
  brightnessIcon: <Brightness7Icon />,
};

export default themeStruct;
