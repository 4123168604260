// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarRoot: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        profileNameField: {
            marginBottom: theme.spacing(1),
        },
        profileGroupFields: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(1),
            "& span": {
                marginLeft: theme.spacing(0.6)
            }
        },
        avatarBackgroundDefault: {
            background: "#e0e0e0",
        },
        avatarMainBackgroundColor: {
            background: "#003366",
            color: theme.palette.common.white,
        },
        profileIconButton: {
          height: 24,
          width: 24,
          color: "inherit"
        },

        memberProfile: {
            display: "flex",
            flexDirection: "row",
            gap: theme.spacing(2),
            width: "100%",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        },

        memberAvatar: {
            marginRight: theme.spacing(3),
            [theme.breakpoints.down("md")]: {
                marginRight: 0,
            },
        },

        memberDetails: {
            flex: 0.6,
            [theme.breakpoints.down("md")]: {
                marginTop: theme.spacing(2),
                flex: 1
            },
        },

        memberDetailsContent: {
            display: "flex",
            gap: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
            },
        },

        memberDetailsColumn: {
            flex: 0.4,
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
            [theme.breakpoints.down("sm")]: {
                flex: 0.5,
            },
            [theme.breakpoints.down("xs")]: {
                flex: 1,
            },
        },
    })
);

export default useStyles;
