import React, {useState} from 'react';
import {Typography, Box, Grid, Avatar, IconButton,} from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupIcon from '@material-ui/icons/Group';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import {FILES_BASE_URL} from "config/constants";
import {getEmployeesNameInitials} from "helpers/getEmployeesNameInitials";
import useStyles from "./styles";
import {ManageTags} from "components/ManageTags/ManageTags";
import {useParams} from "react-router-dom";
import {Params} from "../../types";
import {useAppSelector} from "../../../../store/hook";
import {getTagsByMemberId} from "../../../../store/reducers/tag/tagSlice";
import { canEditTeamsAndTags } from 'helpers/canEditTeamsAndTags';


interface GridItemProps {
    subHeaderText: any;
    icon: any;
    value: any;
    actionAtIcon?: (arg1: React.MouseEvent) => void;
}

interface ProfileCardProps {
    profileData?: any;
}

const GridItem = (props: GridItemProps) => {
    const classes = useStyles();
    return (
        <Grid item>
            <Typography variant="subtitle2" component="span">{props.subHeaderText}</Typography>
            <Box className={classes.profileGroupFields}>
                {props.actionAtIcon ? (
                    <IconButton onClick={props.actionAtIcon} className={classes.profileIconButton}>
                        {props.icon}
                    </IconButton>
                ) : props.icon}
                <Typography variant="subtitle1" component="span">{props.value}</Typography>
            </Box>
        </Grid>
    )
}

const ProfileCard = (props: ProfileCardProps) => {
    const classes = useStyles();
    const { id, employee_id } = useParams<Params>();
    const { user } = useAppSelector((state) => state.user);
    const memberTags = useAppSelector(state => getTagsByMemberId(state, employee_id));
    const [anchorTagMenu, setAnchorTagMenu] = useState<null | Element>(null);
    const member = {
        employee_id: employee_id,
        employee_name: props.profileData?.member_name,
    };

    const handleOpenTagMenu = (e: React.MouseEvent) => {
        setAnchorTagMenu(e.currentTarget)
    };

    const handleCloseTagMenu = () => {
        setAnchorTagMenu(null)
    };

    return (
        <Box className={classes.memberProfile}>
            <Box className={classes.memberAvatar}>
                <Avatar
                    src={props.profileData?.employee_photo_id ?
                        `${FILES_BASE_URL}/images/${props.profileData?.employee_photo_id}/thumbnail/blob` : ""}
                    classes={{
                        root: classes.avatarRoot,
                        colorDefault: props.profileData?.member_name ?
                            classes.avatarMainBackgroundColor : classes.avatarBackgroundDefault,
                    }}
                >
                    {getEmployeesNameInitials(props.profileData?.member_name)}
                </Avatar>
            </Box>
            <Box className={classes.memberDetails}>
                <Box className={classes.profileNameField}>
                    <Typography variant="h5" component="span">{props.profileData?.member_name}</Typography>
                </Box>
                <Box className={classes.memberDetailsContent}>
                    <Box className={classes.memberDetailsColumn}>
                        <Grid container direction="column" spacing={2}>
                            <GridItem
                                subHeaderText={"Role"}
                                value={props.profileData?.member_role}
                                icon={<AccountBoxIcon/>}
                            />
                            <GridItem
                                subHeaderText={"Project"}
                                value={props.profileData?.project_title}
                                icon={<ListAltIcon/>}
                            />
                            <GridItem
                                subHeaderText={"Teams"}
                                value={props.profileData?.groups?.join(", ")}
                                icon={<GroupIcon/>}
                            />
                            <GridItem
                                subHeaderText={"Allocation"}
                                value={props.profileData?.allocation_percentage+'%'}
                                icon={<QueryBuilderIcon/>}
                            />
                        </Grid>
                    </Box>
                    <Box className={classes.memberDetailsColumn}>
                        <Grid container direction="column" spacing={2}>
                            <GridItem
                                subHeaderText={"Email"}
                                value={props.profileData?.email}
                                icon={<MailOutlineIcon/>}
                            />
                            <GridItem
                                subHeaderText={"Tags"}
                                value={memberTags.join(", ")}
                                icon={<LocalOfferIcon/>}
                                actionAtIcon={handleOpenTagMenu}
                            />
                            <GridItem
                                subHeaderText={"Start date on the project"}
                                value={props.profileData?.start_date}
                                icon={<CalendarTodayIcon/>}
                            />
                            <GridItem
                                subHeaderText={"End date on the project"}
                                value={new Date() > new Date(props.profileData?.end_date) ? props.profileData?.end_date : '-'}
                                icon={<CalendarTodayIcon/>}
                            />
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {anchorTagMenu && (
              <ManageTags
                item={member}
                openTagMenu={Boolean(anchorTagMenu) && canEditTeamsAndTags(user, parseInt(id))}
                anchorRef={anchorTagMenu}
                handleCloseTagMenu={handleCloseTagMenu}
              />
            )}
        </Box>
    );
};

export default ProfileCard;
