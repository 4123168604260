//alternative types for alternative endpoints
export interface EngagementList {
  id: number;
  title: string;
  company_name: string;
  team_members_count: number;
  active_workorders_count: number;
  feedback_project_count: number;
  stakeholders_count: number;
  audits_info: AuditInfo[];
  status: string;
}

export interface Audit {
  id: number;
  auditId: number;
  completedAt: Date;
  providedBy: string;
  score: number;
  notes: string;
  status: "draft" | "completed";
}

export interface AuditTemplateForPage {
  //TODO: wrong model, It needs to be redone according to the model that comes from Advance
  id: number;
  title: string;
  audits: Audit[];
}

export interface AuditTemplateForPanel {
  //TODO: wrong model, It needs to be redone according to the model that comes from Advance
  id: number;
  completedCount: number;
  draftCount: number;
  title: string;
  isActive: boolean;
  engagementId: number;
}

export interface Stakeholder {
  id: number;
  full_name: string | null;
  email?: string;
  phone?: string;
  role?: string;
  company_name?: string;
  invited_by?: string | null;
  access_level: number;
  from_advance: boolean;
}

export interface AuditInfo {
  id: string;
  percentage_score: number;
  title: string;
  created_at: string;
}

export interface Engagement {
  id: number;
  status: EngagementStatus;
  title: string;
  description: string;
  type_id: number;
  audit_count: number;
  client_id: number;
  client_name: string;
  client_partner_name: string | null;
  default_client_partner_name: string | null;
  project_lead_name: string;
  sales_name: string | null;
  brm_name: string | null;
  tags: string[];
  user_has_extra_access: boolean;
  without_project_lead: boolean;
  work_order_status_ext: string;
  work_orders: WorkOrder[];
  rag_statuses?: { id: number; name: string; level: 1 | 2 | 3 }[];
  portfolio_director_email: string;
  portfolio_director_name: string;
  header_tags?: any;
  feedback_project_count?: number;
  stakeholders?: Stakeholder[];
  audits_info?: AuditInfo[];
}

export interface FeedbackProject {
  id: number;
  client_id: number;
  title?: string;
  client_name?: string;
  project_lead_name?: string;
  client_partner_name?: string;
  default_client_partner_name?: string;
}

export interface FeedbackCompany {
  id: number;
  name?: string;
}

export type WorkOrder = {
  id: number;
  reference: string;
  start_date: Date;
  end_date: Date;
  renewal_requested_on: Date | null;
  member_assignments: Member[];
};

export interface MemberTag {
  id: string;
  name: string;
}

export interface Member {
  employee_id: number;
  employee_name: string;
  employee_photo_id: number | null;
  start_date: string;
  end_date: string;
  member_role: string | null;
  member_role_id: number;
  allocation_percentage: number;
  price_per_day?: number;
  tags?: MemberTag[];
}

export interface Risk {
  id: number;
  project_id: number;
  created_by: string;
  created_at: string;
  last_modified_at: string;
  description: string;
  risk_type: "External" | "Internal";
  risk_type_id: number;
  risk_state: string | null;
  risk_state_id: number;
  likelihood_status: string | null;
  likelihood_status_id: number;
  impact_status: string | null;
  impact_status_id: number;
  severity_status: string | null;
  severity_status_id: number;
  treatment_option: string | null;
  treatment_option_id: number;
  notes: string | null;
  reason_for_closing: string | null;
}

export interface EngagementMembers {
  id: number;
  project_id: number;
  name: string;
  role: string;
  allocation: string;
  start_date: Date;
  end_date: Date;
}

export interface GroupTeamsMembers {
  employee_id: number;
}

export interface GroupTeams {
  id: string;
  name: string;
  project_id: number;
  member_assignments: GroupTeamsMembers[];
}

export enum EngagementStatus {
  SETTING_UP = "Setting up",
  ONBOARDING = "Onboarding",
  ACTIVE = "Active",
  COMPLETED = "Completed",
}

export interface Survey {
  aphiTemplateId: number;
  aphiTemplateTitle: string;
  badScore: number;
  createdAt: string;
  createdBy: string;
  entityId: number;
  entityName: string;
  id: number;
  isCompleted: boolean;
  notes: string | null;
  percentageScore: number;
  score: number;
  shortTitle: string;
  sections: Section[];
}

export interface Section {
  auditType: string;
  badScore: number;
  description: string;
  id: number;
  percentageScore: number;
  score: number;
  value: string;
  answeredQuestions: AnsweredQuestion[];
}

export interface AnsweredQuestion {
  isInputType: boolean;
  options: QuestionOption[];
  questionId: number;
  questionOrder: number;
  questionTitle: string;
  sectionId: number;
  totalScore: number;
}

export interface QuestionOption {
  optionId: null | number;
  questionId: number;
  risks: string[];
  score: number;
  value: string;
}

export interface User {
  client_id: number;
  email: string | null;
  first_name: string | null;
  id: string;
  is_active: boolean;
  is_superuser: boolean;
  is_verified: boolean;
  is_stakeholder: boolean;
  last_name: string | null;
  role: string | null;
  job_title: string | null;
  login_method: string;
  project_ids?: Array<any> | null;
  access_level: number;
}

export interface FeedbackData {
  id: string;
  provider_full_name: string;
  provider_role: string;
  date: string;
  feedback: string;
}

export interface FeedbackFormInput {
  data: string;
}

export interface ReleaseNote {
  id: string;
  created_by: string;
  release_date: string;
  note_content: string;
}

export interface NoteFormInput {
  release_date: string;
  note_content: string;
}

export interface UrlParams {
  id: string;
  employee_id: string;
}

export interface RequestContent {
  id?: string;
  member_role: string;
  primary_skill: string;
  member_type_quantity: number;
  member_start_date: string;
  member_duration: string;
}

export interface RequestTeam {
  id: string;
  is_new: boolean;
  requested_by: string;
  project_id: number;
  request_date: string;
  request_content: RequestContent[];
  request_comment: string;
}

export interface UploadedFile {
  id: string;
  file_name: string;
  category: string;
  added_on: Date;
  expire_on: Date;
  uploaded_by: string;
  owner_email: string;
  password_hash: string | null;
  archived: boolean;
  archived_on: Date;
}

export interface FileWithExpireOnStatus extends UploadedFile {
  expireOnStatus: string;
}

export interface GroupTeamWithTags extends GroupTeams {
  member_assignments: Member[];
}

export const enum UserAccessLevels {
  AccessLevel_1 = 1,
  AccessLevel_2 = 2,
  AccessLevel_3 = 3,
  AccessLevel_4 = 4,
}

interface MapUserAccessLevels {
  [key: number]: string;
}

export const mapUserAccessLevels: MapUserAccessLevels = {
  1: "ADVANCED",
  2: "STANDARD",
};

export enum UserLoginMethod {
  external = "external",
  internal = "internal",
}

export type FormStatus = "success" | "error" | "sending" | "idle";
export type ButtonCategory = "text" | "icon";
export type ButtonColorVariant = "black" | "white";
export type DrawerDisplayType = "collapse" | "normal";
export type Nullable<T> = T | null | undefined;
export type VoidFunc<T = void> = (_: T) => void;
export type AnyPropsComponent = (props: { [propname: string]: any }) => JSX.Element;
