// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  menuItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  filterPopover: {
    "& .MuiPopover-paper ": {
      overflowY: "hidden",
      overflowX: "hidden",
      width: "300px",
      [theme.breakpoints.down("xs")]: {
        width: "220px",
      },
    },
  },
  menuList: {
    overflowY: "auto",
    maxHeight: 320,
  },
  listItemText: {
    maxWidth: "210px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "140px",
    },
  },
  filterButtonsBox: {
    borderTop: "1px solid #e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    "& button": {
      "& span": {
        textTransform: "unset",
      },
      textTransform: "unset",
    },
  },

  filterButton: {
    [theme.breakpoints.down("xs")]: {
      width: "45%",
    },
  },
}));

export default useStyles;
