// external lib dependencies
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { FC, useEffect } from "react";

// absolute path dependencies
import { Member } from "config/types";

// local dependencies
import useEditHandler from "./useEditHandler";
import useStyles from "../styles";
import Alert from "@material-ui/lab/Alert";

interface Group {
  id: string;
  name: string;
  member_assignments: object[];
  project_id: any;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  item: Member;
  group: Group;
  groupTeams: object[];
  setGroupTeams: any;
  projectId: string | undefined;
}

const DeleteMemberPopup: FC<Props> = ({ open, handleClose, item, group, groupTeams, setGroupTeams, projectId }) => {
  const generalClasses = useStyles();
  const { callback, loading, error, isSuccess } = useEditHandler();
  const [teamMembersAfterDelete, setTeamMembersAfterDelete] = React.useState<any[]>([]);

  const handleDelete = () => {
    const teamMembersGroup = group.member_assignments.filter((value: any) => value.employee_id !== item.employee_id);
    setTeamMembersAfterDelete(teamMembersGroup);
    callback(projectId, group.id, teamMembersGroup);
  };

  useEffect(() => {
    if (isSuccess) {
      setGroupTeams(
        groupTeams.map((value: any) => {
          if (value.id === group.id) {
            return { ...value, member_assignments: teamMembersAfterDelete };
          }
          return { ...value };
        })
      );
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Dialog className={generalClasses.dialogModal} open={open} onClose={handleClose}>
      <DialogTitle className={generalClasses.dialogTitle}>{"Remove Team Member"}</DialogTitle>
      {error && <Alert severity="error">{error}</Alert>}
      <DialogContent>
        <DialogContentText className={generalClasses.dialogContentText} id="alert-dialog-description">
        {`Are you sure you want to remove "${item?.employee_name}" from team "${group?.name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={generalClasses.dialogActionBlock}>
        <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleDelete} disabled={loading}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMemberPopup;
