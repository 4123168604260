// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";

// absolute path dependencies
import BackgroundImg from "assets/images/background.svg";

const useStyles = makeStyles((theme: Theme) => ({
  // common styles
  loginFormInput: {
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: 0,
    color: theme.palette.common.white,
    "&::placeholder": {
      color: theme.palette.common.white,
    },
  },

  errorMsg: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    color: theme.palette.common.white,
    width: theme.spacing(25),
    textAlign: "center",
  },

  // AccessCodeForm styles
  accessCodeForm: {
    display: "flex",
    width: theme.spacing(43.75),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  enterCodeMsg: {
    width: theme.spacing(43.75),
    marginBottom: 5,
    color: theme.palette.common.white,
  },

  enterCodeMsgXS: {
    width: theme.spacing(43.75),
    marginBottom: 5,
    color: theme.palette.common.white,
    textAlign: "center",
  },

  resendAccessCodeBtn: {
    width: "100%",
    marginTop: theme.spacing(1.25),
  },

  successfullyResendCodeMsg: {
    textAlign: "center",
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
  },

  // EmailForm styles
  loginFormContainer: {
    width: theme.spacing(43.75),
    display: "inline-table",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(32.5),
    },
  },

  loginBtn: {
    width: "100%",
    marginTop: theme.spacing(1.25),
  },

  // LoginPage styles
  container: {
    minHeight: "100vh",
    maxWidth: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: `calc(${theme.spacing(1.25)} + 2vmin)`,
    overflow: "hidden",
  },

  backgroundImage: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  logoMiniLoginPage: {
    position: "absolute",
    left: theme.spacing(2.5),
    top: theme.spacing(2.5),
  },

  logoLoginPage: {
    maxWidth: "85%",
    marginBottom: theme.spacing(12.5),
  },

  logoLoginPageXS: {
    maxWidth: "85%",
    marginBottom: theme.spacing(5),
    minWidth: theme.spacing(40),
  },
  checkboxInput: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(1.25),
  },
  expiredTitle: {
    color: "#fff",
  },

  teamMemberBtn: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(1.25),
    textTransform: "none",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default useStyles;
