import { Box, Button, Checkbox, MenuItem, MenuList, Popover, Typography } from "@material-ui/core";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import useStyles from "./styles";
import {
  CheckedFilters,
  FeedbacksState,
  FilterBy,
  IndividualFeedback,
  IndividualFeedbacksFilters,
} from "pages/PeopleFeedback/types";
import { getIndividualFeedbacks } from "services/peopleFeedbackService";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import SearchBar from "components/SearchBar/SearchBar";
import { useFilterByTerm } from "hooks/useFilterByTerm";

interface FilterBoxProps {
  feedbacksState: FeedbacksState;
  checkedFilters: CheckedFilters;
  setCheckedFilters: Dispatch<SetStateAction<CheckedFilters>>;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  filters: IndividualFeedbacksFilters | null;
  filterBy: FilterBy;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setHasMore: Dispatch<SetStateAction<boolean>>;
  setFeedbacksState: Dispatch<SetStateAction<FeedbacksState>>;
  setIndividualFeedbacks: Dispatch<SetStateAction<IndividualFeedback[]>>;
  scrollbarRef: any;
}

const FilterBox = ({
  checkedFilters,
  setCheckedFilters,
  open,
  anchorEl,
  handleClose,
  filters,
  filterBy,
  setIndividualFeedbacks,
  setFeedbacksState,
  feedbacksState,
  setHasMore,
  pageNumber,
  setPageNumber,
  scrollbarRef,
}: FilterBoxProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const classes = useStyles();

  const resetScrollbarPosition = () => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTop = 0;
      scrollbarRef.current.scrollLeft = 0;
    }
  };

  const handleCheck = (event: any, value: string) => {
    if (!checkedFilters[filterBy].includes(value)) {
      setCheckedFilters((prev) => ({
        ...prev,
        [filterBy]: [...prev[filterBy], value],
      }));
    }

    if (checkedFilters[filterBy].includes(value)) {
      setCheckedFilters((prev) => ({
        ...prev,
        [filterBy]: prev[filterBy].filter((item) => item !== value),
      }));
    }
  };

  const handleClearFilters = async () => {
    setCheckedFilters({
      name_filters: [],
      project_name: [],
      company_name: [],
    });

    if (pageNumber !== 1) {
      setPageNumber(1);
      setIndividualFeedbacks([]);
      return;
    }

    setFeedbacksState("loading");
    setIndividualFeedbacks([]);
    try {
      const result = (await getIndividualFeedbacks(1)) as IndividualFeedback[];

      batchedUpdates(() => {
        setIndividualFeedbacks(result);
        setHasMore(result.length > 9);
        setFeedbacksState("success");
      });
      resetScrollbarPosition();
    } catch (error) {
      setFeedbacksState("error");
    }
  };

  const handleApplyFilters = async () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
      setIndividualFeedbacks([]);
      return;
    }

    setFeedbacksState("loading");
    setIndividualFeedbacks([]);
    try {
      const result = (await getIndividualFeedbacks(
        1,
        undefined,
        checkedFilters.name_filters,
        checkedFilters.project_name,
        checkedFilters.company_name,
      )) as IndividualFeedback[];

      batchedUpdates(() => {
        setIndividualFeedbacks(result);
        setHasMore(result.length > 9);
        setFeedbacksState("success");
      });
      resetScrollbarPosition();
    } catch (error) {
      setFeedbacksState("error");
    }
  };

  const filteredBySearchTerms = useFilterByTerm<string>(filters ? filters[filterBy] : [], searchValue);

  const handleSearchBar = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value.toLowerCase());
  };

  return (
    <Popover
      className={classes.filterPopover}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <SearchBar
        textFieldProps={{
          placeholder: "Search",
          onChange: (e) => handleSearchBar(e),
        }}
        boxProps={{
          className: classes.searchBarContainer,
        }}
      />
      <MenuList className={classes.filterList}>
        {filteredBySearchTerms.map((filterItem, index) => {
          return (
            <MenuItem dense key={index} onClick={(e) => handleCheck(e, filterItem)}>
              <Checkbox checked={checkedFilters[filterBy].includes(filterItem)} color="primary" />
              <Typography variant="inherit" noWrap>
                {filterItem}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
      <Box className={classes.filterButtonsBox}>
        <Button
          disabled={feedbacksState === "loading" || checkedFilters[filterBy].length === 0}
          className={classes.filterButton}
          variant={"contained"}
          color="secondary"
          onClick={handleClearFilters}
        >
          Clear filter
        </Button>
        <Button
          disabled={feedbacksState === "loading" || checkedFilters[filterBy].length === 0}
          className={classes.filterButton}
          variant={"contained"}
          color="primary"
          onClick={handleApplyFilters}
        >
          Apply filter
        </Button>
      </Box>
    </Popover>
  );
};

export default FilterBox;
