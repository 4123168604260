import { FeedbackFormInput } from "config/types";
import { FetchData } from "./FetchData";

export async function submitMemberFeedback(feedback: FeedbackFormInput, project_id: string, employee_id: string) {
    return await new FetchData(`/feedback/individual-feedback`).POST({ feedback: feedback.data, project_id, employee_id })
}

export async function getIndividualFeedback(projectId: string, employeeId: string, page: number) {
    return await new FetchData(`/feedback/individual-feedback/${projectId}/${employeeId}?page_number=${page}&items_per_page=5`).GET();
}
