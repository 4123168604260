// external lib dependencies
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
      "& .MuiTableCell-head": {
        fontSize: "1rem",
        color: "black",
        fontWeight: 700,
      },
      "& .MuiTableCell-body": {
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "19px",
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
        paddingLeft: "unset"
      }
    },
    button: {
      backgroundColor: `rgba(0, 0, 0, 0.79)`,
      color: "white",
      width: "18.75rem",
      height: "2.5rem",

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        width: "16rem",
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
        width: "9rem",
        height: "2.75rem"
      },

      "&:hover": {
        backgroundColor: `rgba(0, 0, 0, 0.79)`,
      }
    },
  })
);

export default useStyles;
