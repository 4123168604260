import { FeedbackFormInput } from "config/types";
import { useState } from "react";
import { submitMemberFeedback } from "services/memberFeedback";

export const useSubmitHandler = () => {
    const [error, setError] = useState<string>("");

    const errorMessage: string = "Error while providing feedback";

    async function submitFeedback(data: FeedbackFormInput, project_id: string,
        employee_id: string) {
        try {
            const response = await submitMemberFeedback(data, project_id, employee_id);
            return response;

        } catch (e: any) {
            setError(errorMessage);
        }
    }

    return { submitFeedback, error }
}
