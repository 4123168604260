import { User } from "config/types";

export function canEditTeamsAndTags(user: User | null, projectId: number): boolean {
  if (
    (user?.is_stakeholder && user.project_ids?.includes(projectId)) ||
    (user?.job_title && ["Delivery Lead", "Client Partner", "Portfolio Director"].includes(user.job_title))
  ) {
    return true;
  } else {
    return false;
  }
}
