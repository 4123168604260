import { useCallback, useRef } from "react"


function useDebounce(callback: any, delay?: number){
    const timer = useRef<any>(null)

    const debouncedCallback = useCallback( (...args) =>{
        if(timer.current){
            clearTimeout(timer.current)
        }

        timer.current = setTimeout( () =>{
            callback(...args)
        }, delay || 500)

    }, [callback, delay])

    return debouncedCallback
}


export default useDebounce