// external lib dependencies
import {useState, useCallback} from 'react'

// absolute path dependencies
import {getProfile} from 'services/profile'


const useFetchProfileData = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [data, setData] = useState<any>();
    const fetch = useCallback(async (projectId: string, employeeId: string) => {
        setData(undefined)
        const errorMessage = "Well this is embarrassing... We couldn\'t take the data. Can you please try again later ?"
        try {
            const fetch = await getProfile(projectId, employeeId)
            const response = await fetch.json()

            if (!fetch.ok) {
                throw new Error(response.detail ?? errorMessage);
            }

            if (response) {
                setData(response)
                setLoading(false);
            }
        } catch {
            setError(errorMessage);
        } finally {
            setLoading(false)
        }
    }, []);

    return {fetch, loading, error, data}
}

export default useFetchProfileData;
