// external lib dependencies
import { List, ListItem} from "@material-ui/core";
import { useMemo } from "react";
// absolute path dependencies
import useScreenSize from "hooks/useScreenSize";
// local dependencies
import { useMonthGridStyles } from "./styles";

const MonthGrid = () => {
  const classes = useMonthGridStyles();
  const { isSM } = useScreenSize();

  const months: string[] = useMemo(() => {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  }, []);
  if (!isSM) {
    return (
      <List className={classes.monthGridContainter} data-testid="monthGrid">
        {months.map((month: string, index: number) => (
          <ListItem key={index} className={classes.gridColumn}>
            {/* <Typography variant="body2" className={classes.headingText} data-testid="month">
              {month}
            </Typography> */}
          </ListItem>
        ))}
      </List>
    );
  } else return null;
};

export default MonthGrid;
