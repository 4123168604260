// external lib dependencies
import { Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      marginTop: theme.spacing(7.5),
      marginBottom: theme.spacing(10),
     
    },

    paper:{
        padding: theme.spacing(3),
        display:'flex',
        flexDirection:'row',
        justifyContent: 'center',
    },

    sadIcon:{
        height:theme.spacing(8),
        width:theme.spacing(8),
        marginRight: theme.spacing(10)
    },

    textBox:{
        textAlign: 'left',

    }
  })
);

export default useStyles;
