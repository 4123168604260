import { TableCell, TableRow, IconButton, Collapse, Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import React from "react";
import { FC, useState } from "react";
import useStyles from "./styles";
import { IndividualFeedback } from "pages/PeopleFeedback/types";
import { formatDateMDY } from "helpers/datetime";

interface RowProps {
  row: IndividualFeedback;
  elementRef?: any;
}

const Row: FC<RowProps> = ({ row, elementRef }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <React.Fragment>
      <TableRow className={classes.tableRow} ref={elementRef}>
        <TableCell className={classes.tableCell}>{row.full_name}</TableCell>
        <TableCell className={classes.tableCell}>{row.provider_full_name}</TableCell>
        <TableCell className={classes.tableCell}>{row.project_name}</TableCell>
        <TableCell className={classes.tableCell}>{row.company_name}</TableCell>
        <TableCell className={classes.tableCell}>{formatDateMDY(row.date)}</TableCell>
        <TableCell align="center" className={classes.tableCell}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.feedbackText}>
              <Typography align="justify" variant="body2">
                {row.feedback}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
