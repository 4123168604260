import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

interface Props {
  inputValue?: string;
  setInputValue?: any;
  disabled?: boolean;
}

const SearchField = ({ inputValue, setInputValue, disabled }: Props) => {
  return (
    <TextField
      fullWidth={true}
      disabled={disabled}
      variant="outlined"
      size="small"
      placeholder="Search..."
      onChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
