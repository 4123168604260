import { ITeamFeedbackFormState } from "pages/Team/types";
import { FetchData } from "./FetchData";

export async function createGroupTeam(data: object, projectId: string) {
  return await fetch(`/api/projects/${projectId}/group-teams`, {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export async function getGroupTeamsByEngagementId(projectId: string | undefined) {
  return await fetch(`/api/projects/${projectId}/group-teams`);
}

export async function deleteGroupTeam(projectId: string | undefined, groupId: string | undefined) {
  return await fetch(`/api/projects/${projectId}/group-teams/${groupId}`, {
    method: "DELETE",
  });
}

export async function editGroupTeam(
  projectId: string | undefined,
  groupId: string | undefined,
  data: object[],
  name: string,
) {
  return await fetch(`/api/projects/${projectId}/group-teams/${groupId}?name=${name}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
}

export async function createGroupTeamFeedback(data: ITeamFeedbackFormState, signal?: AbortSignal) {
  return await new FetchData("/feedback/team_feedback/").POST(data, signal);
}
