// external lib dependencies
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { useCallback } from "react";
import { Link } from "react-router-dom";

// absolute path dependencies
import Button from "components/Button";
import StatusChip from "components/StatusChip";
import { AuditInfo, Engagement } from "config/types";
import { formatDateMDY } from "helpers/datetime";
import useScreenSize from "hooks/useScreenSize";
import useTeamMembers from "pages/Engagement/components/TeamSection/hooks/useTeamMembers";
import useBarPercent from "pages/WorkOrders/hooks/useBarPercent";

// local dependencies
import PercentageScore from "./PercentageScore/PercentageScore";
import useStyles from "./styles";
import { useAppSelector } from "store/hook";

interface EngagementCardProps {
  engagement: Engagement;
}

const EngagementCard = ({ engagement }: EngagementCardProps) => {
  const classes = useStyles();
  const getUniqueMemberList = useTeamMembers();
  const { id, title, status, client_name, work_orders } = engagement;
  const { isSM } = useScreenSize();
  const { getTimePassedPercent } = useBarPercent();
  const { user } = useAppSelector((state) => state.user);

  function checkStakeholderStatus() {
    if (!user) return true;
    return user?.is_stakeholder ? true : false;
  }

  const getMembersCount = useCallback(() => {
    const allMembersFromAllWorkOrders = work_orders.map((wo) => wo.member_assignments).flat();
    return getUniqueMemberList(allMembersFromAllWorkOrders).length;
  }, [work_orders, getUniqueMemberList]);

  const activeWO = work_orders.filter(
    (wo: any) => getTimePassedPercent(new Date(wo.start_date), new Date(wo.end_date)) < 100,
  );

  return (
    <Card className={classes.engagementCard} elevation={0}>
      <CardContent className={classes.cardContent}>
        <Box display="flex" justifyContent="space-between" flexDirection={isSM ? "column" : "row"}>
          <Box className={classes.engagementCardLeftSide}>
            <Box className={classes.projectDetails}>
              <StatusChip status={status} />
              <Box>
                <Typography variant="h6" className="overflowText" title={title}>
                  {title}
                </Typography>
                <Typography variant="subtitle2">Company name: {client_name}</Typography>
              </Box>
            </Box>
            <Box className={classes.cardActions}>
              <Link to={`/engagements/${id}/files`} className={classes.linkBtn}>
                <Button colorVariant="black" className={classes.engagementCardButton}>
                  File Sharing
                </Button>
              </Link>
              {checkStakeholderStatus() && (
                <Link to={`/feedback/${id}`} className={classes.linkBtn}>
                  <Button colorVariant="black" className={classes.engagementCardButton}>
                    Service Feedback
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
          <Box className={classes.engagementCardRightSide}>
            <Box className={classes.hoverFlexboxDetails}>
              <Link to={`/engagements/${id}/team`} className={classes.hoverBoxLink}>
                <Typography variant="h6" className={classes.hoverBoxText}>
                  {getMembersCount()}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Team Members
                </Typography>
              </Link>
              <Link to={`/work-orders?project=${title}`} className={classes.hoverBoxLink}>
                <Typography variant="h6" className={classes.hoverBoxText}>
                  {activeWO.length}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Active Work Orders
                </Typography>
              </Link>
              <Link to={`/view-feedback/${id}`} className={classes.hoverBoxLink}>
                <Typography variant="h6" className={classes.hoverBoxText}>
                  {engagement.feedback_project_count}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Received Feedback
                </Typography>
              </Link>

              <Link to={`/engagements/${id}/stakeholders`} className={classes.hoverBoxLink}>
                <Typography variant="h6" className={classes.hoverBoxText}>
                  {engagement.stakeholders?.length}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  Stakeholders
                </Typography>
              </Link>
            </Box>
            <Box className={classes.hoverFlexboxAudits}>
              {engagement.audits_info &&
                (engagement.audits_info.length > 0 ? (
                  engagement.audits_info.map((audit: AuditInfo) => (
                    <Link to={`/engagements/${id}/audits/${audit.id}`} className={classes.hoverBoxLink} key={audit.id}>
                      <PercentageScore value={audit.percentage_score} />
                      <Typography variant="body2" color="textSecondary" className={classes.auditText}>
                        {audit.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatDateMDY(audit.created_at)}
                      </Typography>
                    </Link>
                  ))
                ) : (
                  <Box className={classes.noAuditsBox}>
                    <WarningRoundedIcon />
                    <Typography variant="body2" color="textSecondary" className={classes.noAuditsText}>
                      There are no audits provided for this project
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EngagementCard;
