import SectionTitleBar from "components/SectionTitleBar";
import { Container } from "@material-ui/core";

import useStyles from "./styles";
import TabsComponent from "./componetns/Tabs";

const PeopleFeedback = () => {
  const classes = useStyles();

  return (
    <>
      <SectionTitleBar title={"People Feedback"} peopleFeedbackPage={false} />
      <Container maxWidth="xl" className={classes.container}>
        <TabsComponent />
      </Container>
    </>
  );
};

export default PeopleFeedback;
